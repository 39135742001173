import { action } from "mobx";
import { PAYMENTPAGE } from "npmlinks-constants";
import { getCardDetailsByCardNum } from "./services";
import { validCvvLength } from "./../../utils";

class CardActions {
  @action
  saveCreditCard() {
    this.userEnteredCardData.isSavedCard = !this.userEnteredCardData
      .isSavedCard;
  }
  @action
  setCreditCard(cardNumber, cardHolderName, cvv, expMonth, expYear) {
    this.cardInfoEntered = !(
      !cardNumber ||
      !cardHolderName ||
      !cvv ||
      !expMonth ||
      !expYear
    );
    this.userEnteredCardData = Object.assign(
      {},
      {
        cardNumber,
        cardHolderName,
        cvv,
        expMonth,
        expYear,
        isSavedCard: this.userEnteredCardData.isSavedCard,
      },
    );
  }

  /**
   * if someone enter the wrong value or form got invalidate then reset the card
   */
  @action
  resetCreditCard() {
    this.cardInfoEntered = false;
    // console.log(" resetCreditCard click");
    this.userEnteredCardData = {
      cardNumber: null,
      cardHolderName: null,
      cvv: null,
      expMonth: null,
      expYear: null,
      isSavedCard: this.userEnteredCardData.isSavedCard,
    };
  }

  /**
   * after getting proper response from makepaymentv2 api we have to store this info so that we can submit this info to payu
   * @param successData
   */
  @action
  setSuccessFlow(successData) {
    this.success = successData;
  }

  /**
   * based on 6 digits of card we can make api call and get bin info
   * @param binNumber
   * @returns {Promise}
   */
  @action
  getCardTypeInfo(binNumber) {
    return new Promise((resolve, reject) => {
      getCardDetailsByCardNum(binNumber).then(
        action(res => {
          const { paymentTypeInfo: { bankCode } } = res;
          this.binInfo = {
            cvvLength: validCvvLength(bankCode && bankCode.toUpperCase()),
            cardInfo: res,
          };
          this.binNumber = res.binNumber;
          resolve(this.binInfo);
        }),
        action(err => {
          reject(err);
        }),
      );
    });
  }

  @action
  resetBinNumber(){
    this.binNumber = null;
  }

  /**
   * if card number input got invalidated then do this action
   */
  @action
  resetCardTypeInfo() {
    this.binInfo = {
      cvvLength: PAYMENTPAGE.CARD.CVVLENGTH.DEFAULT,
      cardInfo: null,
    };
  }

  @action
  setCardData(CREDIT_CARD, DEBIT_CARD, eligiblePGs) {
    this.cardData = Object.assign({}, {CREDIT_CARD, DEBIT_CARD, ...eligiblePGs});
  }
}

export default CardActions;
