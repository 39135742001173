import { action } from "mobx";
import fetchBrandsList from "./service";
import { sortArrayOfStrings, getBrandsListFromFiltersAPI } from "../../utils";

class StoreLocatorStoreActions {
  @action
  fetchBrands() {
    return new Promise((resolve, reject) => {
      fetchBrandsList().then(
        response => {
          this.brandsList = sortArrayOfStrings(
            getBrandsListFromFiltersAPI(response),
          );
          resolve(this.brandsList);
        },
        error => {
          this.brandsList = [];
          reject(error);
        },
      );
    });
  }
}

export default StoreLocatorStoreActions;
