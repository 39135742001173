import { observable, computed } from "mobx";
import CodStoreActions from "./actions";
import { PAYMENTPAGE } from "npmlinks-constants";

class CodStoreClass extends CodStoreActions {
  constructor() {
    super();
    this.otpClientID = process.env.REACT_APP_OTP_CLIENT_ID;
    this.captchaToken = "";
    this.isCodVerified = false;
  }
  @computed
  get paymentRequest() {
    return {
      paymentDetail: {
        description: PAYMENTPAGE.ORDER_REQUEST.CASH_ON_DELIVERY,
        saveCard: null,
        savedCardPG: null,
        savedCardToken: null,
      },
      paymentTypeCode: PAYMENTPAGE.ORDER_REQUEST.CASH_ON_DELIVERY,
    };
  }
}

export default CodStoreClass;
