import { action } from "mobx";
import {
  addAddressService,
  pincodeDetailsData,
  removeAddress,
  setAddressDefault,
  userAddressesData,
  editAddress
} from "./services";
import { setLastUsedPincode } from "../../utils";
import GTM from "../../analytics/GTM";
import GTMEvent from "../../analytics/eventFactory/GTMEvents";

class AddressStoreActions {
  @action
  setCurrentPincode(pincode) {
    setLastUsedPincode(pincode);
    this.currentPincode = pincode;
  }
  @action
  setSavedAddressModal(value, heading) {
    this.isSavedAddressOpen = value;
    this.addressBlockHeading = heading;
  }

  setAddAddressModal(value, heading, buttonText, continueToCheckout) {
    this.isAddAddressOpen = value;
    this.addAddressHeading = heading;
    this.addAddressButtonText = buttonText;
    this.continueToCheckout = continueToCheckout || false;
  }

  @action
  showPincodeScreen(value) {
    this.isPincodeScreenOpen = value;
  }

  @action
  setPincodeDetails(data) {
    const modifiedData = data[0];
    this.detailsOfPincode = {
      city: modifiedData.pincode_city.name,
      state: modifiedData.pincode_state.name
    };
  }

  @action
  setMobileNumberVerified() {
    this.mobileNumberVerified = true;
  }

  @action
  getUserAddresses(flag, itemId = null) {
    return new Promise((resolve, reject) => {
      const returnFlag = flag ? { retundaddresscheck: true } : {};
      const itemIdParam = itemId && `?itemid=${itemId}`;
      userAddressesData(returnFlag, itemIdParam)
        .then(res => {
          this.userAddresses = res.data;
          resolve(res.data);
        })
        .catch(err => {
          this.userAddresses = [];
          reject(err);
        });
    });
  }

  @action
  addAddressCall(
    addAddressDetails,
    addToAddresses,
    servicibilityCheck = false,
    itemId = null
  ) {
    this.addressloader = true;
    const itemIdParam = itemId && `?itemid=${itemId}`;
    return new Promise((resolve, reject) => {
      addAddressService(
        JSON.stringify(addAddressDetails),
        servicibilityCheck,
        itemIdParam
      )
        .then(res => {
          this.addressloader = false;
          if (addToAddresses) {
            Object.keys(res).forEach(key => {
              const newAddress = res[key];
              newAddress.id = key;
              let currentUserAddresses = this.userAddresses;
              if (newAddress.isDefault) {
                currentUserAddresses = currentUserAddresses.map(address => {
                  const retAddress = address;
                  retAddress.isDefault = false;
                  return retAddress;
                });
              }
              this.userAddresses = [newAddress, ...currentUserAddresses];
            });
            GTM.pushWithEvent(addAddressDetails, GTMEvent.ADD_ADDRESS);
          }
          resolve(res);
        })
        .catch(error => {
          this.addressloader = false;
          reject(error);
        });
    });
  }

  @action
  editAddress(
    addAddressDetails,
    addressId,
    servicibilityCheck = false,
    itemId = null
  ) {
    this.addressloader = true;
    let addressIdItemIdParam = itemId
      ? `${addressId}?itemid=${itemId}`
      : addressId;
    return new Promise((resolve, reject) => {
      const body = JSON.stringify(addAddressDetails);
      editAddress(body, addressIdItemIdParam, servicibilityCheck)
        .then(res => {
          this.addressloader = false;
          const updatedAddress = Object.values(res)[0];
          if (!updatedAddress.id) {
            updatedAddress.id = Object.keys(res)[0];
          }
          const index = this.userAddresses.findIndex(
            address => address.id === addressId,
          );
          if (index > -1) {
            this.userAddresses.splice(index, 1);
            this.userAddresses = [updatedAddress, ...this.userAddresses];
          }
          resolve(res);
        })
        .catch(error => {
          this.addressloader = false;
          reject(error);
        });
    });
  }

  @action
  getPincodeDetails(pincode) {
    this.addressloader = true;
    return new Promise((resolve, reject) => {
      pincodeDetailsData(pincode)
        .then(res => {
          this.addressloader = false;
          resolve(res.content);
        })
        .catch(err => {
          this.addressloader = false;
          reject(err);
        });
    });
  }

  @action
  removeAddress(addressId) {
    this.addressloader = true;
    return new Promise((resolve, reject) => {
      removeAddress(addressId)
        .then(response => {
          this.addressloader = false;
          resolve(response);
        })
        .catch(error => {
          this.addressloader = false;
          reject(error);
        });
    });
  }

  @action
  setAddressDefault(addressId) {
    this.addressloader = true;
    return new Promise((resolve, reject) => {
      setAddressDefault(addressId)
        .then(response => {
          this.addressloader = false;
          resolve(response);
        })
        .catch(error => {
          this.addressloader = false;
          reject(error);
        });
    });
  }
}

export default AddressStoreActions;