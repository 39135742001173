import { action } from "mobx";

class SavedStoreActions {
  @action
  setSavedCardCvv(cvvData) {
    this.cvvData = cvvData;
  }

  @action
  setSelectedSavedCardToken(selectedSavedCard) {
    let selectedSavedCardTemp = Object.assign({}, selectedSavedCard);
    if (!selectedSavedCard) {
      [selectedSavedCardTemp = {}] =
        this.savedCards &&
        this.savedCards.length &&
        this.savedCards.filter(card => card.isDefault);
    }
    const { cardToken } = selectedSavedCardTemp;
    this.selectedSavedCardToken = cardToken;
  }

  /**
   * if saved card is there in api response set the values
   * @param paymentOptionsResponse
   */
  @action
  initSavedCard(paymentOptionsResponse) {
    const { savedCards } = paymentOptionsResponse;
    this.savedCards = Object.assign([], this.savedCards, savedCards);
    this.setSelectedSavedCardToken();
  }

  /**
   * after getting proper response from makepaymentv2 api we have to store this info so that we can submit this info to payu
   * @param successData
   */
  @action
  setSuccessFlow(successData) {
    this.success = Object.assign({}, this.success, successData);
  }

@action
resetSavedCard(){
  this.savedCards = [];

}
}

export default SavedStoreActions;
