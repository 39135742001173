const ORDER_DETAILS_MESSAGES = {
  ERROR_MESSAGE: "Oops! Something went wrong.",
};

const MY_ORDERS = {
  BUTTON_TEXT: "view details",
};

const ORDER_DETAILS = {
  ORDERDETAILS_FOOTER: {
    MESSAGE: "You need some help?",
    CONTACT_US: "CONTACT US",
  },
  CURRENCY: "₹",
  CAROUSEL_SIZE: {
    XS: 1,
    SM: 3,
    MD: 3,
    LG: 3,
  },
  APP_BREAKPOINTS: {
    XS: 768,
    SM: 992,
    MD: 1200,
  },
  PRICE_OVERVIEW_TITLE: "PRICE DETAILS",
  DELIVERY_STATUS_TITLE: "STATUS",
  TRACKING_TITLE: "TRACKING NUMBER",
  DELIVERY_ADDRESS_TITLE: "DELIVERY ADDRESS",
  STORE_PICK: "Store Pickup",
  TRACKING_LINK: "TRACK IT",
  ORDER_SUMMARY: {
    TITLE: "Order Summary",
    ORDERED_ON: "ORDERED ON",
    STATUS: "STATUS",
    ORDER_NUMBER: "ORDER NUMBER",
    PRODUCTS: "PRODUCTS",
    PAYMENT_METHOD: "PAYMENT METHOD",
    PAYMENT_STATUS: "PAYMENT STATUS"
  },
};

const RETURN_ELIGIBLE = "RETURN";
const CANCEL_ELIGIBLE = "CANCEL";
const EXCHANGE_ELIGIBLE = "EXCHANGE";

const CARTPRODUCTS = {
  SIZE_TEXT: "Size",
  QTY_TEXT: "Qty",
  BRAND_SIZE: "Brand Size",
  COLOR_TEXT: "Color",
};
const EXCHANGE_INFO = {
  RETURN_EXCHANGE_INFO_CTA_TEXT:"Received your order already? Would you like to return?",
  FAQ_EXCHANGE_LINK: "https://www.nnnow.com/customercare#returnsexchanges",
  DELIVERY_MARK_TEXT: "Please note that it takes upto 24 hours for the order to be marked as delivered in our systems.",
  DELIVERY_INITIATE_TEXT: "Rest assured that the return window begins only once the order is marked as delivered.",
  KNOW_MORE_LINK_TEXT: " Know more about Returns policy",
}
export {
  MY_ORDERS,
  ORDER_DETAILS,
  ORDER_DETAILS_MESSAGES,
  RETURN_ELIGIBLE,
  CANCEL_ELIGIBLE,
  EXCHANGE_ELIGIBLE,
  CARTPRODUCTS,
  EXCHANGE_INFO,
};
