import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { readCookie } from "../../utils";
import MyAccountLink from "../MyAccountLink";
import LogoutButton from "../LogoutButton";
import TrackOrderLink from "../TrackOrderLink";
import { GTM } from "../../analytics";
import GTMEvent from "../../analytics/eventFactory/GTMEvents";

@inject("LoginStore")
@observer
class LoggedInMenu extends PureComponent {
  constructor() {
    super();

    this.state = {
      isDowpdownActive: false
    };

    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.triggerGTM = this.triggerGTM.bind(this);
    this.ISDropDownOpened = this.ISDropDownOpened.bind(this);
  }

  get dropdownActiveClassName() {
    return this.state.isDowpdownActive ? "" : "nwc-hide";
  }

  get dropdownInActiveClassName() {
    return !this.state.isDowpdownActive ? "" : "nwc-hide";
  }

  get firstName() {
    const firstName = this.props.LoginStore.firstName;
    return readCookie("userName") || firstName;
  }

  triggerGTM() {
    GTM.pushEvent(GTMEvent.MYACCOUNT_CLICK);
  }

  showDropdown() {
    this.setState({
      isDowpdownActive: true
    });
  }

  hideDropdown() {
    this.setState({
      isDowpdownActive: false
    });
  }

  ISDropDownOpened(){

    if (this.state.isDowpdownActive === false){

      this.showDropdown();
      
    }
    else{
      this.hideDropdown();
      
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div
        className={`nwc-btn-primary-txt
        nw-mybagbutton nw-loggedinmenu ${className}`}
        onMouseEnter={this.showDropdown}
        onMouseLeave={this.hideDropdown}
        onClick = {this.ISDropDownOpened}
      >
        <div>
          <span className="nw-profile-image1"></span>
          <i
            className={`nw-desktop-only2 icomoon-arrow_bottom nw-loggedinmenu-arrowbottom ${this.dropdownInActiveClassName}`}
          />
          <i
            className={`nw-desktop-only2 icomoon-arrow_top nw-loggedinmenu-arrowbottom ${this.dropdownActiveClassName}`}
          />
        </div>
        <ul
          className={`nw-loggedinmenu-listul ${this.dropdownActiveClassName}`}
        >
          <li className="nw-loggedinmenu-listli">
            <MyAccountLink.component
              className="nw-loggedinmenu-btns"
              triggerGTMOnClick={this.triggerGTM}
            />
          </li>
          <li className="nw-loggedinmenu-listli">
            <TrackOrderLink.component className="nw-loggedinmenu-btns">
              My Orders
            </TrackOrderLink.component>
          </li>
          <li className="nw-loggedinmenu-listli nw-loggedinmenu-signout">
            <LogoutButton.component className="nw-loggedinmenu-btns" />
          </li>
        </ul>
      </div>
    );
  }
}

LoggedInMenu.wrappedComponent.defaultProps = {
  className: ""
};

LoggedInMenu.wrappedComponent.propTypes = {
  className: PropTypes.string,
  LoginStore: PropTypes.shape({
    firstName: PropTypes.string.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoggedInMenu.wrappedComponent,
  component: LoggedInMenu
};

export default ProjectComponent;
