import { observable } from "mobx";
import ReturnStoreActions from "./actions";

import { RETURNS } from "npmlinks-constants";

const { RETURN_MODES, EXCHANGE_INITIATE } = RETURNS;

class ReturnStoreClass extends ReturnStoreActions {
  constructor(props) {
    super(props);
    this.getReturnPayload = this.getReturnPayload.bind(this);
    this.getReturnFormPayload = this.getReturnFormPayload.bind(this);
    this.getCancelPayload = this.getCancelPayload.bind(this);
    this.getExchangeMailPayload = this.getExchangeMailPayload.bind(this);
  }

  @observable comment = "";
  @observable isModalOpen = false;
  @observable isNeftNeeded = false;
  @observable itemId = "";
  @observable loader = false;
  @observable neftList = [];
  @observable orderId = "";
  @observable reasons = [];
  @observable refundAmount = 0;
  @observable returnConsignmentId = "";
  @observable returnId = "";
  @observable returnLocation = "";
  @observable returnModes = [];
  @observable selectedNeftId = "";
  @observable selectedReasonId = null;
  @observable selectedReturnAddressId = "";
  @observable selectedReturnMode = RETURN_MODES.REVERSEPICKUP;
  @observable selfShipFcId = null;
  @observable selfShipInfo = {};

  getReturnPayload() {
    const body = {};
    Object.assign(body, {
      itemId: this.itemId,
      reasonId: this.selectedReasonId,
      comment: this.comment,
      returnMode: this.selectedReturnMode,
    });

    if (this.selectedReturnMode === "REVERSEPICKUP") {
      Object.assign(body, {
        address: { addressId: this.selectedReturnAddressId },
      });
    } else {
      Object.assign(body, { fcId: this.selfShipFcId });
    }
    if (this.selectedNeftId) {
      Object.assign(body, { neft: this.selectedNeftId });
    }
    return body;
  }

  getCancelPayload() {
    const body = {};
    Object.assign(body, {
      itemId: this.itemId,
      reasonId: this.selectedReasonId,
      comment: this.comment,
    });
    if (this.selectedNeftId) {
      Object.assign(body, { neftId: this.selectedNeftId });
    }
    return body;
  }

  getReturnFormPayload() {
    const body = {};
    const selectedReason = this.reasons.find(
      data => data.id === this.selectedReasonId,
    );
    const returnDetails = [
      {
        returnId: this.returnId,
        returnConsignmentId: this.returnConsignmentId,
        returnLocation: this.retunLocation,
      },
    ];
    Object.assign(body, {
      orderId: this.orderId,
      returnDetails,
      productId: this.itemId,
      reason: selectedReason.reason,
    });

    return body;
  }

  getExchangeMailPayload(body, mailId, sizeRequested) {
    const { consignmentId, orderId, styleId, ean } = body;
    this.exchangeRequest = {
      from: mailId,
      bcc: [],
      subject: ` - ${orderId} - ${consignmentId}`,
      data: {
        consignment_id: consignmentId,
        order_id: orderId,
        style_id: styleId,
        ean,
        size_requested: sizeRequested,
      },
      isExchangeRequest: true,
    };
    return this.exchangeRequest;
  }
}
export default ReturnStoreClass;
