import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { GridRow, GridColumn, Input, Label } from "arv-reactcomponents";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import { PAYMENTPAGE } from "npmlinks-constants";


class GenericIcon extends Component {
  static formattedPrice(priceObject) {
    let text = "";
    if (priceObject.displayName) {
      text = priceObject.displayName;
    } else if (priceObject.min === 0) {
      text = `Below ${PAYMENTPAGE.NNNOW_CURRENCY} ${priceObject.max}`;
    } else if (priceObject.max === 0) {
      text = `Above ${PAYMENTPAGE.NNNOW_CURRENCY}${priceObject.min}`;
    } else {
      text = `${PAYMENTPAGE.NNNOW_CURRENCY} ${priceObject.min} - ${PAYMENTPAGE.NNNOW_CURRENCY}${priceObject.max}`;
    }
    return text;
  }
  constructor(props) {
    super(props);
    this.state = {};
    this.subMenuClick = this.subMenuClick.bind(this);
  }
  subMenuClick(index) {
    this.props.selectElementAndFilter(this.props.icon.id, index);
    Analytics.trackEvent({
      action: Event.action.getFilterType(this.props.icon.filterType),
      label: Event.label.getSource(window.pageType),
      category: Event.category.SEARCH,
    });
  }

  render() {
    return (
      <GridRow
        role="presentation"
        className={`nw-search-fiter-items-container ${this.props.className}`}
      >
        {this.props.icon.list.map((c, index) => (
          <GridColumn
            className={`nw-search-specific-icon nw-grid-col-sm-6 ${
              c.selected ? "is-selected " : ""
            }`}
            key={c.displayName || c.max}
          >
            <Label
              htmlFor={`searchbar-filter-generic-${GenericIcon.formattedPrice(
                c,
              )}`}
            >
              <Input
                id={`searchbar-filter-generic-${GenericIcon.formattedPrice(c)}`}
                type="checkbox"
                className="nwc-inp-checkbox-primary nwc-filter-checkbox nwc-inp-sm"
                value={index}
                checked={c.selected || false}
                name={GenericIcon.formattedPrice(c)}
                onChange={() => {
                  this.subMenuClick(index);
                }}
              />
              <span className="nw-search-filter-generic-text">
                {GenericIcon.formattedPrice(c)}
              </span>
            </Label>
          </GridColumn>
        ))}
      </GridRow>
    );
  }
}
GenericIcon.defaultProps = {
  icon: { isActive: false },
};
GenericIcon.propTypes = {
  icon: PropTypes.shape({
    id: PropTypes.number,
    filterType: PropTypes.string,
    class: PropTypes.shape({}),
    backgroundColor: PropTypes.string,
    list: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.array]),
    isActive: PropTypes.bool,
  }),
  selectElementAndFilter: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: GenericIcon,
  component: GenericIcon,
};

export default ProjectComponent;
