import { observable } from "mobx";
import NavListActions from "./actions";

class NavListStoreClass extends NavListActions {
  constructor(props) {
    super();
    if (props && Object.keys(props).length > 0) {
      this.navListData1 = props.navListData;
    }

    this.brandPageNnnowMenuShow = false;
  }
  @observable navListData = this.navListData1 || {};
  @observable selectedNav = null;
  @observable brandPageNnnowMenuShow = false;
}

// const NavListStore = new NavListStoreClass();
// export default NavListStore;
export default NavListStoreClass;
