import { Utils } from "arv-reactcomponents";
import { action } from "mobx";
import { getFilters, autoSuggestConfig } from "./service";
import {
  formSearchRequest,
  inputValidSearchParams,
  processFilterResponce
} from "./methods";
import { redirect } from "../../utils";

class SearchStoreActions {
  @action
  setSearchText(searchText) {
    this.searchText = searchText;
  }
  @action
  toggleSearch() {
    this.showSearch = !this.showSearch;
    if (this.showSearch) {
      Utils.fixScroll();
    } else {
      Utils.unFixScroll();
    }
  }
  @action
  getFilters(isDesktop) {
    return new Promise(resolve => {
      getFilters().then(
        action(responce => {
          responce.data.pop();
          this.filters = processFilterResponce(responce.data, isDesktop);
          resolve(this.filters);
        })
      );
    });
  }

  /**
   * We need to handle little differently bcz it has three nested layer than two
   * - so we are handling this based on brandInitialKey
   * @param selectedFilterId
   * @param subfilterMenuId
   * @param brandInitialKey
   */
  @action
  selectElementAndFilter(selectedFilterId, subfilterMenuId, brandInitialKey) {
    // eslint-disable-next-line
    const subfilterMenu = !brandInitialKey
      ? this.filters[selectedFilterId].list[subfilterMenuId]
      : this.filters[selectedFilterId].list[brandInitialKey].list[
          subfilterMenuId
        ];
    subfilterMenu.selected = !subfilterMenu.selected;
    if (
      this.filters[selectedFilterId] &&
      !this.filters[selectedFilterId].selectedSubMenu
    ) {
      this.filters[selectedFilterId].selectedSubMenu = 0;
    }

    if (subfilterMenu.selected) {
      this.filters[selectedFilterId].selectedSubMenu =
        this.filters[selectedFilterId].selectedSubMenu + 1;
    } else {
      this.filters[selectedFilterId].selectedSubMenu =
        this.filters[selectedFilterId].selectedSubMenu - 1;
    }
    // TODO: need to handle parent component
    this.filters.push({});
    this.filters.pop();
  }

  /**
   * when hover on one search filter icon
   *  mouseEnter
   *      we need to display its subfiltermenu, hide other submenu(NOt sure about this )
   *      show its default color and [hide] all other background color
   *  mouseLeave
   *       we need to hide its subfiltermenu
   *        show its default color [show] all other background color
   * @param selectedFilterId
   * @param isEnter
   */
  @action
  hoverOnIconFilter(selectedFilterId, isEnter) {
    this.filters = this.filters.map(tempFilterIcon1 => {
      const tempFilterIcon = tempFilterIcon1;
      // its
      if (tempFilterIcon.id === selectedFilterId) {
        tempFilterIcon.isActive = !tempFilterIcon.isActive;
        tempFilterIcon.isBackgroundHidden = false;
      } else {
        // other
        tempFilterIcon.isActive = false;
        tempFilterIcon.isBackgroundHidden = !!isEnter;
      }
      return tempFilterIcon;
    });
  }

  @action
  handleSearch() {
    const searchQuery = formSearchRequest(this.searchText, this.filters);
    if (searchQuery) {
      redirect(`search?${searchQuery}`);
    } else {
      inputValidSearchParams();
    }
  }
  @action
  toggleActiveBrand(activeBrand, index) {
    this.filters[index].activeBrand = activeBrand;
    this.filters.push({});
    this.filters.pop();
  }

  @action
  autoSuggestConfig() {
    return new Promise(resolve => {
      autoSuggestConfig()
        .then(res => {
          var finalRes;
          var tenantRes = res.data.tenantID;
          if (res && res.data && res.data.bucket_data[tenantRes].length <= 0) {
            finalRes = res.data.bucket_data.default.channels;
          } else {
            finalRes = res.data.bucket_data[tenantRes].channels.includes(
              "odin"
            );
          }

          window.localStorage.setItem("channel", finalRes);
          resolve(res);
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
}

export default SearchStoreActions;
