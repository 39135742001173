import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import "@babel/polyfill";

import App from "./App";
import { init } from "./stores";
import "./index.scss";
import { register } from "./registerServiceWorker";
import { listenForBeforeInstallPrompt } from "./installEventListener";
import { loadableReady } from "@loadable/component";

// TODO: we will insert this info from node side when doing SSR

let timer = 0;
let firstTime = localStorage.getItem("first_time");
if (!firstTime) {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    timer = process.env.REACT_APP_HYDRATE_DELAY;
  }
  localStorage.setItem("first_time", "1");
}

setTimeout(
  function() {
    const stores = init(window.DATA);
    loadableReady(() => {
      ReactDOM.hydrate(
        <Provider {...stores}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>,
        document.getElementById("root")
      );
    });
    if (module.hot) {
      module.hot.accept();
    }
    listenForBeforeInstallPrompt();
    register();
  },
  timer,
  false
);
