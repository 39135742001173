import { get } from '../../api';
import { authHeader } from '../../utils';

const getLoyaltyMoreInfo = brandName => get("moreInfo", { ...authHeader() }, `?brandName=${brandName}`);
const getLoyaltyCards = () => get("loyaltyCards", { ...authHeader() });

export {
  getLoyaltyMoreInfo,
  getLoyaltyCards
};
