import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Toastr } from "arv-reactcomponents";

// Import Depedency Component Here
import ForgotPassword from "../ForgotPassword";
import LoginSub from "./LoginSub";
import SocialLoginError from "../SocialLoginError";
import ResetPassword from "../ResetPassword";
import MissedFields from "../MissedFields";
import Register from "./Register";
import { isAuthenticated } from "../../../utils";
import Analytics from "../../../analytics";
import GTM from "../../../analytics/GTM";
import Event from "../../../analytics/eventFactory";
import { Checkout, pageType, blackboltConfig } from "npmlinks-constants";

@inject("LoginStore", "MybagStore")
@observer
class LoginV1 extends Component {
  constructor() {
    super();
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    if (isAuthenticated()) {
      if (!Checkout.CHECKOUT_PATHNAMES.includes(window.location.pathname)) {
        this.props.MybagStore.getMyBagDetails();
      }
      if (window.location.pathname === pageType.resetPassword) {
        Toastr.showToastr({
          className: "nwc-toastr-list-notification nw-toastr",
          message: blackboltConfig.RESET_PASSWORD_LOGGEDIN,
          timeout: 3000,
        });
      }
      this.props.LoginStore.setLoggedInData();
    } else if (window.location.pathname === pageType.resetPassword) {
      this.props.LoginStore.changeView("resetPassword");
      this.props.LoginStore.setloginModal(true);
    }
  }

  get currentViewSelector() {
    const { currentView } = this.props.LoginStore;
    switch (currentView) {
      case "forgotPassword":
        return <ForgotPassword.component />;
      case "register":
        return <Register.component />;
      case "login":
        return <LoginSub.component />;
      case "missed":
        return <MissedFields.component 
        changeViewValue = {"login"}
        />;
      case "socialLoginError":
        return <SocialLoginError.component />;
      case "resetPassword":
        return <ResetPassword.component />;
      default:
        return <LoginSub.component />;
    }
  }
  closeModal() {
    Analytics.trackEvent({
      action: Event.action.LOGIN_CANCEL,
      category: Event.category.LOGIN,
    });
    GTM.pushEvent(Event.GTMEvent.REGISTER_ABORTED);
    this.props.LoginStore.changeView("login");
    const { preventClose } = this.props.LoginStore;
    if (!preventClose) {
      this.props.LoginStore.setloginModal(false);
    } else {
      window.location.href = window.location.origin;
    }
  }
  render() {
    const { isModalOpen } = this.props.LoginStore;
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={this.closeModal}
        className="nw-login"
      >
        {this.currentViewSelector}
      </Modal>
    );
  }
}

LoginV1.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    preventClose: PropTypes.bool.isRequired,
    setLoggedInData: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    setloginModal: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
  }).isRequired,
  MybagStore: PropTypes.shape({
    getMyBagDetails: PropTypes.func.isRequired,
  }).isRequired,
};

export { default as LoginSub } from "./LoginSub";
export { default as Register } from "./Register";

const getFinalComponent = component =>
  inject("LoginStore", "MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginV1.wrappedComponent,
  component: LoginV1,
};

export default ProjectComponent;
