import React, { Component } from "react";
import PropTypes from "prop-types";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      time: {}, 
      secondsLeft: this.props.timeOut,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    const {secondsLeft} = this.state;
    let timeLeftToResend = this.secondsToTime(secondsLeft);
    this.setState({ time: timeLeftToResend });
  }

  startTimer() {
    const {secondsLeft} = this.state;
    if (this.timer == 0 && secondsLeft > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    var {secondsLeft} = this.state;
    secondsLeft-= 1 ;
    const time = this.secondsToTime(secondsLeft);
    this.setState({
      time,
      secondsLeft: secondsLeft,
    });

    if (Object.keys(time).length && !time.s) {
      this.props.hideTimer();
    }
    
    // Check if we're at zero.
    if (secondsLeft == 0) { 
      clearInterval(this.timer);
    }

  }

  render() {
    const { time } = this.state;
    const { className } = this.props;
    this.startTimer();
    return(
      <span className={className}> 00:{time.s}</span>
    );
  }
}

CountDown.defaultProps = {
  className: "",
  timeOut: 30000,
};

CountDown.propTypes = {
  className: PropTypes.string,
  hideTimer: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: CountDown,
  component: CountDown
};

export default ProjectComponent;