import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Button, Img } from "arv-reactcomponents";
import { ROUTE_PATHS, Endpoints } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";

@inject("CommonStore", "LoginStore")
@observer
class FavoritesLink extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  get hasTextClassName() {
    return this.props.children ? "is-havingtext" : "";
  }

  handleClick() {
    const { isDesktop } = this.props.CommonStore;
    Analytics.trackEvent({
      action: Event.action.FAVORITE_CLICK,
      category: isDesktop
        ? Event.category.TOP_MENU_NAVIGATION
        : Event.category.MOBILE_TOPNAV,
      interaction: true
    });
    GTM.pushEvent(Event.GTMEvent.MYFAVOURITE_CLICK);
  }

  render() {
    const { className, children } = this.props;

    return (
      <>
        {this.props.LoginStore.isUserLoggedIn ? (
          <>
            
            <Anchor.component
              className={`nwc-btn nw-favoriteslink ${className}`}
              to={ROUTE_PATHS.FAVORITES}
              href={ROUTE_PATHS.FAVORITES}
              target="_self"
              onClick={this.handleClick}
            >
              <i
                className={`icomoon-heart-o nw-favoriteslink-icon ${this.hasTextClassName}`}
              />
              <span className="nw-favoriteslink-text">{children}</span>
            </Anchor.component>
            <span className="nw-header-seperator7 nw-desktop-only1">|</span>
          </>
        ) : (
          " "
        )}
      </>
    );
  }
}

FavoritesLink.wrappedComponent.defaultProps = {
  className: "",
  children: ""
};

FavoritesLink.wrappedComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FavoritesLink.wrappedComponent,
  component: FavoritesLink
};

export default ProjectComponent;
