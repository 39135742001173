import { toJS } from "mobx";

import { NAV_BUTTON_CONFIG } from "npmlinks-constants";

const processNavPojoToUseableFormat = (navtree = {}, rootKeys = []) =>
  rootKeys.map(key => {
    const nav = navtree[key] || {};
    const children = (nav && nav.children) || [];
    nav.children =
      children && children.length > 0
        ? processNavPojoToUseableFormat(navtree, children)
        : [];
    return nav;
  });
const processMobileNavPojoToUseableFormat = (navtree = {}, rootKeys = []) =>
  rootKeys.map(key => {
    const nav = navtree[key] || {};
    const navId = nav && nav.navId;
    const parsedNavId = (navId && JSON.parse(navId)) || {};
    const navtreeFromJson =
      parsedNavId && parsedNavId.root && parsedNavId.root.navtree;
    const rootKeysFromJson =
      parsedNavId && parsedNavId.root && parsedNavId.root.rootKeys;
    nav.children =
      navtreeFromJson && rootKeysFromJson
        ? processNavPojoToUseableFormat(navtreeFromJson, rootKeysFromJson)
        : [];
    return nav;
  });

const processLeftNav = (navTree = {}, rootKeys = [], desktop) =>
  desktop
    ? processNavPojoToUseableFormat(navTree, rootKeys)
    : processMobileNavPojoToUseableFormat(navTree, rootKeys);

const checkButtonWithBorderNode = node =>
  node && node.nodeType === NAV_BUTTON_CONFIG.NODE_TYPE;

const checkListWithoutButtons = node =>
  node && node.nodeType !== NAV_BUTTON_CONFIG.NODE_TYPE;

const deduceFinalNavList = navListData => {
  const level2Data = toJS(navListData);
  // L2 level button list
  const buttonList = [];
  if (level2Data.length) {
    level2Data.forEach(navItems => {
      if (navItems && navItems.level3 && navItems.level3.length) {
        // get all L3 button nodes
        const buttonNodes =
          navItems.level3.filter(checkButtonWithBorderNode) || [];
        // push all L3 -> L2 button list
        if (buttonNodes.length) {
          buttonNodes.forEach(nodes => {
            buttonList.push(nodes);
          });
        }
        // get all L3 nodes without button
        const filteredNodes = {
          level3: navItems.level3.filter(checkListWithoutButtons),
        };
        Object.assign(navItems, filteredNodes);
      }
    });
  }
  if (!level2Data.buttonList) {
    level2Data.buttonList = buttonList.slice(0, NAV_BUTTON_CONFIG.MAX_COUNT);
  }
  return level2Data;
};

export {
  processLeftNav,
  processNavPojoToUseableFormat,
  processMobileNavPojoToUseableFormat,
  deduceFinalNavList,
};
