import React from "react";
import { Button, Utils } from "arv-reactcomponents";
import { scrollTime } from "npmlinks-constants";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";

function goBackToTop(event) {
  event.preventDefault();
  Utils.scrollTo(window, 0, 0, scrollTime.slow);
  Analytics.trackEvent({
    action: Event.action.BACK_TO_TOP,
    category: Event.category.FOOTER,
  });
}

function BackToTop() {
  return (
    <div className="nw-back-to-top-btn-wrapper">
      <Button
        className="nw-back-to-top-btn"
        onClick={goBackToTop}
        onKeyPress={Utils.noop}
      >
        <i className="icomoon-arrow_top arrow_top" />
        <span>BACK TO TOP</span>
      </Button>
    </div>
  );
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BackToTop,
  component: BackToTop,
};

export default ProjectComponent;
