import { action, toJS } from "mobx";
import {
  getCartDetails,
  getStoresDetails,
  getDeliveryCharges,
  getOverviewDetails,
  getAllApplicableCoupons
} from "./service";
import {
  clearLocalCart,
  setLocalCart,
  getLocalCart,
  getPromoCode,
  getlastUsedPincode,
  setShippingMethods,
  removeShippingMethods,
  setAddressIdInLocal,
  readCookie
} from "../../utils";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import { Toastr, Utils } from "arv-reactcomponents";
import { pageType, Checkout } from "npmlinks-constants";

const handlePricingError = (data, HSN_ERROR_PRODUCT_NOT_IN_BAG = false) => {
  if (
    data.itemsHavingIssueInPricing &&
    data.itemsHavingIssueInPricing.length > 0
  ) {
    const products =
      data.itemsHavingIssueInPricing.length === 1 ? "product" : "products";
    Toastr.showToastr({
      className: "nwc-toastr-list-danger nw-toastr",
      message: !HSN_ERROR_PRODUCT_NOT_IN_BAG
        ? Checkout.CHECKOUT_MESSAGES.SKU_NOT_ADDED_MESSAGE.replace(
            "$count",
            data.itemsHavingIssueInPricing.length
          ).replace("$product", products)
        : Checkout.CHECKOUT_MESSAGES.SKU_NOT_ADDED_MESSAGE_HSN_ERROR,
      timeout: 3000
    });
  }
};

class CheckoutStoreActions {
  setAddressId(id) {
    this.addressId = id;
    setAddressIdInLocal(id);
  }
  @action
  clearError() {
    this.error = null;
  }
  @action
  setProductToBeFavorited(product) {
    this.productToBeFavorited = product || null;
  }
  @action
  clearCart() {
    this.cartItems = [];
    this.bagTotalItemCount = 0;
  }

  @action
  triggerLoader(bool) {
    this.loading = bool;
  }

  @action
  clearPresets() {
    this.setAddressId("");
    this.selectedShippingMethods = [];
    removeShippingMethods();
  }

  @action
  setMobileNumberVerified() {
    this.shippingAddress.isMobileNumberVerified = true;
  }

  @action
  storeAndUpdateShippingMethods(newShippingMethods, clear) {
    if (clear) {
      this.selectedShippingMethods = [];
      removeShippingMethods();
      return [];
    }
    newShippingMethods.forEach(item => {
      const index = this.selectedShippingMethods.findIndex(
        method => method.skuId === item.skuId
      );
      if (index < 0) {
        this.selectedShippingMethods.push(item);
      } else {
        this.selectedShippingMethods[index] = item;
      }
    });
    if (this.selectedShippingMethods.length > 0) {
      setShippingMethods(this.selectedShippingMethods);
    } else {
      removeShippingMethods();
    }
    return this.selectedShippingMethods;
  }

  @action
  setCartItems(cart, isLoggedIn, pagetype, setInStore) {
    this.cartItems = cart;
    if (!isLoggedIn && (pagetype === pageType.myBag || setInStore)) {
      setLocalCart(cart);
    } else {
      clearLocalCart();
    }
  }

  @action
  setPaymentTypes(paymentTypes) {
    this.paymentTypes = paymentTypes || [];
  }

  @action
  setPaymentError(exceptionMsg = "") {
    this.paymentExceptionMsg = exceptionMsg;
  }

  @action
  setPricingOverviewData(overviewData = {}) {
    this.pricingOverviewData = overviewData;
  }

  @action
  updateCart(
    body = {},
    isLoggedIn,
    donotUpdate,
    donotClearPromotions,
    setInStore
  ) {
    const commonRequestObj = {
      page: window.pageType || pageType.shipping,
      guestCart: getLocalCart()
    };
    const request = {
      ...Checkout.CHECKOUT_BASE_REQUEST,
      ...body,
      ...commonRequestObj
    };
    request.pincode = request.pincode || getlastUsedPincode();
    request.couponCode = request.couponCode || getPromoCode();
    request.type = request.type || Checkout.CHECKOUT_API_ACTIONS.READ;
    request.addressId = request.addressId || this.addressId;
    request.mobileNumber = readCookie("mobileNumber");
    request.isPromotionPaymentConstraintSupported = true;
    if (
      window.pageType !== pageType.myBag &&
      (request.selectedShippingMethods.length > 0 ||
        this.selectedShippingMethods.length > 0) &&
      request.type !== Checkout.CHECKOUT_API_ACTIONS.DELETE
    ) {
      request.type = Checkout.CHECKOUT_API_ACTIONS.UPDATE;
      request.selectedShippingMethods = this.storeAndUpdateShippingMethods(
        request.selectedShippingMethods
      );
    } else if (
      window.pageType === pageType.myBag &&
      request.type !== Checkout.CHECKOUT_API_ACTIONS.READ
    ) {
      request.selectedShippingMethods = this.storeAndUpdateShippingMethods(
        [],
        true
      );
    }
    this.loading = !donotUpdate;
    this.applicablePromotions = donotClearPromotions
      ? this.applicablePromotions
      : [];
    return new Promise((resolve, reject) => {
      getCartDetails(JSON.stringify(request)).then(
        response => {
          this.error = null;
          if (
            response.data &&
            response.data.itemsHavingIssueInPricing &&
            response.data.itemsHavingIssueInPricing.length > 0
          ) {
            handlePricingError(response.data);
          }
          this.loading = false;
          Utils.localStorage.setItem(
            "cartItems",
            JSON.stringify(response.data.products)
          );
          const pricingOverviewData = response.data.overview || {};
          const { shippingCost, total } = pricingOverviewData;
          if (total && shippingCost > 0) {
            Analytics.trackEvent({
              category: window.pageType,
              action: Event.action.SHIPPING,
              label: `${total}_${shippingCost}`
            });
          }
          if (!donotUpdate || setInStore) {
            this.setCartItems(
              response.data.products,
              isLoggedIn,
              window.pageType,
              setInStore
            );
            this.pincode = response.data.pincode;
            this.pricingOverviewData = pricingOverviewData;
            this.couponDetails = response.data.couponDetails || {}
            this.cartProductDetails = response.data.products;
            this.allApplicableCoupons = response.data.applicablePromotionsV2 || {};
            Utils.localStorage.setItem(
              "pricingOverviewData",
              JSON.stringify(pricingOverviewData)
            );
            this.bagTotalItemCount = response.data.bagTotalItemCount;
            this.applicablePromotions =
              response.data.applicablePromotions || [];
            this.paymentTypes = response.data.paymentTypes || [];
            const shippingAddress = response.data.shippingAddress || {};
            this.shippingAddress =
              Object.keys(shippingAddress).length > 0 ? shippingAddress : null;
            this.limitBreachedSkus = response.data.limitBreachedSkus || [];
            this.skusNotAdded = response.data.skusNotAdded || [];
            this.itemsHavingIssueInPricing =
              response.data.itemsHavingIssueInPricing || [];
            this.setAddressId(this.shippingAddress && this.shippingAddress.id);
            if (process.env.REACT_APP_ISLOYALTY === "true") {
              this.loyaltyDetails = response.data.loyaltyDetails || {};
            }
          }
          response.data.requestBody = request;
          resolve(response.data);
        },
        error => {
          this.error = { request, error };
          this.loading = false;
          reject(error);
        }
      );
    });
  }

  @action
  getStores(body) {
    this.storeList = [];
    return new Promise((resolve, reject) => {
      getStoresDetails(JSON.stringify(body)).then(
        response => {
          this.storeList = response.individualStores;
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  @action
  changePricingOverview(reqObj) {
    const request = {};
    const {
      isLoyaltyChecked = false,
      selectedPayemntTypeDetails = null,
      selectedPaymentMode = null,
      removeCoupon = false
    } = reqObj;
    let { couponCode } = this.pricingOverviewData;
    if (!this.pricingOverviewData.isCouponApplied) {
      couponCode = null;
    }
    request.couponCode = removeCoupon ? null : couponCode;
    request.cartItems = this.cartItems;
    request.loyaltyCheck = isLoyaltyChecked;
    request.isPromotionPaymentConstraintSupported = true;
    request.mobileNumber = readCookie("mobileNumber");
    request.selectedPaymentMode = selectedPaymentMode;
    request.selectedPayemntTypeDetails = selectedPayemntTypeDetails;
    this.loading = true;

    return new Promise((resolve, reject) => {
      getOverviewDetails(JSON.stringify(request)).then(
        response => {
          this.pricingOverviewData = response.data.overview;
          this.loading = false;
          this.loyaltyDetails = response.data.loyaltyDetails;
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  @action
  getDeliveryCharges() {
    return new Promise((resolve, reject) => {
      getDeliveryCharges().then(
        response => {
          this.deliveryCharges = response.data;
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}

export default CheckoutStoreActions;
