import { observable } from "mobx";
import MyOrdersStoreAction from "./action";

class MyOrdersStoreClass extends MyOrdersStoreAction {
  @observable
  ordersList = {
    orders: [],
    currentPage: 0,
    totalPages: 0,
  };
  @observable loading = false;
}
// const LoginStore = new LoginStoreClass();
export default MyOrdersStoreClass;
