const PRICE_INFO_BLOCK = {
  headerTitle: "PRICE DETAILS:",
  mrpBlockTitle: "Maximum Retail Price",
  mrpBlockSubTitle: "(inclusive of all taxes)",
  sellingPriceTitle: "Selling Price",
  youSaveTitle: "You Save",
  youSaveMsgPrefix: "Overall you save ",
  youSaveMsgSuffix: " on this product.",
};

export default PRICE_INFO_BLOCK;
