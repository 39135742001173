import React from "react";
import PropTypes from "prop-types";
import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";

import {
  Navigation,
  SearchBar,
  Logo,
  FavoritesLink,
  MyBagButton,
  LoginSwitch,
  LoyaltyLink,
  GetAppLink,
  TrackOrderLink,
  StoreLocatorLink,
  BrandPageNnnowBtn,
  PromoText,
  SubNav,
  BrandNavContainer
} from "../../../molecules";

const BrandHeader = props => {
  const { className } = props;

  return (
    <header className={`nw-brandheader ${className}`}>
      <GridContainer>
        <GridRow className="nw-brandheader-topbarrow">
          <GridColumn className="nw-brandheader-topbarcol">
            <GridRow className="nw-brandheader-topbarcols">
              <GridColumn className="nwc-grid-col-xs nw-brandheader-topbarleftlinks">
                <BrandPageNnnowBtn.component />
                <span className="nw-header-seperator">|</span>
                <StoreLocatorLink.component className="nw-header-linksbtns" />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-brandheader-topbarpromo">
                <PromoText.component />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-brandheader-topbarrightlinks">
                <GetAppLink.component className="nw-header-linksbtns" />
                <span className="nw-header-seperator">|</span>
                <TrackOrderLink.component className="nw-header-linksbtns">
                  Track Order
                </TrackOrderLink.component>
                <span className="nw-header-seperator">|</span>
                <LoyaltyLink.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <GridRow className="nw-brandheader-mainbarrow">
          <GridColumn className="nwc-grid-col-xs nw-brandheader-logocol">
            <Logo.component className="nw-brandheader-logo" />
          </GridColumn>
          <GridColumn className="nw-brandheader-mainbarcol">
            <GridRow className="nw-brandheader-maibarrowcols">
              <GridColumn className="nwc-grid-col-xs nw-brandheader-searchbarcol">
                <SearchBar.component shrunkView={false} />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-brandheader-logocol">
                {/*<Logo.component alt={"NNNOW Logo"}/>*/}
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-brandheader-buttonscol">
                <FavoritesLink.component className="nw-header-linksbtns" />
                <MyBagButton.component className="nw-header-linksbtns" />
                <LoginSwitch.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <GridRow className="nw-brandheader-navigationrow">
          <GridColumn className="nw-brandheader-navigationcol">
            <Navigation.component />
          </GridColumn>
        </GridRow>
      </GridContainer>
      <div className="nw-navtreenew-holder">
        <SubNav.component />
        <BrandNavContainer.component />
      </div>
      <div className="nw-autosuggest-holder" />
    </header>
  );
};

BrandHeader.defaultProps = {
  className: ""
};

BrandHeader.propTypes = {
  className: PropTypes.string
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandHeader,
  component: BrandHeader
};

export default ProjectComponent;
