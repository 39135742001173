import { action } from "mobx";
import * as service from "./service";
import { createCookie } from "../../utils";

class MyAccountStoreAction {
  @action
  changePasswordAction(currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      service
        .changePassword(
          JSON.stringify({
            currentPassword,
            newPassword,
          }),
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  savedCardsDetails() {
    return new Promise((resolve, reject) => {
      service.getSavedCardsDetails().then(
        action(res => {
          this.savedCards = res;
          resolve(res);
        }),
        action(err => {
          this.savedCards = [];
          reject(err);
        }),
      );
    });
  }

  @action
  deletePaymentCard(delCard) {
    return service.deletePaymentCard(delCard).then(
      action(res => {
        this.cardsAvailable = true;
        return res;
      }),
      action(err => {
        this.cardsAvailable = false;
        return err;
      }),
    );
  }
}

export default MyAccountStoreAction;
