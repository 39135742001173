import { action } from "mobx";

class CardlessEmiStoreActions {

  /**
   * after getting proper response from makepaymentv2 api we have to store this info so that we can submit this info to payu
   * @param successData
   * @param cardlessEmiData
   */
  @action
  setSuccessFlow(successData) {
    this.success = Object.assign({}, this.success, successData);
  }

  @action
  setCardlessEmiData(cardlessEmidata) {
    this.cardlessEmiData = Object.assign({}, cardlessEmidata && cardlessEmidata[0]);
  }
}

export default CardlessEmiStoreActions;
