import { action, toJS } from "mobx";
import * as Service from "./services";
// eslint-disable-next-line import/first
import { Utils } from "arv-reactcomponents";
import {
  noResults,
  queryParamsToObject,
  authHeader,
  normaliseUrlFromInvalidCases,
  isClientADesktop,
  validateUrlforMalform
} from "../../utils";
import Methods from "./methods";
import loadashGet from "lodash.get";

class Actions {
  static getListingData(reqObj, headers = {}, isSSRCall) {
    return new Promise((resolve, reject) => {
      Service.allProductData(
        {
          module: "odin",
          ...headers,
        },
        reqObj,
        isSSRCall
      ).then(
        action(res => {
          resolve(res.data || {});
        }),
        action(err => {
          reject(err);
        }),
      );
    });
  }
  @action
  setIsDesktop(value) {
    if(value===undefined || value === null){
     this.isDesktop=isClientADesktop();
    }
    this.isDesktop = value;
}
  @action
  setBannerDetails(arrOfData){
    this.bannerDetails={};
    if (arrOfData && arrOfData.length > 0) {
      const result = arrOfData[0].find(banner => banner.key === "banner");
      if (
        result.value &&
        result.value.images &&
        result.value.images.length > 0 &&
        result.value.images[0].path !== ""
      ) {
        this.bannerDetails=result.value;
      }
    }
  }
  setOmniFilters(data) {
    const filters =
      this.tempFilters || (this.ProductData && this.ProductData.filters);
    Object.keys(data).forEach(key => {
      const index =
        filters &&
        filters.filterBy &&
        filters.filterBy.findIndex(filter => filter.filterType === key);
      if (index > -1) {
        filters.filterBy[index].list = data[key];
        filters.filterBy[index].selectedCount =
          data[key] && data[key].filter(item => item.isSelected).length;
      }
    });
  }
  clearParticularFilterList(type) {
    const filters =
      this.tempFilters || (this.ProductData && this.ProductData.filters);
    const index =
      filters &&
      filters.filterBy &&
      filters.filterBy.findIndex(filter => filter.filterType === type);
    if (index > -1) {
      filters.filterBy[index].list = [];
    }
  }
  getOmniData(url, type) {
    if (!(url && (url.indexOf("p-dl") > -1 || url.indexOf("p-st") > -1))) {
      return null;
    }
    this.storeToStore(url);
    this.clearParticularFilterList(type);
    return new Promise((resolve, reject) => {
      Service.omniFiltersData(
        {
          module: "odin",
        },
        this.appendRequestToUrlAndStoreRelevantStuff(url),
      ).then(
        action(res => {
          this.setOmniFilters(res.data);
          this.callCount = this.callCount + 1;
          resolve(res.data || {});
        }),
        action(err => {
          reject(err);
        }),
      );
    });
  }

  getAllProductData(incomingUrl = "", mobileFilters, requrl, isSSRCall=false) {
    const url = normaliseUrlFromInvalidCases(incomingUrl);
    this.storeToStore(url);
    return new Promise((resolve, reject) => {
      this.isFetchingData = true;
      this.disableFilters = true;
      let promises = [];
      const currentPage = parseInt(Utils.getUrlParameter(url, "p") || 1, 10);

      for (let i = 1; i <= currentPage; i += 1) {
        const tempUrl = Utils.setUrlParameter(url, "p", i);
        const promise = Actions.getListingData(
          this.appendRequestToUrlAndStoreRelevantStuff(tempUrl),
          { requrl },
          isSSRCall
        );
        promises = [...promises, promise];
      }

      Promise.all(promises).then(
        action(res => {
          // if in server side no results- no ssr
          if (
            global.isServer &&
            (!res ||
              !res[0] ||
              !res[0].styles ||
              res[0].styles.styleList.length === 0)
          ) {
            resolve(null);
          } else {
            this.isFetchingData = false;
            this.disableFilters = false;
            if (!mobileFilters) {
              this.ProductData = null;
              this.setProductData(res);
            } else this.setTempFilters(res);
            this.callCount = this.callCount + 1;
            resolve(res);
          }
        }),
        action(err => {
          this.isFetchingData = false;
          reject(err);
        }),
      );
    });
  }

  getPageData(url) {
    return new Promise(resolve => {
      this.isFetchingData = true;
      Actions.getListingData(
        this.appendRequestToUrlAndStoreRelevantStuff(url),
      ).then(
        action(res => {
          this.callCount = this.callCount + 1;
          this.isFetchingData = false;
          this.setProductData([res]);
          resolve(res || {});
        }),
        action(err => {
          this.isFetchingData = false;
          // console.log(err);
        }),
      );
    });
  }

  @action
  toggleMobileFilters(type) {
    const initValue = this.mobileFilterToggle[type];
    Object.keys(this.mobileFilterToggle).forEach(key => {
      this.mobileFilterToggle[key] = false;
    });
    this.mobileFilterToggle[type] = !initValue;
    this.mobileFilterToggle.activeFilter = this.mobileFilterToggle[type]
      ? type
      : "";
  }

  @action
  setProductData(arrOfData) {
    arrOfData.forEach(eachData => {
      if (this.ProductData === null) {
        this.ProductData = eachData;
      } else {
        const prevStyleList = this.ProductData.styles.styleList;
        const newStyleList = eachData.styles.styleList;
        this.ProductData.filters = eachData.filters;
        this.ProductData.selectedFilters = eachData.selectedFilters;
        this.ProductData.sorts = eachData.sorts;
        this.ProductData.styles.styleList = [...prevStyleList, ...newStyleList];
      }
    });
    if (this.ProductData.styles.styleList.length === 0) {
      noResults();
    }
  }

  @action
  setListingPageNo(val) {
    this.listingPageNo = val;
  }

  @action
  setTempFilters(arrOfData) {
    arrOfData.forEach(eachData => {
      if (this.ProductData === null) {
        this.ProductData = eachData;
      } else {
        this.tempFilters = eachData.filters;
      }
    });
  }

  @action
  getDetails(styleId, setStore, requrl, isSSRCall = false) {
    this.styleId = styleId;
    return new Promise((resolve, reject) => {
      Service.getDetails(JSON.stringify({ styleId }), { requrl },isSSRCall).then(
        action(res => {
          if (res && res.data) {
            if (setStore) this.PdpData = res.data;
            resolve(res.data);
          }
        }),
        action(err => {
          reject(err);
        }),
      );
    });
  }
  @action
  setPdpData(obj) {
    this.PdpData = obj;
  }

  @action
  getListingDetails(payload, isSSRCall=false) {
    this.payload = payload;
    return new Promise((resolve, reject) => {
      Service.listingDetails(
        {
          platform: this.isDesktop || this.isDesktopTemp ? "desktop" : "mobile",
        },
        payload,
        isSSRCall
      ).then(
        action(res => {
          const response =
            res && res.data && res.data.length > 0 ? res.data[0] : [];
          if (response && response.length > 0) {
            const urlPrefix = response.find(
              banner => banner.key === "urlPrefix",
            ).value;
            const imagePath = response.find(banner => banner.key === "banner");
            if (
              imagePath &&
              imagePath.value &&
              imagePath.value.images.length > 0
            ) {
              response.find(
                banner => banner.key === "banner",
              ).value.images[0].path =
                urlPrefix + imagePath.value.images[0].path;
            }
            resolve(res.data);
          }
          resolve([]);
        }),
        action(err => {
          reject(err);
        }),
      );
    });
  }
  @action
  storeTempUrl(pathname, search) {
    this.mobileTempUrlCache = {
      pathname,
      search: (search && search.replace("v=0", "")) || "",
    };
  }
  @action
  storeOmniPincode(type, value) {
    this.omniPincodeCache[type] = value;
  }
  @action
  storeToStore(url) {
    if (!this.isDesktop) {
      const urlArray = url.split("?");
      const pathname = urlArray[0];
      const search = urlArray[1] ? `?${urlArray[1]}` : "";
      this.storeTempUrl(pathname, search);
    }
  }
  @action
  setBrandJourneyDetails(obj) {
    const newObj = {
      isBrandJourney: true,
      legalBrandNames: obj && obj.legalBrandNames,
      parentBrand: (() => {
        try {
          return (
            (obj.parentBrandName.length > 0 && obj.parentBrandName[0]) || null
          );
        } catch (err) {
          return null;
        }
      })(),
    };
    if (newObj.parentBrand) {
      this.brandDetails = newObj;
    }
  }
  @action
  toggleFilterView(flag) {
    this.showMobileFilters = flag;
  }
  @action
  appendRequestToUrlAndStoreRelevantStuff(inputUrl) {
    let url = inputUrl;
    if(url){
      url = inputUrl && validateUrlforMalform(url)
    }
    const urlObj = queryParamsToObject(url);
    this.queryApplied = urlObj.q || "";
    let reqObj = {};
    const collectionId =
      this.collectionId || Methods.getCollectionIdFromState();
    if (Methods.collectionHandling(collectionId, inputUrl)) {
      url += `${url.indexOf("?") === -1 ? "?" : "&"}cid=${encodeURIComponent(
        collectionId,
      )}`;
    }
    reqObj.deeplinkurl = url;
    if (this.brandDetails) {
      reqObj.brandDetails = this.brandDetails;
    }
    reqObj = JSON.stringify(reqObj);
    return reqObj;
  }
  @action
  clearProductData() {
    this.ProductData = null;
    this.collectionId = null;
  }
  @action
  setCollectionId(collectionId) {
    this.collectionId = collectionId;
  }
  @action
  setType(type) {
    this.type = type;
  }
  @action
  clearTempFilters() {
    this.tempFilters = null;
    this.mobileTempUrlCache = {};
  }

  @action
  getShippingDetails(body) {
    // console.log(this);
    return new Promise((resolve, reject) => {
      Service.getShippingDetails(body)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  @action
  getRecentlyViewedStyles(body, isUserLoggedIn) {
    // console.log(this);
    const newHeader = isUserLoggedIn ? authHeader() : {};
    return new Promise((resolve, reject) => {
      Service.getRecentlyViewedStyles(body, newHeader)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  @action
  getRecentlyViewedStylesSolus(body) {
    return new Promise((resolve, reject) => {
      Service.getRecentlyViewedStyles(body, {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  @action 
  landingPageSolus(body){
    return new Promise((resolve, reject) => {
      Service.landingPageSolus(body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {   
          reject(err);
        });
    });
  }
  @action
  fetchProductsBySku(body){
    return new Promise((resolve, reject) => {
      Service.fetchProductsBySku(body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {   
          reject(err);
        });
    });
  }
  @action
  inThisLook(body) {
    return new Promise((resolve, reject) => {
      Service.inThisLook(body)
        .then(res => {
          const filteredData = Methods.filterInThisLookByBrands(
            res.data,
            this.brandDetails && this.brandDetails.legalBrandNames,
          );
          resolve(filteredData);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  @action
  similarProducts(body) {
    // console.log(this);
    return new Promise((resolve, reject) => {
      Service.similarProducts(body)
        .then(res => {
          resolve(res.data.similarStyles);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  getFitGuideDtails(sizeChartId, requrl, isSSRCall = false) {
    // console.log(this);
    return new Promise((resolve, reject) => {
      if (this.sizeChartData && this.sizeChartData.id === sizeChartId) {
        resolve(this.sizeChartData.data);
      } else {
        Service.getSizeCharts(sizeChartId, { requrl }, isSSRCall)
          .then(res => {
            this.sizeChartData = {
              id: sizeChartId,
              data: res.data,
            };
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  }

  @action
  setListingGridLayout(gridview, blockview) {
    this.gridActive = gridview;
    this.isGridActiveClassName = gridview ? "nw-productlist-grid-active" : "";
    this.blockActive = blockview;
    this.isBlockActiveClassName = blockview
      ? "nw-productlist-block-active"
      : "";
  }

  @action
  triggerMobileApplyCall(value) {
    this.triggerMobileApply = !!value;
  }

  @action
  getautoSuggestions(body) {
    const reqBody = JSON.stringify({
      body,
    });
    this.suggestionTerm = body.suggestionTerm;
    this.autoSuggestionsData = {title: "", list: []};
    return new Promise((resolve, reject) => {
      Service.autoSuggestions(reqBody)
        .then(response => {
          this.autoSuggestionsData = loadashGet(response, "data", this.autoSuggestionsData);
          this.autoSuggetsLoader = false;
          resolve(response);
        })
        .catch(error => {
          this.autoSuggetsLoader = false;
          reject(error);
        });
    });
  }

  @action
  updateLoader(bool){
    this.autoSuggetsLoader = bool;
  }

  @action
  update_deliveryModes(sdd, ndd, standard, storePickData, storePickUpIncentiveData) {
    this.sddDeliveryMode = sdd ? sdd : false;
    this.nddDeliveryMode = ndd ? ndd : false;
    this.standardDeliveryMode = standard ? standard : false;
    this.storePickData = storePickData ? storePickData : [];
    this.storePickUpIncentiveData = storePickUpIncentiveData ? storePickUpIncentiveData : null;
  }
}
export default Actions;