function normalizeFooterData(parent) {
  if (Array.isArray(parent)) {
    for (let i = 0, l = parent.length; i < l; i += 1) {
      const child = parent[i];
      child.index = i;
      normalizeFooterData(child);
    }
  } else if (parent && parent.children) {
    for (let i = 0, l = parent.children.length; i < l; i += 1) {
      const child = parent.children[i];
      child.index = i;
      normalizeFooterData(child);
    }
  }
}

export default normalizeFooterData;
