import { toJS } from "mobx";
import { Endpoints, filterKeys } from "npmlinks-constants";
import { queryParamsToObject, ToCurrency } from "../../utils";

const calculateColors = (pdpColors, colorDetails) => {
  const pcmColorArray = [colorDetails];
  const pcmColors = {
    "": pcmColorArray,
  };
  if (pdpColors && pdpColors.colors && pdpColors.total > 0) {
    return pdpColors.colors;
  }
  return pcmColors;
};

const getPdpDetails = PdpData => {
  if (PdpData) {
    const { colors: pdpColors, mainStyle } = toJS(PdpData);
    const { videos = [] } = mainStyle;
    const {
      skus,
      images = [
        {
          thumbnail: Endpoints.noImg,
          large: Endpoints.noImg,
          medium: Endpoints.noImg,
        },
      ],
      inStock,
      colorDetails,
      discountRange,
      mrpRange,
      isNew,
      sellingPriceRange,
      promotions,
      styleId,
      quantity,
      url,
      brandName,
      name,
      sizeChartId,
      story,
      brandClickableUrl,
      sizeRecommendation,
      productTags,
      returnable,
      tenantSizeLabel,
      sizeMappingEnabled,
      gender,
      tenantsellableQty,
      pcmArticleType
    } = mainStyle;
    const [image] = images;
    const colors = calculateColors(pdpColors, colorDetails);
    let selectedSku = {};
    if (skus && skus.length === 1) {
      [selectedSku] = skus;
    }
    return {
      skus,
      colors,
      inStock,
      discountRange,
      mrpRange,
      sellingPriceRange,
      isNew,
      promotions,
      brandName,
      name,
      images,
      videos,
      quantity,
      url,
      colorDetails,
      image,
      brandText: brandName,
      styleId,
      sizeChartId,
      story,
      selectedSku,
      brandClickableUrl,
      sizeRecommendation,
      productTags,
      returnable,
      tenantSizeLabel,
      sizeMappingEnabled,
      gender,
      tenantsellableQty,
      pcmArticleType
    };
  }

  return {
    isNew: false,
    inStock: false,
    colors: {},
    skus: [],
    images: [
      {
        thumbnail: null,
        large: null,
        medium: null,
      },
    ],
    videos: [
      {
        thumbnail: null,
        large: null,
        medium: null,
      },
    ],
    promotions: [],
    brandName: "",
    name: "",
  };
};

const getCollectionIdFromState = () =>
  (window &&
    window.history &&
    window.history.state &&
    window.history.state.state &&
    window.history.state.state.collectionId) ||
  "";

const filterInThisLookByBrands = (items, legalBrandNames) => {
  // legalBrandNames = ["GAP"];
  if (legalBrandNames && legalBrandNames.length) {
    const filteredList = items.filter(item =>
      legalBrandNames.includes(item.brandName),
    );
    return filteredList;
  }
  // console.log("WWWWWWWWWW");
  // console.log(filteredList);
  return items;
};

const checkIfCustomPriceFilters = (location, data) => {
  const priceObj =
    (data &&
      data.filters &&
      data.filters.filterBy &&
      data.filters.filterBy.find(item => item.filterType === "Price")) ||
    {};
  const unWrappedPriceObj = toJS(priceObj);
  const priceList = unWrappedPriceObj.list || [];
  const queryObj = queryParamsToObject(location.search);
  const priceInUrl = (queryObj && queryObj[unWrappedPriceObj.mapping]) || "";
  const priceArrayinUrl = (priceInUrl && priceInUrl.split("--")) || [];
  const pricesNotPresent = priceArrayinUrl.filter(
    item => !priceList.find(priceItem => priceItem.value === item),
  );
  return pricesNotPresent.map(item => {
    const [min, max] = item.split("-");
    return {
      min,
      max,
      filterType: "Price",
      mapping: filterKeys.Price,
      value: item,
      displayName: `${ToCurrency(min)}-${ToCurrency(max)}`,
    };
  });
};

const collectionHandling = (collectionId, inputUrl) => {
  if(global && global.isServer) {
    return collectionId && (!inputUrl || inputUrl.indexOf("cid") === -1)
  }
  return collectionId && window.location.search.indexOf("cid") === -1
}

const Methods = {
  calculateColors,
  getPdpDetails,
  getCollectionIdFromState,
  filterInThisLookByBrands,
  checkIfCustomPriceFilters,
  collectionHandling,
};

export default Methods;
