import { observable } from "mobx";
import GetTypeActions from "./actions";

class GetTypeStoreClass extends GetTypeActions {
  constructor(props) {
    super();

    this.datatemp = (props && props.data) || {};
    this.listingDatatemp = (props && props.listingData) || {};
  }

  @observable data = this.datatemp || {};

  // @TODO: Remove this once serviceworker solution implemented
  @observable listingData = this.listingDatatemp || {};
  // @TODO: Remove this once serviceworker solution implemented
}

export default GetTypeStoreClass;
