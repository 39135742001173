import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Button, Utils, Img } from "arv-reactcomponents";
import { Endpoints, Login, blackboltConfig } from "npmlinks-constants";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import GTM from "../../../../analytics/GTM";
import PasswordInput from "../../PasswordInput";
import OtpSub from "../OtpSub";
import lodashGet from "lodash.get";
import { Captcha } from "../../../../molecules";

@inject("OtpStore", "LoginStore")
@observer
class LoginOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBtnLoader: false,
      mobileNo: this.props.LoginStore.userMobileNumberV2 || null,
      otpDetails: {},
    };
    this.showForgotPasswordView = this.showForgotPasswordView.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.otpDetailsCallBack = this.otpDetailsCallBack.bind(this);
    this.gotoOTPScreen = this.gotoOTPScreen.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.props.LoginStore.setHideCloseBtn(true);
    const invalidInput2 = document.querySelector(".nw-sublogin2-form input");
    if (invalidInput2) {
      setTimeout(() => {
        invalidInput2.focus();
      });
    }
  }

  componentDidUpdate() {
    const invalidInput2 = document.querySelector(".nw-sublogin2-form input");
    if (invalidInput2) {
      setTimeout(() => {
        invalidInput2.focus();
      });
    }
  }


  onClickSubmit(e) {
    const { otpDetails } = this.state;
    const { userMobileNumberV2 } = this.props.LoginStore;
    e.preventDefault();
    this.props.LoginStore.loginWithOtp(userMobileNumberV2, otpDetails);
  }

  onLogin(e) {
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-sublogin-form input.is-invalid",
    );
    const userId = this.props.LoginStore.userEmailV2 || this.props.LoginStore.userMobileNumberV2;
    const { password } = this.passwordFields.state;
    if (invalidInput) {
      invalidInput.focus();
    }
    if (
      this.passwordFields.state.isPasswordInputValid
    ) {
      Analytics.trackEvent({
        action: Event.action.LOGIN_FILLED,
        category: Event.category.LOGIN,
        label: this.props.LoginStore.userEmailV2  ? Event.label.EMAIL : Event.label.PHONENO,
      });
      this.props.LoginStore.loginUserAction(userId, password)
        .catch(error => {
          const errCode = lodashGet(error.errors[0], "code", null);
          const mobileNumber = lodashGet(error.errors[0].data, "mobileNumber",null);
          if (errCode === "605") {
            if(mobileNumber){
              this.props.LoginStore.setMobileNumberV2(mobileNumber);
            }
            this.props.LoginStore.setShowHeader(true);
            this.props.LoginStore.setShowOtp(true);
            Analytics.trackEvent({
              action: Event.action.PHONE_VERIFY,
              category: Event.category.LOGIN,
            });
          }
          else {
            this.setState({
              authErrorMsg: blackboltConfig.ERROR_MSG,
            });
          }
          Analytics.trackEvent({
            action: Event.action.REGISTER_ERROR,
            category: Event.category.LOGIN,
            label: Event.label.getRegisterationError(error),
          });
          GTM.pushEvent(Event.GTMEvent.LOGIN_FAILED);
        });
    }
  }

  get showbtnLoader() {
    return this.state.showBtnLoader ? "" : "nwc-hide";
  }

  gotoOTPScreen(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.OTP_CLICKED,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.setShowOtp(true);
  }

  get enableValidateBtn() {
    return !this.state.enableBtn;
  }

  onBack() {
    const { showHeader, showOtp, goBackToLoginSub } = this.props.LoginStore;
    if (showOtp && showHeader && !goBackToLoginSub) {
      this.props.LoginStore.setShowHeader(false);
      this.props.LoginStore.setShowOtp(false);
    } else {
      this.props.LoginStore.changeView("loginSubV2");
    }
  }

  showForgotPasswordView() {
    const { mobileNoEntered, userMobileNumberV2, userEmailV2 } = this.props.LoginStore;
    const communicationType = mobileNoEntered ? blackboltConfig.mobile : blackboltConfig.email;
    const value = mobileNoEntered ? userMobileNumberV2 : userEmailV2;
    Analytics.trackEvent({
      action: Event.action.FORGOT_PASSWORD_ATTEMPT,
      category: Event.category.LOGIN,
      label: communicationType,
    });
    this.props.LoginStore.forgotPasswordActionV2(
      value,
      communicationType,
    );
  }

  componentWillUnmount() {
    this.props.LoginStore.setShowHeader(true);
    this.props.LoginStore.resetErrorMsg("");
  }

  otpDetailsCallBack(otpDetails) {
    this.setState({
      otpDetails: otpDetails
    });
  }


  get header() {
    const { showOtp } = this.props.LoginStore;
    return (
      <div className="nw-login-heading">
        {showOtp ? <div>{Login.LOGIN_SUBV2_MSG.OTP_LABEL}</div> : <div><div>{Login.LOGIN_SUBV2_MSG.PASSWORD_LABEL}</div><div>{Login.LOGIN_SUBV2_MSG.PASSWORD_LABEL2}</div></div>}
        <Img src={Endpoints.LOGIN_SCREEN_IMG} alt="nnnow" onError={Utils.noop} className="nw-loginotp-img" />
      </div>
    );
  }


  render() {
    const { captcha } = this.props.LoginStore;
    const { passwordAuthErrorMsg, disableSigninButton, showOtp, mobileNoEntered, showHeader } = this.props.LoginStore;
    return (
      <div className="nw-loginv2-container nw-loginv2-delay">
        <Button
          className="nw-backbtn"
          onClick={this.onBack}
        >
          <i className="icomoon-arrow_left nw-backbtn-icon" />
        </Button>
        {!showHeader && this.header}
        <div className="nw-form nw-loginv2-form">
          {!showOtp && <form className={`nw-sublogin2-form`} >
            <div className="nw-login2-sub">
              <div className="nw-loginv2-label nw-login2-pwd">{Login.LOGINV2_MESSAGES.PASSWORD_TITLE}</div>
            </div>
            <PasswordInput.component
              className="nwc-inp nw-login-input nw-loginv2-input"
              name="password"
              showErrorPart={false}
              showErrorMsg={Utils.noop}
              ref={values => {
                this.passwordFields = values;
              }}
              parentClassName="nw-loginv2-password"
              required
            />
            <div className="nw-form-err-msgv2">
              {passwordAuthErrorMsg}
            </div>
            <div>
              {mobileNoEntered && process.env.REACT_APP_REGISTER_OTP_ENABLED === "true" &&
                <Button
                  className="nw-goto-pswd-screen"
                  onClick={this.gotoOTPScreen}
                >
                  {Login.LOGIN_SUBV2_MSG.GOTO_PSWD_SCREEN_LABEL}
                </Button>

              }
              <Button
                onClick={this.showForgotPasswordView}
                type="button"
                className="nw-login-link nw-loginotp-forgotpwd"
              >
                {Login.LOGINV2_MESSAGES.FORGOT_PASS_LABEL}
              </Button>
            </div>
            {captcha && <Captcha.component loadFor="login" />}
            <Button
              type="submit"
              className="nwc-btn-primary nw-login-btn nw-register-submitbtn nw-loginotp-signin-submit"
              disabled={disableSigninButton}
              onClick={this.onLogin}
            >
              {Login.LOGINV2_MESSAGES.CONTINUE}
              <div className={`nw-login-loader ${this.showbtnLoader}`}>
                <Img
                  className="nw-loader"
                  src={Endpoints.LOADER}
                  alt="loader"
                  onError={Utils.noop}
                />
              </div>
            </Button>
          </form>}
          {showOtp &&
            <OtpSub.component
              onClickSubmit={this.onClickSubmit}
              otpDetailsCallBack={this.otpDetailsCallBack}
              showHeader={showHeader}
            />
          }
        </div>
      </div>
    );
  }
}

LoginOtp.wrappedComponent.propTypes = {
  mobileNumber: PropTypes.string.isRequired,
  LoginStore: PropTypes.shape({
    disableSigninButton: PropTypes.bool.isRequired,
    userMobileNumberV2: PropTypes.number.isRequired,
    loginWithOtp: PropTypes.func.isRequired,
    loginUserAction: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    resetErrorMsg: PropTypes.func.isRequired,
    passwordAuthErrorMsg: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component => inject("OtpStore", "LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginOtp.wrappedComponent,
  component: LoginOtp
};

export default ProjectComponent;