const USERNAME_REGEX = [
  {
    pattern: /^[a-zA-Z ]+$/,
    msg: "Name must have only alpabets",
  },
  {
    pattern: /^[a-zA-Z ]{3,}$/,
    msg: "Plug in atleast 3 characters.",
  },
];

const UPI_REGEX = [
  {
    pattern: /^.+@.+$/,
    msg: "Invalid UPI Id",
  }
];

const PINCODE_REGEX = [
  {
    pattern: /[1-9][0-9]{5}/,
    msg: "We need a valid 6-digit pincode. Try again, please.",
  },
];

const ADDRESS_REGEX = [
  {
    pattern: /^[ A-Za-z0-9_\.\/#\-,`'()]*$/,
    msg: "We allow only the following characters - a-z, 0-9, -/#().' and ,",
  },
  {
    pattern: /[a-zA-Z0-9]{3,}/,
    msg: "Please enter your address.",
  },
];

const MOBILE_NUMBER_REGEX = [
  {
    pattern: /^[6789]\d{9}$/,
    msg: "Please enter a valid Phone number.",
  },
];

const NEFT_FORM_NAME_REGEX = (minCharErrMsg, invalidErrMsg) => {
  if (
    minCharErrMsg &&
    minCharErrMsg.length &&
    invalidErrMsg &&
    invalidErrMsg.length
  ) {
    return [
      {
        pattern: /^[a-zA-Z ]+$/,
        msg: "Alphabets only, please",
      },
      {
        pattern: /^[a-zA-Z ]{3,}$/,
        msg: `Please enter your ${minCharErrMsg}`,
      },
    ];
  }
  return USERNAME_REGEX;
};

const ACCOUNT_NUMBER_REGEX = [
  {
    pattern: /^\S+$/,
    msg: "Please enter your Account Number!",
  },
];

const SPECIAL_CHAR_REGEX = /[^A-Z0-9]+/gi;

const XSS_REGEX = /(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script/ig
export {
  USERNAME_REGEX,
  SPECIAL_CHAR_REGEX,
  PINCODE_REGEX,
  ADDRESS_REGEX,
  MOBILE_NUMBER_REGEX,
  NEFT_FORM_NAME_REGEX,
  ACCOUNT_NUMBER_REGEX,
  UPI_REGEX,
  XSS_REGEX,
};
