import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
import { Utils } from "arv-reactcomponents";

import { HEADER } from "npmlinks-constants";
import Analytics, { EventFactory as Event } from "../../analytics";
import { generateGaLabel } from "../../utils";
import Anchor from "../../component/Anchor";

@inject("NavListStore")
@observer
class MainNav extends Component {
  static trackShowNavTree(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_MENU_NAVIGATION,
    });
  }

  static trackNavClick(name) {
    MainNav.trackShowNavTree(`${generateGaLabel(name).toLowerCase()}_click`);
  }

  constructor(props) {
    super(props);

    this.showNavTree = this.showNavTree.bind(this);
    this.isActiveClassName = this.isActiveClassName.bind(this);
  }

  get isHighlightedClassName() {
    const {
      menu: { name },
    } = this.props;
    const nameLower = (name && name.toLowerCase()) || null;

    return (
      (HEADER.HIGHLIGHTED_HEADINGS.includes(nameLower) && "is-highlighted") ||
      ""
    );
  }

  isActiveClassName(level2) {
    const { menu, NavListStore } = this.props;
    const { selectedNav } = NavListStore;
    return (menu === selectedNav && toJS(level2).length && "is-active") || "";
  }

  showNavTree(bool, menu) {
    return () => {
      const menuName = lodashGet(menu, "name", null);

      if (bool) {
        const eventAction = `${generateGaLabel(menuName)}_hover`;
        MainNav.trackShowNavTree(eventAction);
        this.props.NavListStore.setSelectedNav(menu);
      } else {
        this.props.NavListStore.setSelectedNav(null);
      }
    };
  }

  get bannerUrl() {
    const { menu } = this.props;
    return (menu && menu.bannerUrl) || null;
  }

  get bannerImage() {
    const { menu } = this.props;
    return (menu && menu.bannerImage) || null;
  }

  render() {
    const { menu } = this.props;
    return (
      <Fragment>
        <li
          key={menu.name}
          className="nw-mainnav-items"
          onMouseEnter={this.showNavTree(true, menu)}
          onFocus={Utils.noop}
          onMouseLeave={this.showNavTree(false)}
          onBlur={Utils.noop}
        >
          <Anchor.component
            href={menu.url}
            to={menu.url}
            onClick={() => {
              MainNav.trackNavClick(menu.name);
            }}
            className={`nw-mainnav-link ${this.isActiveClassName(
              menu.level2,
            )} ${this.isHighlightedClassName}`}
            target="_self"
          >
            <span className="nw-mainnav-text">{menu.name}</span>
          </Anchor.component>
        </li>
      </Fragment>
    );
  }
}

MainNav.wrappedComponent.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  NavListStore: PropTypes.shape({
    selectedNav: PropTypes.shape({}),
    setSelectedNav: PropTypes.func, 
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MainNav.wrappedComponent,
  component: MainNav,
};

export default ProjectComponent;
