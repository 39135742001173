const SSR_CACHING_EXCLUDED_PATHS = [
  "store-locator",
  "mybag",
  "account",
  "checkout",
  "orderdetails",
  "myorders",
  "orderconfirmation",
  "favorites",
  "whoweare",
  "customercare",
  "termsconditions",
  "contactus",
  "privacypolicy",
  "noresults",
  "apps",
  "reset-password",
  "404",
  "apple-app-site-association",
  "brandslist",
];
const SSR_CACHING_EXCLUDED_PATHS_OBJ = {
  "store-locator":true,
  "mybag":true,
  "account":true,
  "checkout":true,
  "orderdetails":true,
  "myorders":true,
  "orderconfirmation":true,
  "favorites":true,
  "whoweare":true,
  "customercare":true,
  "termsconditions":true,
  "contactus":true,
  "privacypolicy":true,
  "noresults":true,
  "apps":true,
  "reset-password":true,
  "404":true,
  "apple-app-site-association":true,
  "brandslist":true,
}
const NECESSARY_CACHING_PARAMS = [
  "at",
  "f-ge",
  "f-br",
  "cid",
  "cpr",
  "f-ad",
  "f-ag",
  "f-age",
  "f-al",
  "f-ar",
  "f-ben",
  "f-bl",
  "f-brist",
  "f-ca",
  "f-ch",
  "f-cl",
  "f-co",
  "f-coi",
  "f-col",
  "f-coll",
  "f-com",
  "f-conc",
  "f-cov",
  "f-cu",
  "f-cuf",
  "f-de",
  "f-den",
  "f-deni",
  "f-di",
  "f-dia",
  "f-dial",
  "f-dial-d",
  "f-dial-t",
  "f-dim",
  "f-dis",
  "f-dr",
  "f-dro",
  "f-dt",
  "f-ea",
  "f-em",
  "f-ex",
  "f-fa",
  "f-fe",
  "f-fea",
  "f-fi",
  "f-fin",
  "f-fl",
  "f-fli",
  "f-form",
  "f-fr",
  "f-fra",
  "f-frag-fam",
  "f-fram",
  "f-frame",
  "f-he",
  "f-hee",
  "f-ho",
  "f-ing-pref",
  "f-je",
  "f-kn",
  "f-la",
  "f-le",
  "f-len",
  "f-li",
  "f-ma",
  "f-me",
  "f-mo",
  "f-mu",
  "f-ne",
  "f-no",
  "f-ns",
  "f-oc",
  "f-pa",
  "f-pad",
  "f-pan",
  "f-pe",
  "f-pl",
  "f-pla",
  "f-po",
  "f-pow",
  "f-pr",
  "f-pro",
  "f-prod",
  "f-pf",
  "f-re",
  "f-sh",
  "f-si",
  "f-sk-tp",
  "f-sl",
  "f-slo",
  "f-so",
  "f-sp",
  "f-st",
  "f-str",
  "f-stra",
  "f-strap",
  "f-strap-c",
  "f-su-pr",
  "f-to",
  "f-tr",
  "f-tro",
  "f-trol",
  "f-ty",
  "f-up",
  "f-us",
  "f-use",
  "f-wa",
  "f-wal",
  "f-war",
  "f-wat",
  "f-watc",
  "f-wate",
  "f-water",
  "f-we",
  "f-wi",
  "ifr",
  "na",
  "os",
  "p",
  "p-dl",
  "p-st",
  "q",
  "qa",
  "r",
  "v",
  "sort",
  "style",
  "category",
  "gender",
  "brand",
  "baseurl",
  "iframeview",
  "id",
  "type",
  "lbn",
  "storeId",
  "module",
  "cmsPreview",
  "priority",
];

export { SSR_CACHING_EXCLUDED_PATHS, NECESSARY_CACHING_PARAMS, SSR_CACHING_EXCLUDED_PATHS_OBJ };
