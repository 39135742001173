import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";

import Analytics, { EventFactory as Event } from "../../analytics";
import { generateGaLabel } from "../../utils";

@inject("CommonStore")
@observer
class NavTree extends Component {
  static trackLinkClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_MENU_NAVIGATION,
    });
  }
  static onViewAllClick() {
    NavTree.trackLinkClick("brands_view_all_click");
  }

  static onLinkClick(event, title, level) {
    event.stopPropagation();
    const eventAction =
      level === "2"
        ? `${generateGaLabel(title)}_click`
        : `${generateGaLabel(title)}_selected`;
    NavTree.trackLinkClick(eventAction);
  }

  get isActiveClassName() {
    return (this.props.active && "is-active") || "";
  }

  render() {
    const { list, level, mainNav } = this.props;

    if (!list || (Array.isArray(toJS(list)) && list.length === 0)) {
      return null;
    }

    return (
      <ul className={`nw-navtreev2-list nw-navtreev2-level${level}`}>
        {list.map(menu => (
          <li
            role="presentation"
            key={`${mainNav}${menu.name}`}
            className={`nw-navtreev2-items nw-navtreev2-items${level}`}
            onClick={e => {
              NavTree.onLinkClick(e, `${menu.name}`, `${level}`);
            }}
          >
            <a
              href={menu.url}
              className={`nw-navtreev2-link nw-navtreev2-link-level${level}`}
            >
              {menu.name}
            </a>
            <NavTree
              level={level + 1}
              list={menu[`level${level + 1}`]}
              mainNav={mainNav}
            />
          </li>
        ))}
      </ul>
    );
  }
}

NavTree.wrappedComponent.defaultProps = {
  list: [],
  active: false,
};

NavTree.wrappedComponent.propTypes = {
  level: PropTypes.number.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  active: PropTypes.bool,
  mainNav: PropTypes.string.isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: NavTree.wrappedComponent,
  component: NavTree,
};

export default ProjectComponent;
