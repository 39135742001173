import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import Anchor from "../../../component/Anchor";

@inject("NavListStore")
@observer
class TopbarText extends Component {
  constructor(props) {
    super(props);
    this.triggerGAOnPromoClick = this.triggerGAOnPromoClick.bind(this);
  }

  triggerGAOnPromoClick() {
    Analytics.trackEvent({
      action: Event.action.PROMO_LINK_CLICKED,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_TOPNAV,
    });
  }

  get navMetaData() {
    return (
      (this.props.NavListStore.navListData &&
        this.props.NavListStore.navListData.data &&
        this.props.NavListStore.navListData.data.metaData) ||
      {}
    );
  }

  render() {
    if (this.navMetaData.topBandMessage) {
      return (
        <Anchor.component
        className="nw-topbartext nw-topbartext-link"
        href={this.navMetaData.topBandUrl}
        to={this.navMetaData.topBandUrl}
        target="_self"
        onClick={this.triggerGAOnPromoClick}
      >
       <i className="icomoon-star nw-topbartext-star nw-topbartext-starviolet" />
             <i className="icomoon-star nw-topbartext-star nw-topbartext-staryellow" />
             <i className="icomoon-star nw-topbartext-star nw-topbartext-starpink" />
             <div className="nw-topbartext-text">
               <span className="nw-topbartext-eachtext">
               </span>
               <span className="nw-topbartext-eachtext">
                 {this.navMetaData.topBandMessage}
               </span>
             </div>
             <i className="icomoon-star nw-topbartext-star nw-topbartext-starpink" />
             <i className="icomoon-star nw-topbartext-star nw-topbartext-staryellow" />
             <i className="icomoon-star nw-topbartext-star nw-topbartext-starviolet" />
      </Anchor.component>
      );
    }

    return null;
  }
}

TopbarText.wrappedComponent.propTypes = {
  NavListStore: PropTypes.shape({
    navListData: PropTypes.object,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: TopbarText.wrappedComponent,
  component: TopbarText,
};

export default ProjectComponent;
