import React, { Component } from "react";
import { Button, Input } from "arv-reactcomponents";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
// eslint-disable-next-line import/no-extraneous-dependencies
import { HEADER } from "npmlinks-constants";

@inject("CommonStore","SearchBarStore")

@observer
class SearchBarForm extends Component {
  constructor(props) {
    super(props);
    this.buttonActions = this.buttonActions.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }
  get brandname() {
    const [brand] = lodashGet(
      this,
      "props.CommonStore.brandDetails.parentBrandName",
      [],
    );
    return brand || HEADER.DEFAULT_SEARCHBRAND;
  }
  componentDidMount() {
    const { autoSuggestConfig } = this.props.SearchBarStore;
    autoSuggestConfig();
  }
  
  get currentInputView() {
    const { shrunkView, searchActive } = this.props;
    const { isDesktop } = this.props.CommonStore;
    return (
      (!isDesktop && "is-default") ||
      (shrunkView && searchActive && "is-shrunkviewopen") ||
      (shrunkView && !searchActive && "is-shrunkview") ||
      (searchActive && "is-searchactive") ||
      ""
    );
  }

  buttonActions(e, side) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.props.searchActive) {
      this.searchBarBlock.querySelector(".nw-searchbar-input").focus();
    }
    this.props.buttonActions(side);
    if(side === "right" )
      this.props.searchBarStatusUpdate(false);
  }
 
  
  keyDown(e) {
    const code = e.keyCode || e.which;
    if ((code === 8) || (code === 46) || (code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 96 && code <= 107) || (code >= 186 && code <= 192))
     this.props.keyDown();
  }

  handleOnFocus(e, bool) {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    const { updateAutoSuggestFlag } = this.props;
    updateAutoSuggestFlag(e, bool);
  }

  handleOnBlur(e, bool) {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    const { updateAutoSuggestFlag } = this.props;
    updateAutoSuggestFlag(e, bool);
  }

  render() {
    const { searchActive, query, toggleDropDownWrapper } = this.props;
    const { isDesktop } = this.props.CommonStore;
    return (
      <div
        className={`nw-searchbar-formcontainer ${(searchActive &&
          "is-active") ||
          ""}`}
        ref={elem => {
          this.searchBarBlock = elem;
        }}
      >
        <Button
          className="nw-searchbar-leftbutton"
          onClick={e => this.buttonActions(e, "left")}
        >
          {isDesktop && <i className="icomoon-search3 nw-searchbar-leftsearchicon" />}
          {!isDesktop && <i className="icomoon-arrow_left nw-searchbar-leftbuttonicon" />}
        </Button>
        <div
          className={`nw-searchbar-toggleablecontents ${this.currentInputView}`}
        >
          <form
            className="nw-searchbar-form"
            onSubmit={e => this.buttonActions(e, "submit")}
          >
            <Input
              className="nw-searchbar-input"
              onFocus={e => this.handleOnFocus(e, true)}
              onBlur={e => this.handleOnBlur(e, false)}
              value={query}
              placeholder={`${HEADER.SEARCHBAR_PLACEHOLDER}${this.brandname}`}
              onChange={e => toggleDropDownWrapper(e, true)}
              autoComplete="off"
              onKeyDown= {(e)=>this.keyDown(e)}
            />
          </form>
          <Button
            className={`nw-searchbar-rightbutton ${this.currentInputView}`}
            onClick={e => this.buttonActions(e, "right")}
          >
            {isDesktop && <span>x</span>}
            {!isDesktop && <i className="icomoon-search3" />}
          </Button>
        </div>
      </div>
    );
  }
}
SearchBarForm.wrappedComponent.propTypes = {
  query: PropTypes.string.isRequired,
  shrunkView: PropTypes.bool.isRequired,
  searchActive: PropTypes.bool.isRequired,
  buttonActions: PropTypes.func.isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};
const getFinalComponent = component =>
  inject("CommonStore")(observer(component));
const ProjectComponent = {
  getFinalComponent,
  wrapper: SearchBarForm.wrappedComponent,
  component: SearchBarForm,
};

export default ProjectComponent;
