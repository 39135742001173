import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Modal } from "arv-reactcomponents";

import { PRICE_INFO_BLOCK } from "npmlinks-constants";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { ToCurrency, CurrencyToVal } from "../../../utils";

@inject("CommonStore")
@observer
class PriceInfoBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMobInfoBlock: false,
    };

    this.handleMobInfoClick = this.handleMobInfoClick.bind(this);
    this.closePrompt = this.closePrompt.bind(this);
    this.priceInfoHover = this.priceInfoHover.bind(this);
  }

  get category() {
    this.pageType = window.pageType;
    return this.pageType;
  }

  handleMobInfoClick() {
    const { styleId } = this.props;
    const { isDesktop } = this.props.CommonStore;
    if (!isDesktop) {
      this.setState({
        showMobInfoBlock: true,
      });
    }
    Analytics.trackEvent({
      action: Event.action.PRICE_INFO_BLOCK_CLICK,
      category: this.category,
      label: styleId,
    });
  }

  closePrompt() {
    this.setState({
      showMobInfoBlock: false,
    });
  }

  priceInfoHover(bool) {
    const { styleId } = this.props;
    if (bool) {
      Analytics.trackEvent({
        action: Event.action.PRICE_INFO_BLOCK_HOVER,
        category: this.category,
        label: styleId,
      });
    }
  }

  get isRange() {
    const { mrpRange={}, sellingPriceRange={} } = this.props;
    const { min: mrpMin = 0, max: mrpMax = 0} = mrpRange;
    const { min: spMin = 0, max: spMax = 0} = sellingPriceRange;
    if (mrpMin === mrpMax && spMin === spMax) {
      return false;
    }
    return true;
  }

  get youSaveValue() {
    const {
      mrpText,
      sellingPriceText,
      mrpRange,
      sellingPriceRange,
    } = this.props;

    if (this.isRange && !mrpText) {
      const { min: mrpMin = 0, max: mrpMax = 0 } = mrpRange;
      const { min: spMin = 0, max: spMax = 0 } = sellingPriceRange;
      const discountMin = mrpMin - spMin;
      const discountMax = mrpMax - spMax;

      return `${ToCurrency(discountMin)} - ${ToCurrency(discountMax)}`;
    } else if (mrpText && sellingPriceText) {
      const finalValue =
        CurrencyToVal(mrpText) - CurrencyToVal(sellingPriceText);
      return `${ToCurrency(finalValue)}`;
    }
    return null;
  }

  get priceinfoSaveBlock() {
    const { discountText } = this.props;
    if (discountText) {
      return (
        <div className="nw-priceinfoblock-saveblock">
          <div className="nw-priceinfoblock-sp">
            {PRICE_INFO_BLOCK.youSaveTitle}
          </div>
          <div className="nw-priceinfoblock-price"> {this.youSaveValue} </div>
        </div>
      );
    }
    return null;
  }

  get youSaveMessageBlock() {
    const { discountText } = this.props;
    if (discountText) {
      return (
        <div className="nw-priceinfoblock-savemsgblock">
          {PRICE_INFO_BLOCK.youSaveMsgPrefix}
          {`${this.youSaveValue}`}
          {` ${discountText}`}
          {PRICE_INFO_BLOCK.youSaveMsgSuffix}
        </div>
      );
    }
    return null;
  }

  get mrpValue() {
    const { mrpRange, sellingPriceText } = this.props;
    if (this.isRange) {
      const { min: mrpMin = 0, max: mrpMax = 0 } = mrpRange;
      return `${ToCurrency(mrpMin)} - ${ToCurrency(mrpMax)}`;
    }
    return sellingPriceText;
  }

  get sellingPriceValue() {
    const { mrpText, sellingPriceRange, sellingPriceText } = this.props;
    if (this.isRange && !mrpText) {
      const { min: spMin, max: spMax } = sellingPriceRange;
      return `${ToCurrency(spMin)} - ${ToCurrency(spMax)}`;
    }
    return sellingPriceText;
  }

  get priceDetailsBlock() {
    const { mrpText, hasDiscountClassName } = this.props;
    return (
      <Fragment className="nw-priceinfoblock-mobwrapper">
        <div className="nw-priceinfoblock-header">
          {PRICE_INFO_BLOCK.headerTitle}
        </div>
        <div className="nw-priceinfoblock-body">
          <div className="nw-priceinfoblock-mrpblock">
            <div>
              <div className="nw-priceinfoblock-sp">
                {PRICE_INFO_BLOCK.mrpBlockTitle}
              </div>
              <div className="nw-priceinfoblock-subtitle">
                {PRICE_INFO_BLOCK.mrpBlockSubTitle}
              </div>
            </div>
            <div className="nw-priceinfoblock-price">
              {(mrpText && (
                <del
                  className={`
                  nw-priceblock-amt
                  nw-priceblock-mrp
                  ${hasDiscountClassName}
                  nw-priceinfoblock-mrp
                `}
                >
                  {mrpText}
                </del>
              )) ||
                this.mrpValue}
            </div>
          </div>
          {this.priceinfoSaveBlock}
        </div>
        <div className="nw-priceinfoblock-footer">
          <div className="nw-priceinfoblock-spblock">
            <div className="nw-priceinfoblock-sp">
              {PRICE_INFO_BLOCK.sellingPriceTitle}
            </div>
            <div className="nw-priceinfoblock-price">
              {this.sellingPriceValue}
            </div>
          </div>
        </div>
        {this.youSaveMessageBlock}
      </Fragment>
    );
  }

  get priceInfoModalBlock() {
    const { showMobInfoBlock } = this.state;
    if (showMobInfoBlock) {
      return (
        <Modal
          isOpen={showMobInfoBlock}
          onClose={this.closePrompt}
          className="nw-priceinfoblock-prompt"
        >
          {this.priceDetailsBlock}
        </Modal>
      );
    }
    return null;
  }

  render() {
    return (
      <div
        className="nw-priceinfoblock-infocontainer"
        onClick={this.handleMobInfoClick}
        onMouseEnter={e => this.priceInfoHover(true)}
        onMouseLeave={e => this.priceInfoHover(false)}
        role="presentation"
      >
        <i className="nw-priceinfoblock-recommendationicon icomoon-notification" />
        <div className="nw-priceinfoblock-infohover nw-desktop-only">
          {this.priceDetailsBlock}
        </div>
        {this.priceInfoModalBlock}
      </div>
    );
  }
}

PriceInfoBlock.propTypes = {
  mrpText: PropTypes.string.isRequired,
  sellingPriceText: PropTypes.string.isRequired,
  discountText: PropTypes.string.isRequired,
  mrpRange: PropTypes.shape({}).isRequired,
  sellingPriceRange: PropTypes.shape({}).isRequired,
  hasDiscountClassName: PropTypes.string.isRequired,
  styleId: PropTypes.bool.isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: PriceInfoBlock,
  component: PriceInfoBlock,
};

export default ProjectComponent;
