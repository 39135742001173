import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { HIDE_BRANDNAME } from "npmlinks-constants";

class ProductName extends PureComponent {
  render() {
    const { brand, styleName } = this.props.item;
    return (
      <div>
        {!HIDE_BRANDNAME && <h3 className="nw-mybagpopup-itemhead">{brand}</h3>}
        <div className="nw-mybagpopup-itemname">{styleName}</div>
      </div>
    );
  }
}
ProductName.propTypes = {
  item: PropTypes.shape({
    brand: PropTypes.string,
    styleName: PropTypes.string,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductName,
  component: ProductName,
};

export default ProjectComponent;
