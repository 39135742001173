/* eslint-disable */
import { observable, action, reaction } from "mobx";
import {
  mybagGuest,
  setGuestCart,
  getGuestCart,
  myBagDetails,
} from "./service";
import { isAuthenticated, arrayToKeyValuePair } from "../../utils";
import MyBagActions from "./actions";
import { Toastr } from "arv-reactcomponents";

import { MyBag } from "npmlinks-constants";

/*
in this store we are storing items as key value pair {} so that we can get and remove items easily
BUt in odin1 we are storing as list [] -- so we need to change the api data later, also  we need to remove odin1.0 dependency
 */

// TODO: if you have  one item in cart and add one more when mybag pop open it got vanished

class MybagStoreClass extends MyBagActions {
  constructor(props) {
    super(props);
    if (props && props.cartItems) {
      this.tempInitialCart = props.cartItems;
    }
    this.isProductInTheBag = this.isProductInTheBag.bind(this);
    this.myBagRequestPayload = this.myBagRequestPayload.bind(this);
    this.getSku = this.getSku.bind(this);
    this.getQty = this.getQty.bind(this);
    this.getStyleId = this.getStyleId.bind(this);
    this.getProductId = this.getProductId.bind(this);
    this.getSellableQuantity = this.getSellableQuantity.bind(this);
    this.handleErrorsAndExceptopions = this.handleErrorsAndExceptopions.bind(
      this,
    );
    this.isCartAvailable = this.isCartAvailable.bind(this);
    if (!isAuthenticated()) {
      this.cartItems = getGuestCart();
    }
  }

  //TODO:: need to use obeserable map otherwise after removing properites it will take time to reflect
  @observable cartItems = this.tempInitialCart || {};

  isProductInTheBag(item) {
    return (
      (item && item.selectedSku && item.selectedSku.skuId in this.cartItems) ||
      (item && item.skuId && item.skuId in this.cartItems)
    );
  }

  getSku(product) {
    return product.skuId || product.selectedSku.skuId;
  }
  getProductId(product) {
    return product.productId || product.selectedSku.productId;
  }
  getQty(product) {
    return product.qty || (product.selectedSku && product.selectedSku.qty) || 1;
  }
  getStyleId(product) {
    return (
      product.styleId ||
      (product.selectedSku && product.selectedSku.styleId) ||
      1
    );
  }
  getSellableQuantity(product) {
    const maxProductQty = product.sellableQty || 0;
    return Math.min(maxProductQty, MyBag.MYBAGPAGE.MAX_QTY_ALLOWED);
  }

  handleErrorsAndExceptopions(myBagOverviewData, tempProductQtyInBag, product) {
    if (myBagOverviewData && myBagOverviewData.exception) {
      CartToaster(myBagOverviewData.msg);
      return;
    }
    if (tempProductQtyInBag > this.getSellableQuantity(product)) {
      CartToaster(`we can add max ${this.getSellableQuantity(product)} `);
      return;
    }
  }

  isCartAvailable(products) {
    let tempproducts = Object.values(products);
    return tempproducts && tempproducts.length;
  }

  updateCart(myBagOverviewData) {
    //before updating apply some logic on cart Items then update local cart according this
    if (
      myBagOverviewData &&
      myBagOverviewData.data &&
      myBagOverviewData.data.products &&
      myBagOverviewData.data.products.length
    ) {
      this.cartItems = arrayToKeyValuePair(
        myBagOverviewData.data.products,
        "skuId",
      );
    } else {
      this.cartItems = arrayToKeyValuePair(myBagOverviewData.products, "skuId");
    }

    setGuestCart(this.cartItems);
  }

  myBagRequestPayload(products = [], isProductRemove = false) {
    const mybagRequest = item => ({
      skuId: this.getSku(item),
      qty: this.getQty(item),
    });
    const mybagRemoverequest = item => ({
      skuId: this.getSku(item),
      styleId: this.getStyleId(item),
      productId: this.getProductId(item),
    });

    if (!Array.isArray(products)) {
      products = [products];
    }
    return {
      products: isProductRemove
        ? products.map(mybagRemoverequest)
        : products.map(mybagRequest),
    };
  }
}

export default MybagStoreClass;
