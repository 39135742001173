// Import Core Components here
import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { Button, Ripple, Utils, Img, Toastr } from "arv-reactcomponents";
import { blackboltConfig, Login, Endpoints, PAYMENTPAGE } from "npmlinks-constants";

// Import App depedency here

import Analytics from "../../../../analytics";
import GTM from "../../../../analytics/GTM";
import Event from "../../../../analytics/eventFactory";
import UserNameInputWrapper from "../../UserNameInputWrapper";
import EmailInput  from "../../EmailInput";
import PasswordInput  from "../../PasswordInput";
import MobileNumberInput  from "../../MobileNumberInput";
import OtpSub from "../OtpSub";
import lodashGet from "lodash.get"; 
import { RegisterCaptcha } from "../../../../molecules";


@inject("OtpStore","LoginStore","CodStore")
@observer
class RegistrationV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authErrorMsg: "",
      mobileNumber: this.props.LoginStore.userMobileNumberV2,
      email: this.props.LoginStore.userEmailV2,
      throughService: PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH,
      onboardDetailsObj: {},
      gender: "",
      showOTPBlock: false,
      showVerfiyButtonLoader: false,
      otpDetails: {},
      userName: "",
      selectSelectedValue: "",
      onBackView: "registrationV2"
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.updateGender = this.updateGender.bind(this);
    this.otpDetailsCallBack = this.otpDetailsCallBack.bind(this);
    this.validateMobileNumber = this.validateMobileNumber.bind(this);
    this.onBack = this.onBack.bind(this);
    this.props.LoginStore.resetErrorMsg("");
  }

  componentDidMount() {
    this.props.LoginStore.getPasswordValidationRegex();
    this.props.LoginStore.setHideCloseBtn(true);
  }

  updateGender = (gender,selectSelectedValue) => {
    const { GENDER_MAPPING } = Login.REGISTRATION_MSG;
    this.setState({
      gender: GENDER_MAPPING[gender],
      selectSelectedValue: selectSelectedValue
    });
  }

  otpDetailsCallBack(otpDetails) {
    this.setState({
      otpDetails: otpDetails
    });
  }
  

  onClickSubmit(e){
    e.preventDefault();
    e.stopPropagation();
    this.createAccount();
  }

  get isShowLoader() {
    const { showVerfiyButtonLoader } = this.state;
    return showVerfiyButtonLoader ? "" : "nwc-hidden";
  }

  get formVisibility() {
    return this.state.showOTPBlock ? "nwc-hidden" : "";
  }

  createAccount() {
    const { onboardDetailsObj, otpDetails } = this.state;
    this.props.LoginStore.registerWithOtp(onboardDetailsObj, otpDetails, false)
      .catch(error => {
        const detail = lodashGet(error.errors[0], "detail", blackboltConfig.ERROR_MSG);
        const errCode = lodashGet(error.errors[0], "code", null);
        if (errCode === "605") {
          this.setState({
            showOTPBlock: true,
          });
          Analytics.trackEvent({
            action: Event.action.PHONE_VERIFY,
            category: Event.category.LOGIN,
          });
        }
        else {
          this.setState({
            authErrorMsg: detail,
          });
          Analytics.trackEvent({
            action: Event.action.REGISTER_ERROR,
            category: Event.category.LOGIN,
            label: Event.label.getRegisterationError(error),
          });
        }
        GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
      });
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    const { email, isEmailInputValid } = this.emailFields.state;
    const { mobileNumber, isMobileInputValid } = this.mobileNoFields.state;
    const { userName: name, isUserNameisValid, selectSelectedValue } = this.userNameFields.state;
    const { password, isPasswordInputValid } = this.passwordFields.state;
    const { gender } = this.state;
    this.setState({
      authErrorMsg: "",
      userName: name,
      selectSelectedValue: selectSelectedValue,
      email: email,
      mobileNumber: mobileNumber
    });
    Analytics.trackEvent({
      action: Event.action.REGISTER_ATTEMPT,
      category: Event.category.LOGIN
    });
    
    if (
      isEmailInputValid &&
      isMobileInputValid &&
      isPasswordInputValid &&
      isUserNameisValid
    ) 
    {
      const onboardDetails = {
        email,
        mobileNumber,
        password,
        name,
        gender
      };
      this.setState({
        onboardDetailsObj: onboardDetails,
      }, () => {
        this.props.LoginStore.setMobileNumberV2(mobileNumber);
        this.createAccount();
      });
    } else {
      const { FORM_ERROR_MSG } = Login.REGISTRATION_MSG;
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: FORM_ERROR_MSG,
        timeout: 3000,
      });
    }
  }

  validateMobileNumber(mobileNumber) {
    this.setState({
      verifyButtonEnable: mobileNumber && mobileNumber.isValid,
    });
  }

  get RegPageheader() {
    const { CREATE_LABEL1, CREATE_LABEL2 } = Login.LOGIN_SUBV2_MSG;
    return (
      <div className="nw-login-heading">
      <div>
        {CREATE_LABEL1}
      </div>
      <div>
        {CREATE_LABEL2}
      </div>
      </div>
    );
  }

  passwordChange() {
    this.setState({ highlightPasswordField: false });
  }

  get userExistErrorMsgBlock() {
    const { otpAuthErrorMsg } = this.props.LoginStore;
    return otpAuthErrorMsg ? (<span className="nw-login-error">{otpAuthErrorMsg}</span>) : "";
  }

  onBack(e) {
    e.preventDefault();
    const { showOTPBlock } = this.state;
    if (showOTPBlock) {
      this.setState({
        showOTPBlock: false,
        otpDetails: {},
      });
      this.props.LoginStore.resetErrorMsg("");
    }
    else {
      this.setState({
        email: "",
        selectSelectedValue: "",
        mobileNumber: "",
      });
      this.props.LoginStore.changeView("loginSubV2");
    }
    this.props.LoginStore.clearUserId();
  }

  get registerWithOTPErrorMsg() {
    const { registerWithOTPErrorMsg } = this.props.LoginStore;
    if (registerWithOTPErrorMsg) {
      Analytics.trackEvent({
        action: Event.action.REGISTER_ERROR,
        category: Event.category.LOGIN,
        label: Event.label.getRegisterationError(registerWithOTPErrorMsg),
      });
      GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
      return (<span className="nw-login-error">{registerWithOTPErrorMsg}</span>);
    }
  }

  render() {
    const {
      showOTPBlock,
      mobileNumber,
      email,
      userName,
      selectSelectedValue,
      onBackView
    } = this.state;
    const captchaEnabled = process.env.REACT_APP_REGISTER_CAPTCHA_ENABLED || false;
    const { passwordValidationRegex } = this.props.LoginStore
    const { regex, errorMessage } = toJS(passwordValidationRegex);
    const { REGISTRATION_FIELDS } = Login.REGISTRATION_MSG;
    return (
      <div className="nw-loginv2-container">
        <Button
          className="nw-backbtn"
          onClick={this.onBack}
        >
          <i className="icomoon-arrow_left nw-backbtn-icon" />
        </Button>
        {!showOTPBlock &&
          <div>{this.RegPageheader}
            <form
              className={`nw-form nw-loginv2-form`}
              name="registerForm"
              autoComplete="off"
            >
              <div className="nw-loginv2-reg-label">{REGISTRATION_FIELDS.NAME}</div>
              <UserNameInputWrapper.component
                className="nw-registrationv2-input nw-registrationv2-userid"
                classNameErr="nw-form-err-msgv2"
                ref={values => {
                  this.userNameFields = values;
                }}
                required
                userName={userName}
                updateGender={this.updateGender}
                selectSelectedValue={selectSelectedValue}
              />
              <div className="nw-loginv2-reg-label">{REGISTRATION_FIELDS.EMAIL}</div>
              <EmailInput.component
                className="nw-login-input nw-registrationv2-input"
                classNameErr="nw-form-err-msgv2"
                name="registrationv2-email"
                ref={values => {
                  this.emailFields = values;
                }}
                email={email}
                required
              />
              <div className="nw-loginv2-reg-label">{REGISTRATION_FIELDS.PASSWORD}</div>
              <PasswordInput.component
                className="nw-login-input nw-registrationv2-input"
                classNameErr="nw-form-err-msgv2"
                name="registrationv2-password"
                showErrorMsg={Utils.noop}
                minLength={8}
                ref={values => {
                  this.passwordFields = values;
                }}
                pattern={regex}
                msg={errorMessage}
                //={this.passwordChange}
                parentClassName="nw-registrationv2-password"
                required
              />
              <div className="nw-loginv2-reg-label">{REGISTRATION_FIELDS.PHONE_NUMBER}</div>
              <MobileNumberInput.component
                className="nw-login-input nw-registrationv2-input"
                classNameErr="nw-form-err-msgv2"
                name="mobile number"
                maxLength={10}
                ref={values => {
                  this.mobileNoFields = values;
                }}
                mobileNo={mobileNumber}
                onChange={this.validateMobileNumber}
                required
              />
              <Ripple className="nwc-ripple-light">
                <Button
                  onClick={this.onSubmit}
                  className={`nwc-btn-primary nw-login-btn nw-registerv2-submitbtn`}
                >
                  {REGISTRATION_FIELDS.VERIFY_BUTTON_MSG}
                  <div className={`nw-login-loader ${this.isShowLoader}`}>
                    <Img
                      className="nw-loader"
                      src={Endpoints.LOADER}
                      alt="loader"
                      onError={Utils.noop}
                    />
                  </div>
                </Button>
              </Ripple>
              {this.userExistErrorMsgBlock}
            </form>
          </div>
        }
        {
          showOTPBlock &&
          <OtpSub.component
            otpDetailsCallBack={this.otpDetailsCallBack}
            onClickSubmit={this.onClickSubmit}
            onBackView={"registrationV2"}
            />
        }
       {!showOTPBlock && captchaEnabled === "true" && onBackView == "registrationV2" &&<RegisterCaptcha.component loadFor="login" />}
      </div>
    );
  }
}

RegistrationV2.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    userOnboard: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
    userMobileNumberV2: PropTypes.number.isRequired,
    userExistErrorMsg: PropTypes.string.isRequired,
    registerWithOTPErrorMsg: PropTypes.string.isRequired,
    checkUserExist: PropTypes.func.isRequired,
    registerWithOtp: PropTypes.func.isRequired,
    setUserExistErrorMsg: PropTypes.func.isRequired,
    passwordValidationRegex: PropTypes.shape({
      regex: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("LoginStore","OtpStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: RegistrationV2.wrappedComponent,
  component: RegistrationV2
};

export default ProjectComponent;