import React, { PureComponent } from "react";
import { Loader } from "arv-reactcomponents";

class BodyOverlay extends PureComponent {
  static show(className) {
    const elem = document.querySelector(".nw-bodyoverlay");
    if (!elem) {
      return;
    }

    elem.className = `nw-bodyoverlay ${className}`;
  }

  static hide() {
    const elem = document.querySelector(".nw-bodyoverlay");
    if (!elem) {
      return;
    }
    elem.className = `nw-bodyoverlay nwc-hide`;
  }

  render() {
    return (
      <div className="nw-bodyoverlay nwc-hide">
        <div className="nw-bodyoverlay-loadercontainer">
          <Loader />
        </div>
      </div>
    );
  }
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BodyOverlay,
  component: BodyOverlay,
};

export default ProjectComponent;
