import React, { Component } from "react";
import { Img } from "arv-reactcomponents";
import { toJS } from "mobx";

import { sanitiseUrl, NavMethods } from "../../utils";
import  Anchor  from "../../component/Anchor";

import NavTree from "../NavTree";
import NavButtonList from "../NavButtonList";

class NavTreeContainer extends Component {
  get isActiveClassName() {
    return (this.props.active && "is-active") || "";
  }

  get bannerClassName() {
    return (
      (this.props.bannerImg && "nw-navtreev2-imagecontainer") ||
      "nw-productview-imgalt"
    );
  }

  get onlyImageClassName() {
    return (
      (this.props.list.length === 0 &&
        this.props.bannerImg &&
        "nw-navtreev2-image") ||
      "nw-navtreev2-image nw-navtreev2-singleimage"
    );
  }

  finalNavList(level) {
    this.finalList = NavMethods.deduceFinalNavList(level);
    return this.finalList;
  }

  render() {
    const {
      list,
      level,
      mainNav,
      viewAllUrl,
      bannerUrl,
      bannerImg,
    } = this.props;

    if (
      !list ||
      (Array.isArray(toJS(list)) && list.length === 0 && !bannerImg)
    ) {
      return null;
    }

    const finalList = this.finalNavList(list);

    const { buttonList } = finalList || {};

    return (
      <div className={`nw-navlist-wrapper ${this.isActiveClassName}`}>
        <div className="nw-sub-navtreeholder2 nwc-custom-scrollbar">
          <div className="nw-navlist-container">
            <NavTree.component
              level={level}
              list={finalList}
              mainNav={mainNav}
              viewAllUrl={viewAllUrl}
              bannerImg={bannerImg}
              bannerUrl={bannerUrl}
            />
            {bannerImg ? (
              <Anchor.component
                className={this.bannerClassName}
                href={sanitiseUrl(bannerUrl)}
                to={sanitiseUrl(bannerUrl)}
                target="_self"
              >
                <Img
                  className={this.onlyImageClassName}
                  src={bannerImg}
                  alt={`${mainNav} BANNER`}
                />
              </Anchor.component>
            ) : null}
          </div>
        </div>
        <div className="nw-sub-navbuttoncontainer">
          <NavButtonList.component buttonList={buttonList} />
        </div>
      </div>
    );
  }
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: NavTreeContainer,
  component: NavTreeContainer,
};

export default ProjectComponent;
