import { META, RegexMethods } from "npmlinks-constants";

const normaliseUrlFromInvalidCases = (search = "") =>
  (search &&
    search
      .split("+")
      .join("%20")
      .split("%%")
      .join("%25%")) ||
  "";
const gethostdomainpath = passedUrl => {
  // TODO:: need to check later regex got modified
  // const [url, domain, path] = new RegExp(DOMAINREGEX).exec(passedUrl);
  const [url, domain, path] = /^(?:\w+\:\/\/)?([^\/]+)(.*)$/.exec(passedUrl);
  return {
    url,
    domain,
    path,
  };
};

const validateUrlforMalform = (url) => {
  return url ? url.replace(/%(?!(20|25|26|2F|27|5C|5E|C3|B4|94|A4|22|0A|AB|AF|B6|BC|BF|84|8B|8F|96|9C|B8|A1|C4|87|A9|B3|C5|9B|BA|BD|81|86|89|83|93|9A|9D|B9|A2|AD|B1|A8|95|99|91|92))/g,' ').replace(/(%)\1+/g,' ') : ''
}

const queryParamsToObject = (search = "") => {
  try {
    const hashes =
      normaliseUrlFromInvalidCases(search)
        .slice(search.indexOf("?") + 1)
        .split("&") || [];
    const params = {};
    hashes.map(hash => {
      let [key, val] = hash.split("=");
      if(val){
        val = val.replace('%26','&')
        val = validateUrlforMalform(val)
      }
      params[key] = decodeURIComponent(val);
      return null;
    });
    Object.keys(params).forEach(key => {
      if (!key) {
        delete params[key];
      }
    });
    return params;
  } catch (error) {
    return {};
  }
};

const makeUrlAbsolute = (url = "") =>
  (url.indexOf(".com") < 0 && `${window.location.origin}${url}`) || url;

function removeProtocolWww(urlTemp = "") {
  let url = urlTemp;
  const presentInStarting = (string, substring) =>
    string.indexOf(substring) === 0;
  if (presentInStarting(url, META.HTTPS)) {
    url = url.substring(META.HTTPS.length);
  } else if (presentInStarting(url, META.HTTP)) {
    url = url.substring(META.HTTP.length);
  }
  if (presentInStarting(url, META.defaultSubDomainWww)) {
    url = url.substring(META.defaultSubDomainWww.length);
  }
  return url;
}

const cacheQueryParser = (query) => {
  let out = "";
  if (typeof query === "string") {
    out = query.split(":").pop().replace(/^[^/]*/, "") || "";
  }
  return out;
};

const validateForXSS = (query) => {
  return RegexMethods.XSS_REGEX.test(query);
}

export {
  gethostdomainpath,
  queryParamsToObject,
  makeUrlAbsolute,
  normaliseUrlFromInvalidCases,
  removeProtocolWww,
  cacheQueryParser,
  validateUrlforMalform,
  validateForXSS
};
export default gethostdomainpath;
