import React, { Component } from "react";
import PropTypes from "prop-types";
import { Img } from "arv-reactcomponents";

import { HOME_PAGE_URL, Endpoints } from "npmlinks-constants";
import Analytics, { EventFactory as Event } from "../../analytics";
import Anchor from "../../component/Anchor";

class NnnowLogo extends Component {
  static onLogoClick() {
    Analytics.trackEvent({
      action: Event.action.NNNOW_LOGO_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_ABOVE_MENU_CTR,
    });
  }

  render() {
    const { className, alt } = this.props;

    return (
      <Anchor.component
        href={HOME_PAGE_URL}
        to={HOME_PAGE_URL}
        target="_self"
        className={`nw-nnnowlogo-link ${className}`}
        onClick={NnnowLogo.onLogoClick}
      >
        <Img
          className="nw-nnnowlogo-img"
          src={Endpoints.nnnowLogoUrl}
          alt={alt}
        />
      </Anchor.component>
    );
  }
}

NnnowLogo.defaultProps = {
  className: "",
  alt: "NNNOW Logo",
};

NnnowLogo.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: NnnowLogo,
  component: NnnowLogo,
};

export default ProjectComponent;
