import Stores from "./Stores";

let stores;
const init = data => {
  stores = new Stores(data).getStores();
  stores.LoginStore.setStores(stores.WishListStore, stores.MybagStore);
  return stores;
};
const getStores = () => stores;

export { init, getStores };
export { default as FooterStoreClass } from "./FooterStore";
export { default as AddressStoreClass } from "./AddressStore";
export { default as HeaderStoreClass } from "./HeaderStore";
export { default as GetTypeStoreClass } from "./GetTypeStore";
export { default as NavListStoreClass } from "./NavListStore";
export { default as CommonStoreClass } from "./CommonStore";
export { default as LoginStoreClass } from "./Login";
export { default as SearchBarStoreClass } from "./SearchBarStore";
export { default as StoreLocatorStore } from "./StoreLocatorStore";
export { default as StoreListStore } from "./StoreListStore";
export { default as ProductStoreClass } from "./ProductStore";
export { default as MybagStoreClass } from "./MybagStore";
export { default as WishListStoreClass } from "./WishListStore";
export { default as BrandStoreClass } from "./BrandStore";
export { default as PromotionsStoreClass } from "./PromotionsStore";
export { default as CheckoutStoreClass } from "./CheckoutStore";
export { default as PaymentStoreClass } from "./PaymentStore";
export { default as CodStoreClass } from "./CodStore";
export { default as OtpStoreClass } from "./OtpStore";
export { default as CreditCardClass } from "./CreditCardStore";
export { default as SavedCardStoreClass } from "./SavedCardStore";
export { default as NetbankingStoreClass } from "./NetbankingStore";
export { default as WalletStoreClass } from "./WalletStore";
export { default as OrderConfirmationClass } from "./OrderConfirmationStore";
export { default as MyAccountStore } from "./MyAccountStore";
export { default as MyOrdersStore } from "./MyOrdersStore";
export { default as NoResultsStore } from "./NoResultsStore";
export { default as ReturnStore } from "./ReturnStore";
export { default as StaticPageStore } from "./StaticPageStore";
export { default as RazorpayStoreClass } from "./RazorpayStore";
export { default as LoyaltyStore } from "./LoyaltyStore";
export { default as ReviewStore } from "./ReviewStore";
export { default as CardlessEmiStore } from "./CardlessEmiStore";
export {default as PhonepeStore} from "./PhonepeStore";
