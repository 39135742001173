const CANCELFLOW = {
  returnOrCancelReason: {
    next: "returnOrCancelRefund",
    previous: null,
    inner: null,
  },
  returnOrCancelRefund: {
    next: "returnOrCancelComplete",
    previous: "returnOrCancelReason",
    inner: 'addNeft',
  },
  returnOrCancelComplete: {
    next: null,
    previous: "returnOrCancelRefund",
    inner: null,
  },
  addNeft: {
    next: "returnOrCancelRefund",
    previous: "returnOrCancelRefund",
    inner: null,
  },
};

const CANCEL_REASONS = {
  TITLE: "Cancel",
  REASON_SELECTION_HEADER: "Reason for cancelling",
  REASON_SELECTION_PLACEHOLDER: "Select Reason",
  COMMENT_HEADER: "Additional comments",
  COMMENT_PLACEHOLDER: "Let us know what happened so that we can improve upon.",
  MAXLENGTH: "255",
  CANCEL_FAILURE_STATUS: 400,
};

const CANCEL_COMPLETE = {
  COMPLETE_IMAGE:
    "https://d2ew2jx6vkwv1p.cloudfront.net/client/assets/returns/images/vector-smart-object.png",
  COMPLETE_TITLE: "Cancel Initiated!",
  CONFIRMATION_CLASS: "nw-returns-confirmation",
  COMPLETE_MESSAGE: "Your refund will be processed in 5-7 days.",
};
const CANCEL_ELIGIBLE = "CANCEL";

export { CANCELFLOW, CANCEL_REASONS, CANCEL_COMPLETE, CANCEL_ELIGIBLE };
