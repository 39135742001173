import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { PropTypes } from "prop-types";
import FilterIcon from "../FilterIcon";
import { Loader } from "arv-reactcomponents";
import { DONOT_SHOW_OCCASION_FILTER_IDS } from "npmlinks-constants";

@inject("CommonStore")
@observer
class FilterIconList extends Component {
  static filterOutIcons(props) {
    const filtersToBeExcluded = [];
    const isBrandPage =
      (props.CommonStore && props.CommonStore.isBrandPage) || false;
    const brandId =
      (props.CommonStore &&
        props.CommonStore.brandDetails &&
        props.CommonStore.brandDetails.id) ||
      "";
    const donotIncludeOccasions = DONOT_SHOW_OCCASION_FILTER_IDS.includes(
      brandId,
    );
    if (isBrandPage) {
      filtersToBeExcluded.push("Brand");
    }
    if (donotIncludeOccasions) {
      filtersToBeExcluded.push("Occasion");
    }
    const updatedFilters =
      props &&
      props.store &&
      props.store.filters &&
      props.store.filters.filter(
        item => !filtersToBeExcluded.includes(item.filterType),
      );
    return updatedFilters;
  }
  constructor(props) {
    super(props);
    this.selectElementAndFilter = this.selectElementAndFilter.bind(this);
    this.hoverOnIconFilter = this.hoverOnIconFilter.bind(this);
    this.toggleActiveBrand = this.toggleActiveBrand.bind(this);
  }
  selectElementAndFilter(filterIcon, element, brandKey) {
    this.props.store.selectElementAndFilter(filterIcon, element, brandKey);
  }
  hoverOnIconFilter(filterIcon, isEnter) {
    this.props.store.hoverOnIconFilter(filterIcon.id, isEnter);
  }
  toggleActiveBrand(activeBrand, index) {
    this.props.store.toggleActiveBrand(activeBrand, index);
  }
  render() {
    const updatedFilters = FilterIconList.filterOutIcons(this.props);
    return (
      <div className={`nw-search-icon ${updatedFilters ? "" : "loading"}`}>
        {updatedFilters &&
          updatedFilters.map(icon => (
            <FilterIcon.component
              key={icon.filterType}
              icon={icon}
              selectElementAndFilter={this.selectElementAndFilter}
              hoverOnIconFilter={this.hoverOnIconFilter}
              toggleActiveBrand={this.toggleActiveBrand}
            />
          ))}
        {!updatedFilters && (
          <div className="nw-searchloader">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

FilterIconList.propTypes = {
  store: PropTypes.shape({
    filters: PropTypes.shape([]),
    selectElementAndFilter: PropTypes.func,
    toggleActiveBrand: PropTypes.func,
    hoverOnIconFilter: PropTypes.func,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FilterIconList.wrappedComponent,
  component: FilterIconList,
};

export default ProjectComponent;
