// Import Core Components here
import React, { Component } from "react";
import PropTypes from "prop-types";

// Import App depedency here
import { Input, Utils, Img } from "arv-reactcomponents";
import { RegexMethods, Endpoints } from "npmlinks-constants";

class UserNameInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserInputValid: false,
      userInputErrorMsg: "",
      userName: props.userName,
      userNameErrorClass: "",
      isValid: "",
    };
    this.onBlur = this.onBlur.bind(this);
    this.validateUserName = this.validateUserName.bind(this);
  }

  componentDidMount() {
    const { userName } = this.state;
    if (userName) {
      const e = {
        target: {
          value: userName,
        },
      };
      this.validateUserName(e);
    }
  }

  onBlur() {
    let userNameErrorClass = "nwc-hidden";
    let isValid = "";
    if (!this.state.isUserInputValid) {
      userNameErrorClass = "";
      isValid = "is-invalid";
    }
    this.setState({ userNameErrorClass, isValid });
    this.props.onBlur(isValid);
  }

  validateUserName(e) {
    this.setState({ userNameErrorClass: "nwc-hidden" });
    const validateUserField = this.validateUserFieldRef.getValidatedOutput(
      e.target.value,
    );
    this.setState({
      userName: validateUserField.value,
      isUserInputValid: validateUserField.isValid,
      userInputErrorMsg: validateUserField.errorMsg,
      isValid: "",
    });
    this.props.onValid(validateUserField.value, validateUserField);
    this.props.onNameChange ? this.props.onNameChange() : null;
  }

  static getDerivedStateFromProps(props, state) {
    const { highlightNameField: isUserInputValid } = props
    if (isUserInputValid !== state.isUserInputValid) {
      return {
        isValid: isUserInputValid ? "is-invalid" : "",
        userNameErrorClass: "",
        userInputErrorMsg: props.userInputErrorMsg
      };
    }
    return null;
  }

  render() {
    let {
      userInputErrorMsg,
      userName,
      userNameErrorClass,
      isValid,
    } = this.state;
    const {
      className,
      placeholder,
      name,
      showWarningIcon,
      showLoader,
      showErrorMsg,
      classNameErr,
      ...otherProps
    } = this.props;
    return (
      <div className="nw-username-input-container">
        <Input
          {...otherProps}
          className={`${className} ${isValid}`}
          name={name}
          onBlur={this.onBlur}
          onChange={this.validateUserName}
          onFocus={this.onFocus}
          placeholder={placeholder}
          type="text"
          value={userName}
          ref={values => {
            this.validateUserFieldRef = values;
          }}
          id="username-input"
          validateWithPattern={RegexMethods.USERNAME_REGEX}
        />
        {userInputErrorMsg !== "" &&
          showWarningIcon && (
            <i className="icomoon-notification nw-input-warning-icon" />
          )}
        {showLoader && (
          <div className="nw-login-loader">
            <Img
              className="nw-username-loader"
              src={Endpoints.LOADER}
              alt="loader"
            />
          </div>
        )}
           {
          showErrorMsg && (
            <div className={`${classNameErr} ${userNameErrorClass}`}>
              {userInputErrorMsg}
            </div>
          )
        }
      </div>
    );
  }
}

UserNameInput.defaultProps = {
  className: "",
  userName: "",
  onValid: Utils.noop,
  showWarningIcon: false,
  showLoader: false,
  required: false,
  userNameInputErrorMsg: "",
  highlightNameField: false,
  showErrorMsg: true,
  onBlur: Utils.noop,
  classNameErr: "nw-form-err-msg",

};

UserNameInput.propTypes = {
  userName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onValid: PropTypes.func,
  showWarningIcon: PropTypes.bool,
  showLoader: PropTypes.bool,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  onBlur: PropTypes.func,
  classNameErr: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: UserNameInput,
  component: UserNameInput,
};

export default ProjectComponent;
