import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ROUTE_PATHS } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import Analytics, { EventFactory as Event, GTM } from "../../analytics";


class StoreLocatorLink extends PureComponent {
  constructor(props) {
    super(props);

    this.onLinkClick = this.onLinkClick.bind(this);
  }

  onLinkClick() {
    Analytics.trackEvent({
      action: Event.action.STORE_LOCATOR_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_ABOVE_MENU_CTR,
    });
    GTM.pushEvent(Event.GTMEvent.STORE_LOCATOR_CLICK);
  }

  render() {
    const { className } = this.props;

    return (
      <Anchor.component
        className={`nw-myaccountlink ${className}`}
        href={`${ROUTE_PATHS.STORELOCATOR}`}
        to={`${ROUTE_PATHS.STORELOCATOR}`}
        target="_self"
        onClick={this.onLinkClick}
      >
        <i className="icomoon-marker3 nw-myaccountlink-personicon" />
        <span className="nw-myaccountlink-text">Store Locator</span>
      </Anchor.component>
    );
  }
};

StoreLocatorLink.defaultProps = {
  className: "",
};

StoreLocatorLink.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: StoreLocatorLink,
  component: StoreLocatorLink,
};

export default ProjectComponent;
