import { observable } from "mobx";
import SearchStoreActions from "./actions";

class SearchBarStoreClass extends SearchStoreActions {
  @observable showSearch;
  @observable searchText;
  @observable filters;
 
}
export default SearchBarStoreClass;
