import { action } from "mobx";
import * as Service from "./services";

class GetTypeActions {
  constructor() {
    this.setGetTypeData = this.setGetTypeData.bind(this);
  }

  @action
  setGetTypeData(data) {
    this.data = data;
  }

  // @TODO: Remove this once serviceworker solution implemented
  @action
  setListingData(path, data) {
    this.listingData[path] = data;
  }
  // @TODO: Remove this once serviceworker solution implemented

  getTypeData(typeId, url, isSSRCall = false) {
    const requrl =
      url || (window && window.location && window.location.hostname) || "";
    const headers = { requrl };
    return new Promise((resolve, reject) => {
      Service.getType(typeId, headers , isSSRCall).then(
        action(res => {
          this.data = res;
          resolve(res || {});
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }
}

export default GetTypeActions;
