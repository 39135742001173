import React, { Component } from "react";
import PropTypes from "prop-types";
import { computed } from "mobx";
import {
  GridRow,
  GridColumn,
  Button,
  Loader,
} from "arv-reactcomponents";
import { inject, observer } from "mobx-react";
import Event from "../../analytics/eventFactory";
import Analytics from "../../analytics";
import { MyBag } from "npmlinks-constants";
import GTM from "../../analytics/GTM";
import MyBagProductView from "./MyBagProductView";

/*
TODO://
understand difference between them which field is getting in which service
iscountInPercentage:30
grossSP:8400
mrp:11999
price:8400


3 diffrent thing color, secondary color and _color --- we are getting in overview v3 find out reason why we need this

 */
@inject("MybagStore", "LoginStore")
@observer
class MyBagPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
    this.goto = this.goto.bind(this);
    this.initialize = this.initialize.bind(this);
  }
  componentDidMount() {
    this.initialize();
  }
  get isActiveClassName() {
    return (this.props.active && "is-active") || "";
  }

  get isNotHavingItems() {
    return (this.cartItems.length <= 0 && "is-empty") || "";
  }

  get isHavingMoreItemsClassName() {
    return (this.cartItems.length <= 2 && "nwc-hide") || "";
  }

  get isHavingMoreThanOneXtraItemClassName() {
    return (this.cartItems.length - 2 <= 1 && "nwc-hide") || "";
  }
  get showBody() {
    return this.state.loader ? "nwc-hide" : "";
  }

  get showLoader() {
    return this.state.loader ? "" : "nwc-hide";
  }
  @computed
  get cartItems() {
    return (
      (this.props.MybagStore.cartItems &&
        Object.values(this.props.MybagStore.cartItems)) ||
      []
    );
  }
  initialize() {
    this.setState({ loader: true });
    this.props.MybagStore.getMyBagDetails().then(() => {
      this.setState({ loader: false });
    });
  }
  goto(path) {
    Analytics.trackEvent({
      action:
        path === "mybag"
          ? Event.action.MINIBAG_MYBAG_CLICK
          : Event.action.MINIBAG_CHECKOUT_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MINIBAG,
    });
    if (path === "checkout" && !this.props.LoginStore.isUserLoggedIn) {
      window.goToRouteOnLogin = path;
      this.props.closeMyBagPopup();
      this.props.LoginStore.setloginModal(true);
    } else {
      if (path === "checkout") {
        GTM.pushEvent(Event.GTMEvent.CHECKOUT_CLICK);
      }
      window.location.href = `${window.location.origin}/${path}`;
    }
  }

  render() {
    const limitedList = this.cartItems.slice(0, 2);
    return (
      <div className="nw-mybagpopup">
        <div className={`nw-mybagpopup-loader ${this.showLoader}`}>
          <Loader className="nw-loader" />
        </div>
        <div
          className={`nw-mybagpopup-holder
            ${this.isActiveClassName}
          `}
        >
          <h3 className="nw-mybagpopup-head">{MyBag.MYBAGPOPUP.MY_BAG_TEXT}</h3>

          <div className={`nw-mybagpopup-body ${this.showBody}`}>
            {limitedList.map(item => {
              return (
                <MyBagProductView.component
                  item={item}
                />
              )
            })}
            <div
              className={`
                nw-mybagpopup-moretext
                ${this.isHavingMoreItemsClassName}
              `}
            >
              {this.cartItems.length - 2} more item
              <span className={`${this.isHavingMoreThanOneXtraItemClassName}`}>
                s
              </span>
              &nbsp;in cart
            </div>
            <GridRow>
              <GridColumn
                className={`
                  nw-mybagpopup-btncol
                  ${this.isNotHavingItems}
                `}
              >
                <Button
                  className="nwc-btn-primary nw-mybagpopup-btn"
                  onClick={() => this.goto("checkout")}
                >
                  CHECKOUT
                </Button>
              </GridColumn>
              <GridColumn
                className={`
                  nw-mybagpopup-btncol
                  ${this.isNotHavingItems}
                `}
              >
                <Button
                  className="nwc-btn-secondary nw-mybagpopup-btn"
                  onClick={() => this.goto("mybag")}
                >
                  {MyBag.MYBAGPOPUP.VIEW_SHOPPING_BAG_TEXT}
                </Button>
              </GridColumn>
              <GridColumn
                className={`
                  nw-mybagpopup-noitems
                  ${this.isNotHavingItems}
                `}
              >
                You don&apos;t have any items in your cart
              </GridColumn>
              <GridColumn>
                <Button
                  className={`
                    nwc-btn-secondary
                    nw-continue-shopping-btn
                    ${this.isNotHavingItems}
                    `}
                  onClick={() => this.goto("products")}
                >
                  CONTINUE SHOPPING
                </Button>
              </GridColumn>
            </GridRow>
          </div>
        </div>
      </div>
    );
  }
}

MyBagPopup.wrappedComponent.defaultProps = {
  active: false,
};

MyBagPopup.wrappedComponent.propTypes = {
  active: PropTypes.bool,
  closeMyBagPopup: PropTypes.func.isRequired,
  MybagStore: PropTypes.shape({
    cartItems: PropTypes.shape({}),
    removeFromBag: PropTypes.func.isRequired,
    getMyBagDetails: PropTypes.func.isRequired,
  }).isRequired,
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool,
    setloginModal: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("MybagStore", "LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MyBagPopup.wrappedComponent,
  component: MyBagPopup,
};

export default ProjectComponent;