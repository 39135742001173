import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Utils } from "arv-reactcomponents";

import { isApp, isIframe, isMybagCheckout } from "../../utils";
import BrandHeader from "./BrandHeader";
import DesktopHeader from "./DesktopHeader";
import DesktopFixedHeader from "./DesktopFixedHeader";
import MobileHeader from "./MobileHeader";
import StaticHeader from "./StaticHeader";
import BreadcrumbList from "../BreadcrumbList";
import { LOGOUT_REDIRECT_PATHS } from "npmlinks-constants";
import MainBar from "../../molecules/MobileHeaderComponents/MainBarv2";
import MobileFilterButtons from "../MobileFilters/MobileFilterButton";
let HeaderComponent;
@inject("HeaderStore", "LoginStore", "MybagStore", "CommonStore")
@observer
class Header extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      staticContext: PropTypes.object
    })
  };

  constructor(props) {
    super(props);

    this.handleFixedStateHeaderMainbar = this.handleFixedStateHeaderMainbar.bind(
      this
    );
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    if (this.props.LoginStore.checkUserRoles()) {
      this.props.LoginStore.logoutAction();
      this.props.MybagStore.clearCart();
      if (LOGOUT_REDIRECT_PATHS.includes(window.pageType)) {
        window.location = "/";
      }
    }

    Utils.onElementScroll(window, ({ scrollTop }) => {
      this.handleFixedStateHeaderMainbar(scrollTop);
    });
  }

  get isStatic() {
    const { staticContext } = this.context.router || {};
    if (isMybagCheckout()) {
      return true;
    }
    if (staticContext) {
      return (
        (staticContext.globalConfig &&
          staticContext.globalConfig.staticHeader) ||
        false
      );
    } else {
      return (window && window.GL && window.GL.staticHeader) || false;
    }
  }

  get hideHeader() {
    const { staticContext } = this.context.router || {};
    const globalConfigVar =
      (window && window.GL) || (staticContext && staticContext.globalConfig);
    return (globalConfigVar && globalConfigVar.noHeader) || false;
  }

  get hideMainHeaderOnFix() {
    const { HeaderStore } = this.props;
    const { isFixed } = HeaderStore;

    return isFixed ? "nwc-hidden" : "";
  }

  handleFixedStateHeaderMainbar(scrollTop) {
    if (scrollTop > 185) {
      this.props.HeaderStore.setFixedState(true);
    } else if (!Utils.isBodyFixed()) {
      this.props.HeaderStore.setFixedState(false);
    }
  }

  render() {
    const { HeaderStore, CommonStore } = this.props;
    const { isBrandPage } = CommonStore;
    const { isFixed, isFixedHeaderHidden } = HeaderStore;
    const { isDesktop } = this.props.CommonStore;

    if (isApp || isIframe || this.hideHeader) {
      return null;
    }

    if (this.isStatic) {
      return <StaticHeader.component className="nw-header" />;
    }

    if (isDesktop && isBrandPage) {
      return (
        <Fragment>
          <BrandHeader.component
            className={`nw-header ${this.hideMainHeaderOnFix}`}
          />
          {isFixed && <DesktopFixedHeader.component />}
          <BreadcrumbList.component />
        </Fragment>
      );
    }

    if (isDesktop) {
      return (
        <Fragment>
          <DesktopHeader.component
            className={`nw-header ${this.hideMainHeaderOnFix}`}
          />
          {isFixed && <DesktopFixedHeader.component />}
          <BreadcrumbList.component />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <MobileHeader.component />
        {isFixed &&
          (isFixedHeaderHidden ? (
            <div className="nw-mobileheader-wrap is-fixed ">
              <MobileFilterButtons.component />
            </div>
          ) : (
            <div className="nw-mobileheader-wrap is-fixed ">
              <MainBar.component />
              <MobileFilterButtons.component />
            </div>
          ))}
      </Fragment>
    );
  }
}

Header.wrappedComponent.propTypes = {
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired,
  HeaderStore: PropTypes.shape({
    setFixedState: PropTypes.func.isRequired,
    isFixed: PropTypes.bool.isRequired
  }).isRequired,
  MybagStore: PropTypes.shape({
    clearCart: PropTypes.func.isRequired
  }).isRequired,
  LoginStore: PropTypes.shape({
    logoutAction: PropTypes.func.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject(
    "HeaderStore",
    "LoginStore",
    "MybagStore",
    "CommonStore"
  )(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Header.wrappedComponent,
  component: Header
};

export default ProjectComponent;