import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ROUTE_PATHS } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";

class TrackOrderLink extends PureComponent {
  constructor(props) {
    super(props);
    this.triggerGAOnClick = this.triggerGAOnClick.bind(this);
  }

  get hasTextClassName() {
    return this.props.children ? "is-havingtext" : "";
  }

  triggerGAOnClick() {
    Analytics.trackEvent({
      action: Event.action.TRACK_ORDER,
      category: Event.category.TOP_ABOVE_MENU_CTR,
      interaction: true,
    });
    GTM.pushEvent(Event.GTMEvent.MYORDER_CLICK);
  }

  render() {
    const { className, children } = this.props;

    return (
      <Anchor.component
        className={`nwc-btn nw-trackorder ${className}`}
        href={`${ROUTE_PATHS.MYORDERS}`}
        to={`${ROUTE_PATHS.MYORDERS}`}
        target="_self"
        onClick={this.triggerGAOnClick}
      >
        <i className={`icomoon-delivery-web nw-trackorder-personicon ${this.hasTextClassName}`} />
        <span className="nw-trackorder-text">{children}</span>
      </Anchor.component>
    );
  }
};

TrackOrderLink.defaultProps = {
  className: "",
  children: "",
};

TrackOrderLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: TrackOrderLink,
  component: TrackOrderLink,
};

export default ProjectComponent;
