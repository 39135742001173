import { post, get, deleteApi } from "../../api";
import { authHeader } from "../../utils";
import { CACHE_SETTINGS } from "npmlinks-constants";

const wishList = body =>
  post(
    "setWishList",
    {
      ...authHeader(),
      ...CACHE_SETTINGS.DONOT_CACHE,
    },
    body,
  );

const getWishList = () =>
  get("getWishList", {
    ...authHeader(),
    favtype: "products",
    ...CACHE_SETTINGS.DONOT_CACHE,
  });

const deleteWishList = body =>
  post(
    "deleteWishList",
    {
      ...authHeader(),
      ...CACHE_SETTINGS.DONOT_CACHE,
    },
    body,
  );
const setBrandWishList = body =>
  post(
    "setBrandWishList",
    {
      ...authHeader(),
      ...CACHE_SETTINGS.DONOT_CACHE,
    },
    body,
  );
const getBrandWishList = () =>
  get("getBrandWishList", {
    ...authHeader(),
    ...CACHE_SETTINGS.DONOT_CACHE,
  });
const deleteFavBrand = path =>
  deleteApi(
    "setBrandWishList",
    {
      ...authHeader(),
      ...CACHE_SETTINGS.DONOT_CACHE,
    },
    {},
    path,
  );

const deleteFavProduct = body =>
  post(
    "deleteFavProduct",
    {
      ...authHeader(),
      ...CACHE_SETTINGS.DONOT_CACHE,
    },
    body,
  );

export {
  wishList,
  getWishList,
  deleteWishList,
  setBrandWishList,
  getBrandWishList,
  deleteFavBrand,
  deleteFavProduct,
};
