import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import FilterContainer from "./FilterContainer";
import { GridContainer, GridRow, GridColumn, Utils } from "arv-reactcomponents";
import BodyOverlay from "../../BodyOverlay";

@inject("ProductStore")
@observer
class StickyFilterBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterFixed: false,
    };

    this.handleFixedStateStickyFilter = this.handleFixedStateStickyFilter.bind(this);
  }
  componentDidMount() {
    Utils.onElementScroll(window, ({ scrollTop }) => {
      this.handleFixedStateStickyFilter(scrollTop);
    });
  }
  get nonomnifilters() {
    const filters =
      this.props.ProductStore &&
      this.props.ProductStore.ProductData &&
      this.props.ProductStore.ProductData.filters;
    return filters.filterBy
      .filter(
        eachfilterBy =>
          eachfilterBy.filterType !== "delivery_time" &&
          eachfilterBy.filterType !== "nearby_stores",
      )
      .slice(0, 4);
  }

  handleFixedStateStickyFilter(scrollTop) {
    const filterContainerRef = document.querySelectorAll(
      ".nw-productfilters-filterscontainer",
    )[0];
    if (!filterContainerRef) {
      return;
    }
    if (Utils.getBoundClientRect(filterContainerRef).bottom < scrollTop) {
      this.setState({
        isFilterFixed: true,
      });
    } else {
      this.setState({
        isFilterFixed: false,
      });
    }
  }

  render() {
    const filters =
      this.props.ProductStore &&
      this.props.ProductStore.ProductData &&
      this.props.ProductStore.ProductData.filters;
    if (!filters) return null;
    const RenderComponent = (
      <GridContainer className="nw-stickyfiltersnew-container">
        <GridRow>
          <GridColumn className="nwc-grid-col-xs nw-desktop-only nw-stickyfiltersnew-col">
            <form name="stickyFilters" className="nw-stickyfilterblocknew">
              <h1 className="nw-stickyfilterblocknew-filterheadings">
                Filter by:
              </h1>
              <ul className="nw-stickyfilterblocknew-list">
                {this.nonomnifilters.map(eachfilterBy => (
                  <FilterContainer.component
                    key={eachfilterBy.displayName}
                    eachfilterBy={eachfilterBy}
                  />
                ))}
              </ul>
            </form>
          </GridColumn>
        </GridRow>
      </GridContainer>
    );
    if (global && global.isServer) {
      return null;
    } else if(this.state.isFilterFixed) {
      return RenderComponent;
    }
    else{ 
      BodyOverlay.component.hide();
    }
    return null;
  }
}

StickyFilterBlock.wrappedComponent.defaultProps = {
  ProductStore: PropTypes.shape({}),
};

StickyFilterBlock.wrappedComponent.propTypes = {
  ProductStore: PropTypes.shape({
    ProductData: PropTypes.object,
  }),
};

export { default as FilterContainer } from "./FilterContainer";

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StickyFilterBlock.wrappedComponent,
  component: StickyFilterBlock,
};

export default ProjectComponent;
