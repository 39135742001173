import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
import { SEPHORA_BRAND_ID } from "npmlinks-constants";

import MainNav from "../MainNav";

@inject("GetTypeStore","BrandStore", "NavListStore", "CommonStore")
@observer
class Navigation extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { isDesktop } = this.props.CommonStore;
    const { data: getTypeData = {} } = this.props.GetTypeStore;
    const { navListData, getNavListData } = this.props.NavListStore;
    const platform = isDesktop ? "desktop" : "mobile";

    if (!navListData) {
      getNavListData(platform, getTypeData.origin).then(data => {
        console.log(data);
      });
    }
  }

  get navMenu() {
    const { navListData } = this.props.NavListStore;
    const menu = lodashGet(navListData, "data.menu.level1", null);

    return menu;
  }

  render() {
    const { className } = this.props;
    const brandId =
      (this.props.CommonStore &&
        this.props.CommonStore.brandDetails &&
        this.props.CommonStore.brandDetails.id) ||
      "";
    const limitedNav = brandId && brandId === SEPHORA_BRAND_ID ? this.navMenu && this.navMenu.slice(0, 8) : this.navMenu && this.navMenu.slice(0, 7) ;
    if (this.navMenu) {
      return (
        <nav className={`nw-navigation ${className}`}>
          <ul className="nw-navigation-list">
            {limitedNav.map(topMenu => (
              <MainNav.component key={topMenu.name} menu={topMenu} />
            )) || null}
          </ul>
        </nav>
      );
    }

    return null;
  }
}

Navigation.wrappedComponent.defaultProps = {
  className: "",
};

Navigation.wrappedComponent.propTypes = {
  className: PropTypes.string,
  NavListStore: PropTypes.shape({
    navListData: PropTypes.shape({}),
    getNavListData: PropTypes.func.isRequired,
  }).isRequired,
  BrandStore: PropTypes.shape({
    brandId: PropTypes.string.isRequired
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
};

const getFinalComponent = component =>
  inject("GetTypeStore", "BrandStore", "NavListStore", "CommonStore")(observer(component));
const ProjectComponent = {
  getFinalComponent,
  wrapper: Navigation.wrappedComponent,
  component: Navigation,
};

export default ProjectComponent;
