import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ROUTE_PATHS } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import GTM from "../../analytics/GTM";
import Event from "../../analytics/eventFactory/GTMEvents";

class MyAccountLink extends PureComponent {
  render() {
    const { className } = this.props;
    return (
      <Anchor.component
        className={`nwc-btn nw-myaccountlink ${className}`}
        to={`${ROUTE_PATHS.ACCOUNT}`}
        href={`${ROUTE_PATHS.ACCOUNT}`}
        target="_self"
        onClick={this.props.triggerGTMOnClick}
      >
        <i className="icomoon-person1 nw-myaccountlink-personicon" />
        <span className="nw-myaccountlink-text">My Account</span>
      </Anchor.component>
    );
  }
};

MyAccountLink.defaultProps = {
  className: "",
};

MyAccountLink.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: MyAccountLink,
  component: MyAccountLink,
};

export default ProjectComponent;
