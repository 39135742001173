import { observable, action, computed } from "mobx";
import CardlessEmiStoreActions from "../CardlessEmiStore/actions";
import { setSurlFurl, paymentBaseRequest } from "../../utils";

class CardlessEmiStoreClass extends CardlessEmiStoreActions {
  @observable
  selectedNetbankingData = {
    bankCode: null,
    isValid: false,
  };
  
  @observable success = null;

  @observable cardlessEmiData = {};


  @computed
  get successWithCardlessEmi() { //to be checked ****
    try {
      const responseDataWithUserSelectedBank = Object.assign({}, this.success);
      if (responseDataWithUserSelectedBank && responseDataWithUserSelectedBank.transactions) {
        const { banking } = responseDataWithUserSelectedBank.transactions;
        let { requestParameters } = banking;
        if (requestParameters && requestParameters.length)
          requestParameters = requestParameters.map(paramTemp => {
            let param = Object.assign({}, paramTemp);
            // going forword we can hit the thor directly no intermidiate server required between Payu and Thor if we can provide redirect url with proper domain
            param = setSurlFurl(param, responseDataWithUserSelectedBank.orderId);
            return param;
          });
        return responseDataWithUserSelectedBank;
      }
      return null;
    } catch (err) {
      return err;
    }
  }

  @computed
  get paymentRequestCardlessEmi() {
    let cardlessData = this.cardlessEmiData || {};
    return {
      paymentDetail: paymentBaseRequest({
        description: "cardlessEMI",
        bankCode: cardlessData && cardlessData.bankCode,
        bankName: cardlessData && cardlessData.bankName || "snapmint",
      }),
      paymentTypeCode: "CARDLESS_EMI",
      requestedPG: cardlessData.requestedPG || null, 
    };
  }
}
export default CardlessEmiStoreClass;
