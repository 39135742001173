import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import {
  Button,
  GridContainer,
  GridRow,
  GridColumn,
  Img,
  Utils,
} from "arv-reactcomponents";

import { Endpoints } from "npmlinks-constants";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import { LoginSwitch } from "../../../../molecules";
import NavContainer from "../../NavContainerv2";
import FavoritesLink from "../../../FavoritesLink";
import MyBagActionButton from "../../../MyBagButton";
import SearchBar from "../../../SearchBar"

@inject("SearchBarStore", "CommonStore", "NavListStore")
@observer
class BrandBar extends Component {
  static trackMenuClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_RIGHTNAV,
    });
  }

  static trackBrandClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_LEFTNAV,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      hideMenu: true,
      hideBrandMenu: true,
    };
    this.onMenuClick = this.onMenuClick.bind(this);
    this.hideWhenSearch = this.hideWhenSearch.bind(this);
    this.onBrandClick = this.onBrandClick.bind(this);
  }

  onMenuClick() {
    const hideMenu = !this.state.hideMenu;
    if (this.props.SearchBarStore.showSearch) {
      this.props.SearchBarStore.toggleSearch();
    }

    const eventAction = !hideMenu
      ? Event.action.MENU_OPEN
      : Event.action.MENU_CLOSE;
    BrandBar.trackMenuClick(eventAction);

    this.setState(
      {
        hideMenu,
        hideBrandMenu: true,
      },
      () => {
        if (this.state.hideMenu) {
          Utils.unFixScroll();
        } else {
          Utils.fixScroll();
        }
      },
    );
  }

  onBrandClick() {
    const hideBrandMenu = !this.state.hideBrandMenu;
    if (this.props.SearchBarStore.showSearch) {
      this.props.SearchBarStore.toggleSearch();
    }

    const eventAction = !hideBrandMenu
      ? Event.action.MENU_OPEN
      : Event.action.MENU_CLOSE;
    BrandBar.trackBrandClick(eventAction);

    this.setState(
      {
        hideBrandMenu,
        hideMenu: true,
      },
      () => {
        if (this.state.hideBrandMenu) {
          Utils.unFixScroll();
        } else {
          Utils.fixScroll();
        }
      },
    );
  }

  get hideMenuClass() {
    return (this.state.hideMenu && "nwc-hide") || "";
  }

  get hideBrandMenuClass() {
    return (this.state.hideBrandMenu && "nwc-hide") || "";
  }

  get menuActiveClass() {
    return (!this.state.hideMenu && "is-active") || "";
  }

  get BrandMenuActiveClass() {
    return (!this.state.hideBrandMenu && "is-active") || "";
  }

  get hideIfNotBrandpageClassName() {
    return (!this.props.CommonStore.isBrandPage && "nwc-hide") || "";
  }

  get brandIconClick() {
    return (!this.state.hideBrandMenu && "nw-brand-active") || "";
  }

  get logoImg() {
    const { navListData } = this.props.NavListStore;
    const alt = "Brand image";
    const logoFromApi =
      (navListData &&
        navListData.data &&
        navListData.data.logos &&
        navListData.data.logos.brand &&
        navListData.data.logos.brand.image) ||
      null;
    const logoUrl = logoFromApi || Endpoints.nnnowLogoUrl;

    if (logoUrl) {
      return <Img className="nw-nnnowbarv2-logoimg" src={logoUrl} alt={alt} />;
    }
    return null;
  }

  hideWhenSearch() {
    this.setState(
      {
        hideMenu: true,
        hideBrandMenu: true,
      },
      () => {
        Utils.unFixScroll();
      },
    );
  }
  render() {
    if (
      !this.props.NavListStore.navListData ||
      !this.props.NavListStore.navListData.data
    )
      return null;
    const data =
      (this.props.NavListStore.navListData.data.nnnow &&
        this.props.NavListStore.navListData.data.nnnow.level1) ||
      [];
    const brandData =
      (this.props.NavListStore.navListData.data.menu &&
        this.props.NavListStore.navListData.data.menu.level1) ||
      [];
    return (
      <Fragment>
        <GridContainer className="nw-brandbarv2-wrapper">
          <GridRow className="nw-brandbarv2-row">
            <GridColumn className="nw-brandbarv2-brandlogo" title="NNNOW Home">
              <Button
                onClick={this.onBrandClick}
                className={`nw-brandbarv2-action-button nw-brandbarv2-menu nw-brandbarv2-hamburger${
                  this.hideIfNotBrandpageClassName
                } ${this.brandIconClick} `}
              >
                <i className="icomoon-menu" />
              </Button>
              <div className="nw-brandbarv2-logo">
                <a href="/" target="_self">
                  {this.logoImg}
                </a>
              </div>
            </GridColumn>
            <GridColumn className="nw-brandbarv2-actions">
              <div
                className="nw-nnnowbarv2-searchwrap"
                onKeyDown={Utils.noop}
                role="presentation"
              >
                <SearchBar.component />
              </div>
              <div
                className="nw-nnnowbarv2-favicon"
                role="presentation"
              >
                <FavoritesLink.component />
              </div>
              <div
                className="nw-nnnowbarv2-mybagwrap nw-brandbarv2-mybagwrap"
              >
                <MyBagActionButton.component />
              </div>
              <div className="nw-brandbarv2-favicon1" role="presentation" >
                <LoginSwitch.component />       
              </div>
              <Button
                onClick={this.onMenuClick}
                className={`nw-brandbarv2-action-button nw-brandbarv2-menu ${
                  this.hideIfNotBrandpageClassName
                } ${this.menuActiveClass}`}
              >
                <img
                  className="nw-brandbarv2-nnnowlogo"
                  src={Endpoints.nnnowMobileLogo}
                  alt="NNNOW"
                />
              </Button>
            </GridColumn>
            <GridColumn className="nw-nnnowbarv2-nav">
              <nav
                className={`nw-brandbarv2-hamburger-menu nw-brandbarv2-nnnow-leftmenu ${
                  this.menuActiveClass
                } ${this.hideMenuClass}`}
              >
                <NavContainer.component
                  className="nw-nnnow-header"
                  navData={data}
                  reset={this.hideWhenSearch}
                />
              </nav>
              <nav
                className={`nw-brandbarv2-hamburger-menu nw-brandbarv2-brand-menu  ${
                  this.hideIfNotBrandpageClassName
                } ${this.BrandMenuActiveClass} ${this.hideBrandMenuClass} `}
              >
                <NavContainer.component
                  className="nw-brand-header"
                  navData={brandData}
                  reset={this.hideWhenSearch}
                />
              </nav>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Fragment>
    );
  }
}

BrandBar.wrappedComponent.propTypes = {
  SearchBarStore: PropTypes.shape({
    showSearch: PropTypes.bool,
    toggleSearch: PropTypes.func,
  }).isRequired,
  NavListStore: PropTypes.shape({
    navListData: PropTypes.object,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("SearchBarStore", "CommonStore", "NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandBar.wrappedComponent,
  component: BrandBar,
};

export default ProjectComponent;
