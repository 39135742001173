import { observable, action } from "mobx";
import getFooterLinkData from "./footerStore.service";
import normalizeFooterData from "./footerStore.methods";

class FooterStoreClass {
  constructor(props) {
    if (props && Object.keys(props).length > 0) {
      this.isSubcribed1 = props.isSubscribed;
      this.footerlinksData1 = props.footerlinksData;
      this.basePathTemp = props.basePath;
    }
  }
  @observable basePath = this.basePathTemp || "";
  @observable isSubcribed = this.isSubcribed1 || false;
  @observable footerlinksData = this.footerlinksData1 || [];
  @observable isFooterDefaultVisible = true;

  @action
  setBasePath(basePath) {
    this.basePath = basePath;
  }
  @action
  changeFooterVisibleState(bool) {
    this.isFooterDefaultVisible = bool;
  }

  @action
  subscribe(email) {
    if (email) {
      this.isSubcribed = true;
    }
  }

  @action
  getFooterLinkData() {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      getFooterLinkData().then(
        action(res => {
          const footerLinkData = res.data;
          normalizeFooterData(footerLinkData);
          this.footerlinksData = footerLinkData;
          resolve(footerLinkData);
        }),
      );
    });
  }
}

export default FooterStoreClass;
