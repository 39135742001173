import lodashGet from "lodash.get";
import { Utils } from "arv-reactcomponents";
import { observable, computed } from "mobx";
import CommonActions from "./actions";

class CommonStoreClass extends CommonActions {
  constructor(props) {
    super(props);
    this.isBrandPageTemp = (props && props.isBrandPage) || false;
    this.brandDetailsTemp = (props && props.brandDetails) || {};
    this.brandTemplateIdTemp = (props && props.brandTemplateId) || "";
    this.isDesktopTemp = props && props.isDesktop;
    this.isStaticHeader = props && props.isStaticHeader;
    this.isIframeTemp = props && props.isIframe;
  }
  @observable isDesktop = this.isDesktopTemp;
  @observable isBrandPage = this.isBrandPageTemp;
  @observable brandDetails = this.brandDetailsTemp;
  @observable
  guestCartSkuIdsList = Utils.localStorage.getItem("cart-guest") || [];
  @observable cartItems = [];
  @observable brandTemplateId = this.brandTemplateIdTemp;
  @observable isIframe = this.isIframeTemp || false;

  @computed
  get brandName() {
    return lodashGet(this.brandDetails, "parentBrandName[0]", "");
  }

  @computed
  get brandJourneyId() {
    const id =
      (this.brandDetails && this.brandDetails.id) ||
      this.brandTemplateId ||
      process.env.REACT_APP_MASTERTEMPLATEID;
    return (id && id.toLowerCase()) || "";
  }

  @computed
  get searchHistory() {
    return (
      Utils.localStorage.getItem(
        `localSearchHistory_${this.brandJourneyId || "nw"}`,
      ) || []
    );
  }
}

export default CommonStoreClass;
