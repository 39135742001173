import fetch from "isomorphic-fetch";
import Event from "./eventFactory";
import { LOGMAN_SERVICE_NAME, Endpoints } from "npmlinks-constants";

import { getClientUniqueId, getCorrelationId } from "../utils";

const GetStaticHeaders = headers => {
  const staticHeaders = {
    module: "odin",
    accept: "application/json",
    "Content-Type": "application/json",
    bbversion: "v2",
    clientSessionId: getClientUniqueId() || "",
  };
  Object.assign(
    headers,
    {
      correlationId: getCorrelationId(),
    },
    staticHeaders,
  );
  const env_origin = process.env.REACT_APP_SITEORIGIN;
  if (env_origin && !headers.requrl) {
    headers.requrl = `https://${env_origin}`;
  }
};
const post = (type, customHeaders, body) => {
  const url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders,
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "POST",
    headers,
    body,
  };
  return fetch(url, requestBody, type)
    .then(response =>
      Promise.all([response.ok, response.json(), response.status]),
    )
    .then(response => {
      const [, res] = Array.from(response);
      return res;
    });
};
const pushToLogman = body => post("dataToLogman", {}, JSON.stringify(body));

export const postFunction = (post);
export default class Logman {
  static trackLog(gaObj) {
    const dataToLogman = {
      data: gaObj,
      service_name: LOGMAN_SERVICE_NAME,
      event_name: gaObj.logmanEventName
        ? gaObj.logmanEventName
        : Event.LogmanEvent.DEFAULT,
    };
    pushToLogman(dataToLogman);
  }
}
