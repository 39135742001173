// Import Core Components here
import React, { Component } from "react";
import PropTypes from "prop-types";

// Import App depedency here
import { Input, Utils } from "arv-reactcomponents";
import { RegexMethods } from "npmlinks-constants";

class MobileNumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: props.mobileNo,
      mobileInputErrorMsg: "",
      isMobileInputValid: false,
      mobileErrorClass: "",
      isValid: "",
    };

    this.onBlur = this.onBlur.bind(this);
    this.validateMobileNo = this.validateMobileNo.bind(this);
    this.init = this.init.bind(this);
  }
  componentDidMount() {
    this.init();
  }
  init() {
    const { mobileNumber } = this.state;
    if (mobileNumber) {
      const e = {
        target: {
          value: mobileNumber,
        },
      };
      this.validateMobileNo(e);
    }
  }
  get currentMobileNumber() {
    const { mobileNumber } = this.state;
    return mobileNumber;
  }
  onBlur() {
    let mobileErrorClass = "nwc-hidden";
    let isValid = "";
    if (!this.state.isMobileInputValid) {
      mobileErrorClass = "";
      isValid = "is-invalid";
    }
    this.setState({ mobileErrorClass, isValid });
  }

  validateMobileNo(e) {
    this.setState({ mobileErrorClass: "nwc-hidden" });
    const validateMobileNoField = this.validateMobileNoFieldRef.getValidatedOutput(
      e.target.value,
    );

    this.setState({
      isMobileInputValid: validateMobileNoField.isValid,
      mobileInputErrorMsg: validateMobileNoField.errorMsg,
      mobileNumber: validateMobileNoField.value,
      isValid: "",
    });
    this.props.onChange(validateMobileNoField);
    this.props.onMobileNumberChange ? this.props.onMobileNumberChange() : null;
  }

  static getDerivedStateFromProps(props, state) {
    const { highlightMobileField: isMobileInputValid } = props
    if (isMobileInputValid !== state.isMobileInputValid) {
      return {
        isValid: isMobileInputValid ? "is-invalid" : "",
        mobileErrorClass: "",
        mobileInputErrorMsg: props.mobileNumberInputErrorMsg
      };
    }
    return null;
  }

  render() {
    let { mobileInputErrorMsg, mobileErrorClass, isValid } = this.state;
    const { className, placeholder, name, maxLength, classNameErr } = this.props;
    return (
      <div>
        <Input
          className={`${className} ${isValid}`}
          maxLength={maxLength}
          name={name}
          onBlur={this.onBlur}
          onChange={this.validateMobileNo}
          placeholder={placeholder}
          type="tel"
          value={this.currentMobileNumber}
          ref={values => {
            this.validateMobileNoFieldRef = values;
          }}
          validateWithPattern={RegexMethods.MOBILE_NUMBER_REGEX}
        />
         <div className={`${classNameErr} ${mobileErrorClass}`}>
          {mobileInputErrorMsg}
        </div>
      </div>
    );
  }
}

MobileNumberInput.defaultProps = {
  className: "",
  maxLength: 10,
  onChange: Utils.noop,
  mobileNumber: "",
  required: false,
  mobileNumberInputErrorMsg: "",
  highlightMobileField: false,
  classNameErr: "nw-form-err-msg"
};

MobileNumberInput.propTypes = {
  mobileNo: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  classNameErr: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: MobileNumberInput,
  component: MobileNumberInput,
};

export default ProjectComponent;
