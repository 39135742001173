import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import loadashGet from "lodash.get";
import { Button, Ripple, Img, Utils } from "arv-reactcomponents";
import { blackboltConfig, Endpoints, Login } from "npmlinks-constants";
import Analytics from "../../../analytics";
import GTM from "../../../analytics/GTM";
import Event from "../../../analytics/eventFactory";
import EmailInput from "../EmailInput";
import MobileNumberInput from "../MobileNumberInput";
import { OtpSub } from "../LoginV2";
import RegisterOtp from "../LoginV1/Register/RegisterOTP";

@inject("LoginStore")
@observer
class MissedFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginWithPhone: false,
      authErrorMsg: "",
      showOtpField: false,
      hideMobileNoInputClass: "",
      otpDetails: {},
    };
    this.emailFields = null;
    this.mobileNoFields = null;
    this.isMobileMissing = false;
    this.isEmailMissing = false;
    this.onSubmit = this.onSubmit.bind(this);
    this.showLoginView = this.showLoginView.bind(this);
    this.onChange = this.onChange.bind(this);
    this.socialRegister = this.socialRegister.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.checkUserExist = this.checkUserExist.bind(this);
    this.otpDetailsCallBack = this.otpDetailsCallBack.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onBack = this.onBack.bind(this);
    this.socialRegisterV2 = this.socialRegisterV2.bind(this);
  }

  componentDidMount() {
    const invalidInput = document.querySelector(".nw-missed-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
    this.props.LoginStore.setHideCloseBtn(false);
  }

  socialRegister(data) {
    this.props.LoginStore.userOnboard(data, true)
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        const err = error && error.data && error.data.errors;
        if (
          err &&
          err[0] &&
          blackboltConfig.ERROR_CODE.includes(err[0].status)
        ) {
          const defaultErrorMsg = blackboltConfig.ERROR_MSG;
          const { detail = defaultErrorMsg } = err[0];
          this.setState({
            authErrorMsg: detail,
          });
        } else {
          this.setState({
            authErrorMsg: blackboltConfig.ERROR_MSG,
          });
        }
        Analytics.trackEvent({
          action: Event.action.REGISTER_ERROR,
          category: Event.category.LOGIN,
          label: Event.label.getRegisterationError(err),
        });
        GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
      });
  }

  onClickSubmit(e) {
    e.preventDefault();
    const { otpDetails } = this.state;
    this.socialRegisterV2(this.onboardDetailsObj, otpDetails);
  }

  socialRegisterV2(data, otpDetails) {
    this.props.LoginStore.registerWithOtp(data, otpDetails, true)
      .catch(error => {
        const errCode = loadashGet(error, "errors[0].code", null);
        const errStatus = loadashGet(error, "errors[0].status", null);
        if (errCode === "605") {
          this.setState({
            showOtpField: true,
            hideMobileNoInputClass: "nwc-hide",
          });
          Analytics.trackEvent({
            action: Event.action.PHONE_VERIFY,
            category: Event.category.LOGIN,
          });
        }
        else if (blackboltConfig.ERROR_CODE.includes(errStatus)) {
          const defaultErrorMsg = blackboltConfig.ERROR_MSG;
          const { detail = defaultErrorMsg } = error.errors[0];
          this.setState({
            authErrorMsg: detail,
          });
        } else {
          this.setState({
            authErrorMsg: blackboltConfig.ERROR_MSG,
          });
        }
        Analytics.trackEvent({
          action: Event.action.REGISTER_ERROR,
          category: Event.category.LOGIN,
          label: Event.label.getRegisterationError(error),
        });
        GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
      });
  }

  otpDetailsCallBack(otpDetails) {
    this.setState({
      otpDetails: otpDetails
    });
  }

  createAccount(data, success) {
    this.setState({ showOtpField: false, hideMobileNoInputClass: "" });
    if (success) {
      this.props.LoginStore.otpUserRegistration(data.register);
    } else {
      const errObj = loadashGet(data, "data.errors", []);
      const finalErrorDetail =
        errObj &&
          errObj[0] &&
          blackboltConfig.ERROR_CODE.includes(errObj[0].status)
          ? errObj[0].detail
          : blackboltConfig.ERROR_MSG;
      this.setState({
        authErrorMsg: finalErrorDetail,
      });
      Analytics.trackEvent({
        action: Event.action.REGISTER_ERROR,
        category: Event.category.LOGIN,
        label: Event.label.getRegisterationError(errObj),
      });
      GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-missed-form input.is-invalid",
    );
    if (invalidInput) {
      return invalidInput.focus();
    }
    let data = {};
    let mobileNo;
    let email;
    let { otpDetails } = this.state;
    if (this.isMobileMissing && this.mobileNoFields.state.isMobileInputValid) {
      mobileNo = this.mobileNoFields.state.mobileNumber;
      data = { ...data, mobileNo };
      this.onboardDetailsObj = { mobileNo };
      this.props.LoginStore.setMobileNumberV2(mobileNo);
      if (process.env.REACT_APP_LOGIN_VERSION === "v2") {
        this.socialRegisterV2(data, otpDetails);
        this.onboardDetailsObj = data;
      } else if (process.env.REACT_APP_REGISTER_OTP_ENABLED === "true") {
        this.checkUserExist(mobileNo);
      }
      else {
        this.socialRegister(data);
      }
    } else if (
      this.isEmailMissing &&
      this.emailFields.state.isEmailInputValid
    ) {
      email = this.emailFields.state.email;
      data = { ...data, email };
      if (process.env.REACT_APP_LOGIN_VERSION === "v2") {
        this.onboardDetailsObj = data;
        return this.socialRegisterV2(data, otpDetails);
      }
      return this.socialRegister(data);
    }
  }

  get otpScreen() {
    if (process.env.REACT_APP_LOGIN_VERSION === "v2") {
      return (<OtpSub.component
        otpDetailsCallBack={this.otpDetailsCallBack}
        onClickSubmit={this.onClickSubmit}
      />
      );
    }
    return (
      <RegisterOtp.component
        onboardDetails={this.onboardDetailsObj}
        createAccount={this.createAccount}
        missingFields
      />
    );
  }

  checkUserExist(mobileNo, email = null) {
    this.props.LoginStore.checkUserExist(mobileNo, email)
      .then(res => {
        const { emailIdPresent, mobileNumberPresent } = res;
        if (emailIdPresent || mobileNumberPresent) {
          const defaultErrorMsg = blackboltConfig.DEFAULT_CHECK_USER_ERROR_MSG;
          const { message = defaultErrorMsg } = res;
          this.setState({
            authErrorMsg: message,
          });
        } else {
          this.setState({
            showOtpField: true,
            hideMobileNoInputClass: "nwc-hide",
          });
        }
      })
      .catch(err => {
        this.setState({
          authErrorMsg: blackboltConfig.ERROR_MSG,
        });
      });
  }

  onChange() {
    const { showLoginWithPhone, authErrorMsg } = this.state;
    if (showLoginWithPhone || authErrorMsg) {
      this.setState({ showLoginWithPhone: false, authErrorMsg: "" });
    }
  }

  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }

  get header() {
    const { hideMobileNoInputClass } = this.state;
    if (process.env.REACT_APP_LOGIN_VERSION === "v2" && this.isMobileMissing) {
      return (
        <Fragment>
          <div className="nw-login-heading">
            <div>
              {Login.LOGIN_SUBV2_MSG.MISSING_FIELD_HEADING1}
            </div>
            <div>
              {Login.LOGIN_SUBV2_MSG.MISSING_FIELD_HEADING2}
            </div>
            <Img src={Endpoints.MISSING_FIELD_IMG} alt="nnnow" onError={Utils.noop} className="nw-loginotp-img"></Img>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="nw-login-heading">
          <h1 className="nw-login-title">{Login.LOGIN_MESSAGES.MISSING_FIELD_HEADING_LABEL}</h1>
        </div>
        <div className={hideMobileNoInputClass}>
          <div className="nw-missingfield-img">
            <Img
              src={Endpoints.missedImage}
              alt="mobile image to show missing mobile num"
            />
          </div>
        </div>
      </Fragment>
    );
  }

  onBack(e) {
    e.preventDefault();
    this.setState({
      showOtpField: false,
      otpDetails: {},
      authErrorMsg: ""
    });
  }

  showLoginView(e) {
    const { changeViewValue } = this.props;
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.SOCIAL_LOGIN_PHONE_NUDGE,
      category: Event.category.LOGIN,
    });
    const { mobileNumber } = this.mobileNoFields.state;
    this.props.LoginStore.changeView(changeViewValue, { userId: mobileNumber });
  }

  render() {
    const { missingFieldsLogin } = this.props.LoginStore;
    const {
      showLoginWithPhone,
      authErrorMsg,
      showOtpField
    } = this.state;
    if (missingFieldsLogin && missingFieldsLogin.missingFields) {
      this.isMobileMissing =
        this.props.LoginStore.missingFieldsLogin.missingFields.indexOf(
          "mobileNumber",
        ) > -1;
      this.isEmailMissing =
        this.props.LoginStore.missingFieldsLogin.missingFields.indexOf(
          "emailId",
        ) > -1;
    }
    return (
      <div className="nw-login-container">
        {process.env.REACT_APP_LOGIN_VERSION === "v2" && showOtpField &&
          <Button
            className="nw-backbtn"
            onClick={this.onBack}
          >
            <i className="icomoon-arrow_left nw-backbtn-icon" />
          </Button>
        }
        {!showOtpField && <div>
          {this.header}
          <form className="nw-form nw-missed-form" onSubmit={this.onLogin}>
            <div className="nw-login2-sub">
              <div className="nw-login2-missed">{Login.LOGIN_SUBV2_MSG.MISSING_FIELD_SUB_HEADING}</div>
            </div>
            {this.isEmailMissing && (
              <EmailInput.component
                className="nw-login-input nw-missed-email"
                placeholder="Enter Your Email-ID"
                name="email"
                ref={values => {
                  this.emailFields = values;
                }}
                required
              />
            )}
            {this.isMobileMissing && (
              <MobileNumberInput.component
                className="nw-login-input nw-missed-mobilenumber"
                name="mobile number"
                maxLength={10}
                onChange={this.onChange}
                ref={values => {
                  this.mobileNoFields = values;
                }}
                required
              />
            )}
            <Ripple className="nwc-ripple-light">
              <Button
                type="submit"
                className="nwc-btn-primary nw-login-btn nw-missed-submitbtn"
                onClick={this.onSubmit}
              >
                REGISTER
                <div className={`nw-login-loader ${this.isShowLoader}`}>
                  <img
                    className="nw-loader"
                    src={Endpoints.LOADER}
                    alt="loader"
                  />
                </div>
              </Button>
            </Ripple>
            {authErrorMsg && (
              <span className="nw-login-error">{authErrorMsg}</span>
            )}
            {showLoginWithPhone && (
              <Button
                className="nwc-button nw-login-link"
                onClick={this.showLoginView}
              >
                Login with Phone
              </Button>
            )}
          </form>
        </div>
        }
        {showOtpField && this.otpScreen}

      </div>
    );
  }
}

MissedFields.defaultProps = {
  changeViewValue: "login"
};

MissedFields.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    userOnboard: PropTypes.func.isRequired,
    missingFieldsLogin: PropTypes.object.isRequired,
    changeView: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MissedFields.wrappedComponent,
  component: MissedFields,
};

export default ProjectComponent;
