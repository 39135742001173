
import RazorpayStoreActions from "../RazorpayStore/actions";
import { observable, computed } from "mobx";
import { PAYMENTPAGE } from "npmlinks-constants";
import { paymentBaseRequest } from "../../utils"; 

const { COMPONENTWALLET_MAPPING } = PAYMENTPAGE;


class RazorpayStoreClass extends RazorpayStoreActions {
  @observable paymentData = null;
  @observable upiId = "";
  @observable upiData = [];
  @observable stayOnpaymentPage = false;

  @computed
  get paymentRequest() {
    const upiData = this.upiData;
    const filteredObjects = upiData.filter(item => item.bankCode === 'UPI');
    const requestedPGForUPI = filteredObjects.map(item => item.requestedPG);
    return {
      paymentDetail: paymentBaseRequest({
        description: COMPONENTWALLET_MAPPING['UPI'],
        bankCode: COMPONENTWALLET_MAPPING['UPI'],
      }),
      paymentTypeCode: COMPONENTWALLET_MAPPING['UPI'],
      requestedPG: upiData && upiData.length && requestedPGForUPI && requestedPGForUPI.length && requestedPGForUPI[0] || null,
      razorpayKey: upiData && upiData.length && upiData[0].pgRequestParameters && upiData[0].pgRequestParameters.RAZORPAY_KEY || '',        
    };
  }
}

export default RazorpayStoreClass;
