import { post, postImage, put } from "../../api";
import { get } from "../../api";
import { deleteApi } from "../../api";
import { authHeader } from "../../utils";

const getReviewConfig = () => get("reviewConfig");

const deleteImage = (itemId, body) =>
  deleteApi("deleteReviewImage", authHeader(), body, itemId);

const reviewImageUpload = (body, itemId) =>
  postImage("reviewImageUpload", authHeader(), body, itemId);

const createReview = body => post("createReview", authHeader(), body);

const updateReview = body => put("createReview", authHeader(), body);

const fetchAllReviews = body => post("fetchAllReviews", null, body);
const fetchReviewWithId = body => post("fetchReviewWithId", null, body);
const getCustomerPhotos = body => post("getCustomerPhotos", null, body);
const saveOrderRatings = body => post("saveOrderRatings", authHeader(), body);
export {
  getReviewConfig,
  deleteImage,
  reviewImageUpload,
  createReview,
  fetchAllReviews,
  fetchReviewWithId,
  getCustomerPhotos,
  updateReview,
  saveOrderRatings
};

// type, customHeaders, body, param
