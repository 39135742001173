
const CANONICALTYPE = {
  canonicalStatus: "canonical",
  permanentRedirectionStatus: "301",
};
const PROTOCOL = {
  HTTP: "http",
  HTTPS: "https",
};
const defaultSubDomainWww = "www";
const MAXREDIRECTIONALLOWED = 2;
const META = { CANONICALTYPE,PROTOCOL,defaultSubDomainWww,MAXREDIRECTIONALLOWED };

export default META;

