import { action, toJS } from "mobx";
import {
  generateOtp,
  resendOtp,
  validateOtp,
  validateCaptcha,
} from "./service";
import { createCookie } from "../../utils";
import { PAYMENTPAGE } from "npmlinks-constants";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
// import { clearLocalCart, setLocalCart } from "../../utils";

class CodStoreActions {
  @action
  setSuccessFlow() {
    const date = new Date();
    const newExpiryTime = date.setMinutes(
      date.getMinutes() + PAYMENTPAGE.COD_COOKIE.EXPIRY,
    );
    createCookie(
      PAYMENTPAGE.COD_COOKIE.NAME,
      PAYMENTPAGE.COD_COOKIE.VALUE,
      newExpiryTime,
    );
  }
  @action
  generateOtpFunc(body) {
    body.otpTemplateId = this.otpClientID;
    body = JSON.stringify(body);
    return new Promise((resolve, reject) => {
      generateOtp(body)
        .then(res => {
          this.sessionID = res && res.data && res.data.sessionID;
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  resendOtpFunc(body) {
    body.otpTemplateId = this.otpClientID;
    body.sessionId = this.sessionID;
    body = JSON.stringify(body);
    return new Promise((resolve, reject) => {
      resendOtp(body)
        .then(res => {
          this.sessionID = res && res.data && res.data.sessionID;
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  validateOtpFunc(body) {
    body.sessionId = this.sessionID;
    body = JSON.stringify(body);
    return new Promise((resolve, reject) => {
      validateOtp(body)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          this.isCodVerified = false;
          reject(err);
        });
    });
  }

  @action
  validateCaptchaFunc(body) {
    body.captcha = this.captchaToken;
    body = JSON.stringify(body);
    Analytics.trackEvent({
      action: Event.action.PAYMENT_COD_CAPTCHA_ATTEMPT,
      category: Event.category.CHECKOUT,
    });
    return new Promise((resolve, reject) => {
      validateCaptcha(body)
        .then(res => {
          this.isCodVerified = true;
          resolve(res);
        })
        .catch(err => {
          this.isCodVerified = true;
          Analytics.trackEvent({
            action: Event.action.PAYMENT_COD_CAPTCHA_ERROR,
            category: Event.category.CHECKOUT,
          });
          reject(err);
        });
    });
  }

  @action
  setCaptchaToken(token) {
    this.captchaToken = token;
    return this.validateCaptchaFunc({});
  }

  @action
  setRegisterCaptchaToken(token) {
    this.captchaToken = token;
  }

  @action
  setCodVerified(flag = false) {
    this.isCodVerified = flag;
  }
}

export default CodStoreActions;
