import { post, get } from "../../api";

const allProductData = (headers, body, isSSRCall) =>
  post("allProductData", headers, body, null, isSSRCall);
const omniFiltersData = (headers, body) =>
  post("omniFiltersData", headers, body);
const getDetails = (body, headers = {}, isSSRCall) =>
  post("details", headers, body, null, isSSRCall);
const listingDetails = (headers, params, isSSRCall) =>
  get("listingDetails", headers, params, isSSRCall);

const getShippingDetails = body => post("pincode", {}, body);
const getRecentlyViewedStyles = (body, headers) =>
  post("recentlyViewedStyles", headers, body);
  const landingPageSolus = (body, headers) =>
  post("landingPageSolus", headers, body);
  const fetchProductsBySku = (body, headers) =>
  post("fetchProductsBySku", headers, body);
const inThisLook = body => post("multipleStyles", {}, body);
const similarProducts = body => post("similarStyles", {}, body);
const getSizeCharts = (sizeChartId, headers = {}, isSSRCall) =>
  get("sizeCharts", headers, sizeChartId, isSSRCall);

const autoSuggestions = body => post("autoSuggestion", {}, body);

export {
  similarProducts,
  inThisLook,
  getRecentlyViewedStyles,
  allProductData,
  getDetails,
  omniFiltersData,
  listingDetails,
  getShippingDetails,
  getSizeCharts,
  autoSuggestions,
  landingPageSolus,
  fetchProductsBySku
};
