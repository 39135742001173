import { post, get, put } from "../../api";
import { authHeader } from "../../utils";
import { CACHE_SETTINGS } from "npmlinks-constants";
import { getGuestCart, clearGuestCart, setGuestCart } from "./localCart";

const mybagHeaders = {
  isapplicablepromotions: false,
  ispromotiondetails: true,
};
const mybagGuest = body =>
  post("mybagGuest", mybagHeaders, body && JSON.stringify(body));

const myBagDetails = () =>
  get("myBagDetails", {
    ...mybagHeaders,
    ...authHeader(),
    ...CACHE_SETTINGS.DONOT_CACHE,
  });

const mergeCart = products =>
  put(
    "mergeCart",
    { ...mybagHeaders, ...authHeader() },
    JSON.stringify(products),
  );

const addToBag = product =>
  put(
    "addToBag",
    { ...mybagHeaders, ...authHeader() },
    JSON.stringify(product),
  );
const mybagApiData = () => get("mybag", { ...authHeader() });

const removeFromBag = product =>
  post(
    "removeFromBag",
    { ...mybagHeaders, ...authHeader() },
    JSON.stringify(product),
  );

export {
  mybagGuest,
  myBagDetails,
  removeFromBag,
  mergeCart,
  addToBag,
  mybagApiData,
  getGuestCart,
  clearGuestCart,
  setGuestCart,
};
