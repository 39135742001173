import { action } from "mobx";
import { Promise } from "es6-promise";
import {
  getReviewConfig,
  deleteImage,
  reviewImageUpload,
  createReview,
  fetchAllReviews,
  updateReview,
  getCustomerPhotos,
  fetchReviewWithId,
  saveOrderRatings
} from "./services";

class ReviewStoreActions {
  @action
  getReviewConfig() {
    return new Promise((resolve, reject) => {
      return getReviewConfig().then(
        action(result => {
          this.reviewConfig = (result && result.data) || {};
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  uploadReviewImage(body,itemId) {
    this.imageUploadResponse = {};
    return new Promise((resolve, reject) => {
      return reviewImageUpload(body, itemId).then(
        action(result => {
          this.imageUploadResponse = (result && result.data) || {};
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  deleteReviewImage(itemId, imagePath) {
    this.imageUploadResponse = {};
    const body ={
      imagePath: imagePath
    }
    return new Promise((resolve, reject) => {
      return deleteImage(itemId, JSON.stringify(body)).then(
        action(result => {
          this.imageUploadResponse = (result && result.data) || {};
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  createReview(body) {
    return new Promise((resolve, reject) => {
      return createReview(body).then(
        action(result => {
          this.response = (result && result.data) || {};
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  updateReview(body) {
    return new Promise((resolve, reject) => {
      return updateReview(body).then(
        action(result => {
          this.response = (result && result.data) || {};
          resolve(result && result.data);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  getAllReviews(pageNo,styleId) {
    this.loading = true;
    const body = {
      pageNumber: pageNo,
      pageSize: 50,
      styleIds: styleId
    }
    return new Promise((resolve, reject) => {
      return fetchAllReviews(JSON.stringify(body)).then(
        action(res => {
          if (body.pageNumber === 1) {
            this.reviewList.reviews = [...res.data.reviews];
          } else {
            this.reviewList.reviews = [
              ...this.reviewList.reviews,
              ...res.data.reviews
            ];
          }
          this.reviewList.currentPage = body.pageNumber;
          this.reviewList.totalReviews = res.data.totalReviews || this.reviewList.totalReviews;
          this.reviewList.totalPages = Math.ceil((this.reviewList.totalReviews) / body.pageSize);
          this.loading = false;
          resolve(res.data);
        }),
        action(err => {
          this.loading = false;
          reject(err);
        })
      );
    });
  }

  @action
  getCustomerPhotos(pageNumber,styleId) {
    this.loading = true;
    const body = {
      pageNumber: pageNumber,
      pageSize: 50,
      styleIds: styleId
    }
    return new Promise((resolve, reject) => {
      return getCustomerPhotos(JSON.stringify(body)).then(
        action(res => {
          if (body.pageNumber === 1) {
            this.reviewImageList.reviews = [...res.data.reviews];
          } else {
            this.reviewImageList.reviews = [
              ...this.reviewImageList.reviews,
              ...res.data.reviews
            ];
          }
          this.reviewImageList.currentPage = body.pageNumber;
          this.reviewImageList.totalReviews = res.data.totalReviews || this.reviewImageList.totalReviews;
          this.reviewImageList.totalPages = Math.ceil((this.reviewImageList.totalReviews) / body.pageSize);
          this.loading = false;
          resolve(res.data);
        }),
        action(err => {
          this.loading = false;
          reject(err);
        })
      );
    });
  }

  @action
  fetchReviewWithId(reviewId) {
    this.loading = true;
    const body = {
      reviewId: reviewId
    }
    return new Promise((resolve, reject) => {
      return fetchReviewWithId(JSON.stringify(body)).then(
        action(result => {
          const response = (result && result.data) || {};
          resolve(response);
          this.loading = false;
        }),
        action(err => {
          reject(err);
          this.loading = false;
        })
      );
    });
  }


  @action
  saveOrderRatings(reqBody) {
    this.submitLoading = true;
    return new Promise((resolve, reject) => {
      return saveOrderRatings(reqBody).then(
        action(result => {
          this.submitLoading = false;
          this.response = (result && result.data) || {};
          resolve(result);
        }),
        action(err => {
          this.submitLoading = false;
          reject(err);
        })
      );
    });
  }
}

export default ReviewStoreActions;
