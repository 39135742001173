/* eslint-disable */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Button } from "arv-reactcomponents";

import { LOGOUT_REDIRECT_PATHS } from "npmlinks-constants";

@inject("LoginStore", "MybagStore")
@observer
class LogoutButton extends Component {
  constructor() {
    super();

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.LoginStore.logoutAction();
    this.props.MybagStore.clearCart();
    if (LOGOUT_REDIRECT_PATHS.includes(window.pageType)) {
      window.location = "/";
    }
  }

  render() {
    const { className } = this.props;

    return (
      <Button
        className={`nw-logoutbutton ${className}`}
        onClick={this.logout}
      >
        <i className="icomoon-sign-out nw-logoutbutton-icon" />
        Sign Out
      </Button>
    );
  }
}

LogoutButton.wrappedComponent.defaultProps = {
  className: "",
};

LogoutButton.wrappedComponent.propTypes = {
  className: PropTypes.string,
  MybagStore: PropTypes.shape({
    clearCart: PropTypes.func.isRequired,
  }).isRequired,
  LoginStore: PropTypes.shape({
    logoutAction: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore", "MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LogoutButton.wrappedComponent,
  component: LogoutButton,
};

export default ProjectComponent;
