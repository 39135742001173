import { get } from "../../api";
import { authHeader } from "../../utils";

const version = "v4";
const size = 10;

const getOrders = page =>
  get(
    "orderDetails",
    { ...authHeader() },
    `${version}?page=${page}&size=${size}`,
  );

const getOrderDetails = orderId =>
  get("ProductOrderDetails", { ...authHeader() }, orderId);

export { getOrders, getOrderDetails };
