const BRANDSLIST = {
  HOME_MASONRY_COLUMN_COUNT: { xs: 12, sm: 4 },
  BRAND_MASONRY_COLUMN_COUNT: { xs: 12, sm: 3 },
  SCROLL_X: 0,
  BRANDSLIST_HEADER_TITLE: "BRAND DIRECTORY",
  INVALID_INPUT_ERR_MSG: "Please enter something to search",
  TRENDING_KEY: "Trending",
  SEARCH_REGEX: /\s/g,
  VALIDATE_VALUE_REGEX: /^[a-zA-z0-9 ]*$/,
  VIEWALL_KEY: "View All",
  HREF_SUFFIX: "//",
  ERROR_MSG: "Oops Something went wrong. Please try again later.",
  HOME_JOURNEY_BRAND_FILTER: "se",
  QUERY_ERROR_MSG: "Please enter a valid brand to search",
};

export default BRANDSLIST;
