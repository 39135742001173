import { observable, action, computed } from "mobx";
import WalletStoreActions from "../WalletStore/actions";
import { setSurlFurl, paymentBaseRequest } from "../../utils";
import { PAYMENTPAGE } from "npmlinks-constants";

class WalletStoreClass extends WalletStoreActions {
  @observable walletMobileNumber = "";
  @observable walletOptions = {};
  @observable eWalletOptions = {};
  @observable walletSelection = {}
  @observable selectedWalletCode = null;
  @observable addMoneyRequestBody = null;
  @observable success = {};
  @observable walletSuccess = {};
  @observable paytmOtpVerified = false;

  @computed
  get selectedWallet() {
    const selectedWallet = this.selectedWalletCode
      ? this.walletOptions[this.selectedWalletCode]
      : null;
    return selectedWallet;
  }

  @computed
  get paymentRequestEWallet() {
    const walletData = this.eWalletOptions[this.selectedWalletCode];
    if (walletData) {
      return {
        paymentDetail: paymentBaseRequest({
          description: this.selectedWalletCode,
          bankCode: walletData.walletCode,
        }),
        paymentTypeCode: PAYMENTPAGE.ORDER_REQUEST.E_WALLET,
        requestedPG: walletData.requestedPG || null,
        razorpayKey: walletData.pgRequestParameters && walletData.pgRequestParameters.RAZORPAY_KEY || '',
      };
    }
    return null;
  }

  @computed
  get paymentRequest() {
    if (this.selectedWalletCode === PAYMENTPAGE.PAYTM) {
      return {
        paymentDetail: paymentBaseRequest({
          description: this.selectedWalletCode,
        }),
        paymentTypeCode: PAYMENTPAGE.ORDER_REQUEST.WALLET,
      };
    }
    return null;
  }
}
export default WalletStoreClass;
