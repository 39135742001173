import { action } from "mobx";

class PromotionsStoreAction {
  @action
  setApplicablePromosModal(value) {
    this.isModalOpen = value;
    return this;
  }
  @action
  setSelectedPromo(promoName) {
    this.selectedPromoName = promoName;
  }
  @action
  setRemoveSuccess(flag) {
    this.removeSuccess = !!flag;
  }
}

export default PromotionsStoreAction;
