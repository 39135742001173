// eslint-disable-next-line
import { gmail, OAuth2, FACEBOOK_WEBVIEW, blackboltConfig, Login } from "npmlinks-constants";
import { Utils } from "arv-reactcomponents";

import { getCorrelationId } from "../../utils";

const attachSigninToCustomButton = (element, auth2, login) => {
  if (auth2) {
    // eslint-disable-next-line
    element.disabled = false;
    auth2.attachClickHandler(
      element,
      {},
      googleUser => {
        const accessToken = googleUser.getAuthResponse().access_token;
        login(accessToken, blackboltConfig.google);
      },
      error => error,
    );
  }
};

const setGmail = login => {
  const { gapi } = window;
  const { appId, cookiepolicy, scope } = gmail;
  gapi.load("auth2", () => {
    // Retrieve the singleton for the GoogleAuth library and set up the client.
    // eslint-disable-next-line
    const auth2 = gapi.auth2.init({
      client_id: appId,
      cookiepolicy,
      scope,
      // Request scopes in addition to 'profile' and 'email'
      // scope: 'additional_scope'
    });
    window.auth2 = auth2;
    attachSigninToCustomButton(
      document.getElementById("google-login"),
      auth2,
      login,
    );
  });
};

const gmailInit = login => {
  if (window.gapi) {
    setGmail(login);
  } else {
    const script = document.createElement("script");
    script.async = true;
    script.onload = () => {
      setTimeout(
        () => {
          setGmail(login);
        },
        300,
        false,
      );
    };
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://apis.google.com/js/platform.js");
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

const extractKey = (key, userState) => {
  const pathHash = userState.substring(1).split("&");
  const extractToken = pathHash && pathHash.filter(item => item.indexOf(key) > -1);
  const value = extractToken && extractToken[extractToken.length - 1].split("=")[1];
  return value;
};

const isWebView = () => {
  const { standalone, userAgent } = window.navigator;
  const userAgentToLowerCase = userAgent.toLowerCase();
  const safari = /safari/.test(userAgentToLowerCase);
  const ios = /iphone|ipod|ipad/.test(userAgentToLowerCase);
  const checkIosWebView = ios && !standalone && !safari;
  return checkIosWebView || userAgent.match(/wv/i) || false;
};

/* eslint-disable */
const handleRedirect = (userState, isFBRedirect) => {
  if (userState) {
    const accessToken = extractKey("access_token", userState);
    Utils.localStorage.removeItem("currentUserState");
    const config = isFBRedirect ? blackboltConfig.facebook : blackboltConfig.google;
    return { accessToken: accessToken, config: config };
  }
};

const decidePlatform = type => {
  const { endPoint, clientId, redirectURI, responseType } = FACEBOOK_WEBVIEW;
  const currentUniqueId = getCorrelationId();
  window.sessionStorage.setItem("userSessionId", currentUniqueId);
  if (type && type.length && type === Login.LOGIN_MESSAGES.FB_TEXT) {
    return {
      endPoint: endPoint,
      params: {
        'client_id': clientId,
        'redirect_uri': `${window.location.origin}${redirectURI}`,
        'response_type': responseType,
        'state': currentUniqueId,
      }
    }
  }
  return {
    endPoint: OAuth2.endPoint,
    params: {
      'client_id': `${OAuth2.clientId}`,
      'redirect_uri': `${window.location.origin}${OAuth2.redirectURI}`,
      'scope': `${OAuth2.scope}`,
      'state': 'try_sample_request',
      'cookiepolicy': `${OAuth2.cookiePolicy}`,
      'include_granted_scopes': 'true',
      'response_type': 'token'
    }
  }
}

const OAuth2Init = platform => {
  const { endPoint, params } = decidePlatform(platform);
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = endPoint;

  // Create element to open OAuth 2.0 endpoint in new window.
  var form = document.createElement('form');
  form.setAttribute('method', 'GET'); // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var parameters = params;

  // Add form parameters as hidden input values.
  for (var p in parameters) {
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);

  // Save the current user state to act after login
  const userState = {
    path: window.location.pathname,
  };

  window.sessionStorage.setItem("goToRouteOnLogin", window.goToRouteOnLogin);
  
  Utils.localStorage.setItem(
    "currentUserState",
    userState
  ); 

  // Submit the form
  form.submit();
}

export { 
  OAuth2Init, 
  gmailInit,
  attachSigninToCustomButton,
  handleRedirect,
  extractKey,
  isWebView };
