import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { Select, Utils } from "arv-reactcomponents";

import UserNameInput from "../UserNameInput";

import { Login } from "npmlinks-constants";

class UserNameInputWrapper extends Component {
  static getList(item) {
    return (
      <li className="nw-common-selectlist" key={item}>
        {item}
      </li>
    );
  }
  constructor(props) {
    super(props);

    this.state = {
      genders: [],
      selectSelectedValue: props.selectSelectedValue,
      isValid: "",
      userName: props.userName,
      isUserNameisValid: false,
      userNameErrorMsg: "",
    };

    this.userNameRef = null;
    this.init = this.init.bind(this);
    this.updateGender = this.updateGender.bind(this);
    this.updateUserName = this.updateUserName.bind(this);
    this.decideErrorMsg = this.decideErrorMsg.bind(this);
    this.isGenderInvalid = this.isGenderInvalid.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const { selectSelectedValue } = this.state
    const { GENDER_OPTIONS } = Login.REGISTRATION_MSG;
    this.setState({
      genders: GENDER_OPTIONS,
      selectSelectedValue: selectSelectedValue || GENDER_OPTIONS[0],
    });
  }

  onBlur(value) {
    if (value) {
      this.setState({
        isValid: value,
      });
    }
  }

  isGenderInvalid(gender) {
    const { GENDER_OPTIONS } = Login.REGISTRATION_MSG;
    return gender === GENDER_OPTIONS[0];
  }

  get getUserNameRef() {
    if (this.userNameRef) {
      const { isValid, errorMsg } = this.userNameRef;
      return { isValid, userNameErrorMsg: errorMsg };
    }
    const { isValid, userNameErrorMsg } = this.state;
    return { isValid, userNameErrorMsg };
  }

  updateGender(gender) {
    const { isValid, userNameErrorMsg } = this.getUserNameRef;
    const isFieldValid = this.isGenderInvalid(gender) ? false : isValid;
    const userNameError = this.isGenderInvalid(gender) ? Login.REGISTRATION_MSG.INVALID_GENDER_MSG : userNameErrorMsg;
    this.setState({
      selectSelectedValue: gender,
      userNameErrorMsg: userNameError,
      isValid: isFieldValid,
      isUserNameisValid: isFieldValid && isValid ,
    });
    this.props.updateGender(gender);
  }

  decideErrorMsg(value, ref) {
    const { selectSelectedValue } = this.state;
    const genderEnabled = process.env.REACT_APP_ISGENDER_ENABLED === "true",
    genderInvalid = this.isGenderInvalid(selectSelectedValue);
    this.setState({
      userName: value,
      isUserNameisValid: genderEnabled ? !genderInvalid && ref.isValid : ref.isValid,
      userNameErrorMsg: genderEnabled && genderInvalid && !ref.errorMsg ? Login.REGISTRATION_MSG.INVALID_GENDER_MSG : ref.errorMsg,
      isValid: genderEnabled && genderInvalid && !ref.isValid ? "is-invalid" : "",
    });
  }



  updateUserName(value, ref) {
    const { selectSelectedValue } = this.state;
    this.userNameRef = ref;
    this.decideErrorMsg(value, ref);
    this.props.onChange(value, ref, selectSelectedValue);
  }



  render() {
    const {
      genders,
      selectSelectedValue,
      isValid,
      isUserNameisValid,
      userNameErrorMsg,
    } = this.state;
    const { className, classNameErr, ...otherProps } = this.props;
    return (
      <Fragment>
        <div className={className}>
          <div className={`nw-userinputwrapper ${isValid}`}>
            {process.env.REACT_APP_ISGENDER_ENABLED === "true" && <Select
              className="nwc-select-secondary nwc-select nw-common-select nw-usernameinputwrapper-genderdropdown"
              selectedValue={selectSelectedValue}
              inpList={genders}
              getSelection={this.updateGender}
              renderList={UserNameInputWrapper.getList}
            />}
            <UserNameInput.component
              className="nw-userinputwrapper-inp"
              name=""
              placeholder=""
              onValid={this.updateUserName}
              highlightNameField={isUserNameisValid}
              showErrorMsg={false}
              onBlur={this.onBlur}
              {...otherProps}
            />
          </div>
        </div>
        <div className={classNameErr}>
          {userNameErrorMsg}
        </div>
      </Fragment>
    );
  }
}

UserNameInputWrapper.defaultProps = {
  className: "",
  onChange: Utils.noop,
  updateGender: Utils.noop,
  classNameErr: "nw-form-err-msg",
  selectSelectedValue: "",
  userName: "",
};

UserNameInputWrapper.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  updateGender: PropTypes.func,
  classNameErr: PropTypes.string,
  selectSelectedValue: PropTypes.string,
  userName: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: UserNameInputWrapper,
  component: UserNameInputWrapper,
};

export default ProjectComponent;