import { action } from "mobx";

class HeaderActions {
  @action
  setFixedState(bool) {
    this.isFixed = bool;
  }

  @action
  setFixedHeaderVisibility(bool) {
    this.isFixedHeaderHidden = bool;
  }
}

export default HeaderActions;
