import { Utils } from "arv-reactcomponents";
import { action } from "mobx";
import { mybagGuest, mybagApiData } from "./services";
import { isClientADesktop } from "../../utils";

class CommonActions {
  @action
  setIsIframe(value) {
    this.isIframe = value;
  }
  @action
  setIsDesktop(value) {
    if(value===undefined || value === null){
     this.isDesktop=isClientADesktop();
    }
    this.isDesktop = value;
}
  @action
  setBrandTemplateId(id) {
    this.brandTemplateId = id;
  }
  @action
  setBrandPageState(bool) {
    this.isBrandPage = bool;
  }
  @action
  setBrandDetails(details = {}) {
    this.brandDetails = details;
  }
  @action
  changeBrandName(brandName) {
    this.brandName = brandName;
  }

  getProductDetailsForSkus() {
    mybagGuest(
      JSON.stringify({
        skus: this.guestCartSkuIdsList,
      }),
    ).then(
      action(res => {
        this.cartItems = res.data || [];
      }),
      action(err => {
        console.log(err);
      }),
    );
  }

  getCartIfLoggedIn() {
    mybagApiData().then(
      action(res => {
        Utils.localStorage.setItem("cart-guest", null);
        this.cartItems = res.data.products || [];
      }),
      action(err => {
        console.log(err);
      }),
    );
  }
}

export default CommonActions;
