import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import LoginButton from "../LoginButton";
import StaticLoggedInMenu from "../StaticLoggedInMenu";

@inject("LoginStore")
@observer
class StaticLoginSwitch extends Component {
  render() {
    const { LoginStore, className } = this.props;
    const { isUserLoggedIn } = LoginStore;

    if (isUserLoggedIn) {
      return <StaticLoggedInMenu.component className={className} />;
    }

    return <LoginButton.component className={className} />;
  }
}

StaticLoginSwitch.wrappedComponent.defaultProps = {
  className: "",
};

StaticLoginSwitch.wrappedComponent.propTypes = {
  className: PropTypes.string,
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StaticLoginSwitch.wrappedComponent,
  component: StaticLoginSwitch,
};

export default ProjectComponent;
