const DESKTOP_ACCORDION = [
  {
    type: "FinerDetails",
    text: "THE FINER DETAILS",
    keyToBeChecked: "finerDetails",
    isShown: true,
  },
  {
    type: "ManufacturerDetails",
    text: "Manufacturer Details",
    keyToBeChecked: "manufacturerDetails",
    isShown: true,
  },
  {
    type: "About",
    text: "About %brand%",
    keyToBeChecked: "about",
    isShown: true,
  },
  {
    type: "Returns",
    text: "RETURNS & EXCHANGES",
    keyToBeChecked: "returnable",
    isShown: true,
  },
  {
    type: "Says",
    text: "NNNOW SAYS",
    keyToBeChecked: "story",
    isShown: true,
  },
];

const MOBILE_ACCORDION = [
  {
    type: "FinerDetails",
    text: "THE FINER DETAILS",
    keyToBeChecked: "finerDetails",
    isShown: true,
    isOpen: false,
  },
  {
    type: "ManufacturerDetails",
    text: "Manufacturer Details",
    keyToBeChecked: "manufacturerDetails",
    isShown: true,
    isOpen: false,
  },
  {
    type: "About",
    text: "About %brand%",
    keyToBeChecked: "about",
    isShown: true,
    isOpen: false,
  },
  {
    type: "Returns",
    text: "RETURNS & EXCHANGES",
    keyToBeChecked: "returnable",
    isShown: true,
    isOpen: false,
  },
  {
    type: "Says",
    text: "NNNOW SAYS",
    keyToBeChecked: "story",
    isShown: true,
    isOpen: false,
  },
];
const ABOUT_TEXT = "Says";
const  EXPRESS_DELIVERY = {
  title: "Get Express Delivery",
  delivery_today: { title:"Delivery Today", text: ""},
  delivery_tomorrow: { title: "Delivery Tomorrow", text: ""},
  delivery_week: { title: "Deliver in a week", text: ""},
  return_policy_text: "15 days return & exchange policy",
  free_std_delivery_text: "Free Standard Delivery in 5 days",
  cod_text: "Cash on delivery Available",
  loyalty_points: "Earn and Spend loyalty points",
  nearby_store: "Available at Nearby Store",
  shop_a_store_btn: "Shop-a-Store",
}
const PDP_CONFIG = {
  MAX_QTY: "You Can Add Max $sellableQuantity Quantity of This Item.",
  OOS_MESSAGE: "Sorry! This Product is Out Of Stock.",
  ADD_TO_BAG_SUCCESS: "We've added this product to your bag",
  ACCORDION_CONFIG: { DESKTOP_ACCORDION, ABOUT_TEXT, MOBILE_ACCORDION },
  PDP_DETAILS_BLOCK: {
    ADD_TO_BAG_STATE: "ADD TO BAG",
    CHECKOUT_STATE: "CHECKOUT",
  },
  PRODUCT_RIGHT_PANEL: {
    ADD_TO_BAG_BUTTON: "ADD TO BAG",
  },
  EXPRESS_DELIVERY,
  VIEW_OTHER_ELIGIBLE_PRODUCTS: "View Other Eligible Products",
  OFFERS_TEXT: "Offers",
  PINCODE_INPUT_PLACE_HOLDER: "Enter your pincode",
  CHECK_DELIVERY_OPTION_TEXT: "Check your delivery options",
  CHECK_BTN_TEXT: "Check",
};

export default PDP_CONFIG;
