export default {
  DEFAULT: "default",
  LISTING_PAGE: "listing",
  IMG_FAIL: "img_fail",
  PRODUCT_DESCRIPTION_PAGE: "pdp",
  TOP_MENU_NAVIGATION: "topnav",
  API_FAIL: "api_fail",
  SLOW_API: "slow_api",
  LOGIN: "login",
  MOBILE_LEFTNAV: "left_brand_nav",
  MOBILE_RIGHTNAV: "new_mobile_rightnav",
  NNNOW_NEW_LEFTNAV: "new_mobile_leftnav",
  SEARCH: "search",
  MOBILE_TOPNAV: "mobile_topnav",
  CHECKOUT: "checkout",
  TOP_ABOVE_MENU_CTR: "supertopnav",
  MYBAG: "mybag",
  FOOTER: "footer",
  HOME: "home",
  BRAND: "brand",
  URL_ISSUE: "url_issue",
  MINIBAG: "minibag",
  CATEGORY_LANDING_2: "category_landing_2",
  PRODUCT_QUICKVIEW: "product_quickview",
  GENERICERROR: "genericError",
  SHOP_THE_LOOK: "shop_the_look",
  HEYNNNOW: "heynnnow",
  PRODUCTS: "products",
  STORE_LOCATOR: "store_locator",
  GET_APP: "get_app",
  FAVOURITES_PAGE: "favourites",
  PAYMENT: "payment",
  ORDER_DETAIL: "order_detail",
  ADD_TO_HOME: "add_to_home",
  AUTOSUGGEST: "autosuggest",
};
