import { action } from "mobx";
// eslint-disable-next-line import/no-extraneous-dependencies
import { OLD_MASTER_TEMPLATES } from "npmlinks-constants";
import {
  getBrandPageData,
  getProductsData,
  getShopAllStylesListApi,
  getAllFav,
  addtoFav,
  removeFav
} from "./service";
import { authHeader } from "../../utils";
import { indexifyResponse } from "./methods";

class BrandActions {
  @action
  toggleProductsToBeSelected(bool) {
    this.productsToBeSelected = !!bool;
  }
  @action
  setPageLevelBools(data) {
    this.isShareable = (data && data.find(item => item.share)) || false;
    this.isFavoritable = (data && data.find(item => item.favorite)) || false;
    this.shareImage =
      (data &&
        data.find(item => item.shareImage) &&
        data.find(item => item.shareImage).shareImage) ||
      null;
  }
  @action
  setToken(token) {
    this.token = token;
  }
  @action
  enablePreviewMode() {
    this.previewMode = true;
  }
  @action
  setTemplateValues(id, templateType, legalBrandNames) {
    this.brandId = id;
    this.templateType = templateType;
    this.legalBrandNames = legalBrandNames;
  }

  @action
  setUrlPrefix(data) {
    let prefix;
    if (
      OLD_MASTER_TEMPLATES &&
      OLD_MASTER_TEMPLATES.length &&
      OLD_MASTER_TEMPLATES.includes(this.templateType)
    ) {
      [{ value: prefix }] =
        data &&
        data
          .filter(
            section =>
              section &&
              section.children &&
              section.children.find(item => item.key === "url_prefix")
          )
          .map(
            item =>
              item.children &&
              item.children.find(
                filteredSection => filteredSection.key === "url_prefix"
              )
          );
      this.urlPrefix = prefix;
    } else {
      prefix = data && data.find(item => item.urlPrefix);
      this.urlPrefix = prefix && prefix.urlPrefix;
    }
  }
  @action
  getBrandPageData(cleanedHostName, isDesktop, isSSRCall = false) {
    this.isDesktop = isDesktop;
    return new Promise((resolve, reject) => {
      getBrandPageData(
        this.apiEndPoint,
        {
          ...authHeader(this.token),
          requrl: cleanedHostName
        },
        isSSRCall
      )
        .then(res => {
          this.data = indexifyResponse(
            res.data,
            this.templateType,
            this.isDesktop
          );
          this.setUrlPrefix(res.data);
          this.setPageLevelBools(res.data);
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  @action
  getCarouselData(type, subtype, body) {
    this.tempExtension = `${type}/${subtype}`;
    const basePath = type === "listing" ? "basePathV2" : "basePathV1";
    return new Promise((resolve, reject) => {
      getProductsData(
        this.tempExtension,
        authHeader(this.token),
        body,
        basePath
      )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  getShopAllStylesList(styleIds) {
    const body = JSON.stringify({
      styleIds
    });
    return new Promise((resolve, reject) => {
      getShopAllStylesListApi(body)
        .then(response => {
          this.shopAllStylesList =
            (response && response.data && response.data.list) || [];
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  @action
  getAllFav() {
    return new Promise((resolve, reject) => {
      getAllFav({ ...authHeader(), alldata: true })
        .then(response => {
          const responseArray =
            (response &&
              response.data &&
              Object.keys(response.data).map(
                item => response.data[item] || []
              )) ||
            [];
          this.favBlogs = [].concat(...responseArray);
          resolve(this.favBlogs);
        })
        .catch(() => {
          this.favBlogs = [];
          reject(this.favBlogs);
        });
    });
  }
  @action
  addtoFav(body, param) {
    return new Promise((resolve, reject) => {
      addtoFav(authHeader(), body, param)
        .then(response => {
          this.favBlogs.push(...response.data);
          resolve(this.favBlogs);
        })
        .catch(() => {
          this.favBlogs = [];
          reject(this.favBlogs);
        });
    });
  }
  @action
  removeFav(body) {
    return new Promise((resolve, reject) => {
      removeFav(authHeader(), body)
        .then(response => {
          const responseId = response && response.data && response.data.id;
          this.favBlogs = this.favBlogs.filter(item => item.id !== responseId);
          resolve(this.favBlogs);
        })
        .catch(() => {
          this.favBlogs = [];
          reject(this.favBlogs);
        });
    });
  }
}

export default BrandActions;
