import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Img } from "arv-reactcomponents";
import { Endpoints, Login, blackboltConfig } from "npmlinks-constants";

@inject("LoginStore")
@observer
class ForgotPasswordV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultMainImage: null
    };
    this.onMainImgError = this.onMainImgError.bind(this);
  }

  componentDidMount() {
    this.props.LoginStore.setHideCloseBtn(false);
  }

  onMainImgError() {
    this.setState({
      defaultMainImage: Endpoints.noImg,
    });
  }

  get header() {
    const text = this.props.LoginStore.mobileNoEntered ? blackboltConfig.mobile : blackboltConfig.email;
    return (
      <div className="nw-login-heading">{`${text} ${blackboltConfig.sent}`}</div>
    );
  }
  render() {
    const { mobileNoEntered, userMobileNumberV2 } = this.props.LoginStore;
    const { defaultMainImage } = this.state;
    return (
      <div className="nw-loginv2-container nw-loginv2-delay">
        <div className="nw-forgot-passwordv2">
          {this.header}
          {(mobileNoEntered ? <div className="forgotpswd-number">
            <Img
              className="forgotpasswordv2-img"
              src={defaultMainImage || Endpoints.FORGOT_PASSWORD_IMG.mobile}
              alt="forgot password"
              onError={this.onMainImgError}
            />
            <div className="forgotpasswordv2-msg">
              {`${Login.LOGIN_SUBV2_MSG.SMS_SENT1} ${userMobileNumberV2} ${Login.LOGIN_SUBV2_MSG.SMS_SENT2}`}
            </div>
          </div>
            :
            <div className="forgotpswd-email">
              <Img
                className="forgotpasswordv2-img"
                src={defaultMainImage || Endpoints.FORGOT_PASSWORD_IMG.email}
                alt="forgot password"
                onError={this.onMainImgError}
              />
              <div className="forgotpasswordv2-msg">
                {Login.LOGIN_SUBV2_MSG.EMAIL_SENT}
              </div>

            </div>
          )}
        </div>
      </div>
    )
  }
}



ForgotPasswordV2.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    mobileNoEntered: PropTypes.bool.isRequired
  }).isRequired,
};

const getFinalComponent = component => inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ForgotPasswordV2.wrappedComponent,
  component: ForgotPasswordV2
};

export default ProjectComponent;