import React from "react";
import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";
import PropTypes from "prop-types";

import {
  Navigation,
  SearchBar,
  Logo,
  FavoritesLink,
  MyBagButton,
  LoginSwitch,
  LoyaltyLink,
  GetAppLink,
  TrackOrderLink,
  StoreLocatorLink,
  PromoText,
  SubNav,
} from "../../../molecules";

const DesktopHeader = props => {
  const { className } = props;

  return (
    <header className={`nw-desktopheader ${className}`}>
      <GridContainer>
        <GridRow className="nw-desktopheader-topbarrow">
          <GridColumn className="nw-desktopheader-topbarcol">
            <GridRow className="nw-desktopheader-topbarcols">
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-topbarleftlinks">
                <StoreLocatorLink.component className="nw-header-linksbtns is-first" />
                <span className="nw-header-seperator">|</span>
                <GetAppLink.component className="nw-header-linksbtns" />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-topbarpromo">
                <PromoText.component />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-topbarrightlinks">
                <TrackOrderLink.component className="nw-header-linksbtns">
                  Track Order
                </TrackOrderLink.component>
                <span className="nw-header-seperator">|</span>
                <LoyaltyLink.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <GridRow className="nw-desktopheader-mainbarrow">
          <GridColumn className="nwc-grid-col-xs nw-desktopheader-logocol">
            <Logo.component className="nw-desktopheader-logo" alt={"NNNOW Logo"}/>
          </GridColumn>
          <GridColumn className="nw-desktopheader-mainbarcol">
            <GridRow className="nw-desktopheader-maibarrowcols">
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-searchbarcol">
                <SearchBar.component shrunkView={false} />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-logocol">
                {/*<Logo.component alt={"NNNOW Logo"}/>*/}
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-desktopheader-buttonscol">
                <FavoritesLink.component className="nw-header-linksbtns" />
                
                <MyBagButton.component className="nw-header-linksbtns" />
                
                <LoginSwitch.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <GridRow className="nw-desktopheader-navigationrow">
          <GridColumn className="nw-desktopheader-navigationcol">
            <Navigation.component />
          </GridColumn>
        </GridRow>
      </GridContainer>
      <div className="nw-navtreenew-holder">
        <SubNav.component />
      </div>
      <div className="nw-autosuggest-holder"/>
    </header>
  );
};


DesktopHeader.defaultProps = {
  className: "",
};

DesktopHeader.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: DesktopHeader,
  component: DesktopHeader,
};

export default ProjectComponent;
