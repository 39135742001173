import actions from "./actions";
import labels from "./labels";
import categories from "./categories";
import GTMEvents from "./GTMEvents";
import LogmanEvents from "./LogmanEvents";

export default {
  action: actions,
  label: labels,
  category: categories,
  GTMEvent: GTMEvents,
  LogmanEvent: LogmanEvents,
};
