
const PAYMENTPAGE = {
  OTPTHROUGHSERVICE: {
    HEIMDALL: "heimdall",
    FLASH: "flash"
  },
  OTP_MINDIGIT: 4,
  OTP_MAXDIGIT: 6,
  OTPSUCCESS: "SUCCESS!",
  PAYMENTREQUEST_FURLKEY: "paymentRequest",
  CARDPAYMENTDESCRIPTION: "card entry",
  PAYMNETBASEREQUEST: {
    bankCode: null,
    description: "",
    saveCard: false,
    savedCardPG: null,
    savedCardToken: null,
  },
  SAVEFORLATER_TITLE: "Save card as per RBI guideline",
  PAYMENTOPTIONS_TITLE_WITHSAVEDCARD: "Other Payment Options",
  PAYMENTOPTIONS_TITLE_WITHOUTSAVEDCARD: "Payment Options",
  SAVECARD_TITLE: "Pay using saved cards",
  ALLBANKS: "All Banks",
  PAPULARBANKS: "Popular Banks",
  BANK_DROPDOWNTITLE: "Select Bank",
  DEFAULTSELECTED_EXPMONTH: "MM",
  DEFAULTSELECTED_EXPYEAR: "YYYY",

  MONTHLENGTH: 12,
  YEARLENGTH: 40,
  PLACEORDER_BUTTON: "PLACE ORDER",
  ORDER_REQUEST: {
    CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
    CREDIT_CARD: "CREDIT_CARD",
    DEBIT_CARD: "DEBIT_CARD",
    NET_BANKING: "NET_BANKING",
    SAVEDCARD: "SAVEDCARD",
    WALLET: "WALLET",
    UPI: "UPI",
    E_WALLET: "E_WALLET",
    LOYALTY: "LOYALTY",
    CARDLESS_EMI: "CARDLESS_EMI"
  },
  PHONEPE_TOASTR_REDIRECTION: "Please ensure that you complete transaction on the respective UPI application on the phone",
  COMPONENTWALLET_MAPPING: {
    WALLET: "Paytm",
    CREDIT_CARD: "CreditCard",
    DEBIT_CARD: "CreditCard",
    NET_BANKING: "NetBanking",
    CASH_ON_DELIVERY: "Cod",
    SAVEDCARD: "SAVEDCARD",
    UPI: "UPI",
    EMI: "EMI",
    E_WALLET: "E_Wallet",
    CARDLESS_EMI:"Cardless_emi"
  },
  PAYMENTTYPE_TITLE: {
    WALLET: "Paytm Wallet",
    CREDIT_CARD: "Credit / Debit Card",
    DEBIT_CARD: "Debit Card",
    NET_BANKING: "Net Banking",
    CARDLESS_EMI: "Cardless EMI (Get EMI on Snapmint)",
    CASH_ON_DELIVERY:{
      "ALBL":
      {
        DISPLAY_VALUE:"Pay on Delivery (UPI, Wallet, Card and Cash)"
      },
      default : {
        DISPLAY_VALUE:"Cash On Delivery"
      },
      DISPLAY_NAME : "CASH_ON_DELIVERY"
    } ,
    UPI: "UPI",
    EMI: "EMI",
    E_WALLET: "Other Wallets",
  },
  CARD_LENGTH_FORBIN: 6,
  CARD: {
    CVVLENGTH: {
      AMEX: 4,
      DEFAULT: 3,
    },
    MAXCARDLENGTH: 19,
    MINCARDLENGTH: 13,
  },
  CARDTEXT: {
    CARDTITLE: "Enter your card number",
    CARDSUBTITLE: "(Visa, Mastercard, Amex)",
    CARDHOLDERTITLE: "Enter Name on Card",
    CARDCVV: "CVV",
  },
  CARD_VALIDATION_MESSAGES: {
    CARDNUMBER_VALID: "Please enter valid card details",
    CARDNUMBER_PATTERN: "Please enter card Id as Digits",
    CARDHOLDERNAME_PATTERN: "* Invalid characters",
    CVV_PATTERN: "* Not a valid CVV",
    CVV_INVALID: "Please enter a valid cvv",
    CVV_MISSING: "Hold On! Please enter a valid CVV before you proceed",
  },
  OTP_HEADING: "Verify Mobile Number",
  OTP_TOOLTIP_MSG:
    "This is done so that we can reach you while delivering the order.",
  OTP_SENT_MSG: "SMS with OTP Code is sent to",
  PAYTM_OTP_SENT_MSG: "Paytm has sent an otp to",
  OTP_RESND_CTA: "Resend",
  OTP_RESEND_MSG:"Didn't Recieve? ",
  OTP_VERIFY_BTN: "VERIFY",
  OTP_PLACEHOLDER: "Please enter OTP",
  OTP_ERROR_MSG: "Invalid OTP. Please check again.",
  OTP_SUCCESS_MSG: "Mobile Number Verified.",
  OTP_SUCCESS_MSG_TIME: 5000,
  PAYTM_OTP_SUCCESS_MSG: "Yay! Paytm account linked with NNNOW",
  OTP_VALIDATE_ERROR_MSG: "Invalid OTP, please try again.",
  DEFAULT_ERROR_MESSAGE: "Oops Something went wrong. Please try again later.",
  VALID_OTP_SIZES: [4, 5, 6],
  EXP_DATE_LABEL: "Exp. Date",
  ERRORS: {
    PAYTM_ERROR: {
      LIST: ["INSUFFICIENT_BALANCE_IN_WALLET", "NOT_ABLE_TO_CONNECT_PAYTM", "PAYTM_TOKEN_EXPIRED", "FAILED_TO_FETCH_PAYTM_OTP", "FAILED_TO_VALIDATE_PAYTM_OTP", "FAILED_TO_FETCH_BALANCE_FROM_PAYTM", "USER_NOT_REGISTERED", "PAYTM_USER_NOT_ELIGIBLE"],
    },
    WALLET_INSUFFICIENT_BALANCE: "WALLET_INSUFFICIENT_BALANCE",
    WRONG_BIN_NUMBER: "We are not able to fetch card details, Please try again",
    INVALID_BIN_NUMBER: "Invalid card number.",
    NO_PAYMENT_OPTIONS: "NO PAYMENT OPTIONS are available",
    CVV_EMPTY: "CVV is Empty",
    CVV_ERROR: "Please Enter Proper Cvv Number",
    IMS_OUT_OF_STOCK_KEY: "IMS_OUT_OF_STOCK",
    IMS_OUT_OF_STOCK:
      "One or more items have gone out of stock. The cart has been updated",
    OTP_NOT_VERIFIED: "Mobile Number is not Verifed",
    CAPTCHA_NOT_VERIFIED: "Captcha is not Verified",
    MOBILE_NOT_VERIFIED: "Mobile number is not verified.",
    SOMETHING_ERROR_MSG: "Something went wrong, Please try again.",
    NO_ADDRESS_SELECTED: "Please select/add a delivery address",
    NO_PAYMENT_METHOD: "Hold on! How would you like to pay for this order?",
    CARD_INFO_NOT_ENTERED: "Please enter all the card details",
    NO_BANK_SELECTED: "Please select a bank",
    NO_WALLET_SELECTED: "Please select a wallet option",
    LOYALTY_NOT_VERIFIED: "Mobile Number not verified for Loyalty",
    LOYALTY_INSUFFICIENT_BALANCE:
      "Insufficient Loyalty money. Please select one more payment option available",
    OTP_IS_NOT_VERIFIED: "Please enter the OTP sent by Paytm.",
    UPI_NOT_ADDED: "Please enter your UPI ID",
    LOYALTY_FAILED_TO_REDEEM: "LOYALTY_FAILED_TO_REDEEM",
    CARDLESS_EMI_ERROR: "Method not selected"
  },
  COD_COOKIE: {
    NAME: "codCaptcha",
    VALUE: "codOrderPlaced",
    EXPIRY: "5",
  },
  NETBANKING_REDIRECTION_MSG:
    "You will be re-directed to a secure payment gateway",
  PAYMENT_GATEWAYS: {
    RAZORPAY: 'razorpay',
    PAYU: 'payu',
    SNAPMINT: 'snapmint',
    PHONEPE: 'phonepe'
  },
  RAZORPAY_DATA: {
    'EMAIL': 'email',
    'PHONE': 'phone',
    'RAZORPAY_ORDER_ID': 'orderid',
    'CCNAME': 'name',
    'ORDERID': 'orderId',
    'CCNUM': 'cardnumber',
    'CCEXPM': 'cardexpirymonth',
    'CCEXPYR': 'cardexpiry',
    'CCVV': 'cvv',
    'AMOUNT': 'amount',
    'RAZORPAY_KEY': 'key',
    'ENFORCE': 'enforce',
    'RAZORPAY_CUSTOMER_ID': 'RAZORPAY_CUSTOMER_ID',
    'SAVE_CARD_TOKEN': 'SAVE_CARD_TOKEN',
    'PROVIDER_NAME': "provider",  
    'OFFERKEY': "offer_id"
  },
  PHONEPE_DATA: {
    'EMAIL': 'email',
    'PHONE': 'phone',
    'RAZORPAY_ORDER_ID': 'orderid',
    'ORDERID': 'orderId',
    'AMOUNT': 'amount',
    'MERCHANT_TRANSACTION_ID': 'merchantTransactionId',
    'MERCHANT_ID': 'merchantId',
    'MERCHANT_USER_ID': 'merchantUserId',
    'PENDINGURL': 'pending_confirmation_url',
    'CALL_BACK_URL': 'callbackUrl',
    'PAYMENT_INSTRUMENT_TYPE': 'paymentInstrument',
    'BANK': 'bankCode',
    'REDIRECT_MODE': 'redirectMode',
    'MOBILE': 'mobileNumber'
  },
  RAZORPAY_PAYMENT_MODE: {
    'CreditCard': 'card',
    'NetBanking': 'netbanking',
    'UPI': 'upi',
    'E_Wallet': 'wallet',
    'SAVEDCARD': 'card',
    'Cardless_emi': 'cardless_emi'
  },
  NNNOW_CURRENCY: "₹",
  PAYTM: "PAYTM_WALLET",
  LOYALTY_OTP_HEADING: "Verify OTP to apply loyalty",
  LOYALTY_POINTS_TEXT: "Use your loyalty points",
  LOYALTY_OTP_TOOLTIP_MSG: "This is done so that we can verify the rightful owner.",
  SAVEDCARDMODAL_TITLE: "Change Card",
  SAVEDCARD_CVV_PATTERN: /^\d+$/,
  SAVEDCARDVIEW_VALIDTHROUGH: "Valid Through",
  SAVEDCARDVIEW_SELECT_BTN_TEXT: "Select",
  PAYMENTMETHODS_SUBTITLE: "PAYMENT METHODS",
  UPI_CONSTANTS: {
    VPA_TEXT: "UPI ID : ",
    VPA_PLACEHOLDER: "Pay with any UPI app - example@bank",
    INVALID_ID: "Invalid UPI Id"
  },
  RAZORPAY_CURRENCY_INR: "INR",
  PREPAID_MODE_MSG_PREFIX: "(extra ₹ ",
  PREPAID_MODE_MSG_SUFFIX: " Off)",
  POSTPAID_MODE_TYPE: "CASH_ON_DELIVERY",
  POSTPAID_MODE_MSG_PREFIX: "(additional charge ₹ ",
  POSTPAID_MODE_MSG_SUFFIX: ")",
  CREDIT_OR_DEBIT_CARD_TYPE: "CREDIT_CARD",
  PROMO_ERROR_IMG: "http://d2ew2jx6vkwv1p.cloudfront.net/client/assets/Coupons%20Image.png",
  PROMO_ERROR_MODAL:{
    TITLE: "SORRY !",
    GO_BACK_BTN_LABEL: "GO BACK",
    CONTINUE_BTN_LABEL: "PROCEED WITHOUT COUPON",
    ALT: "promo error img"
  },
  RBI_GUIDLINES: `As per the new RBI guidelines effective from October 1, 2022,
  merchants can no longer save customer credit or debit card
  details. Instead, customers must enter their complete card
  details for every payment done via card. As an alternative, RBI
  has authorized card networks and card issuers to offer card
  tokenisation services, which means the replacement of actual
  credit and debit card details with an alternate code called
  “token”. The user can either choose to tokenise their card by
  giving consent for future transactions or choose to continue
  without tokenisation.`
};

export default PAYMENTPAGE;
