import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { computed } from "mobx";
import { observer, inject } from "mobx-react";

import { Button, Modal } from "arv-reactcomponents";
import MyBagPopup from "../MyBagPopup";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";

@inject("MybagStore", "CommonStore", "LoginStore")
@observer
class MyBagButton extends Component {
  static trackOpenMyBagPopup(isDesktop) {
    Analytics.trackEvent({
      action: Event.action.MYBAG_CLICK,
      label: Event.label.getSource(window.pageType),
      category: isDesktop
        ? Event.category.TOP_MENU_NAVIGATION
        : Event.category.MOBILE_TOPNAV
    });

    GTM.pushEvent(Event.GTMEvent.MYBAG_CLICK);
  }

  constructor(props) {
    super(props);

    this.state = {
      isMyBagPopupActive: false
    };
    this.openMyBagPopup = this.openMyBagPopup.bind(this);
    this.closeMyBagPopup = this.closeMyBagPopup.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  componentDidMount() {}

  @computed
  get cartItems() {
    return (
      (this.props.MybagStore.cartItems &&
        Object.values(this.props.MybagStore.cartItems)) ||
      []
    );
  }

  get cartCount() {
    const cartItemsNew = this.cartItems;
    if (cartItemsNew.length > 0) {
      const count = cartItemsNew.reduce(
        (accumulator, currentValue) => accumulator + currentValue.qty,
        0
      );
      return count;
    }
    return "";
  }

  openMyBagPopup() {
    const { isDesktop } = this.props.CommonStore;
    if (isDesktop) {
      this.setState(state => ({ ...state, ...{ isMyBagPopupActive: true } }));
    } else {
      window.location.href = `${window.location.origin}/mybag`;
    }
    MyBagButton.trackOpenMyBagPopup(isDesktop);
  }

  closeMyBagPopup() {
    this.setState(state => ({ ...state, ...{ isMyBagPopupActive: false } }));
  }

  onLogin() {
    this.props.LoginStore.setloginModal(true);
    this.reset();
    this.triggerGTMOnClick(GTMEvent.LOGIN_CLICKED);
  }

  reset() {
    this.props.reset();
  }

  triggerGTMOnClick(e, eventName) {
    GTM.pushEvent(eventName);
  }

  render() {
    const { isMyBagPopupActive } = this.state;
    const { className } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const headerseperatorBeforeLogin = isDesktop ? "nw-header-seperator5" : "nw-header-seperator5-mweb";
    const headerseperatorForMyBag = isDesktop ? "nw-header-seperator10" : "nw-header-seperator10-mweb";
    return (
      <Fragment>
        {this.props.LoginStore.isUserLoggedIn ? (
          <>
            <Button
              className={`
              nwc-btn-primary-txt
              nw-mybagbutton
              ${className}
            `}
              onClick={this.openMyBagPopup}
            >
              <i className="icomoon-bag1 nw-favoriteslink1 nw-mybagbutton-icon" />
              {this.cartCount && this.cartCount > 0 && (
                <span className="nw-mybagbutton-text">{this.cartCount}</span>
              )}
            </Button>
            <span className= {`nw-desktop-only  ${headerseperatorBeforeLogin}`} >|</span>
            <Modal
              isOpen={isMyBagPopupActive}
              onClose={this.closeMyBagPopup}
              className="nw-mybagpopup"
            >
              <MyBagPopup.component closeMyBagPopup={this.closeMyBagPopup} />
            </Modal>
          </>
        ) : (
          <>
            <Button
              className={`
            nwc-btn-primary-txt
            nw-mybagbutton1
            ${className}
          `}
              onClick={this.openMyBagPopup}
            >
              <i className="icomoon-bag1 nw-favoriteslink1 nw-mybagbutton-icon" />
              {this.cartCount && this.cartCount > 0 && (
                <span className="nw-mybagbutton-text-before-login">{this.cartCount}</span>
              )}
            </Button>
            <span className= {`nw-desktop-only  ${headerseperatorForMyBag}`} >|</span>
            <Modal
              isOpen={isMyBagPopupActive}
              onClose={this.closeMyBagPopup}
              className="nw-mybagpopup"
            >
              <MyBagPopup.component closeMyBagPopup={this.closeMyBagPopup} />
            </Modal>
          </>
        )}
      </Fragment>
    );
  }
}

MyBagButton.wrappedComponent.defaultProps = {
  className: ""
};

MyBagButton.wrappedComponent.propTypes = {
  className: PropTypes.string,
  MybagStore: PropTypes.shape({
    cartItems: PropTypes.shape({})
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("MybagStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MyBagButton.wrappedComponent,
  component: MyBagButton
};

export default ProjectComponent;
