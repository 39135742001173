/* eslint-disable react/no-danger */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { FOOTER } from "npmlinks-constants";
import {
  HTMLUtils,
  deduceValuesFromPipedData,
  isPdpPage,
} from "../../../utils";
import FooterDoc from "../FooterDoc";

@inject("GetTypeStore")
@observer
class FooterDocs extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      staticContext: PropTypes.object,
    }),
  };

  componentDidMount() {
    if (this.invalidContent.length > 0) {
      console.error(
        "FOOTER DOCS, the following docs had invalid html tags",
        this.invalidContent,
      );
    }
  }

  get alignment() {
    const { data } = this.props.GetTypeStore;
    return data && data.alignment || "VERTICAL";
  }

  get pathname() {
    const { staticContext } = this.context.router || {};
    const globalConfigVar =
      (window && window.GL) || (staticContext && staticContext.globalConfig);
    return (
      (globalConfigVar && globalConfigVar.pathname) || window.location.pathname
    );
  }

  get getTypeType() {
    const { data } = this.props.GetTypeStore;
    const { docType = null } = data;
    const { type } = deduceValuesFromPipedData(data.type);
    return docType || type;
  }

  get isHome() {
    return (
      this.pathname === "/" &&
      (!this.getTypeType || this.getTypeType === "unknown")
    );
  }

  get content() {
    const { data } = this.props.GetTypeStore;
    const dataFromGetType =
      data && data.footerSeoList && data.footerSeoList.length > 0
        ? data.footerSeoList
        : null;
    const dataForPage = FOOTER.SPECIFIC_PAGES[this.getTypeType];
    const defaultData = FOOTER.DEFAULT_FOOTER_DOCS;
    return (
      dataFromGetType ||
      (this.isHome && FOOTER.SPECIFIC_PAGES.HOME) ||
      (isPdpPage(this.pathname) && []) ||
      dataForPage ||
      defaultData ||
      []
    );
  }

  get validContent() {
    return this.content.filter(
      item =>
        ((item.footerDescription &&
          HTMLUtils.validate(item.footerDescription)) ||
          !item.footerDescription) &&
        ((item.footerTitle && HTMLUtils.validate(item.footerTitle)) ||
          !item.footerTitle),
    );
  }

  get invalidContent() {
    return this.content.filter(
      item =>
        !(
          (item.footerDescription &&
            HTMLUtils.validate(item.footerDescription)) ||
          !item.footerDescription
        ) ||
        !(
          (item.footerTitle && HTMLUtils.validate(item.footerTitle)) ||
          !item.footerTitle
        ),
    );
  }

  get isLanding() {
    return this.pathname === "/";
  }

  get classNameFooter(){
    const { data } = this.props.GetTypeStore;
    let classNameFooterValue ="nw-footerdocs";
    if(data.alignment=== "HORIZONTAL"){
      classNameFooterValue = "nw-footerdocs-align-horizontal";
    }
    return classNameFooterValue;
  }

  render() {
    if (this.content.length === 0) {
      return null;
    }
    return (
      <div className={this.classNameFooter}>
        {this.content.map((item, key) => (
          <FooterDoc.component
            key={item.footerTitle}
            isLanding={this.isLanding}
            item={item}
            index={key}
            alignment={this.alignment}
          />
        ))}
      </div>
    );
  }
}

FooterDocs.wrappedComponent.propTypes = {
  GetTypeStore: PropTypes.shape({
    data: PropTypes.shape({}).isRequired,
  }).isRequired,
};
const getFinalComponent = component =>
  inject("GetTypeStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FooterDocs.wrappedComponent,
  component: FooterDocs,
};

export default ProjectComponent;
