import { Toastr } from "arv-reactcomponents";

const somethingWentWrong = () => {
  Toastr.showToastr({
    className: "nwc-toastr-list-danger nw-toastr",
    message: `Sorry, Something went wrong...`,
    timeout: 3000,
  });
  return null;
};

export default somethingWentWrong;
