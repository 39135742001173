import lodashGet from "lodash.get";

import { BRANDSLIST } from "npmlinks-constants";

function checkBrandName(brand) {
  let properBrandName;
  if (brand && brand.brandName) {
    properBrandName = brand.brandName.charAt(0).toLowerCase();
  } else if (brand && brand.legalBrandName && brand.legalBrandName.length) {
    properBrandName = brand.legalBrandName[0].charAt(0).toLowerCase();
  }
  return properBrandName;
}

function sortBrandData(brand1, brand2) {
  const brandName1 = checkBrandName(brand1);
  const brandName2 = checkBrandName(brand2);

  let comparison = 0;

  if (brandName1 > brandName2) {
    comparison = 1;
  } else if (brandName1 < brandName2) {
    comparison = -1;
  }

  return comparison;
}

const processBrandListData = brandData => {
  try {
    if (brandData && brandData.data && brandData.data.data) {
      const nonSortedBrandData = lodashGet(
        brandData,
        "data.data.brandList",
        [],
      );
      const brandList = nonSortedBrandData.sort(sortBrandData);
      const brandImageList = lodashGet(
        brandData,
        "data.data.brandImageList",
        [],
      );
      let firstChar;
      const brandslist = [];
      brandList.forEach(data => {
        if (firstChar !== checkBrandName(data)) {
          firstChar = checkBrandName(data);
          // Initialising the array with key
          brandslist.push({
            key: firstChar.toUpperCase(),
            value: [],
          });
        }

        // Pushing the according data
        brandslist[brandslist.length - 1].value.push({
          brandName:
            (data.brandName && data.brandName.toUpperCase()) ||
            (data.legalBrandName && data.legalBrandName[0].toUpperCase()),
          brandType: data.brandType,
          productListingUrl: data.productListingUrl,
          url: data.url,
          brandId: data.brandId,
          images: data.images,
          parentBrand: data.parentBrand,
        });
      });
      const processedBrandList = {
        brandList: brandslist,
        brandImageList,
      };
      return processedBrandList;
    }
    return [];
  } catch (error) {
    return error;
  }
};

const brandFilter = (array, id, filter, search, homeJourneyBrandFilter) => {
  if (array && array.length) {
    let result = array.map(item => {
      const validList = {
        key: "",
        value: [],
      };

      const isParentBrandMatch = brand =>
        brand.parentBrand === id || brand.brandId === id;

      const homeJourneyFilter = brand =>
        brand.parentBrand !== homeJourneyBrandFilter;

      const isSearchKeyPresent = brand =>
        brand.brandName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

      const isFilterTypeMatched = brand => brand.brandType === filter;

      // Filtering based on Brand Journey = parentBrandId + search
      if (id && id.length) {
        validList.value =
          search && search.length
            ? // ParentBrandId + search
              item.value.filter(isParentBrandMatch).filter(isSearchKeyPresent)
            : // ParentBrandId
              item.value.filter(isParentBrandMatch);
      }

      // Filtering based on Home Journey = search + brandType
      if (!id && filter && filter !== BRANDSLIST.VIEWALL_KEY) {
        validList.value =
          search && search.length
            ? item.value
                .filter(homeJourneyFilter)
                .filter(isFilterTypeMatched)
                .filter(isSearchKeyPresent)
            : item.value.filter(homeJourneyFilter).filter(isFilterTypeMatched);
      } else if (!id) {
        validList.value =
          search && search.length
            ? item.value.filter(homeJourneyFilter).filter(isSearchKeyPresent)
            : item.value.filter(homeJourneyFilter);
      }

      validList.key =
        (validList.value.length &&
          validList.value[validList.value.length - 1].brandName &&
          validList.value[validList.value.length - 1].brandName.charAt(0)) ||
        "";

      return validList;
    });

    result = result.filter(item => item.key !== "");
    return result;
  }
  return [];
};

export { processBrandListData, brandFilter };
