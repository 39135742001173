import { observable } from "mobx";

import WishListActions from "./actions";

class WishListStoreClass extends WishListActions {
  constructor(...props) {
    super(props);
  }
  @observable favoritesData = [];
  @observable wishListData = [];
  @observable brandWishListData = [];
  @observable tempWishListBrand = "";
}

export default WishListStoreClass;
