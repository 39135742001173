import React, { Component } from "react";
import PropTypes from "prop-types";
import lodashGet from "lodash.get";
import { isPdpPage, deduceValuesFromPipedData } from "../utils";
import { pageType, GETTYPE_PAGEWISE_VALUES } from "npmlinks-constants";

import {
  Listing,
  Pdp,
  BrandPage,
  NotFound,
} from "./routes.js";
import { observer, inject } from "mobx-react";

@inject("GetTypeStore")
@observer
class UiRoutingComponent extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      staticContext: PropTypes.object,
    }),
  };
  selectedComponent() {
    const { data: getTypeData = {} } = this.props.GetTypeStore;
    const { staticContext } = (this.context && this.context.router) || {};
    const pathname =
      (staticContext &&
        staticContext.globalConfig &&
        staticContext.globalConfig.pathname) ||
      (window && window.location && window.location.pathname) ||
      "";
    const getType = getTypeData;
    const currentPage =
      (window.history &&
        window.history.state &&
        window.history.state.state &&
        window.history.state.state.currentPage &&
        window.history.state.state.currentPage) ||
      "";
    const isCurrentStatePdp = currentPage === pageType.pdp;
    const isCurrentStateListing = currentPage === pageType.listing;
    const documentType = getType && getType.documentType;
    const { type } = deduceValuesFromPipedData(getType && getType.type);
    const finalType = documentType || type;
    const cidOverride =
      lodashGet(this, "context.router.staticContext.globalConfig.cid", null) ||
      window.location.search.indexOf("cid") > -1
        ? "Collection"
        : "";
    const pdpFallBack = isPdpPage(pathname) ? "Style" : "";
    return (
      cidOverride ||
      (isCurrentStatePdp && "Style") ||
      (isCurrentStateListing && "Collection") ||
      pdpFallBack ||
      finalType
    );
  }

  logComponent() {
    let { data: getTypeData = {} } = this.props.GetTypeStore;
    let getType = getTypeData;
    let getTypeStatusCode =
      (getType &&
        getType.backendUnknownErr &&
        getType.backendUnknownErr.statusCode) ||
      "200";
    let { staticContext } = (this.context && this.context.router) || {};
    let pathname =
      (staticContext &&
        staticContext.globalConfig &&
        staticContext.globalConfig.pathname) ||
      (window && window.location && window.location.pathname) ||
      "";
    let URL = (window && window.location && window.location.href) || "";
    let logs = `404_REDIRECT , PATH: ${pathname}, URL: ${URL}, GetTypeResponse: ${JSON.stringify(
      getType
    )}, GetTypeStatus: ${getTypeStatusCode}`;
    return logs;
  }
  
  render() {
    const { props } = this;
    const type = this.selectedComponent();
    switch (true) {
      case GETTYPE_PAGEWISE_VALUES.LISTING.includes(type):
        return <Listing {...props} />;
      case GETTYPE_PAGEWISE_VALUES.PDP.includes(type):
        return <Pdp {...props} />;
      case GETTYPE_PAGEWISE_VALUES.MASTER_TEMPLATE.includes(type):
        return <BrandPage {...props} />;
      default:
        return <NotFound {...props} />;
    }
  }
}
export default UiRoutingComponent;