import { observable, computed } from "mobx";
import BrandActions from "./actions";
import { getIsLandscape } from "../../utils";
import { MASTERTEMPLATE } from "npmlinks-constants";

class BrandStoreClass extends BrandActions {
  constructor(props) {
    super();
    this.dataTemp = (props && props.data) || null;
    this.brandIdTemp =
      (props && props.brandId) || process.env.REACT_APP_MASTERTEMPLATEID;
    this.legalBrandNamesTemp =
      (props && props.legalBrandNames) ||
      (process.env.REACT_APP_MASTERTEMPLATELEGALBRANDNAMES &&
        JSON.parse(process.env.REACT_APP_MASTERTEMPLATELEGALBRANDNAMES)) ||
      [];
    this.templateTypeTemp =
      (props && props.templateType) || process.env.REACT_APP_MASTERTEMPLATETYPE;
    this.urlPrefixTemp = (props && props.urlPrefix) || "";
    this.previewModeTemp = (props && props.previewMode) || false;
    this.isShareableTemp = (props && props.isShareable) || false;
    this.isFavoritableTemp = (props && props.isFavoritable) || false;
    this.shareImageTemp = (props && props.shareImage) || null;
    this.isDesktopTemp = props && props.isDesktop;
  }
  @observable isDesktop = this.isDesktopTemp;
  @observable previewMode = this.previewModeTemp;
  @observable brandId = this.brandIdTemp;
  @observable legalBrandNames = this.legalBrandNamesTemp;
  @observable templateType = this.templateTypeTemp;
  @observable data = this.dataTemp;
  @observable urlPrefix = this.urlPrefixTemp;
  @observable token = null;
  @observable shopAllStylesList = [];
  @observable favBlogs = [];
  @observable isShareable = this.isShareableTemp;
  @observable isFavoritable = this.isFavoritableTemp;
  @observable shareImage = this.shareImageTemp;
  @observable productsToBeSelected = false;
  @computed
  get apiEndPoint() {
    return `${this.templateType}/${this.brandId}/${
      this.isDesktop || getIsLandscape() ? "desktop" : "mobile"
    }${this.previewMode ? "/?cmsPreview=1" : ""}`;
  }
  @computed
  get templateClass() {
    return `nw-mt-${this.templateType && this.templateType.toLowerCase()}`;
  }

  @computed
  get isBT() {
    return (this.templateType === MASTERTEMPLATE.BT && "bt") || "";
  }
}

export default BrandStoreClass;
