import { action } from "mobx";

import lodashGet from "lodash.get";

import { BRANDSLIST } from "npmlinks-constants";

import { processBrandListData } from "./method";

import {
  fetchWhoWeAreContent,
  fetchCustomercareContent,
  fetchPrivacyPolicyContent,
  fetchTermsAndConditionContent,
  sendContactMessage,
  sendAppLink,
  getBrandsList,
} from "./service";

class StaticPageStoreActions {
  @action
  fetchWhoWeAreDescription() {
    return new Promise((resolve, reject) => {
      fetchWhoWeAreContent().then(
        response => {
          this.whoWeAreDescription =
            response &&
            response.data &&
            response.data.whoweare &&
            response.data.whoweare.description;
          resolve(response.data.whoweare.description);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  @action
  fetchCustomerCareDescription() {
    return new Promise((resolve, reject) => {
      fetchCustomercareContent().then(
        response => {
          this.customerCareDescription =
            response &&
            response.data &&
            response.data.customercare &&
            response.data.customercare.description;
          resolve(response.data.customercare.description);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  @action
  fetchPrivacyPolicyDescription() {
    return new Promise((resolve, reject) => {
      fetchPrivacyPolicyContent().then(
        response => {
          this.privacyPolicyDescription =
            response &&
            response.data &&
            response.data.privacy &&
            response.data.privacy.description;
          resolve(response.data.privacy.description);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  @action
  fetchTermsAndConditionDescription() {
    return new Promise((resolve, reject) => {
      fetchTermsAndConditionContent().then(
        response => {
          this.termsAndConditionDescription =
            response &&
            response.data &&
            response.data.terms &&
            response.data.terms.description;
          resolve(response.data.terms.description);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  @action
  sendContactUsMessage(contactUsMessage) {
    return new Promise((resolve, reject) => {
      sendContactMessage(JSON.stringify(contactUsMessage)).then(
        response => {
          if (response) {
            this.contactusResponse = response && response.status;
            resolve(response);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  sendNNNOWAppLink(number) {
    return sendAppLink(JSON.stringify(number));
  }

  @action
  fetchBrandsListData() {
    return new Promise((resolve, reject) => {
      getBrandsList()
        .then(response => {
          const processData = processBrandListData(response);
          this.brandListObject = lodashGet(processData, "brandList", []);
          this.brandBannerObject = lodashGet(processData, "brandImageList", []);
          // eslint-disable-next-line no-unused-expressions
          if (this.brandListObject.length) {
            this.brandListObject.map(data => {
              data.value.map(banner => {
                if (banner.brandType === BRANDSLIST.TRENDING_KEY) {
                  this.trendingBannerObject.push(banner);
                }
                if (
                  banner.brandType !== "" &&
                  !this.brandListFilter.includes(banner.brandType)
                ) {
                  this.brandListFilter.push(banner.brandType);
                }
              });
            });
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  setFilterValue(filter) {
    this.filterValue = filter;
  }

  @action
  setSearchValue(search) {
    this.searchValue = search.toLowerCase();
  }

  @action
  setBrandFilterId(id) {
    this.brandFilterId = id || null;
  }
}

export default StaticPageStoreActions;
