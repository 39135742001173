import { observable, computed } from "mobx";
import CheckoutActions from "./actions";
import { getShippingMethods, getAddressIdInLocal } from "../../utils";
import { Checkout } from "npmlinks-constants";

class CheckoutStoreClass extends CheckoutActions {
  @observable error = null;
  @observable loading = false;
  // will have valid cart items only
  @observable cartItems = [];
  // will return cart from cookie if local cart not present, use this variable for forming all requests
  @observable bagTotalItemCount = 0;
  @observable applicablePromotions = [];

  // TODO:: need to remove in future
  @observable storeList = [];
  @observable selectedShippingMethods = getShippingMethods() || [];
  @observable pricingOverviewData = {};
  @observable paymentTypes = [];
  @observable shippingAddress = {};
  @observable pincode = "";
  @observable productToBeFavorited = null;
  @observable limitBreachedSkus = [];
  @observable skusNotAdded = [];
  @observable itemsHavingIssueInPricing = [];
  @observable addressId = getAddressIdInLocal() || null;

  @observable deliveryCharges = {};

  @observable loyaltyDetails = {};

  @observable allApplicableCoupons = [];

  @observable couponDetails = {};

  @observable cartProductDetails = [];

  @computed
  get breachMessages() {
    return this.limitBreachedSkus.map(skuId => {
      const product = this.cartItems.find(item => item.skuId === skuId);
      const { sellableQty, styleName } = product;
      return Checkout.CHECKOUT_MESSAGES.LIMIT_BREACH_MESSAGE.replace(
        "$count",
        sellableQty
      ).replace("$name", styleName);
    });
  }

  get cartItemsRequest() {
    if (this.cartItems && this.cartItems.length) {
      return this.cartItems.map(item => {
        if (item && item.shippingMethod && !item.shipping) {
          // eslint-disable-next-line no-param-reassign
          item.shipping = {
            shippingMethod: {
              deliveryType: item.shippingMethod,
            },
          };
        }
        return item;
      });
    }
    return [];
  }

  get couponCode() {
    return this.pricingOverviewData.isCouponApplied ? this.pricingOverviewData.couponCode : null ;
  }

  @computed
  get getPaymentOptionsRequestData() {
    return {
      paymentTypes: this.paymentTypes,
      amount: this.pricingOverviewData.total,
      loyaltyDetails: this.loyaltyDetails,
      cartItems: this.cartItemsRequest,
      couponCode:  this.couponCode,
      isPromotionWithPaymentConstraintApplied: this.pricingOverviewData.isPromotionWithPaymentConstraintApplied || false,
      isPromotionPaymentConstraintSupported: true
    };
  }
  @computed
  get shippingValidData() {
    let message;
    const cartItems = this.cartItems || [];
    const isMinOneCartItem = () => cartItems.length;
    const outOfStockItems = () => cartItems.some(cart => !cart.qty);
    const servicableToPincode = true;
    const isAllStoredPickup = () =>
      cartItems.some(
        product =>
          product.shippingMethod ===
          Checkout.CHECKOUT_DELIVERY_OPTIONS.STOREPICK
      );
    return {
      isAllStoredPickup: isAllStoredPickup(),
      isValid: isMinOneCartItem() && !outOfStockItems() && servicableToPincode,
      message
    };
  }

  @computed
  get placeOrderShippingRequestData() {
    const productsShippingDetails = this.cartItems.map(product => {
      const retObj = {
        skuId: product.skuId || product.selectedSku.skuId,
        qty:
          product.qty || (product.selectedSku && product.selectedSku.qty) || 1,
        productId: product.productId,
        shippingMethod: product.shippingMethod
      };
      const storeId = product.store && product.store.fcId;
      if (storeId) {
        retObj.storeId = storeId;
      }
      return retObj;
    });
    let {
      subtotal: mrp,
      discounts: totalDiscount,
      tax,
      shippingCost,
      nnnowCash: totalNNNowCash,
      total: netAmount,
      couponCode
    } = this.pricingOverviewData;
    if (!this.pricingOverviewData.isCouponApplied) {
      couponCode = null;
    }
    return {
      productsShippingDetails,
      pricingDetails: {
        mrp,
        shippingCost,
        totalDiscount,
        tax,
        totalNNNowCash,
        netAmount,
        couponCode
      }
    };
  }

  constructor() {
    super();
    this.paymentExceptionMsg = "";
  }
}

export default CheckoutStoreClass;
