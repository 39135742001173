import React from "react";
import PropTypes from "prop-types";
import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";

import {
  Logo,
  StaticLoginSwitch,
} from "../../../molecules";

const DesktopFixedHeader = props => {
  const { className } = props;

  return (
    <header className={`nw-staticheader ${className}`}>
      <GridContainer>
        <GridRow className="nw-staticheader-row">
          <GridColumn className="nw-staticheader-col">
            <GridRow>
              <GridColumn className="nwc-grid-col-xs nw-staticheader-leftcol">
                <Logo.component className="nw-staticheader-logo" />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-staticheader-buttonscol">
                <StaticLoginSwitch.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </header>
  );
};

DesktopFixedHeader.defaultProps = {
  className: "",
};

DesktopFixedHeader.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: DesktopFixedHeader,
  component: DesktopFixedHeader,
};

export default ProjectComponent;
