import React from "react";
import PropTypes from "prop-types";

function FooterLinkList({ footerLink }) {
  const navItem = footerLink.map(item => (
    <span className="nw-footer-links-content" key={item.index}>
      <a
        className="nw-footer-links-content-link"
        target="_blank"
        alt={item.label}
        href={item.url}
      >
        {item.label}
      </a>
    </span>
  ));
  return <div className="nw-footer-links-content">{navItem}</div>;
}

FooterLinkList.propTypes = {
  footerLink: PropTypes.shape({
    children: PropTypes.array,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FooterLinkList,
  component: FooterLinkList,
};

export default ProjectComponent;
