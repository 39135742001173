import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
import {
  HEADER,
  pageType,
  INITIAL_AUTOSUGGESTIONS,
  INVALID_AUTO_SUGGEST_INPUT,
  RegexMethods,
  ARE_YOU_LOOKIG_FOR_TEXT
} from "npmlinks-constants";
import { Utils, Button, Modal, Toastr } from "arv-reactcomponents";
import SearchBarForm from "../SearchBarForm";
import SuggestionList from "../SuggestionList";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";
import GTMEvents from "../../analytics/eventFactory/GTMEvents";
import { validateUrlforMalform } from "../../utils";
import { toJS } from "mobx";

@withRouter
@inject(
  "ProductStore",
  "CommonStore",
  "LoginStore",
  "MybagStore",
  "HeaderStore"
)
@observer
class SearchBar extends Component {
  static trackToggleSearch(isDesktop) {
    Analytics.trackEvent({
      action: Event.action.SEARCH_CLICK,
      label: Event.label.getSource(window.pageType),
      category: isDesktop
        ? Event.category.TOP_MENU_NAVIGATION
        : Event.category.MOBILE_TOPNAV
    });
  }
  constructor(props) {
    super(props);
    let queryParam = validateUrlforMalform(this.props.location.search) || "";
    this.state = {
      query: Utils.getUrlParameter(queryParam, "q") || "",
      searchActive: false,
      searchHistory:
        Utils.localStorage.getItem(
          `localSearchHistory_${this.brandid || "nw"}`
        ) || [],
      modalActive: false,
      callAutoSuggestFlag: false,
      showLocalSuggestion: true
    };
    this.buttonActions = this.buttonActions.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
    this.innerContents = this.innerContents.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.validateSearch = this.validateSearch.bind(this);
    this.toggleDropDownWrapper = this.toggleDropDownWrapper.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.validateQuery = this.validateQuery.bind(this);
    this.timeoutId = null;
    this.searchBarStatusUpdate = this.searchBarStatusUpdate.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }
  get brandid() {
    return lodashGet(this, "props.CommonStore.brandDetails.id", null);
  }
  get getSuggestions() {
    let odinPresent = window.localStorage.getItem("channel");
    const { query, searchHistory, showLocalSuggestion } = this.state;
    const suggestionsObj = JSON.parse(
      process.env.REACT_APP_SEARCHSUGGESTIONS || "{}"
    );
    const initArray = showLocalSuggestion
      ? [
          {
            title: HEADER.SEARCHBAR_RECENTS_HEADING,
            list: searchHistory
          },
          {
            title: HEADER.SEARCHBAR_SUGGESTIONS_HEADING,
            list:
              (this.brandid && suggestionsObj[this.brandid]) ||
              (!this.brandid && suggestionsObj.nw) ||
              []
          }
        ]
      : [];

    return query.length >= 3 &&
      this.validateQuery(query) &&
      odinPresent === "true"
      ? this.getAutoSuggestions
      : initArray;
  }

  validateQuery(query) {
    return !RegexMethods.XSS_REGEX.test(query);
  }

  get getAutoSuggestions() {
    const { query } = this.state;
    const { ProductStore, HeaderStore, CommonStore } = this.props;
    const { isFixed } = HeaderStore;
    const { isDesktop } = CommonStore;
    const { autoSuggestionsData, autoSuggetsLoader } = ProductStore;
    const data = toJS(autoSuggestionsData);
    const suggestionsLimit = (data && data.length) || 0;

    const suggestions =
      data &&
      data.length &&
      data.map(item => {
        if (item.value.toLowerCase().includes(query.toLowerCase())) {
          const regEx = new RegExp(query, "ig");
          const replaceMask = `<span class="nw-autosuggest-term">${query}</span>`;
          return [
            `<span class="nw-autosuggest-termcontainer">${item.value.replace(
              regEx,
              replaceMask
            )}</span>`,
            item.value,
            item.url
          ];
        }
        return [`<span >${item.value}</span>`, item.value, item.url];
      });

    data &&
      data.length == 0 &&
      Analytics.trackEvent({
        action: Event.action.SEARCH_NORESULT,
        label: query,
        category: Event.category.SEARCH
      });

    const autoSuggestions =
      suggestions && suggestions.length
        ? process.env.REACT_APP_AUTOSUGGEST_FULL_SCREEN == "true" &&
          isDesktop &&
          !isFixed
          ? [
              {
                title: ARE_YOU_LOOKIG_FOR_TEXT,
                list: suggestions
              }
            ]
          : [
              {
                title: ARE_YOU_LOOKIG_FOR_TEXT,
                list: suggestions.slice(0, Math.round(suggestionsLimit / 2))
              },
              {
                title: "",
                list:
                  suggestions.length > 1
                    ? suggestions.slice(-(suggestionsLimit / 2))
                    : []
              }
            ]
        : [
            {
              title: ARE_YOU_LOOKIG_FOR_TEXT,
              list: [
                <div className="nw-noresult-text">
                  Sorry!! No Results Found
                </div>,
                ""
              ]
            }
          ];
    return autoSuggetsLoader ? this.loadingScreen : autoSuggestions;
  }

  get loadingScreen() {
    const { HeaderStore, CommonStore } = this.props;
    const { isFixed } = HeaderStore;
    const { isDesktop } = CommonStore;
    return process.env.REACT_APP_AUTOSUGGEST_FULL_SCREEN == "true" &&
      isDesktop &&
      !isFixed
      ? INITIAL_AUTOSUGGESTIONS.v2
      : INITIAL_AUTOSUGGESTIONS.v1;
  }

  componentDidMount() {
    this.validateSearch();
  }
  validateSearch() {
    const {
      location: { pathname }
    } = this.props;
    if (pathname === "/noresults") {
      const { searchHistory } = this.state;
      let params = new URL(document.location).searchParams;
      let q = params.get("q");
      if (searchHistory.indexOf(q) == 0) {
        searchHistory.splice(0, 1);
        this.setState({ searchHistory, query: "" });
      }
      this.setState({ searchHistory, query: "" });
      Utils.localStorage.setItem(
        `localSearchHistory_${this.brandid || "nw"}`,
        searchHistory
      );
    }
  }
  toggleModal(bool) {
    const { isDesktop } = this.props.CommonStore;
    if (bool) {
      SearchBar.trackToggleSearch(isDesktop);
    }
    this.props.searchBarStatus(bool);
    this.setState({ modalActive: bool, query: "" });
  }
  triggerSearch(url = null) {
    const { query, searchHistory } = this.state;
    if (window.pageType === pageType.listing) {
      Analytics.trackEvent({
        action: query
          ? Event.action.REPEAT_SEARCH
          : Event.action.BLANK_QUERY_ERROR,
        label: Event.label.getSearchQuery(query),
        category: Event.category.SEARCH
      });
    } else {
      Analytics.trackEvent({
        action: query
          ? Event.action.QUERY_ENTER
          : Event.action.BLANK_QUERY_ERROR,
        label: Event.label.getSearchQuery(query),
        category: Event.category.SEARCH
      });
    }
    query
      ? this.setState({ query: query }, () => {
          this.trigerAutosuggestGA(query);
        })
      : "";
    GTM.pushWithEvent(
      {
        queryString: query
      },
      GTMEvents.SEARCH_QUERY
    );
    if (!query) {
      Toastr.showToastr({
        className: "nwc-toastr-list-danger nw-toastr",
        message: HEADER.SEARCHBAR_INVAIDSEARCHMESSAGE,
        timeout: 3000
      });
      return;
    }
    const index = searchHistory.findIndex(
      item => item.toLocaleLowerCase() === query.toLocaleLowerCase()
    );
    if (index > -1) {
      searchHistory.splice(index, 1);
    }
    searchHistory.unshift(query);
    const strippedSearchHistory = searchHistory.slice(0, 5);
    this.setState({ modalActive: false });
    Utils.localStorage.setItem(
      `localSearchHistory_${this.brandid || "nw"}`,
      strippedSearchHistory
    );
    let queryString = validateUrlforMalform(query) || "";
    queryString = queryString.replace(" & ", " %26 ");
    url = url && url.startsWith("%2F") ? url.replace("%2F", "/") : url;
    const suggestionUrl =
      url && url.startsWith("/")
        ? `${window.location.origin}${url}`
        : `${window.location.protocol}//${url}`;

    window.location.href = url
      ? suggestionUrl
      : `${window.location.origin}/search?q=${queryString}`;
  }

  toggleDropDown(bool) {
    const { isDesktop } = this.props.CommonStore;
    if (bool) {
      SearchBar.trackToggleSearch(isDesktop);
    }
    const {
      location: { pathname }
    } = this.props;
    this.setState({
      searchActive: bool,
      query:
        !bool &&
        Utils.getUrlParameter(window.location.href, "q") !== this.state.query &&
        pathname !== "/noresults"
          ? Utils.getUrlParameter(this.props.location.search, "q") || ""
          : this.state.query
    });
    if (isDesktop) {
      this.props.searchBarStatus(bool);
    }
  }
  buttonActions(side) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    const { isDesktop } = this.props.CommonStore;
    const { autoSuggestionsData } = this.props.ProductStore;
    const data = toJS(autoSuggestionsData);
    const url = data && data[0] && data[0].url ? data[0].url : "";
    const queryString = data && data[0] && data[0].value ? data[0].value : "";
    if (isDesktop) {
      if (!this.state.searchActive && side === "left") {
        this.toggleDropDown(true);
      }
      if (this.state.searchActive && side === "left") {
        this.triggerSearch();
      }
      if (side === "submit") {
        this.triggerSearch();
      }
      if (this.state.searchActive && side === "right") {
        this.toggleDropDown(false);
        this.setState({ query: "", searchActive: false });
        Analytics.trackEvent({
          action: Event.action.SEARCH_CLOSE,
          label: Event.label.getSource(window.pageType),
          category: Event.category.SEARCH
        });
      }
    } else if (side === "left") {
      this.toggleModal(false);
      Analytics.trackEvent({
        action: Event.action.SEARCH_CLOSE,
        label: Event.label.getSource(window.pageType),
        category: Event.category.SEARCH
      });
    } else {
      this.triggerSearch();
      queryString
        ? () => {
            this.trigerAutosuggestGA(queryString);
          }
        : "";
    }
  }

  trigerAutosuggestGA = query => {
    let odinPresent = window.localStorage.getItem("channel");
    if (odinPresent === "true") {
      Analytics.trackEvent({
        action: `${Event.action.AUTOSUGGEST_LIST_ITEM_CLICK}0`,
        label: `${query}_clicked`,
        category: Event.category.AUTOSUGGEST
      });
    }
  };

  selectItem(item, trigger, url = null, eventType) {
    this.setState({ triggers: trigger, query: item }, () => {
      if (trigger) {
        this.triggerSearch(url);
        this.setState({ searchActive: false });
        const { HeaderStore, CommonStore } = this.props;
        const { isFixed } = HeaderStore;
        const { isDesktop } = CommonStore;
        isDesktop && isFixed ? this.toggleDropDown(false) : "";
      }
    });
    const { callAutoSuggestFlag } = this.state;
    if (
      item.length >= 3 &&
      this.validateQuery(item) &&
      !trigger &&
      eventType !== "blur"
    ) {
      this.props.ProductStore.updateLoader(true);

      if (callAutoSuggestFlag) {
        let odinPresent = window.localStorage.getItem("channel");
        const { suggestionTerm } = this.props.ProductStore;
        eventType !== "focus" &&
          (odinPresent === "true"
            ? Analytics.trackEvent({
                action: Event.action.AUTOSUGGEST_SEARCH_CLICK,
                label: item,
                category: Event.category.AUTOSUGGEST
              })
            : "");

        if (suggestionTerm.trim() === item.trim())
          this.props.ProductStore.updateLoader(false);
        else {
          let odinPresent = window.localStorage.getItem("channel");

          if (odinPresent === "true") {
            const { isBrandPage, brandDetails } = this.props.CommonStore;
            const { parentBrandName = "", legalBrandNames = "" } = brandDetails;
            const reqBody = {
              suggestionTerm: item,
              isBrandJourney: isBrandPage,
              parentBrand:
                (parentBrandName && parentBrandName[0]) ||
                (legalBrandNames && legalBrandNames[0]),
              isQueryLoggingEnable: false
            };
            this.props.ProductStore.getautoSuggestions(reqBody);
          }
        }
      }
    }
  }

  updateAutoSuggestFlag = (e, bool) => {
    this.setState({ callAutoSuggestFlag: bool }, () =>
      this.toggleDropDownWrapper(e, bool)
    );
  };

  keyDown() {
    const keyStrokeTime =
      process.env.REACT_APP_AUTOSUGGESTIONS_KEYSTOKE_TIME &&
      parseInt(process.env.REACT_APP_AUTOSUGGESTIONS_KEYSTOKE_TIME, 10) < 600
        ? 600
        : parseInt(process.env.REACT_APP_AUTOSUGGESTIONS_KEYSTOKE_TIME, 10);

    clearTimeout(this.timer);
    this.setState({
      callAutoSuggestFlag: false
    });
    this.timer = setTimeout(() => {
      this.setState(
        {
          callAutoSuggestFlag: true
        },
        () => {
          const { query } = this.state;
          this.selectItem(query, false);
        }
      );
    }, keyStrokeTime || 600);
  }

  toggleDropDownWrapper(e, bool) {
    e.preventDefault();
    e.stopPropagation();
    const { searchActive } = this.state;
    const { isDesktop } = this.props.CommonStore;
    if (bool !== searchActive && isDesktop) {
      this.timeoutId = setTimeout(() => {
        this.toggleDropDown(bool);
      }, (!bool && 300) || 0);
    }
    const value = e.target.value;
    const eventType = e.type;
    eventType === "focus" || eventType === "blur"
      ? this.setState({ showLocalSuggestion: true }, () => {
          this.selectItem(value, false, null, eventType);
        })
      : this.setState({ showLocalSuggestion: false }, () => {
          this.selectItem(value, false, null, eventType);
        });
  }

  searchBarStatusUpdate() {
    this.props.searchBarStatus(false);
  }

  innerContents() {
    if (this.state.query.indexOf("<") > -1) {
      this.state.query = this.state.query.replace("<", "");
    }
    const { searchActive, query } = this.state;
    const { className, shrunkView } = this.props;
    const { isFixed } = this.props.HeaderStore;
    const { isDesktop } = this.props.CommonStore;
    return (
      <div
        className={`nw-searchbar-container ${className} ${((searchActive ||
          !shrunkView) &&
          "show-border") ||
          ""}`}
      >
        <SearchBarForm.component
          searchActive={searchActive}
          buttonActions={this.buttonActions}
          shrunkView={shrunkView}
          toggleDropDown={this.toggleDropDown}
          query={query}
          selectItem={this.selectItem}
          keyDown={this.keyDown}
          keyUp={this.keyUp}
          updateAutoSuggestFlag={this.updateAutoSuggestFlag}
          toggleDropDownWrapper={this.toggleDropDownWrapper}
          searchBarStatusUpdate={this.searchBarStatusUpdate}
        />

        {((process.env.REACT_APP_AUTOSUGGEST_FULL_SCREEN === "true" &&
          (isFixed || !isDesktop)) ||
          process.env.REACT_APP_AUTOSUGGEST_FULL_SCREEN !== "true") &&
          this.getSuggestions && (
            <SuggestionList.component
              active={searchActive}
              list={this.getSuggestions}
              selectItem={this.selectItem}
              query={query}
            />
          )}

        {process.env.REACT_APP_AUTOSUGGEST_FULL_SCREEN == "true" &&
        isDesktop &&
        !isFixed &&
        document.getElementsByClassName("nw-autosuggest-holder")[0]
          ? ReactDOM.createPortal(
              <div className="nw-suggestions-fullwidth">
                <SuggestionList.component
                  active={searchActive}
                  list={this.getSuggestions}
                  selectItem={this.selectItem}
                  query={query}
                />
              </div>,
              document.getElementsByClassName("nw-autosuggest-holder")[0]
            )
          : null}
      </div>
    );
  }

  onLogin() {
    this.props.LoginStore.setloginModal(true);
    this.reset();
    this.triggerGTMOnClick(GTMEvent.LOGIN_CLICKED);
  }

  reset() {
    this.props.reset();
  }

  triggerGTMOnClick(e, eventName) {
    GTM.pushEvent(eventName);
  }

  render() {
    const { isDesktop } = this.props.CommonStore;
    if (isDesktop) {
      return this.innerContents();
    }
    return (
      <Fragment>
        {this.props.LoginStore.isUserLoggedIn ? (
          <Button
            className="nw-searchbar-mobilebutton"
            onClick={() => this.toggleModal(true)}
          >
            <i className="icomoon-search3" />
          </Button>
        ) : (
          <>
            <Button
              className="nw-searchbar-mobilebutton1"
              onClick={() => this.toggleModal(true)}
            >
              <i className="icomoon-search3" />
            </Button>

            <span className="nw-header-seperator1">|</span>
          </>
        )}
        <Modal
          isOpen={this.state.modalActive}
          onClose={() => this.toggleModal(false)}
          className="nw-searchbar-modal"
        >
          {this.innerContents()}
        </Modal>
      </Fragment>
    );
  }
}
SearchBar.wrappedComponent.defaultProps = {
  className: "",
  shrunkView: true,
  searchBarStatus: Utils.noop
};

SearchBar.wrappedComponent.propTypes = {
  className: PropTypes.string,
  searchBarStatus: PropTypes.func,
  ProductStore: PropTypes.shape({
    clearProductData: PropTypes.func.isRequired
  }).isRequired,
  shrunkView: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("ProductStore", "CommonStore", "HeaderStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: SearchBar.wrappedComponent,
  component: SearchBar
};

export default ProjectComponent;
