import React from "react";
import PropTypes from "prop-types";
import { PropTypes as MobXProptypes } from "mobx-react";
import FooterLinkList from "../FooterLinkList";

function FooterLinks({ footerLinkData }) {
  if (footerLinkData && footerLinkData.length > 0) {
    const FooterLink = footerLinkData.map(item => (
      <div className="nw-footer-links-wrapper" key={item.index}>
        <div className="nw-footer-links-title">{item.label}</div>
        {item.children.map(footerLink => (
          <FooterLinkList.component
            key={footerLink.index}
            footerLink={footerLink.children}
          />
        ))}
      </div>
    ));
    return <div className="nw-footer-links-container"> {FooterLink} </div>;
  }
  return null;
}

FooterLinks.propTypes = {
  footerLinkData: MobXProptypes.observableArrayOf(
    PropTypes.shape({
      children: PropTypes.object,
    }),
  ).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FooterLinks,
  component: FooterLinks,
};

export default ProjectComponent;
