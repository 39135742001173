import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import Breadcrumb from "./Breadcrumb";
import { getHref, isPdpPage } from "../../utils";
import { BREADCRUMB_HOME } from "npmlinks-constants";
import { toJS } from "mobx";

const BRAND_NAME = BREADCRUMB_HOME || "Home";

@inject("GetTypeStore", "CommonStore")
@observer
class BreadcrumbList extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      staticContext: PropTypes.object,
    }),
  };
  constructor() {
    super();
    this.currentNode = this.currentNode.bind(this);
    this.breadcrumblink = this.breadcrumblink.bind(this);
  }

  get list() {
    const { data: getTypeData = {} } = this.props.GetTypeStore;
    const { breadCrumbs } = getTypeData;
    const list = toJS(breadCrumbs) || [];
    const listClone = [...list];
    const { brandDetails = {} } = this.props.CommonStore;
    const { parentBrandName = [] } = brandDetails;
    const firstArrayValue =
      listClone &&
      listClone[0] &&
      listClone[0].breadcrumbText &&
      listClone[0].breadcrumbText.toLowerCase();

    let parentBrand = (parentBrandName && parentBrandName[0]) || BRAND_NAME;
    parentBrand =
      parentBrand.charAt(0).toUpperCase() + parentBrand.substring(1);
    const parentBrandCheck = parentBrand.toLowerCase();

    if (parentBrandCheck !== firstArrayValue) {
      listClone.unshift({
        breadcrumbText: parentBrand,
        breadcrumbUrl: "/",
      });
    }

    if (firstArrayValue) {
      listClone[0].breadcrumbUrl = "/";
    }

    return listClone;
  }
  breadcrumblink(url) {
    const { data: getTypeData = {} } = this.props.GetTypeStore;
    let breadcrumb_url = url;
    breadcrumb_url = (/^\//).test(breadcrumb_url) ? getHref(`${getTypeData.origin}${breadcrumb_url}`) : getHref(breadcrumb_url);
    return breadcrumb_url; 
  }
  currentNode(index, item) {
    const { staticContext } = (this.context && this.context.router) || {};
    const pathname =
      (staticContext &&
        staticContext.globalConfig &&
        staticContext.globalConfig.pathname) ||
      (window && window.location && window.location.pathname) ||
      "";
    if (this.list.length - 1 === index && !isPdpPage(pathname)) {
      return (
        <h1 className="nw-breadcrumb-listitem" itemProp="name">
          {item.breadcrumbText}
        </h1>
      );
    }
    return (
      <span className="nw-breadcrumb-listitem" itemProp="name">
        {item.breadcrumbText}
      </span>
    );
  }

  render() {
    if (this.list && this.list.length > 1) {
      return (
        <section className="nw-breadcrumblist-section">
          <ol
            className="nw-breadcrumblist-list"
            itemScope
            itemType="http://schema.org/BreadcrumbList"
          >
            {this.list.map((item, index) => (
              <li
                key={item.breadcrumbText}
                className="nw-breadcrumblist-item"
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <Breadcrumb.component
                  index={index}
                  link={this.breadcrumblink(item.breadcrumbUrl)}
                  selectedIndex={this.list.length - 1}
                  itemProp="item"
                >
                  {this.currentNode(index, item)}
                </Breadcrumb.component>
                <meta itemProp="position" content={index + 1} />
              </li>
            ))}
          </ol>
        </section>
      );
    }

    return null;
  }
}

export { default as Breadcrumb } from "./Breadcrumb";

BreadcrumbList.wrappedComponent.propTypes = {
  GetTypeStore: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool,
    brandDetails: PropTypes.shape({}),
  }).isRequired,
};

const getFinalComponent = component =>
  inject("GetTypeStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BreadcrumbList.wrappedComponent,
  component: BreadcrumbList,
};

export default ProjectComponent;