import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { Utils } from "arv-reactcomponents";

import Analytics, { EventFactory as Event } from "../../analytics";

class BrandNavTree extends Component {
  static trackLinkClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_ABOVE_MENU_CTR,
    });
  }

  static hasSubNav(list) {
    return Array.isArray(toJS(list)) && list.length !== 0;
  }

  static isLastItem(list) {
    return (!BrandNavTree.hasSubNav(list) && "is-last") || "";
  }

  onLinkClick(menu) {
    const { level } = this.props;
    const label = menu.name.toLowerCase();
    const hasSubNav = BrandNavTree.hasSubNav(menu[`level${level + 1}`]);

    return e => {
      if (hasSubNav) {
        Utils.preventDefault(e);
      } else {
        const eventAction = `nnnow_nav_${label}._click`;
        BrandNavTree.trackLinkClick(eventAction);
      }
    };
  }

  get isLevel2ScrollClassName() {
    return (this.props.level === 2 && "nwc-custom-scrollbar") || "";
  }

  get isActiveClassName() {
    return (this.props.active && this.props.level === 2 && "is-active") || "";
  }

  render() {
    const { list, level } = this.props;

    if (!list || (Array.isArray(toJS(list)) && list.length === 0)) {
      return null;
    }

    return (
      <ul
        className={`
          nw-brandnavtree-list 
          nw-brandnavtree-level${level} 
          ${this.isActiveClassName}
          ${this.isLevel2ScrollClassName}
        `}
      >
        {list.map(menu => (
          <li
            key={menu.name}
            className={`nw-brandnavtree-items nw-brandnavtree-items${level}`}
          >
            <a
              href={menu.url}
              className={`
                nw-brandnavtree-link 
                nw-brandnavtree-link-level${level}
                ${BrandNavTree.isLastItem(menu[`level${level + 1}`])}
              `}
              onClick={this.onLinkClick(menu)}
            >
              {menu.name}
            </a>
            <BrandNavTree level={level + 1} list={menu[`level${level + 1}`]} />
          </li>
        ))}
      </ul>
    );
  }
}

BrandNavTree.defaultProps = {
  list: [],
  active: false,
};

BrandNavTree.propTypes = {
  level: PropTypes.number.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  active: PropTypes.bool,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandNavTree,
  component: BrandNavTree,
};

export default ProjectComponent;
