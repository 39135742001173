import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import {
  Img,
  Button,
  GridContainer,
  GridRow,
  GridColumn,
  Utils
} from "arv-reactcomponents";

import { Endpoints } from "npmlinks-constants";
import { LoginSwitch } from "../../../../molecules";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";

import NavContainer from "../../NavContainerv2";
import FavoritesLink from "../../../FavoritesLink";
import MyBagActionButton from "../../../MyBagButton";
import SearchBar from "../../../SearchBar";

@inject("SearchBarStore", "CommonStore", "NavListStore")
@observer
class NnnowBar extends Component {
  static onLogoClick() {
    Analytics.trackEvent({
      action: Event.action.LOGO_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_TOPNAV
    });
  }

  static trackMenuClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.NNNOW_NEW_LEFTNAV
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      hideMenu: true
    };
    this.onMenuClick = this.onMenuClick.bind(this);
    this.hideWhenSearch = this.hideWhenSearch.bind(this);
  }

  onMenuClick() {
    const hideMenu = !this.state.hideMenu;
    if (this.props.SearchBarStore.showSearch) {
      this.props.SearchBarStore.toggleSearch();
    }

    const eventAction = !hideMenu
      ? Event.action.MENU_OPEN
      : Event.action.MENU_CLOSE;
    NnnowBar.trackMenuClick(eventAction);

    this.setState(
      {
        hideMenu
      },
      () => {
        if (this.state.hideMenu) {
          Utils.unFixScroll();
        } else {
          Utils.fixScroll();
        }
      }
    );
  }
  get hideMenuClass() {
    return (this.state.hideMenu && "nwc-hide") || "";
  }

  get menuActiveClass() {
    return (!this.state.hideMenu && "is-active") || "";
  }

  get hideIfBrandpageClassName() {
    return (this.props.CommonStore.isBrandPage && "nwc-hide") || "";
  }

  get logoUrl() {
    const { navListData } = this.props.NavListStore;
    const logoFromApi =
      (navListData &&
        navListData.data &&
        navListData.data.logos &&
        navListData.data.logos.nnnow &&
        navListData.data.logos.nnnow.image) ||
      null;
    const logoUrl = Endpoints.nnnowLogoUrl || logoFromApi; // need to swap once data is fine in cdn
    return logoUrl;
  }

  hideWhenSearch() {
    if (!this.state.hideMenu) {
      this.setState(
        {
          hideMenu: true
        },
        () => {
          Utils.unFixScroll();
        }
      );
    }
  }
  render() {
    const data =
      (this.props.NavListStore &&
        this.props.NavListStore.navListData &&
        this.props.NavListStore.navListData.data &&
        this.props.NavListStore.navListData.data.menu &&
        this.props.NavListStore.navListData.data.menu.level1) ||
      [];

    return (
      <Fragment>
        <GridContainer className="nw-brandbarv2-wrapper">
          <GridRow className="nw-brandbarv2-row">
            <GridColumn className="nw-nnnowbarv2-leftmenu">
              <Button
                onClick={this.onMenuClick}
                className={`nw-brandbarv2-action-button nw-brandbarv2-menu  ${this.hideIfBrandpageClassName} ${this.menuActiveClass}`}
              >
                <i className="icomoon-menu" />
              </Button>
            </GridColumn>
            <GridColumn
              className="nw-nnnowbarv2-logo nw-nnnowbarv2-logowrap"
              title="NNNOW Home"
            >
              <a href="/" target="_self" onClick={NnnowBar.onLogoClick}>
                <Img
                  className={`nw-nnnowbarv2-logoimg ${this.hideIfBrandpageClassName}`}
                  src={this.logoUrl}
                />
              </a>
            </GridColumn>
            <GridColumn className="nw-brandbarv2-actions">
              <div
                className="nw-nnnowbarv2-searchwrap"
                onKeyDown={Utils.noop}
                role="presentation"
              >
                <SearchBar.component />
              </div>
              <div className="nw-nnnowbarv2-favicon" role="presentation">
                <FavoritesLink.component />
              </div>
              <div className="nw-nnnowbarv2-mybagwrap nw-nnnowbarv2-bagicon">
                <MyBagActionButton.component />
              </div>
              <div className="nw-nnnowbarv2-favicon1" role="presentation" >
                <LoginSwitch.component />
              </div>
            </GridColumn>
            <GridColumn className="nw-nnnowbarv2-nav">
              <nav
                className={`nw-brandbarv2-hamburger-menu nw-brandbarv2-nnnow-menu ${this.menuActiveClass} ${this.hideMenuClass}`}
              >
                <NavContainer.component
                  className="nw-nnnow-header"
                  navData={data}
                  reset={this.hideWhenSearch}
                />
              </nav>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Fragment>
    );
  }
}

NnnowBar.wrappedComponent.propTypes = {
  SearchBarStore: PropTypes.shape({
    showSearch: PropTypes.bool,
    toggleSearch: PropTypes.func
  }).isRequired,
  NavListStore: PropTypes.shape({
    navListData: PropTypes.object
  }).isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("SearchBarStore", "CommonStore", "NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: NnnowBar.wrappedComponent,
  component: NnnowBar
};

export default ProjectComponent;
