import { post, put, get } from "../../api";
import { authHeader } from "../../utils";
import { blackboltConfig } from "npmlinks-constants";

const login = (type,body) => post(type, {"X-Channel": blackboltConfig.channel}, body);

const loginWithOtp = body =>post("loginV2", {"X-Channel": blackboltConfig.channel}, body);

const socialLogin = (type,body) => post(type, {"X-Channel": blackboltConfig.channel}, body);

const forgotPassword = (headers, body) => post("forgotPassword", headers, body);

const loginV2 = body =>post("loginV2", {"X-Channel": blackboltConfig.channel}, body);

const resetPassword = (sessionId, body, headers) =>
  put("resetPassword", headers, body, `reset-password?token=${sessionId}`);

const register = (type, body) => post(type, {}, body);

const registerWithOtp = (type, body) => post(type, {"X-Channel": blackboltConfig.channel}, body);

const getAccount = () =>
  // TODO move custom Headers from here
  get("account", { ...authHeader() });

const updateUserInfo = userInfoData =>
  put("updateUserInfo", { ...authHeader() }, JSON.stringify(userInfoData));

const checkUser = (headers, body) =>
  post("checkUserExist", headers, JSON.stringify(body));

const passwordValidationRegex = () =>
  get("passwordRegex", {});


export {
  updateUserInfo,
  forgotPassword,
  login,
  loginWithOtp,
  register,
  registerWithOtp,
  getAccount,
  socialLogin,
  resetPassword,
  checkUser,
  passwordValidationRegex,
  loginV2,
};
