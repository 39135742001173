import { PRICINGOVERVIEW } from "npmlinks-constants";

const ToCurrency = (value, currency = PRICINGOVERVIEW.CURRENCY) => {
  if (value === undefined || value === null) {
    return "";
  }
  const newValue = parseFloat(`${value}`.replace(/,/, ""), 10);
  return `${currency} ${Number(newValue).toFixed(2).toLocaleString()}`;
};

const CurrencyToVal = (value, currency = PRICINGOVERVIEW.CURRENCY) => {
  const newcurrency = value.replace(`${currency} `, "").replace(/[,]+/g, "");
  return newcurrency && parseInt(newcurrency, 10);
};

export { ToCurrency, CurrencyToVal };
