// Import Core Components here
import React, { Component } from "react";
import PropTypes from "prop-types";

// Import App depedency here
import { Input, Utils } from "arv-reactcomponents";
import { RegexMethods } from "npmlinks-constants";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordInputErrorMsg: "",
      isPasswordInputValid: false,
      passwordErrorClass: "",
      isDisplayPwd: false,
      isValid: "",
      passwordErrorHeightClass: "",
    };

    this.onBlur = this.onBlur.bind(this);
    this.passwordToggle = this.passwordToggle.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.setErrorMsg = this.setErrorMsg.bind(this);
  }

  onBlur() {
    this.props.showErrorMsg(false);
    let passwordErrorClass = "nwc-hidden";
    let isValid = "";
    let passwordErrorHeightClass;
    if (!this.state.isPasswordInputValid) {
      passwordErrorClass = this.state.password ? "" : "nwc-hidden";
      isValid = "is-invalid";
      passwordErrorHeightClass = "nw-password-err-msg-height"
    }
    this.setState({ passwordErrorClass, isValid, passwordErrorHeightClass });
  }

  setErrorMsg(errorMsg) {
    this.setState({
      passwordErrorClass: "",
      passwordInputErrorMsg: errorMsg,
    });
  }

  get passwordEyeClass() {
    return !this.state.isDisplayPwd ? "icomoon-eye" : "icomoon-eye-slash";
  }

  get inputType() {
    return !this.state.isDisplayPwd ? "password" : "text";
  }

  passwordToggle() {
    const isDisplayPwd = !this.state.isDisplayPwd;
    this.setState({ isDisplayPwd });
  }

  validatePassword(e) {
    this.setState({ passwordErrorClass: "nwc-hidden", passwordErrorHeightClass: ""});
    const validatePasswordField = this.validatePasswordFieldRef.getValidatedOutput(
      e.target.value,
    );
    this.setState({
      isPasswordInputValid: validatePasswordField.isValid,
      passwordInputErrorMsg: validatePasswordField.errorMsg,
      password: validatePasswordField.value,
      isValid: "",
    });
    this.props.showErrorMsg(false);
    this.props.onPasswordChange ? this.props.onPasswordChange() : null;
  }

  static getDerivedStateFromProps(props, state) {
    const { highlightPasswordField: isPasswordInputValid } = props
    if (isPasswordInputValid !== state.isPasswordInputValid) {
      return {
        isValid: isPasswordInputValid ? "is-invalid" : "",
        passwordErrorClass: "",
        passwordInputErrorMsg: props.userPasswordInputErrorMsg
      };
    }
    return null;
  }


  render() {
    let {
      password,
      passwordInputErrorMsg,
      passwordErrorClass,
      isValid,
      passwordErrorHeightClass,
    } = this.state;
    const {
      className,
      placeholder,
      name,
      minLength,
      parentClassName,
      onPaste,
      pattern, 
      msg,
      classNameErr,
      showErrorPart
    } = this.props;
    return (
      <div className={`${parentClassName} nw-pwd-input`}>
        <Input
          className={`${className} ${isValid}`}
          minLength={minLength}
          name={name}
          onBlur={this.onBlur}
          onChange={this.validatePassword}
          placeholder={placeholder}
          type={this.inputType}
          value={password}
          onPaste={onPaste}
          ref={values => {
            this.validatePasswordFieldRef = values;
          }}
          validateWithPattern={[
            {
              pattern: /^\S+$/,
              msg: "No Space Allowed",
            },
            {
              pattern: new RegExp(pattern),
              msg: msg,
            },
          ]}
        />
        <i
          role="button"
          className={`eye-icon nw-frgt-pwd ${this.passwordEyeClass}`}
          onClick={this.passwordToggle}
          onKeyPress={this.passwordToggle}
        />
          { showErrorPart &&
        <div className={`${classNameErr} ${passwordErrorClass} ${passwordErrorHeightClass}`}>
          {passwordInputErrorMsg}
        </div>
        }
      </div>
    );
  }
}

PasswordInput.defaultProps = {
  className: "",
  minLength: 0,
  parentClassName: "",
  required: false,
  showErrorMsg: Utils.noop,
  onPaste: Utils.noop,
  passwordInputErrorMsg: "",
  highlightPasswordField: false,
  classNameErr:"nw-form-err-msg",
  showErrorPart:true,
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  parentClassName: PropTypes.string,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.func,
  onPaste: PropTypes.func,
  pattern: PropTypes.string,
  msg: PropTypes.string,
  classNameErr: PropTypes.string,
  showErrorPart:PropTypes.bool
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: PasswordInput,
  component: PasswordInput,
};

export default ProjectComponent;
