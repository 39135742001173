import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { scrollTime, MOBILE_FILTER_BUTTON } from "npmlinks-constants";
import { Button, Utils } from "arv-reactcomponents";

import { getCurrentHref } from "../../../utils";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";

@inject("ProductStore", "CommonStore")
@observer
class MobileFilterButtons extends Component {
  constructor() {
    super();
    this.toggleMobileFilter = this.toggleMobileFilter.bind(this);
    this.blockButtonClick = this.blockButtonClick.bind(this);
  }

  componentDidMount() {
    const headerContainer = document.querySelectorAll(
      ".nw-header-container",
    )[0];
    if (headerContainer) {
      headerContainer.style.minHeight = "140px";
    }
  }

  componentWillUnmount() {
    const headerContainer = document.querySelectorAll(
      ".nw-header-container",
    )[0];
    if (headerContainer && headerContainer.style) {
      headerContainer.style.minHeight = null;
    }
  }

  toggleMobileFilter(type, event) {
    event.preventDefault();
    this.props.ProductStore.toggleMobileFilters(type);
  }

  blockButtonClick(type, event) {
    event.preventDefault();
    Utils.scrollTo(window, MOBILE_FILTER_BUTTON.SCROLLX, MOBILE_FILTER_BUTTON.SCROLLY, scrollTime.fast);
    let gridView = false;
    let blockView = false;
    switch (type) {
      case "gridActive":
        gridView = true;
        break;
      case "blockActive":
        blockView = true;
        break;
      default:
        break;
    }
    this.props.ProductStore.setListingGridLayout(gridView, blockView);
    Analytics.trackEvent({
      action:
        type === "gridActive" ? Event.action.GRID_VIEW : Event.action.LIST_VIEW,
      label: getCurrentHref(),
      category: Event.category.LISTING_PAGE,
    });
  }

  render() {
    const { showMobileFilters, disableFilters, isBlockActiveClassName, isGridActiveClassName } = this.props.ProductStore;
    const { isDesktop } = this.props.CommonStore;

    if (!showMobileFilters) return null;
    const RenderComponent = (
      <div className="nw-mobilefilterbuttons">
        <Button
          className="nw-mobilefilterbuttons-item nwc-btn-secondary"
          onClick={event => {
            this.toggleMobileFilter("refine", event);
          }}
          disabled={disableFilters}
        >
          {MOBILE_FILTER_BUTTON.REFINE_BUTTON}
        </Button>
        <Button
          className="nw-mobilefilterbuttons-item nwc-btn-secondary"
          onClick={event => {
            this.toggleMobileFilter("sortBy", event);
          }}
          disabled={disableFilters}
        >
          {MOBILE_FILTER_BUTTON.SORT_BUTTON}
        </Button>
        <div className="nw-productlist-togglewrap nw-mobile-only">
          <Button
            onClick={e => this.blockButtonClick("gridActive", e)}
            className={`nw-productlist-grid ${isGridActiveClassName}`}
          >
            <i className="icomoon-th-large" />
          </Button>
          <Button
            onClick={e => this.blockButtonClick("blockActive", e)}
            className={`nw-productlist-block ${
              isBlockActiveClassName
            }`}
          >
            <i className="icomoon-square" />
          </Button>
        </div>
      </div>
    );
    if ((global && global.isServer) || isDesktop) {
      return null;
    }
    return RenderComponent;
  }
}
MobileFilterButtons.wrappedComponent.propTypes = {
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  ProductStore: PropTypes.shape({
    disableFilters: PropTypes.bool.isRequired,
    toggleMobileFilters: PropTypes.func.isRequired,
    setListingGridLayout: PropTypes.func.isRequired,
    showMobileFilters: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MobileFilterButtons.wrappedComponent,
  component: MobileFilterButtons,
};

export default ProjectComponent;