import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Button } from "arv-reactcomponents";

import Analytics, { GTM, EventFactory as Event } from "../../analytics";

@inject("LoginStore")
@observer
class LoginButton extends Component {
  static trackLoginClick() {
    Analytics.trackEvent({
      action: Event.action.LOGIN_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_MENU_NAVIGATION,
    });
  }

  constructor() {
    super();
    this.showLoginModal = this.showLoginModal.bind(this);
  }

  showLoginModal() {
    LoginButton.trackLoginClick();
    this.props.LoginStore.setloginModal(true);
    GTM.pushEvent(Event.GTMEvent.LOGIN_CLICKED);
  }

  render() {
    const { className } = this.props;

    return (
      
      <Button
        className={`nw-loginbutton ${className}`}
        onClick={this.showLoginModal}
      >
        <i className="icomoon-person1 nw-loginbutton-icon" />
        <span className="nw-loginbutton-text">Login</span>
      </Button>
    );
  }
}

LoginButton.wrappedComponent.defaultProps = {
  className: "",
};

LoginButton.wrappedComponent.propTypes = {
  className: PropTypes.string,
  LoginStore: PropTypes.shape({
    setloginModal: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginButton.wrappedComponent,
  component: LoginButton,
};

export default ProjectComponent;
