import { observable } from "mobx";
import ReviewStoreActions from "./action";

class ReviewStoreClass extends ReviewStoreActions {
  @observable reviewConfig = {};
  @observable imageUploadResponse = {};
  @observable loading =false;
  @observable writeReview=false;
  @observable viewReview=false;
  @observable submitLoading=false;
  @observable totalRating=0;
  @observable
  reviewList = {
    reviews: [],
    currentPage: 0,
    totalReviews: 0,
    totalPages: 0
  };

  @observable
  reviewImageList = {
    reviews: [],
    currentPage: 0,
    totalReviews: 0,
    totalPages: 0
  };
}

export default ReviewStoreClass;
