const SCROLLTOTOP_EXCLUDEDPAGES = ["product-listing-anonymous"];
const SCROLLTOTOP_EXCLUDEDPATHS = ["/search", "/products", "/customercare"];
const DEF_API_TIMEOUT = 60000;
const SSR_CACHING_CONFIGS = {
  maxAge: 1000 * 60 * 2,
  max: 500,
};

export {
  SCROLLTOTOP_EXCLUDEDPAGES,
  SCROLLTOTOP_EXCLUDEDPATHS,
  DEF_API_TIMEOUT,
  SSR_CACHING_CONFIGS,
};
