
const LOGIN_MESSAGES = {
  LOGINPAGE_TITLE: "login",
  LOGINPAGE_SUBTITLE: "with",
  GOOGLE_TEXT: "GOOGLE",
  FB_TEXT: "FACEBOOK",
  LOGINPAGE_PH_USERID: "Email / Phone Number",
  LOGINPAGE_PH_PASSWORD: "Password",
  FORGOT_PASS_TEXT: "forgot password?",
  LOGIN_BTN_TEXT: "LOGIN",
  LOGINWITH_TEXT: "Login through",
  NEW_USER_TEXT: "New user?",
  REGISTER_TEXT: "Register",
  REGISTERPAGE_TITLE: "register",
  REGISTERPAGE_SUBTITLE: "to your new account",
  REGISTERPAGE_PH_NAME: "Name",
  REGISTERPAGE_PH_MOBILENO: "Mobile Number",
  REGISTERPAGE_PH_EMAIL: "Email Address",
  REGISTERPAGE_PH_PASSWORD: "Password",
  REGISTERPAGE_PH_OTP_SENT_MESSAGE: "Please enter the OTP sent to ",
  REGISTERPAGE_PH_OTP_VALIDATING_MESSAGE: "Validating phone number is necessary for registration.",
  
  FORGOTPASSPAGE_TITLE: "forgot",
  FORGOTPASSPAGE_SUBTITLE: "your password?",
  FORGOTPASSPAGE_PH_USERID: "Email / Phone Number",
  FORGOTPASSWORD_INVALID_CREDENTIALS: "Please enter valid Email / Phone Number",
  CREATEACCOUNT: "Create Account",
  BACKTOLOGIN: "Back to login",
  SUCCESSFULL_SIGNUP_MESSAGE:
    "Welcome, you're one of us NNNOW. Thanks for signing up.",
  FAILED_RESET_PASSWORD_MESSAGE:
    "Failed to reset password. Please try again later.",
  RESET_PASSWORD_SUCCESSFUL: "Yay! Password is updated, please login to continue.",
  MESSAGE_SENT: "sent, Please Check",
  DEFAULT_VALID_DATA_MESSAGE: "Please enter valid Data",
  UNABLE_TO_CONNECT_TO_FB:
    "Sorry! not able to connect to Facebook. Please try login via Email or Google",
  FAILED_TO_LOGIN_WITH_GMAIL:
    "Failed to Login with Gmail. Check your Gmail Credentials.",
  FB_CREDENTIAL_ERROR:
    "not able to get your email and phone number from Facebook.",
  INVALID_DATA: "Please enter valid Data",
  INVALID_CREDENTIALS: "Please enter valid credentials",
  INVALID_ID: "Please enter valid email/phone number",
  INVALID_PHONE_NUMBER: "Enter a valid phone number",
  FAILED_TO_LOGIN_WITH_FB: "Failed to Login with Facebook. Please try login again",
  MAX_INVALID_LOGIN_ATTEMPT: 5,
  MISSING_FIELD_HEADING_LABEL: "YOU ARE ALMOST THERE!"
};

const REGISTRATION_MSG = {
  INVALID_USERNAME: "Please enter a valid username",
  GENDER_OPTIONS: ["Select", "Mr", "Ms"],
  GENDER_MAPPING: {
    "Mr": "Male",
    "Ms": "Female",
  },
  INVALID_GENDER_MSG: "Select a title, please.",
  INVALID_EMAIL_MSG: "Enter a valid email address, please.",
  REGISTRATION_FIELDS: {
    NAME: "Name*",
    EMAIL: "Email*",
    PASSWORD: "Password*",
    PHONE_NUMBER: "Phone Number*",
    VERIFY_BUTTON_MSG: "CONTINUE",
  },
  REGISTRATION_HEADER_MSG: "CREATE ACCOUNT",
  DEFAULT_ERROR_MSG: "Oops! Something went wrong.",
  FORM_ERROR_MSG: "Hold on! Please enter all the required fields."
};

const LOGINV2_MESSAGES = {
  LOGIN_HEADER_MSG: "Please enter the password for your number",
  SIGN_IN_LBEL: "Sign In",
  LOGIN_USING_OTP_BTN_LABEL : "Login using OTP",
  SUBMIT_BTN_LABEL: "Submit",
  FORGOT_PASS_LABEL: "Forgot password?",
  PASSWORD_TITLE: "Password*",
  CONTINUE: "CONTINUE"
};

const LOGIN_SUBV2_MSG = {
  HEADING: "LOGIN",
  OTP_LABEL: "ENTER OTP",
  PASSWORD_LABEL: "ENTER ",
  PASSWORD_LABEL2: "PASSWORD",
  TERMS_AND_CONDITION_MSG: "By proceeding, you agree to",
  PHONE_NUMBER_TITLE : "Enter your Phone / Email",
  GMAIL_LOGIN_TEXT:"Continue with Google",
  FB_LOGIN_TEXT:"Continue with Facebook",
  OTP_VERIFICATION_HEADING1:"VERIFY",
  OTP_VERIFICATION_HEADING2:"PHONE NUMBER",
  OTP_SUB_HEADING1:"We’ve sent an OTP to +91 ",
  OTP_SUB_HEADING2:" via SMS. Please enter it below, and you’re done.",
  OTP_SCREEN_HEADING1: "VERIFY",
  OTP_SCREEN_HEADING2: "PHONE NUMBER",
  MISSING_FIELD_HEADING1:"CONFIRM",
  MISSING_FIELD_HEADING2:"PHONE NUMBER",
  MISSING_FIELD_SUB_HEADING:"Enter your Phone Number",
  GOTO_PSWD_SCREEN_LABEL: "Use OTP",
  GOTO_OTP_SCREEN_LABEL: "Use Password ",
  EMAIL_SENT:"Email sent. Please check (kindly check your spam folder as well if you can’t find the email).",
  SMS_SENT1:"We’ve sent an SMS to +91 ",
  SMS_SENT2:"Please check.",
  COUNTRY_CALLING_CODE:"+91",
  DIVIDER_OR_TEXT: "Or",
  CONTINUE_MSG:"CONTINUE",
  CREATE_LABEL1:"CREATE",
  CREATE_LABEL2:"ACCOUNT",
  VERIFIED_USER_FLASH_ERROR_MSG:"Sorry! not able to generate the OTP at the moment. Please try another way to login.",
  NON_VERIFIED_USER_FLASH_ERROR_MSG:"Sorry! not able to generate the OTP at the moment. Please try a bit later.",
  COMMA_SEPERATOR:", "
};
export { LOGIN_MESSAGES, REGISTRATION_MSG, LOGINV2_MESSAGES, LOGIN_SUBV2_MSG };
