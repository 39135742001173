import { action } from "mobx";
import { Promise } from "es6-promise";
import { getNoResults, brandNewArrivals, brandOnSale } from "./services";

class NoResultsActions {
  @action
  clearPresets() {
    this.onSaleData = [];
    this.newArrivalData = [];
  }

  @action
  getGeneralNoResults() {
    this.loading = true;
    const body = JSON.stringify({ size: 20 });
    return new Promise((resolve, reject) => {
      getNoResults(body).then(
        response => {
          this.loading = false;
          this.newArrivalData = response.data.newArrivals;
          this.onSaleData = response.data.onSale;
          resolve({
            newArrivalData: this.newArrivalData,
            onSaleData: this.onSaleData,
          });
        },
        error => {
          this.loading = false;
          this.clearPresets();
          reject(error);
        },
      );
    });
  }

  @action
  getBrandNoResults(body) {
    this.loading = true;
    const requestBody = JSON.stringify(Object.assign(body, { size: 20 }));
    const requests = [brandNewArrivals, brandOnSale];
    return new Promise((resolve, reject) => {
      Promise.all(requests.map(request => request(requestBody))).then(
        ([newArrivals, onSale]) => {
          this.loading = false;
          this.newArrivalData = newArrivals.data;
          this.onSaleData = onSale.data;
          resolve({
            newArrivalData: this.newArrivalData,
            onSaleData: this.onSaleData,
          });
        },
        error => {
          this.loading = false;
          this.clearPresets();
          reject(error);
        },
      );
    });
  }
}

export default NoResultsActions;
