import { action } from "mobx";
import fetchStoresList from "./service";

import { SOLID_COLOR_SET, Endpoints } from "npmlinks-constants";
import { getlastUsedPincode ,isClientADesktop} from "../../utils";
import { sortStoresByDistance } from "./methods";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";

class StoreListStoreActions {
  @action
  setIsDesktop(value) {
    if(value===undefined){
     this.isDesktop=isClientADesktop();
    }
    else{
    this.isDesktop = value;
  }
}
  @action
  fetchStores(brandName, pinCode) {
    let body = {
      brandName,
      pinCode,
    };
    this.setBrand(brandName);
    this.currentPincode = pinCode;
    body = JSON.stringify(body);
    this.notAValidPincode = "";
    let params = {};
    return fetchStoresList(body).then(
      action(response => {
        this.appliedBrandName = this.brandName;
        let coords;
        if (response.currentLocation && response.currentLocation.coordinates) {
          if (response.stores && !response.stores.length) {
            this.storesList = [];
            params = {
              showStoreLoader: false,
              showNoStoreImage: true,
              showStoreList: false,
              showForm: true,
              showMap: this.isDesktop,
              storePlaceholderImage: false,
            };
          } else {
            coords = response.currentLocation.coordinates;
            this.currentLocationCoords = {
              lat: parseInt(coords[0], 10),
              lng: parseInt(coords[1], 10),
            };
            let stores = sortStoresByDistance(response.stores);
            stores = stores.map((store, index) => {
              const tempStore = store;
              tempStore.markerColor =
                SOLID_COLOR_SET[(index + 1) % SOLID_COLOR_SET.length];
              return tempStore;
            });
            if (!stores.length) {
              this.storesList = [];
              params = {
                showStoreLoader: false,
                showNoStoreImage: true,
                showStoreList: false,
                showForm: true,
                showMap: this.isDesktop,
                storePlaceholderImage: false,
              };
            } else {
              this.storesList = stores;
              params = {
                showStoreLoader: false,
                showNoStoreImage: false,
                showStoreList: true,
                showForm: this.isDesktop,
                showMap: true,
                storePlaceholderImage: false,
              };
            }
          }
          this.setStates(params);
        } else {
          // this.notAValidPincode = "Please enter a valid pincode";
          this.storesList = [];
          this.states.showNoStoreImage = true;
          this.states.showStoreLoader = false;
          this.states.storePlaceholderImage = false;
        }
      }),
      action(() => {
        this.storesList = [];
        params = {
          showStoreLoader: false,
          showNoStoreImage: true,
          showStoreList: false,
          showForm: this.isDesktop,
          showMap: true,
          storePlaceholderImage: false,
        };
        this.setStates(params);
      }),
    );
  }

  @action
  setStates({
    showStoreLoader,
    showNoStoreImage,
    showStoreList,
    showForm,
    showMap,
    storePlaceholderImage,
  }) {
    this.states = {
      showForm,
      showMap,
      showStoreLoader,
      showNoStoreImage,
      showStoreList,
      storePlaceholderImage,
    };
  }

  @action
  setMobileMapView() {
    if (!this.isDesktop) {
      this.states.showForm = false;
      this.states.showMap = false;
    }
  }

  @action
  setBrand(brandName) {
    this.brandName = brandName;
  }

  @action
  resetMobileView() {
    if (!this.isDesktop) {
      const params = {
        showStoreLoader: false,
        showNoStoreImage: false,
        showStoreList: false,
        showForm: true,
        showMap: false,
        storePlaceholderImage: true,
      };
      this.setStates(params);
    }
  }

  @action
  resetStoreLocatorPage(brandName) {
    this.storesList = [];
    this.brandName = brandName;
    const params = {
      showStoreLoader: false,
      showNoStoreImage: false,
      showStoreList: false,
      showForm: true,
      showMap: this.isDesktop,
      storePlaceholderImage: true,
    };
    this.setStates(params);
  }

  @action
  getPincode(currentLocationCoords) {
    try {
      const latlng = new google.maps.LatLng(
        currentLocationCoords.lat,
        currentLocationCoords.lng,
      );
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, results => {
        const result =
          results &&
          results[0] &&
          results[0].address_components &&
          results[0].address_components.find(
            item => item && item.types && item.types.includes("postal_code"),
          );
        const pincode = result && result.long_name;
        this.currentPincode = pincode || getlastUsedPincode();
      });
    } catch (e) {
      // console.log(e);
      this.currentPincode = getlastUsedPincode();
    }
  }

  initCurrentLocation(defaultLocation = { lat: 20.5937, lng: 79.9629 }) {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(
        action(position => {
          this.currentLocationCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.getPincode(this.currentLocationCoords);
          resolve();
        }),
        action(() => {
          this.currentLocationCoords = defaultLocation;
          resolve();
        }),
      );
    });
  }

  @action
  setCurrentLocation(lat, lng) {
    this.currentLocationCoords = {
      lat,
      lng,
    };
  }

  @action
  setSelectedStoreFCID(fcId) {
    this.selectedStoreFCID = fcId;
  }

  @action
  gotoGoogleMaps(lat, lng) {
    Analytics.trackEvent({
      action: Event.action.GET_DIRECTION_CLICK,
      category: Event.category.STORE_LOCATOR,
    });
    this.targetLocationCoords = {
      lat,
      lng,
    };
    const gMapsUrl = `${Endpoints.googleMapsUrl}${lat}+${lng}`;
    window.open(gMapsUrl);
  }

  @action
  setPincodeError(message = "") {
    this.notAValidPincode = message;
  }
}

export default StoreListStoreActions;
