import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";

import { Utils } from "arv-reactcomponents";

import Analytics, { EventFactory as Event } from "../../../analytics";

import BrandNavTree from "../../BrandNavTree";
import Anchor from "../../../component/Anchor";

class MainList extends Component {
  static trackLinkClick(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_ABOVE_MENU_CTR,
    });
  }

  static hasSubNav(list) {
    return Array.isArray(toJS(list)) && list.length !== 0;
  }

  static getIconIfHasSubList(list) {
    if (MainList.hasSubNav(list)) {
      return (
        <i className="icomoon-arrow_right1 nw-brandnavcontainernew-linkicon" />
      );
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.onLinkClick = this.onLinkClick.bind(this);
  }

  onLinkClick(e) {
    const { menu } = this.props;
    const label = menu.name.toLowerCase();
    const hasSubNav = MainList.hasSubNav(menu.level2);

    if (hasSubNav) {
      Utils.preventDefault(e);
    } else {
      const eventAction = `nnnow_nav_${label}._click`;
      MainList.trackLinkClick(eventAction);
    }
  }

  get isActiveClassName() {
    return (this.state.isActive && "is-active") || "";
  }

  showNavTree(bool) {
    return () => {
      this.setState({
        isActive: bool,
      });
    };
  }

  render() {
    const { menu } = this.props;
    const { isActive } = this.state;

    return (
      <li
        key={menu.name}
        className={`nw-brandnavcontainernew-items ${this.isActiveClassName}`}
        onMouseEnter={this.showNavTree(true)}
        onFocus={this.showNavTree(true)}
        onMouseLeave={this.showNavTree(false)}
        onBlur={this.showNavTree(false)}
      >
        <Anchor.component
          href={menu.url}
          to={menu.url}
          className="nw-brandnavcontainernew-link"
          onClick={this.onLinkClick}
          target="_self"
        >
          {menu.name}
          {MainList.getIconIfHasSubList(menu.level2)}
        </Anchor.component>
        <BrandNavTree.component
          level={2}
          list={menu.level2}
          active={isActive}
        />
      </li>
    );
  }
}

MainList.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: MainList,
  component: MainList,
};

export default ProjectComponent;
