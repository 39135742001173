import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import { isApp, constructUrlForIframe } from "../../utils";

@inject("CommonStore")
@observer
class Anchor extends Component {
  render() {
    const { children, className, CommonStore, ...otherProps } = this.props;
    const { isDesktop, isIframe } = CommonStore;
    const modifiedClassName = `${className} nwc-anchortag`;
    const { href } = otherProps;
    const isItAbsolute = (href && href.indexOf("http") > -1) ||(window && window.location && window.location.hostname.indexOf("localhost") > -1)|| false;
    if ((isApp || isItAbsolute) && !isIframe) {
      return (
        <a {...otherProps} className={modifiedClassName}>
          {children}
        </a>
      );
    } else if (isIframe) {
      const newProps = otherProps;
      newProps.target = isDesktop ? "_blank" : "_parent";
      newProps.href = constructUrlForIframe(otherProps.href);
      return (
        <a {...newProps} className={modifiedClassName}>
          {children}
        </a>
      );
    } else if (otherProps.to && otherProps.href) {
      return (
        <Link {...otherProps} className={modifiedClassName}>
          {children}
        </Link>
      );
    }
    return (
      <span {...otherProps} className={modifiedClassName}>
        {children}
      </span>
    );
  }
}

Anchor.wrappedComponent.defaultProps = {
  children: "",
  className: "",
};

Anchor.wrappedComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Anchor.wrappedComponent,
  component: Anchor,
};

export default ProjectComponent;
