import { observable, computed } from "mobx";
import OtpStoreActions from "./actions";
import { PAYMENTPAGE } from "npmlinks-constants";

class OtpStoreClass extends OtpStoreActions {
  constructor() {
    super();
    this.otpClientID = process.env.REACT_APP_OTP_CLIENT_ID;
    this.otpStateInfo = {};
    this.sessionID = null;
  }
}

export default OtpStoreClass;
