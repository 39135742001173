import { post } from "../../api";
import { authHeader } from "../../utils";

const generateOtp = body => post("generateOtp", {}, body);

const resendOtp = body => post("resendOtp", {}, body);

const validateOtp = body => post("validateOtp", authHeader(), body);

const validateCaptcha = body =>
  post("validateCaptcha", { origin: process.env.REACT_APP_ORIGIN }, body);

export { generateOtp, resendOtp, validateOtp, validateCaptcha };
