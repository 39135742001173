import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Img } from "arv-reactcomponents";
import { sanitiseUrl } from "../../../utils";
import MobileAction from "../MobileActionButtonv2";
import { NavList } from "../NavListv2";
import Anchor from "../../../component/Anchor";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";

@inject("CommonStore")
@observer
class NavContainer extends Component {
  static trackMobileRightNavList(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.NNNOW_NEW_LEFTNAV,
    });
  }

  static trackMobileLeftNavList(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_LEFTNAV,
    });
  }

  static trackMobileNnnowRightList(eventAction) {
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.MOBILE_RIGHTNAV,
    });
  }

  constructor(props) {
    super(props);
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
  }

  get isNnnowNav() {
    if (this.props.className === "nw-nnnow-header") {
      return true;
    }
    return false;
  }

  toggleMenuMobile(topMenu, hasSublist) {
    this.setState({});
    const toggle = topMenu;
    toggle.isMenuOpen = !toggle.isMenuOpen;

    let eventAction;
    if (hasSublist) {
      eventAction = `${topMenu.name.toLowerCase()}_${(toggle.isMenuOpen &&
        "expand") ||
        "collapse"}`;
    } else {
      eventAction = topMenu.name.toLowerCase();
    }
    if (this.isNnnowNav && this.props.CommonStore.isBrandPage) {
      NavContainer.trackMobileNnnowRightList(eventAction);
    } else if  (this.isNnnowNav){
      NavContainer.trackMobileRightNavList(eventAction);
    } else {
      NavContainer.trackMobileLeftNavList(eventAction);
    }
  }

  get bannerUrl() {
    const { navData } = this.props;
    return (navData && navData[0] && navData[0].bannerUrl) || null;
  }

  get bannerImage() {
    const { navData } = this.props;
    return (navData && navData[0] && navData[0].bannerImage) || null;
  }

  get bannerClassName() {
    const { navData } = this.props;
    return (
      (navData && navData[0] && navData[0].bannerImage) ||
      "nw-productview-imgalt"
    );
  }

  render() {
    this.navData = this.props.navData;

    if (!this.navData) return null;
    return (
      <ul className="nw-navcontainerv2-listwrap">
        <NavList.component
          className={this.props.className}
          level={1}
          list={this.navData}
          action={this.toggleMenuMobile}
        />
        <div>
          <MobileAction.component
            className={this.props.className}
            reset={this.props.reset}
          />
        </div>
        {this.bannerImage ? (
          <Anchor.component
            className={this.bannerClassName}
            href={sanitiseUrl(this.bannerUrl)}
            to={sanitiseUrl(this.bannerUrl)}
            target="_self"
          >
            <Img
              className="nw-navcontainerv2-banner"
              src={this.bannerImage}
              alt={`${this.navData[0].name} BANNER`}
            />
          </Anchor.component>
        ) : null}
      </ul>
    );
  }
}

NavContainer.wrappedComponent.defaultProps = {
  navData: {},
  className: "",
};


NavContainer.wrappedComponent.propTypes = {
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool.isRequired,
  }).isRequired,
  reset: PropTypes.func.isRequired,
  navData: PropTypes.shape({}),
  className: PropTypes.string,
};


const getFinalComponent = component =>
  inject( "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: NavContainer.wrappedComponent,
  component: NavContainer,
};

export default ProjectComponent;
