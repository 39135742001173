const REVIEW = {
  REVIEW_PRODUCT: "Review Product",
  UPDATE_REVIEW: "Edit Review",
  EDIT_REVIEW:"EDIT REVIEW",
  VIEW_REVIEW: "View Review",
  VIEW_REVIEW_BUTTON: "View Review",
  WRITE_YOUR_REVIEW: "WRITE YOUR REVIEW",
  SHOW_OFF_YOUR_LOOK: "SHOW OFF YOUR LOOK",
  UPLOAD_PHOTOS_USING_THE_PRODUCT: "Upload photos using the product.",
  SUBMIT: "SUBMIT",
  ADD_PHOTOS_FROM: "Add Photos From",
  CAMERA: "Camera",
  GALLERY: "Gallery",
  RATINGS_AND_REVIEW: "RATINGS & REVIEWS",
  WRITE_A_REVIEW: "Write a Review",
  CUSTOMER_PHOTOS: "CUSTOMER PHOTOS",
  CUSTOMER_REVIEW: "CUSTOMER REVIEW",
  REVIEW_SUCCESS: "Review submitted successfully and it is under moderation",
  REVIEW_UPDATE_SUCCESS:"Review updated successfully and it is under moderation",
  REVIEW_FAILED: "Ooops! Your review was not submitted",
  REVIEW_TEXT_MANDATORY:"Please include review description as well if image is uploaded",
  REVIEW_CHARACTER: "Review text should have minimum 3 characters to maximum 10000 characters",
  REVIEW_IMAGE_SUCCESS:"Image uploaded successfully",
  REVIEW_IMAGE_FAILED:"Not able to upload image",
  REVIEW_IMAGE_DELETE:"Image deleted successfully",
  REVIEW_IMAGE_DELETE_FAILED:"Not able to delete image",
  REVIEW_SEEALL_TEXT :"SEE ALL REVIEWS",
  REVIEW_COLOR: "Colour",
  REVIEW_SIZE: "Size",
  REVIEW_RATINGS_SUCCESS: "Review submitted successfully",
  REVIEW_RATINGS_UPDATESUCCESS:"Ratings updated successfully",
  REVIEW_NOREVIEWS: "No Reviews Yet",
  REVIEW_READMORE: "VIEW MORE",
  REVIEW_READLESS: "VIEW LESS",
  REVIEW_LOADMORE: "LOAD MORE",
  REVIEW_LOADING: "LOADING...",
  REVIEW_BUTTON_TOOLTIP:"Review is under moderation",
  RATING: "RATING",
  PHOTO: "PHOTOS",
  REVIEW: "REVIEW",
  MODERATION:"Review under Moderation",
  MODERATION_TEXT:"Your review under moderation. It will take some time to get it published.",
  PUBLISHED: "Review Published",
  PUBLISHED_TEXT:"Thank you. Your review has been published.",
  REJECTED:"Review Rejected",
  REJECTED_TEXT:"Sorry this product review has been rejected. It may be due to images or the review is not appropriate",
  IMAGEUPLOADING:"Please wait for the image to be uploaded ",
  REVIEW_INAPPTEXT: "Would you Like to rate your order experience",
  REVIEW_INAPPSTORETEXT: "How about a quick rating on the",
  REVIEW_INAPPSURE: "Yes, Sure!",
  REVIEW_INAPPSKIP: "No, Thanks!",
  ERROR_MESSAGE: "Oops! Something went wrong.",
  APP_STORE: "App Store",
  PLAY_STORE: "Play Store",
  STORE_TITLE: "Thank you!",
};

export default REVIEW;
