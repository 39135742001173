import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";

const SuggestionList = props => {
  const { active, list, selectItem } = props;
  function getClassName(subitem, title){
    const loader =  Array.isArray(subitem) && !subitem[0] ? "nwc-contentloader" : "";
    const fontFamily = title && (title.toLowerCase() === "recently searched" || title.toLowerCase() === "popular searches") ? "nw-local-suggestion" : "";
    return `${loader} ${fontFamily}`
  }

  function handleOnMouseDown(subitem, index) {
    let odinPresent = window.localStorage.getItem("channel");
    const isObj = Array.isArray(subitem);
    if(isObj && subitem[2] && (odinPresent === "true")) {
      Analytics.trackEvent({
        action: `${Event.action.AUTOSUGGEST_LIST_ITEM_CLICK}${index}`,
        label: `${parse(subitem[1])}_clicked`,
        category: Event.category.AUTOSUGGEST
      });
    }
    isObj && !subitem[1] ? null : selectItem(isObj ? parse(subitem[1]): subitem, true, isObj ? subitem[2]: null)
  }

  return (
    <div
      className={`nw-searchbar-suggestionscontainer ${(active && "is-active") ||
        ""}`}
    >
      <div className="nw-searchbar-suggestionscontainerconainer">
        {list &&
          list.length ?
          list.map(
            item =>
              item.list.length > 0 && (
                <ul className="nw-searchbar-suggestionsul">
                  {item.title !== "invalid" ? <li className="nw-searchbar-suggestionstitle">
                    {item.title}
                  </li> : ""}
                  {item.list.map((subitem, index) => (
                    <li
                      role="presentation"
                      className={`nw-searchbar-suggestionsitem ${getClassName(subitem, item.title)}`}
                      onMouseDown={() => handleOnMouseDown(subitem, index)}
                    >
                      {Array.isArray(subitem) ? parse(subitem[1]) : subitem}
                    </li>
                  ))}
                </ul>
              )
          ): ""}
      </div>
    </div>
    
  );
};

SuggestionList.defaultProps = {
  list: [],
  className: ""
};

SuggestionList.propTypes = {
  active: PropTypes.bool.isRequired,
  list: PropTypes.shape([]).isRequired,
  selectItem: PropTypes.func.isRequired,
};

const getFinalComponent = component => component;
const ProjectComponent = {
  getFinalComponent,
  wrapper: SuggestionList,
  component: SuggestionList
};

export default ProjectComponent;
