import { get, post } from "../../api";

const fetchWhoWeAreContent = () => get("whoWeAre");
const fetchCustomercareContent = () => get("customerCare");
const fetchTermsAndConditionContent = () => get("termsAndCondition");
const fetchPrivacyPolicyContent = () => get("privacyPolicy");
const sendContactMessage = body => post("contactUs", {}, body);
const sendAppLink = body => post("appLink", {}, body);
const getBrandsList = () => get("brandsList");

export {
  fetchWhoWeAreContent,
  fetchCustomercareContent,
  fetchTermsAndConditionContent,
  fetchPrivacyPolicyContent,
  sendContactMessage,
  sendAppLink,
  getBrandsList,
};
