function* getColorFromColors(colors) {
  for (let i = 0; i <= colors.length + 1; i += 1) {
    i = (i < colors.length && i) || 0;
    yield colors[i];
  }
}

const sortStoresByDistance = array => {
  let sortedArray = array.sort((a, b) => {
    if (a.distance < b.distance) return -1;
    if (a.distance > b.distance) return 1;
    return 0;
  });
  sortedArray = sortedArray.filter(elem => elem.storeLocatorEnabled);
  return sortedArray;
};

export { getColorFromColors, sortStoresByDistance };
