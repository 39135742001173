import { post, get } from "../../api";
import { authHeader } from "../../utils";

const getCartDetails = body => post("checkout", authHeader(), body);
const getStoresDetails = body => post("checkoutStores", authHeader(), body);
const getOverviewDetails = body =>
  post("getpricingOverview", authHeader(), body);

const getPaymentDetails = null;
const getDeliveryCharges = (headers = {}) => get("deliveryCharges", headers);

export {
  getCartDetails,
  getPaymentDetails,
  getStoresDetails,
  getDeliveryCharges,
  getOverviewDetails
};
