import { post } from "../../api";
import { authHeader } from "../../utils";

const generateOtp = (body,throughService) => post("generateOtpV2",authHeader(), body,throughService);

const generateOtpV2 = (body,throughService) => post("generateRegisterOtpV2",authHeader(), body,throughService);

const resendOtp = (body,throughService) => post("resendOtpV2", authHeader(), body,throughService);

const resendOtpV2 = (body,throughService) => post("resendOtpV3", authHeader(), body,throughService);

const validateOtp = (body,throughService) => post("validateOtpV2", authHeader(), body,throughService);


export { generateOtp, resendOtp, validateOtp, generateOtpV2, resendOtpV2 };
