import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailSubscriptionv2 from "../EmailSubscriptionv2";
import { Img } from "arv-reactcomponents";
import Anchor from "../../../component/Anchor";
import { Endpoints, APPS } from "npmlinks-constants";
import SocialMediaLinks from "../SocialMediaLinks";
import FooterLinks from "../FooterLinks";
import FooterDocs from "../FooterDocs";

class PdpFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { FooterStore } = this.props;
    return (
      <footer className="nw-footer-wrapper nw-pdp-footer">
        <div className="nw-footer-getappblock">
          <div className="nw-footer-getapptitle">
            Get the latest styles from the NNNOW App
          </div>
          <div>
            <Anchor.component
              className="nw-apps-store"
              to={APPS.PLAY_STORE_PATH}
              href={APPS.PLAY_STORE_PATH}
            >
              <Img src={Endpoints.pdp_footer_get_app.google_play} />
            </Anchor.component>
            <Anchor.component
              className="nw-apps-store"
              to={APPS.APP_STORE_PATH}
              href={APPS.APP_STORE_PATH}
            >
              <Img src={Endpoints.pdp_footer_get_app.app_store} />
            </Anchor.component>
          </div>
        </div>
        <EmailSubscriptionv2.component />
        <FooterDocs.component />
        <div className="nw-footer-sociallinkcontainer">
          <div className="nw-footer-sociallinktitle">Get social with us</div>
          <SocialMediaLinks.component />
        </div>
        <FooterLinks.component footerLinkData={FooterStore.footerlinksData} />
      </footer>
    );
  }
}

PdpFooter.propTypes = {
  FooterStore: PropTypes.shape({
    footerlinksData: PropTypes.object,
    footerData: PropTypes.object,
    getFooterLinkData: PropTypes.func,
    isFooterDefaultVisible: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component => component;

const ProjectComponent = {
    getFinalComponent,
    wrapper: PdpFooter,
  component: PdpFooter
};

export default ProjectComponent;
