import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer, inject } from "mobx-react";
import { Button } from "arv-reactcomponents";

import IconSpecific from "../IconSpecific";

@inject("CommonStore")
@observer
class FilterIcon extends Component {
  constructor(props) {
    super(props);

    this.iconClick = this.iconClick.bind(this);
    this.iconHover = this.iconHover.bind(this);
    this.iconStyle = this.iconStyle.bind(this);
  }

  iconClick() {
    // console.log(icon);
    // props.selectElementAndFilter(icon);
  }

  iconHover(icon, isEnter) {
    props.hoverOnIconFilter(icon, isEnter);
  }

  iconStyle(icon) {
    if (icon.isBackgroundHidden) {
      return {};
    }
    return { backgroundColor: icon.backgroundColor };
  }

  render() {
    const { props } = this;
    const { isDesktop } = props.CommonStore;
    let dummyDiv = <div className="nw-search-fiter-items-dummy-space-div" />;
    if (!isDesktop) {
      dummyDiv = "";
    }

    return (
      <Button
        onClick={() => this.iconClick()}
        onMouseEnter={() => this.iconHover(props.icon, true)}
        onMouseLeave={() => this.iconHover(props.icon, false)}
        className="nwc-filter-icon"
      >
        <div
          className="nw-search-filter-icon-container"
          style={this.iconStyle(props.icon)}
        >
          <i
            className={`${
              props.icon.selectedSubMenu && props.icon.selectedSubMenu > 0
                ? props.icon.class.selected
                : props.icon.class.default
            } nw-search-filter-icon`}
          />
        </div>
        <p className="nw-filter-icon-text">{props.icon.filterType}</p>
        <div
          className={`nw-search-fiter-items ${
            props.icon.isActive ? "is-active" : ""
          } ${props.icon.filterType.toLowerCase()}`}
        >
          {dummyDiv}
          <IconSpecific.component
            className="nw-search-fiter-items-container"
            selectedIcon={props.icon}
            selectElementAndFilter={props.selectElementAndFilter}
            toggleActiveBrand={props.toggleActiveBrand}
          />
        </div>
      </Button>
    );
  }
}

FilterIcon.wrappedComponent.propTypes = {
  icon: PropTypes.shape({
    filterType: PropTypes.string,
    class: PropTypes.shape({
      selected: PropTypes.string,
      default: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    list: PropTypes.shape([]),
    isActive: PropTypes.bool,
    isBackgroundHidden: PropTypes.bool,
    selectedSubMenu: PropTypes.number,
  }).isRequired,
  selectElementAndFilter: PropTypes.func.isRequired,
  hoverOnIconFilter: PropTypes.func.isRequired,
  toggleActiveBrand: PropTypes.func.isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FilterIcon.wrappedComponent,
  component: FilterIcon,
};

export default ProjectComponent;
