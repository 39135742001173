import { observable, action, computed } from "mobx";
import NetbankingStoreActions from "../NetbankingStore/actions";
import { setSurlFurl, paymentBaseRequest } from "../../utils";

class NetbankingStoreClass extends NetbankingStoreActions {
  @observable netBankingBanks = [];
  @observable
  selectedNetbankingData = {
    bankCode: null,
    isValid: false,
  };

  @observable success = null;

  // @computed
  // get defaultSavedCard() {
  //   return this.savedCards && this.savedCards.length
  //     ? this.savedCards.filter(card => card.isDefault)
  //     : [];
  // }

  // @computed
  // get selectedSavedCard() {
  //   return this.savedCards.find(
  //     card => card.cardToken === this.selectedSavedCardToken,
  //   );
  // }

  @computed
  get successWithUserSelectedBank() {
    try {
      const responseDataWithUserSelectedBank = Object.assign({}, this.success);
      const { bankCode } = this.selectedNetbankingData;
      if (responseDataWithUserSelectedBank && responseDataWithUserSelectedBank.transactions) {
        const { banking } = responseDataWithUserSelectedBank.transactions;
        let { requestParameters } = banking;
        if (requestParameters && requestParameters.length)
          requestParameters = requestParameters.map(paramTemp => {
            let param = Object.assign({}, paramTemp);
            // going forword we can hit the thor directly no intermidiate server required between Payu and Thor if we can provide redirect url with proper domain
            param = setSurlFurl(param, responseDataWithUserSelectedBank.orderId);
            return param;
          });
        return responseDataWithUserSelectedBank;
      }
      return null;
    } catch (err) {
      // console.log(err);
      return err;
    }
  }

  @computed
  get paymentRequest() {
    return {
      paymentDetail: paymentBaseRequest({
        bankCode: this.selectedNetbankingData.bankCode,
        description: "netbanking",
      }),
      paymentTypeCode: "NET_BANKING",
      requestedPG: this.selectedNetbankingData.requestedPG || null,    
      razorpayKey: this.selectedNetbankingData.pgRequestParameters && this.selectedNetbankingData.pgRequestParameters.RAZORPAY_KEY || '',        
    };
  }
}
export default NetbankingStoreClass;
