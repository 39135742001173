const DEFAULT_FOOTER_DOCS = [];
const SPECIFIC_PAGES = {
  Style: [],
  HOME: [
    {
      footerTitle: "Online Fashion Shopping on NNNOW.com",
      footerDescription:
        'NNNOW.com is the digital destination of Arvind Lifestyle Brands Limited - for you, this means NNNOW.com is the official online store India’s largest and most loved brands. Topping the charts are U. S. Polo Assn., Flying Machine, Arrow, Tommy Hilfiger, Calvin Klein and many more!',
    },
    {
      footerTitle: "Why buy on NNNOW?",
      footerDescription:
        "What’s the big deal about shopping on a brand’s official online store, you say? For starters, you get access to 100% of the styles that a brand has to offer in a season, (something no other website can promise). If you don’t like missing out, NNNOW.com should be your go-to site for the hottest and the best in online fashion shopping experience. Secondly, don’t we all hate fake merchandise? Well, a brand’s official website gives a 100% guarantee of authenticity and quality so that you can go on shopping without a worry in the world!",
    },
    {
      footerTitle:
        "One-stop Destination for your Favourite Brands & Categories",
      footerDescription:
        'If you’re the kind of person for whom Polo T-shirts only from U.S. Polo Assn., jeans and jackets only from Flying machine, work wear only from Arrow and everyday outfits only from the best brands will do, you’ll find your soul mate in NNNOW. To make the deal sweeter, when you shop on NNNOW.com you enjoy benefits that you’ll find nowhere else. For example, if your online order doesn’t fit you well, you can get it altered, returned at a store near you for free! In case you’re in a hurry, you can place an order online and pick it up from your nearest store too. Wow! This just keeps getting better, right? But, we’ve saved the best for the last.',
    },
    {
      footerTitle: "Earn reward points on every purchase",
      footerDescription:
        "No truly authentic branded shopping experience is complete if you can’t earn and spend branded reward points. And we’ve taken care of that on NNNOW.com. Every time you buy from us you’ll earn reward points just the way you would, had you shopped at any of our branded outlets. You can also spend your reward points online or at any store you visit.",
    },
    {
      footerTitle: "",
      footerDescription:
        "Welcome to NNNOW.com - the future of online shopping.",
    },
  ],
};
const FOOTER = { DEFAULT_FOOTER_DOCS, SPECIFIC_PAGES };

export default FOOTER;
