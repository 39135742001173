const cdnBasePath = `https://${process.env.REACT_APP_CDNBASEPATH}`
const IMAGES = {
  EMPTY_BAG_URL:
    `${cdnBasePath}/client/assets/images/checkout-final-02.png`,
  SECURITY_IMAGES: [
    `${cdnBasePath}/Verified_by_Visa_logo.png`,
    `${cdnBasePath}/verified-mastercard.png`,
    `${cdnBasePath}/verified-pci.png`,
    `${cdnBasePath}/AMEX-safekey.png`,
  ],
};
const MYBAGPAGE = {
  MAX_QTY_ALLOWED: 10,
  SHOPMORE_BUTTON: "SHOP MORE",
  CHECKOUT_BUTTON: "CHECKOUT",
  MYBAG_TEXT: "MY BAG",
  MYBAG_EMPTY_MSG: "That's one really empty bag....",
  SHOPNOW_BUTTON: "SHOP NOW",
  ADDITEMS_CTA: "+ Add items from favorites",
  ALT_IMG_TEXT: "nnnow",
};

const MYBAGPOPUP = {
  MY_BAG_TEXT: "MY BAG",
  VIEW_SHOPPING_BAG_TEXT: "VIEW SHOPPING BAG",
  TOASTER_MSG: "We've removed $styleName from your bag"
};

const MYBAGOFFERINGBLOCK = {
  DELIVERY_TITLE: "FREE * STANDARD DELIVERY",
  DELIVERY_LABEL: "Faster delivery options available to most countries.",
  RETURN_TITLE: "FREE & EASY RETURNS",
  RETURN_LABEL: "Send it back within 28 days of receiving your order.",
  SAVEDITEM_TITLE: "WONDERING WHERE YOUR ITEMS HAVE GONE?",
  SAVEDITEM_LABEL: "No worries you can find them in your saved items",
  SAVED_BUTTONTEXT: " View All Saved Items",
};

const CARTPRODUCTS = {
  SIZE_TEXT: "Size",
  QTY_TEXT: "Qty",
  BRAND_SIZE: "Brand Size",
  COLOR: "Color",
}

const DATA = { MYBAGPAGE, IMAGES, MYBAGPOPUP, CARTPRODUCTS };
export default DATA;
