import { get, post } from "../../api";

const getBrandPageData = (path, header, isSSRCall) => get("masterTemplates", header, path, isSSRCall);
const getProductsData = (extension, header, body, basePath) =>
  post(basePath, header, body, extension);
const getShopAllStylesListApi = body =>
  post("multipleStylesDetails", {}, body, "");
const getAllFav = header => get("allFav", header);
const addtoFav = (header, body, param) => post("allFav", header, body, param);
const removeFav = (header, body) => post("deleteFavProduct", header, body);
export {
  getBrandPageData,
  getProductsData,
  getAllFav,
  addtoFav,
  removeFav,
  getShopAllStylesListApi,
};
