import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-google";
import { Loader } from "arv-reactcomponents";

@inject("CodStore", "LoginStore")
@observer
class RegisterCaptcha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentWillMount() {
    loadReCaptcha();
  }

  componentDidMount() {
    if (this.captcha) {
      this.captcha.reset();
      this.captcha.execute();
    }
  }
  onLoadRecaptcha() {
    if (this.captcha) {
      this.captcha.reset();
      this.captcha.execute();
    }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!
    const { loadFor, LoginStore } = this.props;
    this.props.CodStore.setRegisterCaptchaToken(recaptchaToken);
  }

  render() {
    const {
      loadFor,
      CodStore: { isCodVerified = false }
    } = this.props;
    if (isCodVerified && loadFor !== "login") {
      return <span />;
    }
    const { loading } = this.state;
    return (
      <div>
        {loading && (
          <div className="nw-mybagpage-initialloader nw-captcha-loader nwc-translucentbackground-white">
            <Loader />
          </div>
        )}
        {/* You can replace captcha with any ref word */}
        <ReCaptcha
          ref={el => {
            this.captcha = el;
          }}
          size="invisible"
          render="explicit"
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </div>
    );
  }
}

RegisterCaptcha.wrappedComponent.defaultProps = {
  loadFor: ""
};

RegisterCaptcha.wrappedComponent.propTypes = {
  loadFor: PropTypes.string,
  CodStore: PropTypes.shape({
    setCaptchaToken: PropTypes.func.isRequired,
    isCodVerified: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("CodStore", "LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: RegisterCaptcha.wrappedComponent,
  component: RegisterCaptcha
};

export default ProjectComponent;
