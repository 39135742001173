// Suppress ga undefined error
import Logman from "./logman";
import GTM from "./GTM";
import { toJS } from "mobx";
import { cookiesStorage, isClientADesktop } from "../utils";
/* eslint-disable no-undef */
export default class GoogleAnalytics {
  static pageView(sourceType) {
    const isClientADesk = isClientADesktop() || "";
    const userProperty = {
      email_Id: window.localStorage.getItem('user_email') || "",
      name: cookiesStorage.getItem('userName') || "",
      mobile_Number: cookiesStorage.getItem('mobileNumber') || "",
      device: isClientADesk ? "desktop" : "mweb",
      pageType: window.location.pathname ? window.location.pathname : ""
    }
    let page = window.location.pathname + window.location.search;
    page = `${page}${
      page.indexOf("?") === -1 ? `?type=${sourceType}` : `&type=${sourceType}`
    }`;
    ga("send", "pageview", page);
    GTM.pushWithEvent(toJS(userProperty), "pageVisited");
  }

  static setDimension(dimension, value) {
    ga("set", dimension, value);
  }

  static trackEvent({
    category,
    action,
    label = `source=${window.pageType}`,
    interaction = false,
    toLogman = false,
    logmanEventName = "",
    ...otherParams
  }) {
    if (toLogman) {
      Logman.trackLog(arguments[0]);
    }

    ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      nonInteraction: interaction,
      ...otherParams,
    });
  }
}
