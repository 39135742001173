const cdnBasePath = `https://${process.env.REACT_APP_CDNBASEPATH}`
const NOT_FOUND = {
  IMAGE: {
    DESKTOP: `${cdnBasePath}/nnnow_404_web.png`,
    MOBILE: `${cdnBasePath}/nnnow_404_mobile.png`,
  },
  TITLE: "404",
  MESSAGE: "Page Not Found",
  STATUS: 404,
};

export default NOT_FOUND;
