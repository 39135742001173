import { put, get, post } from "../../api";
import { authHeader, readCookie } from "../../utils";
import * as utils from "../Login/loginStore.methods";

const getSavedCardsDetails = () => get("savedCards", { ...authHeader() });

const deletePaymentCard = delCard =>
  post("deletePaymentCard", { ...authHeader() }, JSON.stringify(delCard));

const changePassword = body => {
  const decodedAccessToken = utils.decodeToken(readCookie("accessToken"));
  const userId = decodedAccessToken.user_id;
  return put(
    "changePassword",
    { ...authHeader(), "x-session-id": decodedAccessToken.session },
    body,
    `${userId}/password`,
  );
};

export { getSavedCardsDetails, deletePaymentCard, changePassword };
