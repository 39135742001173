import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ROUTE_PATHS } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";

class LoyaltyLink extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    Analytics.trackEvent({
      action: Event.action.LOYALTY_CLICK,
      category: Event.category.TOP_ABOVE_MENU_CTR,
      interaction: true,
    });
  }
  render() {
    const { className } = this.props;


    return (
      <Anchor.component
        className={`nw-loyaltylink ${className}`}
        href={`${ROUTE_PATHS.LOYALTY}`}
        to={`${ROUTE_PATHS.LOYALTY}`}
        target="_self"
        onClick={this.handleClick}
      >
        <i className="icomoon-loyalty_main nw-loyaltylink-loyaltymain" />
        <span className="nw-loyaltylink-text">Loyalty</span>
      </Anchor.component>
    );
  }
};

LoyaltyLink.defaultProps = {
  className: "",
};

LoyaltyLink.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoyaltyLink,
  component: LoyaltyLink,
};

export default ProjectComponent;
