import {action} from "mobx";
import * as Service from "./service.js";

class LoyaltyStoreAction {

  @action
  setLoyaltyVerified(bool) {
    this.isLoyaltyVerified = bool;
  }

  @action
  toggleLoyaltyCheck(bool) {
    this.loyaltyCheck = bool;
  }

  @action
  getLoyaltyCards() {
    return new Promise((resolve, reject) => {
      return Service.getLoyaltyCards().then(
        action(result => {
          this.loyaltyCards = (result && result.data) || [];
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

  @action
  getLoyaltyMoreInfo(brandName) {
    this.loyaltyMoreInfoData = {};
    return new Promise((resolve, reject) => {
      return Service.getLoyaltyMoreInfo(brandName).then(
        action(result => {
          this.loyaltyMoreInfoData = (result && result.data) || {};
          this.loyaltyMoreInfoData.brand = brandName;
          resolve(result);
        }),
        action(err => {
          reject(err);
        })
      );
    });
  }

}

export default LoyaltyStoreAction;
