const RETURNFLOW = {
  returnOrCancelReason: {
    next: "returnModes",
    previous: null,
    inner: null,
  },
  returnModes: {
    next: "returnOrCancelRefund",
    previous: "returnOrCancelReason",
    inner: "returnAddress",
  },
  returnOrCancelRefund: {
    next: "returnOrCancelComplete",
    previous: "returnModes",
    inner: "addNeft",
  },
  returnOrCancelComplete: {
    next: null,
    previous: "returnOrCancelRefund",
    inner: null,
  },
  returnAddress: {
    next: null,
    previous: "returnModes",
    inner: null,
  },
  addNeft: {
    next: "returnOrCancelRefund",
    previous: "returnOrCancelRefund",
    inner: null,
  },
};

const RETURN_MODES = {
  REVERSEPICKUP: "REVERSEPICKUP",
  SELFSHIP: "SELFSHIP",
};

const RETURN_REASONS = {
  TITLE: "Return",
  REASON_SELECTION_HEADER: "Reason for returning",
  REASON_SELECTION_PLACEHOLDER: "Select Reason",
  COMMENT_HEADER: "Additional comments",
  COMMENT_PLACEHOLDER: "Let us know what happened so that we can improve upon.",
  MAXLENGTH: "500",
  ERROR_MESSAGE: "Oops! Something went wrong.",
};

const RETURN_REFUND = {
  TITLE: "Return",
  REFUND_AMOUNT_TITLE: "REFUND AMOUNT",
  REFUND_INTO_TEXT: "WILL BE REFUNDED TO",
  REFUND_INTO_SOURCE_TEXT: "Source",
  ADD_NEFT_TEXT: "ADD NEW NEFT DETAILS",
};

const ADD_ADDRESS = {
  controls: {
    firstName: "Name*",
    lastName: "LAST NAME",
    address: "Address*",
    landmark: "Landmark",
    phone: "Phone*",
    alternateNumber: "Alternate Number",
    pincode: "Pincode*",
    city: "City",
    state: "State",
    nationality: "Nationality",
    addAddressPlaceHolder: "(House/Flat No., Building, Street, Area/Locality)",
  },
  EDIT: {
    submit: "UPDATE",
    title: "Edit Address",
  },
  ADD: {
    title: "Add Address",
    submit: "SAVE",
  },
  PINCODE_ERROR_MSG: "This pincode is not serviceable.",
  DEFAULT_PINCODE_ERROR_MSG:
    "Oops Something went wrong. Please try again later.",
};

const RETURN_COMPLETE = {
  COMPLETE_BUTTON: "DOWNLOAD FORM",
  COMPLETE_ID: "ID: ",
  COMPLETE_IMAGE:
    "https://d2ew2jx6vkwv1p.cloudfront.net/client/assets/returns/images/vector-smart-object.png",
  COMPLETE_MESSAGE1: "Your return form has been mailed to you at",
  COMPLETE_MESSAGE2:
    "Please print the form and put it in the package with the product you are returning.",
  COMPLETE_MESSAGE3:
    "Your Refund will be processed in 5-7 days, after we receive the product.",
  COMPLETE_TITLE: "Return Initiated!",
  CONFIRMATION_CLASS: "nw-returns-confirmation",
  DOWNLOAD_FORM_TITLE: "downloadReturnForm",
  ERROR_MESSAGE: "Oops! Something went wrong.",
};
const RETURN_ELIGIBLE = "RETURN";

const ADDRESS = {
  TITLE: "My Addresses",
  NO_ADDRESS: "No Addresses here",
  START_SHOPPING: "Start shopping for your shipping address to appear",
};
const ADDR_NOT_SERVICIBLE = "Not eligible for Pickup";

const EXCHANGE_INITIATE = {
  TITLE: "Exchange",
  USERBLOCK: "Hi",
  MSG: "We’ll quickly check & get back to you about availability of an Exchange.",
  SIZEBLOCK0: "Please tell us the ",
  SIZEBLOCK1: "Size ",
  SIZEBLOCK2: " you would like to order for this product.",
  BUTTONTEXT: "SUBMIT",
  PLACEHOLDER: "Enter size",
  MESSAGE: "Initiate Exchange",
  ERROR_MESSAGE: "Oops! Something went wrong.",
};

const EXCHANGE_COMPLETE = {
  TITLE: "Thank you!",
  MESSAGE1: "Exchange request received,",
  MESSAGE2: "we’ll get back to you in 5-6 business hours (9AM - 10PM).",
};

export {
  RETURNFLOW,
  RETURN_MODES,
  ADD_ADDRESS,
  RETURN_REASONS,
  RETURN_REFUND,
  RETURN_COMPLETE,
  RETURN_ELIGIBLE,
  ADDRESS,
  ADDR_NOT_SERVICIBLE,
  EXCHANGE_INITIATE,
  EXCHANGE_COMPLETE,
};
