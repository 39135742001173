import { observable } from "mobx";
import PromotionsStoreAction from "./actions";
import { getPromoCode } from "../../utils";

class PromotionsStoreClass extends PromotionsStoreAction {
  @observable isModalOpen = false;
  @observable selectedPromoName = getPromoCode();
  @observable removeSuccess = false;
}

export default PromotionsStoreClass;
