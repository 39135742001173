// Import Core Components Here
import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Toastr,Loader } from "arv-reactcomponents";

// Import Depedency Component Here
import LoginSub from "./LoginSubV2";
import SocialLoginError from "../SocialLoginError";
import ResetPassword from "../ResetPassword";
import MissedFields from "../MissedFields";
import { isAuthenticated } from "../../../utils";
import Analytics from "../../../analytics";
import GTM from "../../../analytics/GTM";
import Event from "../../../analytics/eventFactory";
import { Checkout, pageType, blackboltConfig } from "npmlinks-constants";
import LoginSubV2 from "./LoginSubV2";
import LoginOtp from "./LoginOtp";
import RegistrationV2 from "./RegistrationV2";
import ForgotPassword from "../ForgotPassword";
import OtpSub from "../LoginV2/OtpSub";
import ForgotPasswordV2 from "../LoginV2/ForgotPasswordV2";

@inject("LoginStore", "MybagStore")
@observer
class LoginV2 extends Component {
  constructor() {
    super();
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    if (isAuthenticated()) {
      if (!Checkout.CHECKOUT_PATHNAMES.includes(window.location.pathname)) {
        this.props.MybagStore.getMyBagDetails();
      }
      if (window.location.pathname === pageType.resetPassword) {
        Toastr.showToastr({
          className: "nwc-toastr-list-notification nw-toastr",
          message: blackboltConfig.RESET_PASSWORD_LOGGEDIN,
          timeout: 3000,
        });
      }
      this.props.LoginStore.setLoggedInData();
    } else if (window.location.pathname === pageType.resetPassword) {
      this.props.LoginStore.changeView("resetPassword");
      this.props.LoginStore.setloginModal(true);
    }
  }

  get currentViewSelector() {
    let { currentView } = this.props.LoginStore;
    switch (currentView) {
      case "loginOtp":
        return <LoginOtp.component />;
      case "registrationV2":
        return <RegistrationV2.component />;
      case "missed":
        return <MissedFields.component 
        changeViewValue = {"loginSubV2"}
        />;
      case "socialLoginError":
        return <SocialLoginError.component />;
      case "resetPassword":
        return <ResetPassword.component />;
      case "forgotPassword":
        return <ForgotPassword.component />;
      case "loginSubV2":
        return <LoginSubV2.component/>;
        case "forgotPasswordV2":
          return <ForgotPasswordV2.component />;
      default:
        return <LoginSubV2.component />;
    }
  }
  closeModal() {
    Analytics.trackEvent({
      action: Event.action.LOGIN_CANCEL,
      category: Event.category.LOGIN,
    });
    GTM.pushEvent(Event.GTMEvent.REGISTER_ABORTED);
    this.props.LoginStore.changeView("login");
    const { preventClose } = this.props.LoginStore;
    if (!preventClose) {
      this.props.LoginStore.setloginModal(false);
    } else {
      window.location.href = window.location.origin;
    }
  }

  get hideCloseBtn() {
    const { hideCloseBtn } = this.props.LoginStore;
    return hideCloseBtn ? "nw-hide-close-btn" : "";
  }

  render() {
    const { isModalOpen } = this.props.LoginStore;
    const { loader } = this.props.LoginStore;
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={this.closeModal}
        className={`nw-loginv2 ${this.hideCloseBtn}`}
      >
        {loader &&
          <div className="nw-storelistmodal-loading nwc-translucentbackground-white">
            <Loader />
          </div>
        }
        {this.currentViewSelector}
      </Modal>
    );
  }
}

LoginV2.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    preventClose: PropTypes.bool.isRequired,
    setLoggedInData: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    setloginModal: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    currentView: PropTypes.string.isRequired,
  }).isRequired,
  MybagStore: PropTypes.shape({
    getMyBagDetails: PropTypes.func.isRequired,
  }).isRequired,
};

export { default as LoginOtp } from "./LoginOtp";
export { default as LoginSubV2 } from "./LoginSubV2";
export { default as OtpSub } from "./OtpSub";
export { default as RegistrationV2 } from "./RegistrationV2";


const getFinalComponent = component =>
  inject("LoginStore", "MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginV2.wrappedComponent,
  component: LoginV2,
};

export default ProjectComponent;