import { action } from "mobx";
import { loadPhonepe, phonepeValidateVpa, onPaymentValidate } from "./services";

class PhonepeStoreActions {
  @action
  loadPhonepe(phonepeRequest) {
    let body = phonepeRequest || {};
    return new Promise((resolve, reject) => {
      loadPhonepe(JSON.stringify(body))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  phonepeValidateVpa(vpa) {
    let body = {"vpa": vpa};
    return new Promise((resolve, reject) => {
      phonepeValidateVpa(JSON.stringify(body))
        .then(res => {
          let data = res && res.data || {};
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  @action
  onPaymentValidate(body) {
    return new Promise((resolve, reject) => {
      onPaymentValidate(JSON.stringify(body))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default PhonepeStoreActions;
