import {
  readCookie,
  decodeToken,
  createCookie,
  eraseCookie,
  isIframe,
} from "../../utils";
import { Toastr } from "arv-reactcomponents";
import {
  SOMETHING_FISHY,
  STORE_CHECK_LOGOUT,
  LOGOUT_DELAY_TIME,
  blackboltConfig,
} from "npmlinks-constants";

const createCookies = (...cookies) => {
  cookies.forEach(cookie => {
    const [username] = Object.getOwnPropertyNames(cookie);
    createCookie(username, cookie[username]);
  });
};
const tokenHasRoles = (token, role) => token.roles.includes(role);

// Login methods goes here
const formatLoginQuery = (username, password) =>
  JSON.stringify({
    grant_type: blackboltConfig.grant_type,
    username,
    password,
  });

  const formatLoginQueryV2 = (username, password) =>
  JSON.stringify({
    grant_type: blackboltConfig.grant_type,
    username,
    password,
    countryCallingCode : "+91",
  });

const formatResetPasswordQuery = (id, type) => {
  const headers = {
    "X-Channel": blackboltConfig.channel,
    "X-Communication-Type": type,
  };
  const body = JSON.stringify({
    username: id,
  });
  return {
    headers,
    body,
  };
};

const formatSocialQueryWithOtp = ({
  email = null,
  emailId = null,
  mobileNo = null,
  mobileNumber = null,
  countryCallingCode = "+91",
  source,
  socialToken,
  sessionID,
  passPhrase
}) => {

  const finalDetails = {

    userDetails: {
      emailId: email || emailId,
      mobileNumber: mobileNo || mobileNumber,
      countryCallingCode: countryCallingCode,
      socialIdentityProvider: source,
      socialToken,
      userType: "SOCIAL_USER",
    },
    ... (sessionID && passPhrase && {
      otpDetails: {
        sessionID: sessionID,
        passPhrase: passPhrase
      }
    })
  }
  return JSON.stringify(finalDetails);

};

function setTokens({ access_token: accessToken, refresh_token: refreshToken }) {
  createCookies({ accessToken }, { refreshToken });
}
const deleteCookies = (...cookies) => {
  cookies.forEach(val => {
    eraseCookie(val);
  });
};

// Register methods goes here
const formatSocialQuery = userData => {
  const {
    email = null,
    emailId = null,
    mobileNo = null,
    mobileNumber = null,
    source,
    socialToken,
  } = userData;
  // TODO pick template from separate file
  const userDetails = {
    emailId: email || emailId,
    mobileNumber: mobileNo || mobileNumber,
    userType: "SOCIAL_USER",
    socialIdentityProvider: source,
    socialToken,
  };
  return JSON.stringify(userDetails);
};

const formatRegisterQuery = ({
  password,
  email: emailId,
  name: firstName,
  mobileNo: mobileNumber

}) => {
  const userDetails = {
    emailId,
    firstName,
    mobileNumber,
    password,
    userType: "END_USER",
  };
  return JSON.stringify(userDetails);
};

const formatOtpLoginQuery = ({
  mobileNo,
  sessionID,
  passPhrase
}) => {
  const details = {
    grant_type: blackboltConfig.grant_type_Otp,
    mobileNumber: {
    mobileNumber: mobileNo,
    countryCallingCode: "+91"
      },
    otpDetails: {
        sessionID: sessionID,
        passPhrase:passPhrase
    }
  };
  return JSON.stringify(details);
}

const formatRegisterQuery2 = ({
  password,
  email:emailId,
  name: firstName,
  mobileNumber,
  gender
}) => {
  const userDetails = {
    emailId,
    firstName,
    middleName: "",
    lastName:"",
    mobileNumber,
    countryCallingCode:"+91",
    isMobileNumberVerified:false,
    password,
    gender,
    isEmailVerified:false,
    userType: "END_USER",
  };
  return JSON.stringify(userDetails);
};

const formatRegisterWithOtpQuery = ({
  password,
  email:emailId,
  name: firstName,
  mobileNumber,
  gender,
  sessionID,
  passPhrase

}) => {
  const finalDetails = {
    
    userDetails:{
    emailId,
    firstName,
    middleName: "",
    lastName:"",
    mobileNumber,
    countryCallingCode:"+91",
    isMobileNumberVerified:false,
    password,
    gender,
    isEmailVerified:false,
    userType: "END_USER",
  },
  ... (sessionID && passPhrase && {
    otpDetails: {
      sessionID: sessionID,
      passPhrase: passPhrase
    }
  })
  }
  return JSON.stringify(finalDetails);
};

const tokenValidator = token => {
  const decodedToken = decodeToken(token);
  if (decodedToken && decodedToken.roles) {
    if (
      tokenHasRoles(decodedToken, "ROLE_BUSINESS_USER") &&
      !tokenHasRoles(decodedToken, "ROLE_END_USER")
    ) {
      Toastr.showToastr({
        className: "nwc-toastr-list-danger nw-toastr",
        message: `${STORE_CHECK_LOGOUT}`,
        timeout: `${LOGOUT_DELAY_TIME}`,
      });
      return true;
    } else if (
      (tokenHasRoles(decodedToken, "ROLE_BUSINESS_USER") &&
        tokenHasRoles(decodedToken, "ROLE_END_USER")) ||
      (!tokenHasRoles(decodedToken, "ROLE_BUSINESS_USER") &&
        !tokenHasRoles(decodedToken, "ROLE_END_USER"))
    ) {
      Toastr.showToastr({
        className: "nwc-toastr-list-danger nw-toastr",
        message: `${SOMETHING_FISHY}`,
        timeout: `${LOGOUT_DELAY_TIME}`,
      });
      return true;
    }
    return false;
  }
  return false;
};
// This functions ensures store agent logout in nnnow.
const checkUserRoles = () => {
  const token = readCookie("accessToken");
  if (token && !isIframe) {
    return tokenValidator(token);
  }
  return false;
};

export {
  decodeToken,
  formatLoginQuery,
  formatRegisterQuery,
  formatSocialQuery,
  formatResetPasswordQuery,
  setTokens,
  deleteCookies,
  createCookies,
  checkUserRoles,
  formatRegisterWithOtpQuery,
  formatOtpLoginQuery,
  formatRegisterQuery2,
  formatSocialQueryWithOtp,
  formatLoginQueryV2,
};
