/* eslint-disable import/first */
import uuid from "react-native-uuid";
import { Utils } from "arv-reactcomponents";
import lodashGet from "lodash.get";
import cookiesStorage, {
  createCookie,
  readCookie,
  eraseCookie
} from "./cookies";
import { generateMonthList, generateYearList } from "./dates";
import {
  validCvvLength,
  checkIfAllareStorePick,
  setSurlFurl,
  paymentBaseRequest,
  isPaymentOptionsNotAvailable,
  decidePG,
  formatPGparamsRP,
  formatPGparamsPP,
  convertToPaise,
  razorpayConstruncts,
  PhonePeConstructs
} from "./Payment";
import {
  gethostdomainpath,
  queryParamsToObject,
  makeUrlAbsolute,
  normaliseUrlFromInvalidCases,
  removeProtocolWww,
  cacheQueryParser,
  validateUrlforMalform
} from "./url";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  searchQuerySeparator,
  requiredKeysForListing,
  facebook,
  APP_BREAKPOINTS,
  subPromoSeparator,
  STORAGE_KEYS,
  PASTEL_COLOR_SET,
  PINCODE_LENGTH,
  CUSTOMER_CARE,
  SOLID_COLOR_SET,
  APP_PROMPT_URL,
  WHO_WE_ARE,
  MONTHS,
  Checkout,
  APPS,
  Endpoints,
  CACHINGVALS,
  BRANDS_LIST,
  GOOGLE_SITE_KEYS,
  DEFAULT_GOOGLE_KEY,
  FB_DOMAIN_VERIFICATION_KEYS
} from "npmlinks-constants";
import { arrayToKeyValuePair } from "./normalization";
import somethingWentWrong from "./toastrs";

// const basePathUrl = process.env.REACT_APP_BASE_PATH_URL;
const decodeToken = token => {
  let tokenString = token;
  tokenString = tokenString.split(".");
  let [, str] = tokenString;
  str = str.replace("/-/g", "+");
  str = str.replace("/_/g", "/");
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += "==";
      break;
    case 3:
      str += "=";
      break;
    default: {
      const err = "Invalid token";
      throw err;
    }
  }
  // eslint-disable-next-line
  str = (str + "===").slice(0, str.length + (str.length % 4));
  str = str.replace(/-/g, "+").replace(/_/g, "/");

  str = decodeURIComponent(escape(atob(str)));

  str = JSON.parse(str);
  return str;
};

const getGoogleVerificationKey = domainHost => {
  let brandKey = domainHost.split(".")[0];
  return (
    (BRANDS_LIST[brandKey] && GOOGLE_SITE_KEYS["BRAND_JOURNEY"]) ||
    GOOGLE_SITE_KEYS[DEFAULT_GOOGLE_KEY]
  );
};

const getFbDomainVerificationKey = domainHost => {
  let brandKey = domainHost.split(".")[0];
  // brandkey clean up for qa and preprod
  let cleanBrandKey = brandKey.replace("-mib", "").replace("-preprod", "");
  return FB_DOMAIN_VERIFICATION_KEYS[`${cleanBrandKey.toUpperCase()}`] || null;
};

const noResults = () => {
  window.location.href = `${window.location.origin}/noresults${window.location.search}`;
};
const objToQueryParams = paramsObj =>
  Object.keys(paramsObj)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObj[key])}`
    )
    .join("&");

const getlastUsedPincode = () => {
  try {
    window.localStorage.removeItem("last-used-pincode"); // to be removed after sometime
    let pincodeArray = window.localStorage.getItem(
      STORAGE_KEYS.LAST_USED_PINCODE
    );
    if (!pincodeArray) {
      pincodeArray = JSON.stringify({ list: [] });
    }
    pincodeArray = JSON.parse(pincodeArray);
    return (
      (pincodeArray &&
        pincodeArray.list &&
        pincodeArray.list[0] &&
        pincodeArray.list[0].pincode) ||
      ""
    );
  } catch (err) {
    // catch error if no getItem on localStorage
    // console.log("RECENTLY USED PINCODE OPERATION FAILED");
    if (window && window.localStorage) {
      window.localStorage.removeItem(STORAGE_KEYS.LAST_USED_PINCODE);
    }
    return "";
  }
};
const removeAddressIdInLocal = () =>
  Utils.localStorage.removeItem(Checkout.CHECKOUT_CONFIG.ADDRESS_ID);
const removeShippingMethods = () =>
  Utils.localStorage.removeItem(
    Checkout.CHECKOUT_CONFIG.SAVED_SHIPPING_METHODS
  );
const setLastUsedPincode = pincode => {
  try {
    window.localStorage.removeItem("last-used-pincode"); // to be removed after sometime
    let pincodeArray = window.localStorage.getItem(
      STORAGE_KEYS.LAST_USED_PINCODE
    );
    if (getlastUsedPincode() !== pincode) {
      removeAddressIdInLocal();
      removeShippingMethods();
    }
    if (!pincodeArray) {
      pincodeArray = JSON.stringify({ list: [] });
    }
    pincodeArray = JSON.parse(pincodeArray);
    pincodeArray.list = pincodeArray.list.filter(
      item => item.pincode !== pincode
    );
    const d = new Date();
    const lastModifiedDate = `${[
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate()
    ].join("-")} ${[d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds()].join(
      ":"
    )}`;
    pincodeArray.list.unshift({ pincode, lastModifiedDate });
    window.localStorage.setItem(
      STORAGE_KEYS.LAST_USED_PINCODE,
      JSON.stringify(pincodeArray)
    );
  } catch (err) {
    window.localStorage.removeItem(STORAGE_KEYS.LAST_USED_PINCODE);
  }
};

const getHref = href => {
  if (!href || !href.replace("s", "g")) {
    // eslint-disable-next-line no-script-url
    return "javascript:void(0)";
  }
  if (/^(http:\/\/|https:\/\/|\/\/|\/)/.test(href)) {
    return href;
  }
  return `//${href}`;
};

const redirect = path => {
  window.location.href = `${window.location.origin}/${path}`;
};
const getAuthenticationToken = token => {
  try {
    const accessToken = readCookie(token);
    return accessToken || null;
  } catch (exception) {
    return null;
  }
};

const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    return true;
  }
  return false;
};

// const globalConfigVar =
//   (window && window.GL) ||
//   (global && global.globalConfig && global.globalConfig.isDesktop);
// console.log(
//   "globalConfigglobalConfigglobalConfigglobalConfigglobalConfigglobalConfig",
//   globalConfigVar,
// );
// const isDesktop = () => globalConfig && globalConfig.isDesktop;
// eslint-disable-next-line import/no-mutable-exports
let isLandscape = lodashGet(global, "globalConfig.otherVals.isTablet", false);
const setIsLandscapeServer = () => {
  isLandscape = lodashGet(global, "globalConfig.otherVals.isTablet", false);
};
const setIsLandscape = () => {
  const { screen, innerWidth } = window || {};
  const { orientation } = screen || {};
  const { type = "" } = orientation || {};
  const orientationFromVal = type.indexOf("landscape") > -1;
  const orientationFromWidth = innerWidth > APP_BREAKPOINTS.XS;
  isLandscape = orientationFromVal || orientationFromWidth;
};
const getIsLandscape = () => isLandscape;
const isApp = !!readCookie("app") || false;

const jumpToTopHideHeader = () => {
  window.scrollTo(0, 100);
};

const getPagePath = () => {
  // const path = window.location.pathname;
  const brand = "Arrow";
  return brand;
};

const getBrandName = () => {
  const brandName = getPagePath();
  return brandName;
};

const isBrandPage = () => {
  const brand = getPagePath();
  return !!brand;
};

const sortArrayOfStrings = array => {
  const sortedArray = array.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  return sortedArray;
};

const ifElementInParentView = (parent, el) => {
  const elem = el;
  const elTop = elem.offsetTop;
  const elBottom = elTop + elem.offsetHeight;

  const pTop = parent.offsetTop;
  const pBottom = pTop + parent.offsetHeight;

  return elBottom >= pTop && elTop <= pBottom;
};

const getFilterUrl = (filterby, mapping, incomingSearch) => {
  const search = incomingSearch.replace(/\+/g, "%20");
  const urlObject = queryParamsToObject(search);
  switch (mapping) {
    case "na":
    case "os":
      if (urlObject[mapping]) {
        delete urlObject[mapping];
      } else {
        urlObject[mapping] = true;
      }
      break;
    case "p-dl":
    case "p-st":
    case "sort":
    case "r":
      urlObject[mapping] = filterby.value;
      break;
    case "f-pro":
      if (!urlObject[mapping]) {
        urlObject[mapping] = filterby.value;
      } else {
        const urlArray = urlObject[mapping].split(searchQuerySeparator);
        const requestPromotionArray = filterby.value.split(subPromoSeparator);
        const requestBasePromotion = requestPromotionArray[0];
        requestPromotionArray.splice(0, 1);
        const requestSubPromotion = requestPromotionArray;
        const indexAtUrlArray = urlArray.findIndex(
          item => item.indexOf(requestBasePromotion) > -1
        );
        const filterAtUrl = urlArray[indexAtUrlArray];
        if (indexAtUrlArray === -1) {
          urlArray.push(filterby.value);
        } else if (requestSubPromotion.length === 0) {
          urlArray.splice(indexAtUrlArray, 1);
        } else if (filterAtUrl === filterby.value) {
          urlArray.splice(indexAtUrlArray, 1);
          urlArray.push(requestBasePromotion);
        } else {
          urlArray[indexAtUrlArray] = filterby.value;
        }

        urlObject[mapping] = urlArray.join(searchQuerySeparator);
        if (!urlArray.length) {
          delete urlObject[mapping];
        }
      }
      break;
    default:
      if (!urlObject[mapping]) {
        urlObject[mapping] = filterby.value;
      } else {
        const urlArray = urlObject[mapping].split(searchQuerySeparator);
        const index = urlArray.indexOf(filterby.value);
        if (index > -1) {
          urlArray.splice(index, 1);
        } else {
          urlArray.push(filterby.value);
        }
        urlObject[mapping] = urlArray.join(searchQuerySeparator);
        if (!urlArray.length) {
          delete urlObject[mapping];
        }
      }
      break;
  }
  return objToQueryParams(urlObject);
};
const appendCprToUrl = (url, data) => {
  const urlObj = queryParamsToObject(url);
  if (!urlObj["f-pr"]) {
    delete urlObj.cpr;
    return objToQueryParams(urlObj);
  }
  const priceFilter =
    data &&
    data.filters &&
    data.filters.filterBy &&
    data.filters.filterBy.find(filter => filter.filterType === "Price");
  const cpr =
    (priceFilter &&
      priceFilter.list &&
      priceFilter.list
        .map(filter => filter.value)
        .join(searchQuerySeparator)) ||
    null;
  urlObj.cpr = cpr;
  return objToQueryParams(urlObj);
};

const isStringInArray = (string, array) => {
  const selection = array.find(
    item => string.toLowerCase() === item.toLowerCase()
  );
  return selection;
};

const setPageType = sourcePage => {
  window.pageType = sourcePage;
};
const isFilterSelected = (filter, mapping, searchString) => {
  const queryObj = queryParamsToObject(searchString);
  const filterAtUrl = queryObj[mapping] || "";
  return (
    filterAtUrl
      .split(searchQuerySeparator)
      .filter(item => item === filter.value).length > 0
  );
};

const isAuthenticated = () => {
  const accessToken = readCookie("accessToken");
  const refreshToken = readCookie("refreshToken");
  return !!accessToken && !!refreshToken;
};

// This function decodes the access-token to fetch the loggedin user details
const getKeyFromDecodedToken = () => {
  const token = getAuthenticationToken("accessToken");
  const decodedToken = decodeToken(token);
  return decodedToken;
};

const getPageDetails = pathname => {
  switch (pathname) {
    case "/whoweare":
      return WHO_WE_ARE;
    case "/customercare":
      return CUSTOMER_CARE;
    default:
      return " ";
  }
};

const getRandomNumberWithRange = () => {
  const min = 0;
  const max = 4;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const validatePincode = input => {
  let errorMsg = "";
  if (input.length === 0) {
    errorMsg = "Please enter pincode";
  } else if (!/^\d+$/.test(input)) {
    errorMsg = "Please enter only digits";
  } else if (input.length !== PINCODE_LENGTH) {
    errorMsg = `Please enter ${PINCODE_LENGTH} Digits`;
  }
  return errorMsg;
};

const getClientUniqueId = () => {
  if (process.env.NODE_ENV === "test") {
    return Date.now() + Math.round(Math.random() * 10 ** 10);
  }
  if (!global || !global.isServer) {
    const clientUniqueId = window.sessionStorage.getItem("client_unique_id");
    if (!clientUniqueId) {
      const clientId = Date.now() + Math.round(Math.random() * 10 ** 10);
      window.sessionStorage.setItem("client_unique_id", clientId);
      return clientId;
    }
    return clientUniqueId;
  }
  const clientUniqueId = window.sessionStorage.getItem("client_unique_id");
  if (!clientUniqueId) {
    const clientId = Date.now() + Math.round(Math.random() * 10 ** 10);
    window.sessionStorage.setItem("client_unique_id", clientId);
    return clientId;
  }
  return clientUniqueId;
};

const getCorrelationId = () => uuid.v4();

const clearedUrl = (pathname, search) => {
  const searchObj = queryParamsToObject(search);
  const newObj = {};
  Object.entries(searchObj).forEach(([key, value]) => {
    if (requiredKeysForListing.includes(key)) {
      newObj[key] = value;
    }
  });

  const newSearch = objToQueryParams(newObj);
  return `${pathname}?${newSearch}`;
};
const imageSize = 24;

const processShippingDate = shippingDetails => {
  const modes = [];
  shippingDetails.forEach(value => {
    const item = {};
    if (value.toLowerCase().indexOf("standard") > -1) {
      item.order = 3;
      item.name = "Delivery within 5-7 days";
      modes.push(item);
    } else if (value.toLowerCase().indexOf("next day delivery") > -1) {
      item.order = 2;
      item.name = "Delivery Tomorrow";
      modes.push(item);
    } else if (value.toLowerCase().indexOf("same day delivery") > -1) {
      item.order = 1;
      item.name = "Delivery Today";
      modes.push(item);
    } else {
      item.order = 0;
      item.name = "Pickup from the nearest";
      item.popup =
        "Place the order now and collect your items from a nearby store. We will keep it ready for you";
      modes.push(item);
    }
  });
  modes.sort((a, b) => a.order - b.order);
  return modes;
};

const isWishList = styleId => {
  if (!styleId) {
    const wishlist = window.sessionStorage.getItem("wishList");
    // TODO enable array wishlist post ODIN2.0 pdp

    // wishlist = JSON.parse(wishlist);
    // if (wishlist && wishlist.length > 0) {
    //   const body = [];
    //   wishlist.forEach(style => {
    //     body.push({ styleId: style });
    //   });
    //   return JSON.stringify(body);
    // }
    if (wishlist) {
      return JSON.stringify([{ styleId: wishlist }]);
    }
    return null;
  }
  return JSON.stringify([{ styleId }]);
};

const fbLoad = () => {
  const { appId, version, scope, xfbml } = facebook;
  window.FB.init({
    appId,
    xfbml,
    version,
    scope
  });
};
const fbInit = fbLoaded => {
  if (window.FB) {
    fbLoaded();
  }
  (function(d, s, id) {
    let js = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.onload = () => {
      fbLoad();
      fbLoaded();
    };
    js.src = facebook.url;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-all");
};
const authHeader = (token = readCookie("accessToken")) => {
  const retObj = token
    ? {
        authorization: `Bearer ${token}`
      }
    : {};
  return retObj;
};
const sanitiseUrl = url =>
  url && url.indexOf(".com") > -1 && url.indexOf("http") === -1
    ? `https://${url}`
    : url;

const getCurrentHref = () =>
  (window && window.location && window.location.href) || Utils.noop;

const generateGaLabel = value => value.replace(/\s+/g, "_").toLowerCase();
const isPdpPage = url => {
  const regex = new RegExp("^/?((?:[\\w\\d]+-+)){2,}[A-Z\\d]{10,11}$");
  return regex.test(url);
};
const deduceValuesFromPipedData = (pcmData = "") => {
  const splitArray = (pcmData && pcmData.split("||")) || [];
  let type = "";
  let id = "";
  let url = "";
  let legalBrandNameString = "";
  let image = "";
  let sapBrand = "";
  let baseProduct = "";
  let baseProductId = "";
  const getTypeMetaData =
    pcmData && pcmData.match(/.*(?:\|\|)(.*)(?:\|\|)(.*)(?:\|\|)(.*)$/);
  const iterablegetTypeMetaData = getTypeMetaData ? [...getTypeMetaData] : [];
  const [, keywords, description, title] = iterablegetTypeMetaData;

  [type, id, url, legalBrandNameString] = splitArray;
  if (type === "Style") {
    [
      type,
      id,
      url,
      image,
      sapBrand,
      legalBrandNameString,
      baseProduct,
      baseProductId
    ] = splitArray;
  }
  return {
    type,
    id,
    url: sanitiseUrl(url),
    legalBrandNames:
      (legalBrandNameString && legalBrandNameString.split(",")) || [],
    keywords,
    description,
    title,
    image,
    sapBrand,
    baseProduct,
    baseProductId
  };
};
function* loopArrayValues(setArray) {
  for (let i = 0; i <= setArray.length + 1; i += 1) {
    i = (i < setArray.length && i) || 0;
    yield setArray[i];
  }
}
const colorIterator = loopArrayValues(PASTEL_COLOR_SET);
const getBackgroundColorCode = () => colorIterator.next().value;
const nnnowColorIterator = loopArrayValues(SOLID_COLOR_SET);
const getNnnowColors = () => nnnowColorIterator.next().value;
const getBrandsListFromFiltersAPI = response => {
  // const brandObject = response.filter(obj => obj.filterType === "Brand");
  const brandsList = response.map(brand => brand.parentBrand);
  return brandsList;
};

const getAppURLPrompt = url => {
  const appUrl = APP_PROMPT_URL.concat(url.split("/")[3]);
  return `intent://url?url=${appUrl}#Intent;scheme=nnnow;package=com.nnnow.arvind;end`;
};

const getBaseRouteForPath = url =>
  (url && url.split("/").filter(item => item)[0]) || "";

const checkIfBrandJourney = hostname =>
  !/^(www|smpreprod|preprod|odin2-mib|odin-qa\d?|sm\d?|shakthiman-qa\d?|mumbai-prod)\.(ailiens|nnnow)\.com/.test(
    hostname
  );

const mockPromise = data => {
  const mock = new Promise(resolve => {
    resolve(data);
  });
  return mock;
};

const getStrippedCart = (cart = [], extraKeysNeeded = []) =>
  cart.map(product => {
    const retObj = {
      skuId: product.skuId || product.selectedSku.skuId,
      qty:
        product.selectedQty ||
        product.qty ||
        (product.selectedSku && product.selectedSku.qty) ||
        1
    };
    extraKeysNeeded.forEach(key => {
      switch (key) {
        case "storeId":
          retObj.storeId = product.store && product.store.fcId;
          break;
        default:
          retObj[key] = product[key];
          break;
      }
    });
    return retObj;
  });
const isMybagCheckout = () => {
  const pathaname = window.location.pathname;
  return pathaname && Checkout.CHECKOUT_PATHNAMES.includes(pathaname);
};
const clearLocalCart = () =>
  cookiesStorage.removeItem(Checkout.CHECKOUT_CONFIG.COOKIE_KEY);
const setLocalCart = cart => {
  if (!cart || cart.length === 0) {
    clearLocalCart();
  } else {
    const cartToBeSaved = getStrippedCart(cart);
    cookiesStorage.setItem(
      Checkout.CHECKOUT_CONFIG.COOKIE_KEY,
      JSON.stringify(cartToBeSaved)
    );
  }
};
const getLocalCart = () => {
  const guestCart = cookiesStorage.getItem(Checkout.CHECKOUT_CONFIG.COOKIE_KEY);
  return guestCart ? JSON.parse(guestCart) : [];
};

const getLogoURLFromNav = navListData => {
  const logoFromApi =
    (navListData &&
      navListData.data &&
      navListData.data.logos &&
      navListData.data.logos.brand &&
      navListData.data.logos.brand.image) ||
    null;
  const logoUrl = logoFromApi || Endpoints.nnnowLogoUrl;
  return logoUrl;
};

const setPromoCode = promo =>
  Utils.localStorage.setItem(Checkout.CHECKOUT_CONFIG.PROMO_KEY, promo);
const getPromoCode = () =>
  Utils.localStorage.getItem(Checkout.CHECKOUT_CONFIG.PROMO_KEY);
const removePromoCode = () =>
  Utils.localStorage.removeItem(Checkout.CHECKOUT_CONFIG.PROMO_KEY);

const setShippingMethods = methods => {
  Utils.localStorage.setItem(
    Checkout.CHECKOUT_CONFIG.SAVED_SHIPPING_METHODS,
    methods
  );
};

const getShippingMethods = () =>
  Utils.localStorage.getItem(Checkout.CHECKOUT_CONFIG.SAVED_SHIPPING_METHODS);

const setAddressIdInLocal = id => {
  Utils.localStorage.setItem(Checkout.CHECKOUT_CONFIG.ADDRESS_ID, id);
};

const getAddressIdInLocal = () =>
  Utils.localStorage.getItem(Checkout.CHECKOUT_CONFIG.ADDRESS_ID);

const removedUndefinedFromObj = objitem => {
  const obj = objitem;
  Object.keys(obj).forEach(
    key => (obj[key] === undefined || obj[key] === undefined) && delete obj[key]
  );
  return obj;
};

const generateDateFormat = orderDate => {
  const parsedDate = new Date(orderDate);
  const date = parsedDate.getDate();
  const year = parseInt(
    parsedDate
      .getFullYear()
      .toString()
      .substr(-2),
    10
  );
  const month = parsedDate.getMonth();
  const monthInLetters = MONTHS[month];
  return `${date} ${monthInLetters}' ${year}`;
};

const generateDateFormatFromSystemDate = date => {
  if (date) {
    const dateArray = date.split("T");
    return generateDateFormat(dateArray[0]);
  }
  return "";
};

const getCardLogoType = cardData => {
  const cardType = cardData && cardData.bankCode;
  return (cardType && cardType.replace("_", "-").toLowerCase()) || "";
};
const isClientADesktop = () => window.innerWidth > APP_BREAKPOINTS.XS;

const getStorePath = object => {
  if (object.isMobile && (object.isAndroid || object.isAndroidTablet)) {
    return APPS.PLAY_STORE_PATH;
  } else if (object.isMobile && (object.isiPhone || object.isiPad)) {
    return APPS.APP_STORE_PATH;
  }
  return null;
};

const valueFromObjectComposition = (obj = {}, keyArray = "") => {
  const array = keyArray.split(".");
  let retObj = Object.assign({}, obj);
  array.forEach(key => {
    let tempObj;
    if (retObj) {
      tempObj = retObj[key];
    } else {
      tempObj = null;
    }
    retObj = tempObj;
  });
  return retObj;
};
const allUrlQueryParams = () => {
  const [, paramString] = window.location.href.split("?");
  return queryParamsToObject(paramString);
};

let isIframeInServer = false;
const setIsIframe = () => {
  isIframeInServer = true;
};
const isIframe = (() => {
  const { iframeview } = allUrlQueryParams();
  return isIframeInServer || iframeview;
})();
const constructUrlForIframe = url => {
  const { baseurl } = allUrlQueryParams();
  const relativepath =
    url && url.indexOf(".com") === -1 ? url : url.split(".com")[1];
  return `${baseurl}${relativepath}`;
};
const postIframeMessage = data => {
  window.parent.postMessage(data, "*");
};

const returnAnchorWithId = (key, value) => {
  for (let i = 0; i < value.length; i += 1) {
    if (value[i].id === key) {
      return value[i];
    }
  }
};
const validCachingPath = (relativepath = "/") => {
  const { NECESSARY_CACHING_PARAMS } = CACHINGVALS;
  const [basePath, params] = relativepath.split("?") || [];
  const queryObj = queryParamsToObject(params || "");
  Object.keys(queryObj).forEach(item => {
    if (!NECESSARY_CACHING_PARAMS.includes(item)) {
      delete queryObj[item];
    }
  });

  const newPath = `${basePath || ""}?${objToQueryParams(queryObj)}`;
  return newPath;
};

const imageFileName = (src = "") =>
  src
    .split("/")
    .pop()
    .split(".")
    .slice(0, -1)
    .join(" ")
    // eslint-disable-next-line no-useless-escape
    .replace(/\_/g, " ")
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, " ") || "";

const LOAD_RAZORPAY = () =>
  (function(w, d, s) {
    let f = d.getElementsByTagName(s)[0],
      j = d.createElement(s);
    j.defer = true;
    j.src = "https://checkout.razorpay.com/v1/razorpay.js";
    f.parentNode.insertBefore(j, f);
  })(window, document, "script");

  const LOAD_SNAPMINT = () =>
    (function() {
      var snapmint = document.createElement("script");
      snapmint.type = "text/javascript";
      snapmint.async = true;
      snapmint.src =
        (document.location.protocol == "https:" ? "https://" : "http://") +
        "assets.snapmint.com/assets/merchant/2435/snapmint_emi.js";
      let s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(snapmint, s);
    })();

export {
  getFbDomainVerificationKey,
  getGoogleVerificationKey,
  normaliseUrlFromInvalidCases,
  allUrlQueryParams,
  setIsIframe,
  isIframe,
  constructUrlForIframe,
  postIframeMessage,
  valueFromObjectComposition,
  decodeToken,
  isPaymentOptionsNotAvailable,
  paymentBaseRequest,
  setSurlFurl,
  removedUndefinedFromObj,
  gethostdomainpath,
  makeUrlAbsolute,
  setAddressIdInLocal,
  getAddressIdInLocal,
  removeAddressIdInLocal,
  setShippingMethods,
  getShippingMethods,
  removeShippingMethods,
  validCvvLength,
  setPromoCode,
  getPromoCode,
  removePromoCode,
  getNnnowColors,
  setLocalCart,
  getLocalCart,
  clearLocalCart,
  getStrippedCart,
  mockPromise,
  checkIfBrandJourney,
  getBaseRouteForPath,
  isApp,
  getBackgroundColorCode,
  deduceValuesFromPipedData,
  isPdpPage,
  sanitiseUrl,
  authHeader,
  fbInit,
  clearedUrl,
  somethingWentWrong,
  isFilterSelected,
  setPageType,
  noResults,
  objToQueryParams,
  getlastUsedPincode,
  setLastUsedPincode,
  getHref,
  createCookie,
  readCookie,
  eraseCookie,
  cookiesStorage,
  getAuthenticationToken,
  redirect,
  validateEmail,
  isEmptyObject,
  isLandscape,
  jumpToTopHideHeader,
  getBrandName,
  isBrandPage,
  sortArrayOfStrings,
  ifElementInParentView,
  queryParamsToObject,
  getFilterUrl,
  appendCprToUrl,
  isStringInArray,
  isAuthenticated,
  arrayToKeyValuePair,
  validatePincode,
  getClientUniqueId,
  getCorrelationId,
  imageSize,
  processShippingDate,
  isWishList,
  getCurrentHref,
  generateGaLabel,
  getBrandsListFromFiltersAPI,
  isMybagCheckout,
  getLogoURLFromNav,
  generateMonthList,
  generateDateFormat,
  checkIfAllareStorePick,
  getAppURLPrompt,
  getCardLogoType,
  getKeyFromDecodedToken,
  getRandomNumberWithRange,
  generateDateFormatFromSystemDate,
  getPageDetails,
  isClientADesktop,
  getStorePath,
  generateYearList,
  returnAnchorWithId,
  decidePG,
  formatPGparamsRP,
  formatPGparamsPP,
  convertToPaise,
  setIsLandscape,
  setIsLandscapeServer,
  getIsLandscape,
  removeProtocolWww,
  cacheQueryParser,
  razorpayConstruncts,
  PhonePeConstructs,
  validCachingPath,
  imageFileName,
  validateUrlforMalform,
  LOAD_RAZORPAY,
  LOAD_SNAPMINT
};

export { ToCurrency, CurrencyToVal } from "./currency";
import * as NavMethods from "./nav";
export { NavMethods };
export { default as HTMLUtils } from "./html";
