import { action } from "mobx";
import { Utils } from "arv-reactcomponents";
import { getPaymentmethods, makePayment } from "./service";
import { PAYMENTPAGE } from "npmlinks-constants";

class PaymentStoreActions {
  /**
   * 1)  Once user click on any payment method (paymentOption )then we have to toggle value of this payment options
   * @param paymentOption
   */
  @action
  togglePaymentOptions(paymentOption) {
    this.resetPaymentOptions(Object.keys(this.PaymentTypeOptions));
    if (paymentOption) {
      this.paymentOptionsSelection[paymentOption] = true;
    }
    this.unSelectSavedCard();
    return this.paymentOptionsSelection;
  }
  @action
  unSelectSavedCard() {
    this.isSavedCardSelected = false;
    this.paymentOptionsSelection.SAVEDCARD = false;
  }
  @action
  selectSavedCard() {
    this.isSavedCardSelected = true;
    this.paymentOptionsSelection.SAVEDCARD = true;
    this.unselectOtherPaymentOptions();
  }
  @action
  unselectOtherPaymentOptions() {
    Object.keys(this.PaymentTypeOptions).forEach(paymentOption => {
      this.paymentOptionsSelection[paymentOption] = false;
    });
  }

  toggleSavedCard() {
    this.isSavedCardOpen = !this.isSavedCardOpen;
    this.paymentOptionsSelection.SAVEDCARD = false;
    if (this.isSavedCardOpen) {
      this.resetPaymentOptions(Object.keys(this.PaymentTypeOptions));
    }
  }

  @action
  setPaymentOptionLoyalty(isLoyalty) {
    this.paymentOptionsSelection.LOYALTY = isLoyalty;
  }
  /**
   * this method will initialise the payment options and by default there will be no payment options selected
   * @param paymentOption
   */
  @action
  initPaymentOptions(paymentOptions) {
    const paymentOptionsTemp = paymentOptions.filter(
      payment => payment !== PAYMENTPAGE.ORDER_REQUEST.SAVEDCARD
    );
    const tempObj = {};
    paymentOptionsTemp.forEach(paymentOption => {
      tempObj[paymentOption] = false;
    });
    this.paymentOptionsSelection = Object.assign({}, tempObj);
    this.paymentOptionsSelection.SAVEDCARD = false;
    this.PaymentTypeOptions = Object.assign({}, tempObj);
  }

  @action
  resetPaymentOptions(paymentOptions) {
    const paymentOptionsTemp = paymentOptions.filter(
      payment => payment !== PAYMENTPAGE.ORDER_REQUEST.SAVEDCARD
    );
    const tempObj = {};
    paymentOptionsTemp.forEach(paymentOption => {
      tempObj[paymentOption] = false;
    });
    const tempLoyaltyVal = this.paymentOptionsSelection.LOYALTY;
    this.paymentOptionsSelection = Object.assign({}, tempObj);
    this.paymentOptionsSelection.LOYALTY = tempLoyaltyVal;
    this.paymentOptionsSelection.SAVEDCARD = false;
    this.PaymentTypeOptions = Object.assign({}, tempObj);
  }

  @action
  setPaymentLoading(isLoading) {
    // make sure always boolean value in case of undefined also
    this.loading = !!isLoading;
  }

  @action
  setBankSelected() {
    this.bankSelected = true;
  }

  @action
  getPaymentOptions(reqBody, mobileNumber) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      getPaymentmethods(reqBody, mobileNumber).then(
        paymentResponse => {
          Utils.localStorage.setItem("paymentTypes", JSON.stringify(reqBody));
          const { loyalty } = paymentResponse.data;
          // WE HAVE TO HIDE CREDIT CARD AND DEBIT CARD AND SHOW ONLY ONE COMPONENT
          paymentResponse.data.paymentOptions = Array.from(
            new Set(paymentResponse.data.paymentOptions)
          );
          const debitCardIndex = paymentResponse.data.paymentOptions.indexOf(
            PAYMENTPAGE.ORDER_REQUEST.DEBIT_CARD
          );
          if (debitCardIndex !== -1) {
            paymentResponse.data.paymentOptions.splice(debitCardIndex,1)
          }
          this.paymentOptionsResponse = paymentResponse.data;
          this.paymentTypeDetails = Object.assign(
            {},
            paymentResponse.data.paymentTypeDetails
          );
          this.initPaymentOptions(paymentResponse.data.paymentOptions);
          this.loyalty = loyalty;
          this.loading = false;
          resolve(paymentResponse.data);
        },
        err => {
          this.loading = false;
          // console.log("got error ");
          reject(err);
        }
      );
    });
  }

  @action
  placeOrder(reqBody) {
    this.loading = true;
    Utils.localStorage.setItem("paymentRequest", reqBody);
    return new Promise((resolve, reject) => {
      makePayment(JSON.stringify(reqBody)).then(
        action(res => {
          const paymentResponse = res.data;
          // this.loading = false;
          // UI redirect url can be different for different paymet methode
          paymentResponse.url = `/orderconfirmation/${paymentResponse.orderId}/success`;

          // Store OrderId to cookies storage
          // set payment request in cookies for surl/furl
          resolve(Object.assign({}, paymentResponse));
        }),
        action(err => {
          this.loading = false;
          reject(err);
        })
      );
    });
  }
}

export default PaymentStoreActions;
