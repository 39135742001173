import React from "react";
import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";
import PropTypes from "prop-types";

import {
  SearchNavContainer,
  Logo,
  MyBagButton,
  LoginSwitch,
  SubNav,
} from "../../../molecules";
import StickyFilterBlock from "../../ProductFilters/StickyFilterBlockNew";

const DesktopFixedHeader = props => {
  const { className } = props;

  return (
    <div className={`nw-desktopfixedheader ${className}`}>
      <GridContainer>
        <GridRow className="nw-desktopfixedheader-row">
          <GridColumn className="nw-desktopfixedheader-col">
            <GridRow className="nw-desktopfixedheader-cols">
              <GridColumn className="nwc-grid-col-xs nw-desktopfixedheader-leftcol">
                <Logo.component className="nw-desktopfixedheader-logo" />
                <span className="nw-header-seperator">|</span>
              </GridColumn>
              <GridColumn className="nw-desktopfixedheader-navigationcol">
                <SearchNavContainer.component
                  className="nw-desktopfixedheader-searchnavcontainer"
                />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-desktopfixedheader-buttonscol">
                <MyBagButton.component className="nw-header-linksbtns" />
                <LoginSwitch.component className="nw-header-linksbtns" />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </GridContainer>
      <div className="nw-navtreefixed-holder">
        <SubNav.component />
      </div>
      <StickyFilterBlock.component />
    </div>
  );
};


DesktopFixedHeader.defaultProps = {
  className: "",
};

DesktopFixedHeader.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: DesktopFixedHeader,
  component: DesktopFixedHeader,
};

export default ProjectComponent;
