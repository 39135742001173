import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Anchor from "../../Anchor";

class Breadcrumb extends PureComponent {
  get isIndexEqualToSelected() {
    const { index, selectedIndex } = this.props;
    return index === selectedIndex;
  }

  get isActiveClassName() {
    return (this.isIndexEqualToSelected && "is-active") || "";
  }

  render() {
    const { link, children: text, ...otherProps } = this.props;
    return (
      <Fragment>
        <Anchor.component
          className={`nw-breadcrumb-link ${this.isActiveClassName}`}
          to={link}
          href={link}
          target="_self"
          {...otherProps}
        >
          {text}
        </Anchor.component>
        {(!this.isIndexEqualToSelected && "/") || null}
      </Fragment>
    );
  }
}

Breadcrumb.propTypes = {
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: Breadcrumb,
  component: Breadcrumb,
};

export default ProjectComponent;
