// Import Core Components here

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Login, Endpoints, blackboltConfig, footerUrls, FOOTER_TEXTS } from "npmlinks-constants";
import UserId from "../../UserId"

// Import App depedency here
import {
  Button,
  Img,
  Loader,
  Toastr,
  Utils,
} from "arv-reactcomponents";
import Anchor from "../../../Anchor";
import { gmailInit, OAuth2Init, isWebView } from "../../utils";
import { fbInit } from "../../../../utils";

import Analytics from "../../../../analytics";
import GTM from "../../../../analytics/GTM";
import Event from "../../../../analytics/eventFactory";

@inject("LoginStore", "CommonStore")
@observer
class LoginSubV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FbLoaded: false,
      errorMsg: '',
      value: ''
    };
    this.passwordFields = null;
    this.fbLoaded = this.fbLoaded.bind(this);
    this.showForgotPasswordView = this.showForgotPasswordView.bind(this);
    this.onFbClick = this.onFbClick.bind(this);
    this.onGmailClick = this.onGmailClick.bind(this);
    this.mwebGmailClick = this.mwebGmailClick.bind(this);
    this.mobileNoFields = null;
    this.onChange = this.onChange.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const { isDesktop } = this.props.CommonStore;
    if (process.env.REACT_APP_SOCIAL_LOGIN_ENABLED === "true" && isDesktop) {
      gmailInit(this.onGmailClick);
      fbInit(this.fbLoaded);
    }
    this.props.LoginStore.setHideCloseBtn(false);
    const invalidInput = document.querySelector(".nw-loginsub-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
    this.props.LoginStore.setEmailV2("");
  }
  onFbClick() {
    Analytics.trackEvent({
      action: Event.action.FACEBOOK_LOGIN,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.fbAction();
  }
  onGmailClick(accessToken, type) {
    Analytics.trackEvent({
      action: Event.action.GMAIL_LOGIN,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.loginUserSocially(accessToken, type);
  }
  get isFormValid() {
    return this.props.LoginStore.authError ? "" : "nwc-hidden";
  }
  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }
  get isSocialLoader() {
    return this.props.LoginStore.socialLoader ? "" : "nwc-hidden";
  }

  fbLoaded() {
    this.setState({ FbLoaded: true });
  }

  showForgotPasswordView() {
    if (this.userIdFields.state.isUserIdValid) {
      const { userId } = this.userIdFields.state;
      this.props.LoginStore.changeView("forgotPassword", userId);
    } else {
      this.props.LoginStore.changeView("forgotPassword");
    }
    Analytics.trackEvent({
      action: Event.action.FORGOT_PASSWORD_CLICK,
      category: Event.category.LOGIN,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  mwebGmailClick(event) {
    event.preventDefault();
    OAuth2Init(this.onGmailClick);
  }

  checkUserExist(mobileNo, email) {
    const showOtpScreen = process.env.REACT_APP_LOGIN_OTP_SCREEN === "PSWD" ? false : true;
    this.props.LoginStore.setMobileNumberV2(mobileNo);
    this.props.LoginStore.checkUserExist(mobileNo, email)
      .then(res => {
        const { mobileNumberPresent, emailIdPresent } = res;
        if (mobileNumberPresent || emailIdPresent) {
          this.props.LoginStore.setShowHeader(false);
          this.props.LoginStore.changeView("loginOtp", { showOtp: mobileNumberPresent && showOtpScreen });
          this.props.LoginStore.setMobileNoEntered(mobileNumberPresent);
          Analytics.trackEvent({
            action: Event.action.USER_FOUND,
            category: Event.category.LOGIN,
            label: mobileNumberPresent ? Event.label.PHONENO : Event.label.EMAIL,
          });
        } else {
          Analytics.trackEvent({
            action: Event.action.NEW_USER,
            category: Event.category.LOGIN,
          });
          GTM.pushEvent(Event.GTMEvent.REGISTER_CLICKED);
          this.props.LoginStore.changeView("registrationV2");
        }
      })
      .catch(err => {
        this.setState({
          authErrorMsg: blackboltConfig.ERROR_MSG,
        });
      });
  }

  get gmailComponent() {
    if (isWebView()) {
      return (
        <Button
          className="nw-social-btn nw-gmail"
          id="google-oauth-login"
          onClick={this.mwebGmailClick}
        >
          <Img
            className="nw-loginv2-sociallogin-buttons"
            src={Endpoints.gmailLogo}
            alt="google sign-in"
            onError={Utils.noop}
          />
          <span className="nw-social-text">
            {Login.LOGIN_SUBV2_MSG.GMAIL_LOGIN_TEXT}
          </span>
        </Button>
      );
    }
    return (
      <Button className="nw-social-btn nw-gmail" id="google-login" disabled>
        <Img
          className="nw-loginv2-sociallogin-buttons"
          src={Endpoints.gmailLogo} alt="google sign-in"
          onError={Utils.noop}
        />
        <span className="nw-social-text">
          {Login.LOGIN_SUBV2_MSG.GMAIL_LOGIN_TEXT}
        </span>
      </Button>
    );
  }

  get facebookComponent() {
    return (
      <Button
        className="nw-social-btn nw-fb"
        onClick={this.onFbClick}
        disabled={!this.state.FbLoaded}
      >
        <Img className="nw-loginv2-sociallogin-buttons" src={Endpoints.fbLogo} alt="facebook sign-in" onError={Utils.noop} />
        <span className="nw-social-text">
          {Login.LOGIN_SUBV2_MSG.FB_LOGIN_TEXT}
        </span>
      </Button>
    )
  }

  onSubmit(e) {
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-loginsub-form input.is-invalid",
    );
    const { userId, inputType, isUserIdValid } = this.userIdFields.state;
    if (invalidInput) {
      invalidInput.focus();
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Please enter email or phone",
        timeout: 3000,
      });
      return;
    }
    if (isUserIdValid) {
      if (inputType === "mobile") {
        Analytics.trackEvent({
          action: Event.action.FILLED_ATTEMPT,
          category: Event.category.LOGIN,
          label: Event.label.PHONENO
        });
        this.checkUserExist(userId, null);
        this.props.LoginStore.setMobileNumberV2(userId);
      } else {
        this.props.LoginStore.setEmailV2(userId);
        Analytics.trackEvent({
          action: Event.action.FILLED_ATTEMPT,
          category: Event.category.LOGIN,
          label: Event.label.EMAIL
        });
        this.checkUserExist("", userId);
      }
    }
  }

  onBack() {
    this.props.LoginStore.changeView("loginSubV2");
  }

  onChange(validatedObj) {
    this.mobileNoFields = {
      state: validatedObj
    };
    this.setState({ ...validatedObj });
  }

  render() {
    const { isDesktop } = this.props.CommonStore;
    return (
      <div className="nw-loginv2-container">
        <div
          className={`
            nw-login-loader
            nw-social-loader
            ${this.isSocialLoader}
          `}
        >
          <Loader className="nw-sloader" />
        </div>
        <div className="nw-login-heading nw-loginsubv2-heading">
          {Login.LOGIN_SUBV2_MSG.HEADING}
        </div>
        <form
          className="nw-form nw-loginsub-form"
          onSubmit={this.onSubmit}
        >
          <div className="nw-loginv2-sub-heading">
            {Login.LOGIN_SUBV2_MSG.PHONE_NUMBER_TITLE}
          </div>
          <UserId.component
            className="nw-login-input nw-registrationv2-input"
            name="text"
            value={this.userId}
            ref={values => {
              this.userIdFields = values;
            }}
            required
          />

          <Button
            onClick={this.onSubmit}
            className="nwc-btn-primary nw-login-btn nw-loginv2-submitbtn"
          >
            {Login.LOGINV2_MESSAGES.CONTINUE}
            <div className={`nw-login-loader ${this.isShowLoader}`}>
              <Img
                className="nw-loader"
                src={Endpoints.LOADER}
                alt="loader"
                onError={Utils.noop}
              />
            </div>
          </Button>
          {process.env.REACT_APP_SOCIAL_LOGIN_ENABLED === "true" && isDesktop &&
            <div className="nw-login-divider">
              <span className="nw-login-or">{Login.LOGIN_SUBV2_MSG.DIVIDER_OR_TEXT}</span>
              <div className="nw-loginv2-text-divider" />
            </div>
          }
          <div>
            {process.env.REACT_APP_SOCIAL_LOGIN_ENABLED === "true" && isDesktop &&
              <div>
                <div className="nw-login2-gmail-btn">
                  {this.gmailComponent}
                </div>
                <div className="nw-login2-fb-btn">
                  {this.facebookComponent}
                </div>
              </div>
            }
            <div className="nw-loginv2-tnc-block">
            <span className="nw-loginv2-tnc-text">
              {Login.LOGIN_SUBV2_MSG.TERMS_AND_CONDITION_MSG}&nbsp;
              <Anchor.component
              className="nw-loginv2-tnc-link"
              href={footerUrls.privacypolicy}
              to={footerUrls.privacypolicy}
              target="_self"
            >
              {FOOTER_TEXTS.PRIVACY_POLICY}
              </Anchor.component>
              </span>
              <span>{Login.LOGIN_SUBV2_MSG.COMMA_SEPERATOR}</span>
              <span className="nw-loginv2-tnc-text">
              <Anchor.component
                className="nw-loginv2-tnc-link"
                href={footerUrls.termsconditions}
                to={footerUrls.termsconditions}
                target="_self"
              >
                {FOOTER_TEXTS.TNC}
              </Anchor.component>
            </span>
          </div>
          </div>
        </form>
      </div>
    );
  }
}

LoginSubV2.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    loginUserAction: PropTypes.func.isRequired,
    fbAction: PropTypes.func.isRequired,
    loginUserSocially: PropTypes.func.isRequired,
    authError: PropTypes.bool.isRequired,
    authErrorMsg: PropTypes.string.isRequired,
    changeView: PropTypes.func.isRequired,
    userId: PropTypes.string,
    loader: PropTypes.bool.isRequired,
    socialLoader: PropTypes.bool.isRequired,
    setMobileNumberV2: PropTypes.func.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginSubV2.wrappedComponent,
  component: LoginSubV2,
};

export default ProjectComponent;