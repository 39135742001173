const COMPONENTS_AT_SECTION_LEVEL = ["storyGrid"];

const sectionTypes = [
  "section",
  "primaryStorySection",
  "secondaryStorySection",
  "tertiaryStorySection",
  "primaryStories",
];

const MANDAROTY_FULLWIDTH_COMPONENTS = [
  "leftNav",
  "productCarousal",
  "NewArrival",
  "bannerCarousel",
  "heroBanner",
  "topBanner",
  "paddingComponent",
  "storyCarousel",
  "storyGrid",
];
const NOPADDING_COMPONENTS = ["paddingComponent"];
const DEFAULT_IMAGE_DIMENTIONS = { defaultWidth: 309, defaultHeight: 400 };
const BT = "BaseTemplate";
export {
  COMPONENTS_AT_SECTION_LEVEL,
  sectionTypes,
  MANDAROTY_FULLWIDTH_COMPONENTS,
  NOPADDING_COMPONENTS,
  DEFAULT_IMAGE_DIMENTIONS,
  BT,
};
