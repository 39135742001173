import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { brandFilterKeys } from "npmlinks-constants";
import { GridRow, GridColumn, Button } from "arv-reactcomponents";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";

class BrandIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleActiveBrand = this.toggleActiveBrand.bind(this);
    this.subMenuClick = this.subMenuClick.bind(this);
  }

  get brandFilter() {
    return this.props.icon.activeBrand
      .split("")
      .map(alphabet => this.props.icon.list[alphabet])
      .filter(group => group && group.list && group.list.length > 0);
  }

  subMenuClick(index, brandkey) {
    this.props.selectElementAndFilter(this.props.icon.id, index, brandkey);
    Analytics.trackEvent({
      action: Event.action.FILTER_BRAND_APPLIED,
      label: Event.label.getSource(window.pageType),
      category: Event.category.SEARCH,
    });
  }

  toggleActiveBrand(event) {
    event.stopPropagation();
    this.props.toggleActiveBrand(
      event.target.getAttribute("value"),
      this.props.icon.id,
    );
  }
  render() {
    return (
      <GridRow className={`nw-search-brand-filter ${this.props.className}`}>
        <GridColumn className="nw-search-brand-filter-options">
          <GridRow className="nw-search-brand-filter-options-inner">
            {brandFilterKeys.map(filterKey => (
              <GridColumn
                className={`nwc-grid-col-lg-6 nw-search-brand-filter-keys ${
                  filterKey === this.props.icon.activeBrand ? "is-active" : ""
                }`}
                onClick={this.toggleActiveBrand}
                key={filterKey}
                value={filterKey}
              >
                {filterKey}
              </GridColumn>
            ))}
          </GridRow>
        </GridColumn>
        <GridColumn className="nw-search-brand-filter-list">
          <GridRow>
            {this.brandFilter.map(c => (
              <GridColumn
                key={c.key}
                className="nwc-grid-col-lg-6 nw-search-brand-filter-list-group"
              >
                <div className="nw-search-brand-filter-list-heading">
                  {c.key}
                </div>
                <div>
                  {c.list.map((brand, index) => (
                    <div
                      className={`nwc-btn nw-search-brandname ${
                        brand.selected ? "is-selected" : ""
                      }`}
                      key={brand.value}
                      onClick={() => {
                        this.subMenuClick(index, c.key);
                      }}
                      role="button"
                    >
                      {brand.displayName}
                    </div>
                  ))}
                </div>
              </GridColumn>
            ))}
          </GridRow>
        </GridColumn>
      </GridRow>
    );
  }
}
BrandIcon.propTypes = {
  icon: PropTypes.shape({
    id: PropTypes.number,
    activeBrand: PropTypes.string,
    filterType: PropTypes.string,
    class: PropTypes.shape({}),
    backgroundColor: PropTypes.string,
    list: PropTypes.shape([]),
    isActive: PropTypes.bool,
  }).isRequired,
  toggleActiveBrand: PropTypes.func.isRequired,
  selectElementAndFilter: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandIcon,
  component: BrandIcon,
};

export default ProjectComponent;
