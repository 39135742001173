import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import NnnowBar from "./NnnowBarV2";
import BrandBar from "./BrandBarV2";

@inject("CommonStore")
@observer
class MainBar extends Component {
  get hideIfNotBrandpageClassName() {
    return (!this.props.CommonStore.isBrandPage && "nwc-hide") || "";
  }

  get hideIfBrandpageClassName() {
    return (this.props.CommonStore.isBrandPage && "nwc-hide") || "";
  }
  render() {
    return (
      <div>
        <div className={this.hideIfBrandpageClassName}>
          <NnnowBar.component />
        </div>
        <div className={this.hideIfNotBrandpageClassName}>
          <BrandBar.component />
        </div>
      </div>
    );
  }
}

MainBar.wrappedComponent.propTypes = {
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool,
  }).isRequired,
};

export { default as BrandBar } from "./BrandBarV2";
export { default as NnnowBar } from "./NnnowBarV2";

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MainBar.wrappedComponent,
  component: MainBar,
};

export default ProjectComponent;
