import { observable, computed } from "mobx";
import AddressStoreActions from "./actions";
import { getlastUsedPincode, readCookie } from "../../utils";

class AddressStoreClass extends AddressStoreActions {
  @observable isSavedAddressOpen = false;
  @observable isAddAddressOpen = false;
  @observable addressBlockHeading = "";
  @observable addAddressHeading = "";
  @observable addAddressButtonText = "";
  @observable addressloader = false;
  @observable isPincodeScreenOpen = false;
  @observable detailsOfPincode = {};
  @observable currentPincode = getlastUsedPincode() || ""; // dummy for now. to be removed later;
  @observable userAddresses = [];
  @observable mobileNumberVerified = false;
  @observable continueToCheckout = false;
  @computed
  get defaultAddress() {
    const [defaultAddress] = this.userAddresses.filter(
      address => address.default,
    );
    return defaultAddress;
  }
  @computed
  get selectedAddress() {
    const pincode = this.currentPincode || getlastUsedPincode();
    const addresssesMatchingPincode = this.userAddresses.filter(
      address => address.pincode === pincode,
    );
    const [defaultAddress] = addresssesMatchingPincode.filter(
      address => address.default,
    );
    const [selectedAddress] = addresssesMatchingPincode.filter(
      address => address.id === this.addressId,
    );
    const [lastModifiedAddress] = addresssesMatchingPincode.sort(
      (a, b) => new Date(b.lastModifiedDate) - new Date(a.lastModifiedDate),
    );

    return selectedAddress || defaultAddress || lastModifiedAddress || null;
  }
  @computed
  get mobileNumber() {
    const [verifiedMobileAddress] = this.userAddresses.filter(
      address => address.isMobileNumberVerified,
    );
    const [defaultMobAddress] = this.userAddresses.filter(
      address => address.default,
    );
    return (
      (this.selectedAddress && this.selectedAddress.mobileNumber) ||
      (verifiedMobileAddress && verifiedMobileAddress.mobileNumber) ||
      (defaultMobAddress && defaultMobAddress.mobileNumber) ||
      readCookie("mobileNumber")
    );
  }
}
export default AddressStoreClass;
