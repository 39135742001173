import { action } from "mobx";

import * as service from "./service";

class MyOrdersStoreAction {
  // My Orders actions goes here

  @action
  getOrderHistory(page) {
    this.loading = true;
    return new Promise((resolve, reject) =>
      service.getOrders(page).then(
        action(res => {
          if (page === 0) {
            this.ordersList.orders = [...res.data.content];
          } else {
            this.ordersList.orders = [
              ...this.ordersList.orders,
              ...res.data.content,
            ];
          }
          this.ordersList.currentPage = res.data.number + 1;
          this.ordersList.totalPages = res.data.totalPages;
          this.loading = false;
          resolve(res.data.content);
        }),
        action(err => {
          this.loading = false;
          reject(err);
        }),
      ),
    );
  }
  getOrderDetails(orderId) {
    return service.getOrderDetails(orderId);
  }
}

export default MyOrdersStoreAction;
