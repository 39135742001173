const PRICINGOVERVIEW = {
  DELIVERY_CHARGES_LINK: "/delivery_charges",
  TOTAL_PAYABLE: "Total Payable Amount",
  CURRENCY: "₹",
  DISCOUNT_CURRENCY: "- ₹",
  OVERVIEW: "Overview",
  TEXT: {
    subtotal: "Subtotal",
    discounts: "Discount",
    tax: "GST",
    shippingCost: "Delivery Charges",
    loyalty: "Loyalty",
    netAmount: "Net Amount",
    CODCharges: "COD Charges",
  },
  TOTAL: {
    total: "Total",
  },
};

export default PRICINGOVERVIEW;
