import React from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { Button, Img } from "arv-reactcomponents";
import { Endpoints } from "npmlinks-constants";

@inject("LoginStore")
@observer
class SocialLoginError extends React.Component {
  constructor(props) {
    super(props);
    this.showLoginView = this.showLoginView.bind(this);
  }

  showLoginView(e) {
    e.preventDefault();
    this.props.LoginStore.changeView("login");
  }
  render() {
    return (
      <div className="nw-login-container">
        <div className="nw-fb-error">
          <div className="sad-face-img">
            <Img src={Endpoints.sadFaceLogo} alt="sad face" />
          </div>
          <div className="nw-fb-sorry-text">
            <span>SORRY!</span>
          </div>
          <div className="nw-login-error nw-social-error-text">
            {this.props.LoginStore.authErrorMsg}
          </div>
          <div className="nw-login-error nw-social-message">
            <p>Please login using</p>
            <p>your phone/email</p>
          </div>
        </div>
        <Button
          className="nwc-btn-primary nw-login-btn nw-sociallogin-home"
          onClick={this.showLoginView}
        >
          Back to login
        </Button>
      </div>
    );
  }
}
SocialLoginError.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    authError: PropTypes.bool.isRequired,
    authErrorMsg: PropTypes.string.isRequired,
    changeView: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: SocialLoginError.wrappedComponent,
  component: SocialLoginError,
};

export default ProjectComponent;
