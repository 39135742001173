import React from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import PasswordInput from "../PasswordInput";
import { Button, Toastr } from "arv-reactcomponents";
import { Endpoints } from "npmlinks-constants";
import { blackboltConfig } from "../../../constants";
import { queryParamsToObject } from "../../../utils";
import { withRouter } from "react-router";
import Analytics from "../../../analytics";
import lodashGet from "lodash.get";

@inject("LoginStore")
@withRouter
@observer
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authErrorMsg: "",
      authError: false,
    };
    this.passwordFieldNew = null;
    this.passwordFieldConfirm = null;
    this.onSubmit = this.onSubmit.bind(this);
    this.showForgotPasswordView = this.showForgotPasswordView.bind(this);
    this.showErrorMsg = this.showErrorMsg.bind(this);
    this.disablePaste = this.disablePaste.bind(this);
  }
  componentDidMount() {
    const invalidInput = document.querySelector(".nw-resetpwd-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
  }

  showForgotPasswordView() {
    this.props.history.replace("");
    this.props.LoginStore.changeView("forgotPassword");
    Analytics.trackEvent({
      action: Event.action.FORGOT_PASSWORD_CLICK,
      category: Event.category.LOGIN,
    });
  }

  showErrorMsg(bool) {
    this.setState({ authErrorMsg: "", authError: bool });
  }

  onSubmit(e) {
    this.setState({ authErrorMsg: "", authError: false });
    const search = queryParamsToObject(window.location.search);
    const { sessionId = null, type = null } = search;
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-resetpwd-form input.is-invalid",
    );

    const { password: newPassword } = this.passwordFieldNew.state;
    const { password: confirmPassword } = this.passwordFieldConfirm.state;
    if (!newPassword) {
      this.passwordFieldNew.setErrorMsg(blackboltConfig.RESET_PASSWORD_NULL);
    }
    if (!confirmPassword) {
      this.passwordFieldConfirm.setErrorMsg(
        blackboltConfig.RESET_PASSWORD_NULL,
      );
    }
    if (!newPassword || !confirmPassword) {
      return;
    }
    if (invalidInput) {
      invalidInput.focus();
      return;
    }
    if (
      this.passwordFieldNew.state.isPasswordInputValid &&
      this.passwordFieldConfirm.state.isPasswordInputValid &&
      newPassword === confirmPassword
    ) {
      if (sessionId) {
        this.props.LoginStore.resetPasswordAction(sessionId, newPassword, type)
          .then(() => {
            this.props.LoginStore.changeView("login");
            Toastr.showToastr({
              className: "nwc-toastr-list-notification nw-toastr",
              message: blackboltConfig.RESET_PASSWORD_SUCCESS,
              timeout: 3000,
            });
            this.setState({ authErrorMsg: "", authError: false });
            this.props.history.replace("");
          })
          .catch(err => {
            let authErrorMsg = "";
            const errorStatus = lodashGet(err,"status", null);
            if (
              err &&
              blackboltConfig.ERROR_CODE.includes(JSON.stringify(errorStatus))
            ) {
              authErrorMsg = lodashGet(err, "errors[0].detail", blackboltConfig.RESET_ERROR_MSG);
            } else if(err.errorStatus === "302"){
              authErrorMsg = lodashGet(err,"message",blackboltConfig.RESET_ERROR_MSG);
            } else {
              authErrorMsg = blackboltConfig.RESET_ERROR_MSG;
            }
            this.setState({ authErrorMsg, authError: true });
          });
      } else {
        this.setState({
          authErrorMsg: blackboltConfig.RESET_ERROR_MSG,
          authError: true,
        });
      }
    } else {
      this.setState({
        authErrorMsg: blackboltConfig.RESET_PASSWORD_MISMATCH,
        authError: true,
      });
    }
  }
  get checkIsFormValid() {
    return this.state.authError ? "" : "nwc-hidden";
  }
  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }

  disablePaste(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div className="nw-login-container">
        <div className="nw-login-heading">
          <h1 className="nw-login-title">
            {blackboltConfig.RESET_PASSWORD_TEXT}
          </h1>
        </div>
        <div className="nw-reset-img">
          <img
            className="nw-reset-password-image"
            src={Endpoints.resetImage}
            alt="reset password"
          />
        </div>
        <form className="nw-form nw-resetpwd-form" onSubmit={this.onSubmit}>
          <PasswordInput.component
            className="nw-login-input nw-resetpwd-password"
            placeholder="Enter New Password"
            name="newPassword"
            minLength={8}
            showErrorMsg={this.showErrorMsg}
            ref={values => {
              this.passwordFieldNew = values;
            }}
            required
          />
          <PasswordInput.component
            className="nw-login-input nw-resetpwd-confirmpassword"
            placeholder="Confirm Password"
            name="confirmPassword"
            minLength={8}
            showErrorMsg={this.showErrorMsg}
            onPaste={this.disablePaste}
            ref={values => {
              this.passwordFieldConfirm = values;
            }}
            required
          />
          <span className={`nw-login-error ${this.checkIsFormValid}`}>
            {this.state.authErrorMsg}
          </span>
          <Button
            type="submit"
            className="nwc-btn-primary nw-login-btn nw-resetpwd-submitbtn"
            onClick={this.onSubmit}
          >
            SUBMIT
            <div className={`nw-login-loader ${this.isShowLoader}`}>
              <img className="nw-loader" src={Endpoints.LOADER} alt="loader" />
            </div>
          </Button>

          <Button
            className={`nw-login-link nw-sublogin-forgotpwd ${
              this.checkIsFormValid
            }`}
            onClick={this.showForgotPasswordView}
            type="button"
          >
            forgot password
          </Button>
        </form>
      </div>
    );
  }
}
ResetPassword.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    resetPasswordAction: PropTypes.func,
    changeView: PropTypes.func,
    loader: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ResetPassword.wrappedComponent,
  component: ResetPassword,
};

export default ProjectComponent;
