import { observable, computed } from "mobx";
import PaymentStoreActions from "./actions";

class PaymentStoreClass extends PaymentStoreActions {
  constructor() {
    super();
    this.bankSelected = false;
  }
  @observable loading = false;
  @observable isSavedCardOpen = true;
  /**
   * this contain all paymenet get from backend credit card,  dedit card , cod
   * @type {{}}
   */
  @observable PaymentTypeOptions = {};
  /**
   * this contain  PaymentTypeOptions + savedCard options + future payment options
   * @type {{}}
   */
  @observable paymentOptionsSelection = {};

  @observable paymentOptionsResponse = {};
  @observable isSavedCardSelected = false;
  @observable loyalty = {};
  @observable paymentTypeDetails = {};

  @computed
  get selectedPaymentOptions() {
    return Object.keys(this.paymentOptionsSelection).filter(key => {
      if (this.paymentOptionsSelection[key]) {
        return key;
      }
    });
  }
  @computed
  get loyaltyValue() {
    return (
      this.loyalty && this.loyalty.points && this.loyalty.points.loyaltyValue
    );
  }
}

export default PaymentStoreClass;
