import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { Utils } from "arv-reactcomponents";
import TopBar from "../../../molecules/MobileHeaderComponents/TopBarv2";
import MainBar from "../../../molecules/MobileHeaderComponents/MainBarv2";
import Search from "../../Search";

@inject("HeaderStore", "SearchBarStore", "NavListStore")
@observer
class MobileHeader extends Component {
  componentDidMount() {
    if (
      this.props.NavListStore &&
      Object.keys(this.props.NavListStore.navListData).length <= 0
    ) {
      this.props.NavListStore.getNavListData().then(() => {
        console.log("API called from client...NavList");
      });
    }
    Utils.onElementScroll(window, ({ scrollTop, direction }) => {
      this.handleFixedStateHeaderMainbar(scrollTop, direction);
    });
  }

  get isFixedClassName() {
    const { isFixed, isFixedHeaderHidden } = this.props.HeaderStore;

    if (isFixed && isFixedHeaderHidden) {
      return "is-fixed is-header-hidden";
    } else if (isFixed) {
      return "is-fixed";
    }

    return "";
  }
  get navMetaData() {
    return (
      (this.props.NavListStore.navListData &&
        this.props.NavListStore.navListData.data &&
        this.props.NavListStore.navListData.data.metaData) ||
      {}
    );
  }

  handleFixedStateHeaderMainbar(scrollTop, direction) {
    if (scrollTop > 100 && direction === "down") {
      this.props.HeaderStore.setFixedState(true);
      this.props.HeaderStore.setFixedHeaderVisibility(true);
    } else if (scrollTop > 100 && direction === "up") {
      this.props.HeaderStore.setFixedState(true);
      this.props.HeaderStore.setFixedHeaderVisibility(false);
    } else if (!scrollTop || scrollTop <= 100) {
      this.props.HeaderStore.setFixedState(false);
      this.props.HeaderStore.setFixedHeaderVisibility(false);
    }
  }

  render() {
    return (
        <header
          className={`nw-mobileheader-wrap ${(this.navMetaData.topBandMessage &&
            "is-having-shippingtext") ||
          ""}`}
        >
          {!this.props.SearchBarStore.showSearch ? <TopBar.component /> : null}
          <MainBar.component />
          <Search.component currentState={this.isFixedClassName} />
        </header> 
    );
  }
}

MobileHeader.wrappedComponent.propTypes = {
  HeaderStore: PropTypes.shape({
    isFixed: PropTypes.bool.isRequired,
    isFixedHeaderHidden: PropTypes.bool.isRequired,
    setFixedState: PropTypes.func.isRequired,
    setFixedHeaderVisibility: PropTypes.func.isRequired,
  }).isRequired,

  SearchBarStore: PropTypes.shape({
    showSearch: PropTypes.bool,
  }).isRequired,
  NavListStore: PropTypes.shape({
    getNavListData: PropTypes.func,
    navListData: PropTypes.shape({
      data: PropTypes.shape().isRequired,
    }).isRequired,
  }).isRequired,
};

export { default as MainBar, BrandBar, NnnowBar } from "../../../molecules/MobileHeaderComponents//MainBarv2";
export { default as MobileActionButton } from "../../../molecules/MobileHeaderComponents/MobileActionButtonv2";
export { default as NavContainer } from "../../../molecules/MobileHeaderComponents/NavContainerv2";
export { NavList } from "../../../molecules/MobileHeaderComponents/NavListv2";
export { default as TopBar } from "../../../molecules/MobileHeaderComponents/TopBarv2";

const getFinalComponent = component =>
  inject("HeaderStore", "SearchBarStore", "NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MobileHeader.wrappedComponent,
  component: MobileHeader,
};

export default ProjectComponent;