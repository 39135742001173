import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";

import SearchBar from "../SearchBar";
import Navigation from "../Navigation";

class SearchNavContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchIsCollapsed: true,
    };

    this.searchBarStatus = this.searchBarStatus.bind(this);
  }

  get hideNavClassName() {
    return this.state.searchIsCollapsed ? "" : "nwc-hide";
  }

  get isSearchActiveClassName() {
    return this.state.searchIsCollapsed ? "" : "is-searchactive";
  }

  searchBarStatus(bool) {
    this.setState({
      searchIsCollapsed: !bool,
    });
  }

  render() {
    const { className } = this.props;

    return (
      <div className={`nw-searchnavcontainer ${className} ${this.isSearchActiveClassName}`}>
        <SearchBar.component
          className="nw-searchnavcontainer-searchbar"
          searchBarStatus={this.searchBarStatus}
        />
        <Navigation.component
          className={`nw-searchnavcontainer-navigation ${this.hideNavClassName}`}
        />
      </div>
    );
  }
}

SearchNavContainer.defaultProps = {
  className: "",
};

SearchNavContainer.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SearchNavContainer,
  component: SearchNavContainer,
};

export default ProjectComponent;
