import { observable } from "mobx";
import StoreLocatorStoreActions from "./actions";

class StoreLocatorStore extends StoreLocatorStoreActions {
  @observable brandsList;

  constructor() {
    super();
    this.brandsList = ["Fetching Brands"];
  }
}

export default StoreLocatorStore;
