import { observable } from "mobx";
import StoreListStoreActions from "./actions";

class StoreListStore extends StoreListStoreActions {
  constructor(props) {
    super(props);
    this.isDesktopTemp = props && props.isDesktop;
    this.selectedStoreFCID = "";
    this.currentLocationCoords = null;
    this.targetLocationCoords = null;
    this.storesList = [];
    this.notAValidPincode = "";
    this.state = {
      showStoreLoader: false,
      showNoStoreImage: false,
      showStoreList: false,
      showForm: true,
      showMap: false,
      storePlaceholderImage: true,
    };
    if (this.isDesktop) {
      this.states.showForm = true;
      this.states.showMap = true;
    }
  }
  @observable isDesktop = this.isDesktopTemp;
  @observable selectedStoreFCID;
  @observable currentLocationCoords;
  @observable storesList;
  @observable targetLocationCoords;
  @observable notAValidPincode;
  @observable currentPincode = "";
  @observable brandName = "";
  @observable appliedBrandName = "";
  @observable
  states = {
    showStoreLoader: false,
    showNoStoreImage: false,
    showStoreList: false,
    showForm: true,
    showMap: false,
    storePlaceholderImage: true,
  };
}

export default StoreListStore;
