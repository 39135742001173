import Analytics from "./analytics/";
import Event from "./analytics/eventFactory";

// eslint-disable-next-line import/prefer-default-export
export function listenForBeforeInstallPrompt() {
  // To trigger GA if app is successfully installed
  window.addEventListener("appinstalled", e => {
    console.log("a2hs installed", e);
    Analytics.trackEvent({
      category: Event.category.ADD_TO_HOME,
      action: Event.action.ADD_TO_HOME_SUCCESS,
    });
  });

  // To trigger GA if user is using PWA app
  if (window.matchMedia("(display-mode: standalone)").matches) {
    console.log("display-mode is standalone");
    Analytics.trackEvent({
      category: Event.category.ADD_TO_HOME,
      action: Event.action.ADD_TO_HOME_APP,
    });
  }

  window.addEventListener("beforeinstallprompt", e => {
    if (process.env.REACT_APP_ORIGIN !== window.location.origin) {
      // Prevent Chrome 76 and later from showing the mini-infobar for brand journeys
      e.preventDefault();
    }
    window.deferredPrompt = e;
  });
}
