import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  GridRow,
  GridColumn,
  LazyImg,
  Button,
  Toastr,
  Loader,
} from "arv-reactcomponents";
import { inject, observer } from "mobx-react";
import PriceBlock from "../../../component/ProductList/PriceBlock";
import ProductName from "../../../component/ProductList/ProductName";
import Event from "../../../analytics/eventFactory";
import Analytics from "../../../analytics";
import { Endpoints, MyBag } from "npmlinks-constants";
import GTM from "../../../analytics/GTM";
import Anchor from "../../../component/Anchor";
const { CARTPRODUCTS } = MyBag;

@inject("MybagStore")
@observer
class MyBagProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultMainImage: null,
      itemsInDeleteState: [],
    };
    this.onMainImgError = this.onMainImgError.bind(this);
    this.removeFromBag = this.removeFromBag.bind(this);
    this.getTenantSizeDescription = this.getTenantSizeDescription.bind(this);
  }

  removeFromBag(item) {
    const { itemsInDeleteState } = this.state;
    itemsInDeleteState.push(item.skuId);
    this.setState({ itemsInDeleteState });
    const { styleName = "an item" } = item;
    this.props.MybagStore.removeFromBag(item).then(() => {
      itemsInDeleteState.splice(itemsInDeleteState.indexOf(item.skuId), 1);
      Analytics.trackEvent({
        action: Event.action.MINIBAG_PRODUCT_REMOVE,
        label: Event.label.getSource(window.pageType),
        category: Event.category.MINIBAG,
      });

      const productInfoGa = {
        item_id: item.styleId,
        item_name: item.styleName,
        currency: Event.GTMEvent.GTM_CURRENCY,
        discount: item.discountInPercentage || 0,
        item_brand: item.brand,
        item_category2: item.gender,
        price: item.totalGrossSP || item.totalMRP,
        item_variant: item.secondaryColor || item.primaryColor,
        quantity: item.qty
      };

      let ecommObj = {
        currency: "INR",
        value: item.totalMRP || 0,
        items: [productInfoGa]
      }

      GTM.pushWithEvent({
        value: item.totalMRP,
        currency: Event.GTMEvent.GTM_CURRENCY,
        content_name: item.styleName,
        content_category: item.pcmArticleType,
        content_id: item.skuId,
        contents: [
          {
            Id: item.skuId,
            brand: item.brand,
            desc: item.styleName,
            Qty: item.qty,
            size: item.size,
            dscnt: item.discountInPercentage,
            price: item.totalGrossSP || item.totalMRP,
            mrp: item.totalMRP,
          },
        ],
      }, Event.GTMEvent.REMOVE_PRODUCT
      );

      GTM.pushObject({"ecommerce": ecommObj,"event": Event.GTMEvent.REMOVE_PRODUCT});
      Toastr.showToastr({
        className: "nwc-toastr-list-success nw-toastr",
        message: `${MyBag.MYBAGPOPUP.TOASTER_MSG}`.replace("$styleName", styleName),
        timeout: 3000,
      });
    });
  }

  onMainImgError() {
    this.setState({
      defaultMainImage: Endpoints.noImg,
    });
  }

  image(item) {
    return (item.thumbnailImage || item.image);
  }

  getTenantSizeDescription(item){
    const {size} = item;
    return (
      <div className="nw-mybagpopup-itemlabel">
        {CARTPRODUCTS.BRAND_SIZE}:
       <span className="nw-mybagpopup-itemvalue">
          &nbsp;
          {size}
       </span>
      </div>
  )}

  render() {
    const { defaultMainImage, itemsInDeleteState } = this.state;
    const { item } = this.props;
    const { mrp, grossSP, discountInPercentage, skuId, tenantSize, tenantSizeLabel, size} = item;
    const dynamicSizeHeaderValue =  tenantSize && tenantSizeLabel ? tenantSizeLabel :CARTPRODUCTS.SIZE_TEXT;
    const dynamicSizeValue = tenantSize ? tenantSize : size;
    const selectedSku = {
      mrp,
      price: grossSP || mrp,
      discountInPercentage,
      skuId,
    };
    return (
      <GridRow key={item.skuId} className="nw-mybagpopup-item">
        {itemsInDeleteState.includes(item.skuId) && (
          <GridColumn className="nw-mybagpopup-individialloader nwc-translucentbackground-white">
            <Loader />
          </GridColumn>
        )}
        <GridColumn className="nwc-grid-col-xs-4">
          <Anchor.component
            className="nw-mybagpopup-link"
            href={item.url}
          >
            <LazyImg
              className="nw-mybagpopup-itemimage"
              src={defaultMainImage || this.image(item)}
              rootMargin={"1500px"}
              onError={this.onMainImgError}
              alt="Product Image"
              onWinLoad
            />
          </Anchor.component>
        </GridColumn>
        <GridColumn className="nwc-grid-col-xs-7">
          <ProductName.component item={item} />
          <div className="nw-mybagpopup-itemlabel">
            <span>{dynamicSizeHeaderValue}:</span>
            <span className="nw-mybagpopup-itemvalue">
              &nbsp;
              {dynamicSizeValue}{tenantSize && this.getTenantSizeDescription(item)}
            </span>
          </div>
          <div className="nw-mybagpopup-itemlabel">
            <span>Color:</span>
            <span className="nw-mybagpopup-itemvalue">
              &nbsp;
              {item.color || item.secondaryColor}
            </span>
          </div>
          <PriceBlock.component
            selectedSku={selectedSku}
            className="nw-productview-priceblk"
          />
        </GridColumn>
        <GridColumn className="nwc-grid-col-xs-1">
          <Button
            className="nwc-mybagpopup-close"
            onClick={() => this.removeFromBag(item)}
          >
            x
            </Button>
        </GridColumn>
      </GridRow>
    );

  }
}

MyBagProductView.wrappedComponent.propTypes = {
  MybagStore: PropTypes.shape({
    removeFromBag: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    skuId: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MyBagProductView.wrappedComponent,
  component: MyBagProductView,
};

export default ProjectComponent;