// import Dimension from "./eventFactory/dimensions";
/* eslint-disable  func-names */
import GoogleAnalytics from "./GoogleAnalytics";
import GTM from "./GTM";

const IDLE_TIMEOUT = 1000;

class Analytics {
  static CURRENCY = "INR";

  /**
   * Wraps analytics calls with a promise
   * and delays firing of events
   * using Idle Callback
   */
  static firePromiseWhenIdle(func, analyticsObject, maxTimeout = IDLE_TIMEOUT) {
    /* eslint-disable consistent-return, no-unused-vars */
    return new Promise((resolve, reject) => {
      if (global !== undefined && global.isServer) {
        return resolve(analyticsObject);
      }
      window.requestIdleCallback(() => resolve(func()), {
        timeout: maxTimeout,
      });
    });
  }

  static pageView(pageType) {
    const analyticsObject = {
      pageType,
    };

    return this.firePromiseWhenIdle(
      () => {
        GoogleAnalytics.pageView(pageType);
        GTM.reset();
      },
      analyticsObject,
      0,
    );
  }

  static trackEvent(pageType, isImpressionData = false) {
    const analyticsObject = {
      pageType,
    };
    
    if (!(isImpressionData && process.env.REACT_APP_GA_IMPRESSION === "false")) {
    return this.firePromiseWhenIdle(
      () => {
        GoogleAnalytics.trackEvent(pageType);
        GTM.reset();
      },
      analyticsObject,
      0,
    );
   }
  }
}

export { default as EventFactory } from "./eventFactory";
export { default as GoogleAnalytics } from "./GoogleAnalytics.js";
export { default as GTM } from "./GTM.js";
export { default as LogMan } from "./logman.js";
export default Analytics;

// if (global === undefined && typeof __INITIAL_ANALYTICS__ !== "undefined") {
//   // Pop out all initial events populated by server
//   window.__INITIAL_ANALYTICS__
//         .forEach(analyticsObject => mapAnalyticsObjectToEvent(analyticsObject));
//   delete window.__INITIAL_ANALYTICS__;
// }
