import PhonepeStoreActions from "../PhonepeStore/actions";
import { observable, computed } from "mobx";
import { PAYMENTPAGE } from "npmlinks-constants";
import { paymentBaseRequest } from "../../utils";


const { COMPONENTWALLET_MAPPING } = PAYMENTPAGE;
class PhonePeStoreClass extends PhonepeStoreActions {
    @observable paymentData = null;
    @observable upiId = "";
    @observable upiData = {};
    @observable stayOnpaymentPage = false;
  
    @computed
    get paymentRequest() {
      const upiData = this.upiData;
      return {
        paymentDetail: paymentBaseRequest({
          description: COMPONENTWALLET_MAPPING['UPI'],
          bankCode: COMPONENTWALLET_MAPPING['UPI'],
        }),
        paymentTypeCode: COMPONENTWALLET_MAPPING['UPI'],
        requestedPG: upiData.requestedPG || null,
        razorpayKey: upiData.pgRequestParameters && upiData.pgRequestParameters.RAZORPAY_KEY || '',        
      };
    }
  }
  
  export default PhonePeStoreClass;