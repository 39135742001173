export default {
  // TOP_ABOVE_MENU_CTR
  getProperLabelWithoutSpaces: string => string && string.split(" ").join("_"),
  getMasterTemplateLabels: (inputArray = []) =>
    inputArray.filter(item => item || item === 0).join("_"),
  getSource: source => `source=${source}`,

  // LOGIN
  getRegisterationError: err => JSON.stringify(err),
  EMAIL:"email",
  PHONENO:"phone",

  // SEARCH
  getSearchQuery: query => `query=${query}`,

  // API_FAIL
  getApiFailObj: err => JSON.stringify(err),

  // FOOTER
  getEmailSubscribeLabel: email => {
    const brandSpecific =
      window.location.host.split(".")[0] === "www"
        ? "nnnow"
        : window.location.host.split(".")[0];
    return `[${email}]_[${brandSpecific}]`;
  },

  // PDP
  getImageChangeDirection: direction => `${direction}`,
  getShareType: channel => `${channel}`,
  getShareTypeBT: channel => `${channel}_click`,
  getRazorpaySuccess: res => JSON.stringify(res),
  getRazorpayFailure: err => JSON.stringify(err),
  getPaymentGateway: (requestedPG = "", recievedPG = "") =>
    JSON.stringify({ requestedPG, recievedPG }),
};
