import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Input, Button, Toastr } from "arv-reactcomponents";
import { PAYMENTPAGE, Endpoints } from "npmlinks-constants";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";

@inject("OtpStore")
@observer
class OtpV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generateOtpReqBody: {},
      mobileNumber: "",
      throughService: "",
      errorMsg: "",
      successMsg: "",
      showBtnLoader: false,
      enableBtn: false,
      resendButtonDisabled: false,
    };
    this.otp = "";
    this.generateOtp = this.generateOtp.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.validateOtpEntered = this.validateOtpEntered.bind(this);
    this.afterValidateOtpStateupdateAndGA = this.afterValidateOtpStateupdateAndGA.bind(
      this,
    );
  }

  componentWillReceiveProps(nextProps) {
    const { mobileNumber: oldMobileNumber } = this.props.generateOtpReqBody;
    const { mobileNumber: newMobileNumber } = nextProps.generateOtpReqBody;
    const {
      state: oldState,
      sessionID: oldSessionID,
    } = this.props.validateOtpReq;
    const {
      state: newState,
      sessionID: newSessionID,
    } = nextProps.validateOtpReq;

    if (oldMobileNumber !== newMobileNumber) {
      this.setState({
        generateOtpReqBody: nextProps.generateOtpReqBody,
        mobileNumber: nextProps.generateOtpReqBody.mobileNumber,
        throughService: nextProps.throughService,
      });
      this.generateOtp(nextProps.generateOtpReqBody, nextProps.throughService);
    } else if (oldState !== newState || oldSessionID != newSessionID) {
      this.setState({
        validateOtpReq: nextProps.validateOtpReq,
        throughService: nextProps.throughService,
      });
    }
  }

  generateOtp(generateOtpReqBody, throughService) {
    this.props.OtpStore.generateOtpFunc(
      generateOtpReqBody,
      throughService,
    ).then(
      res => {
        this.props.afterGenerateOtpCallBack(res);
      },
      err => {
        this.props.afterGenerateOtpCallBack(err);
      },
    );
  }

  get resentButtonDisabled() {
    return this.state.resendButtonDisabled;
  }

  resendOtp() {
    Analytics.trackEvent({
      action: Event.action.OTP_RESEND_REQUESTED,
      category: Event.category.CHECKOUT,
    });
    this.otp = "";
    this.setState({
      errorMsg: "",
      resendButtonDisabled: true,
    });
    this.props.OtpStore.resendOtpFunc(
      this.state.generateOtpReqBody,
      this.state.throughService,
    ).then(
      res => {
        this.setState({ resendButtonDisabled: false });
        this.props.afterGenerateOtpCallBack(res);
      },
      error => {
        const errorMsg =
          (error &&
            error.data &&
            error.data.detail) ||
          "";
          if (errorMsg) {
            Toastr.showToastr({
              className: "nwc-toastr-list-danger nw-toastr",
              message: errorMsg,
              timeout: 3000,
            });
          } 
        this.setState({
          resendButtonDisabled: !!errorMsg,
          errorMsg,
        });
        this.props.afterGenerateOtpCallBack(error);
      },
    );
    document.getElementById("nw-otp-input").value = "";
  }

  afterValidateOtpStateupdateAndGA(isOtpValidatedSuccessfully) {
    Analytics.trackEvent({
      action: isOtpValidatedSuccessfully
        ? Event.action.OTP_VERIFICATION_SUCCESS
        : Event.action.OTP_VERIFICATION_FAILED,
      category: Event.category.CHECKOUT,
    });
    this.setState({
      successMsg: isOtpValidatedSuccessfully ? PAYMENTPAGE.OTP_SUCCESS_MSG : "",
      errorMsg: !isOtpValidatedSuccessfully ? PAYMENTPAGE.OTP_ERROR_MSG : "",
      showBtnLoader: false,
    });
  }

  submitOtp(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.OTP_VERIFICATION_REQUESTED,
      category: Event.category.CHECKOUT,
    });
    this.setState({
      showBtnLoader: true,
    });
    const reqBody = Object.assign({}, this.state.validateOtpReq, {
      otp: this.otp,
      passPhrase: this.otp,
      registration: (this.props && this.props.registration) || null,
      onboardDetails: this.props && this.props.onboardDetails,
    });
    this.props.OtpStore.validateOtpFunc(reqBody, this.state.throughService)
      .then(res => {
        const isValidated =
          (this.state.throughService === "heimdall" && res.otpvaildated) ||
          res.data;
        this.afterValidateOtpStateupdateAndGA(isValidated);
        this.props.afterValidationCallBack(res);
      })
      .catch(err => {
        this.setState({
          enableBtn: false,
          showBtnLoader: false,
        });
        if (err && err.data.call_for === "register") {
          this.props.afterValidationFailedCallBack(err);
        } else {
          this.setState({
            errorMsg: PAYMENTPAGE.DEFAULT_ERROR_MESSAGE,
          });
        }
      });
  }

  validateOtpEntered(e) {
    const charCode = typeof e.which === "number" ? e.which : e.keyCode;
    if (isNaN(String.fromCharCode(charCode))) {
      //eslint-disable-line
      e.preventDefault();
    }
    this.setState({
      errorMsg: "",
    });
    this.otp = e.target.value;
    if (PAYMENTPAGE.VALID_OTP_SIZES.includes(this.otp.length)) {
      this.setState({
        enableBtn: true,
      });
    } else {
      this.setState({
        enableBtn: false,
      });
    }
  }

  get errorState() {
    return this.state.errorMsg ? "" : "nwc-hide";
  }

  get successState() {
    return this.state.successMsg ? "" : "nwc-hide";
  }

  get succesStateHideForm() {
    return !this.state.successMsg ? "" : "nwc-hide";
  }

  get showbtnLoader() {
    return this.state.showBtnLoader ? "" : "nwc-hide";
  }

  get enableValidateBtn() {
    return !this.state.enableBtn;
  }

  render() {
    if (!this.state.mobileNumber) {
      return <span />;
    }
    const { errorMsg, successMsg } = this.state;
    return (
      <div className="nw-otp-container">
        <div className={`nw-otp-wrapper ${this.succesStateHideForm}`}>
          {this.props.otpHeading ? (
            <div className="nw-otp-heading">
              <span className="nw-otp-heading-text">
                {this.props.otpHeading}
              </span>
              <i className="icomoon-info">
                <div className="nw-otp-tooltip">
                  <p className="nw-otp-tooltip-info">
                    {this.props.tooltipMessage || PAYMENTPAGE.OTP_TOOLTIP_MSG}
                  </p>
                </div>
              </i>
            </div>
          ) : (
            ""
          )}
          {this.props.otpSubHeading ? (
            <div className="nw-otp-sent-resend nw-otp-sent-heading">
              <span className="nw-otp-sent-text">
                {this.props.otpSubHeading}{" "}
                <strong>{this.state.mobileNumber}</strong>
              </span>
              <Button
                className="nw-otp-resendbtn"
                onClick={() => this.resendOtp()}
                disabled={this.resentButtonDisabled}
              >
                {PAYMENTPAGE.OTP_RESND_CTA}
              </Button>
            </div>
          ) : (
            ""
          )}
          <div className="nw-otp-form">
            <form noValidate name="registerForm" onSubmit={this.submitOtp}>
              <fieldset>
                <Input
                  id="nw-otp-input"
                  className="nw-otp-input"
                  type="tel"
                  placeholder={PAYMENTPAGE.OTP_PLACEHOLDER}
                  maxlength={PAYMENTPAGE.OTP_MAXDIGIT}
                  minlength={PAYMENTPAGE.OTP_MINDIGIT}
                  onChange={this.validateOtpEntered}
                  onKeyPress={this.validateOtpEntered}
                />
                <Button
                  type="buton"
                  className="nw-otp-verifybtn"
                  onClick={this.submitOtp}
                  disabled={this.enableValidateBtn}
                >
                  {PAYMENTPAGE.OTP_VERIFY_BTN}
                </Button>
              </fieldset>
              <div className={`nw-otp-btn-loader ${this.showbtnLoader}`}>
                <img
                  className="nw-otp-btn-loader-gif"
                  src={Endpoints.LOADER}
                  alt="otp loader"
                />
              </div>
            </form>
            <div className={`nw-otp-error ${this.errorState}`}>
              <i className="icomoon-sad" />
              <span>{errorMsg}</span>
            </div>
            {this.props.phoneOtpValidatingMessage && (
              <div className="nw-otp-validating-msg">
                {" "}
                {this.props.phoneOtpValidatingMessage}
              </div>
            )}
          </div>
        </div>
        <div className={`nw-otp-successblock ${this.successState}`}>
          <i className="icomoon-check nw-otp-check" />
          <span>
            <strong> {PAYMENTPAGE.OTPSUCCESS}</strong> {successMsg}
          </span>
        </div>
      </div>
    );
  }
}

OtpV2.defaultProps = {
  phoneOtpValidatingMessage: null,
  tooltipMessage: "",
};

OtpV2.wrappedComponent.propTypes = {
  generateOtpReqBody: PropTypes.shape({}).isRequired,
  validateOtpReq: PropTypes.shape({}).isRequired,
  otpHeading: PropTypes.string.isRequired,
  otpSubHeading: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
  throughService: PropTypes.string.isRequired,
  afterGenerateOtpCallBack: PropTypes.func.isRequired,
  afterValidationCallBack: PropTypes.func.isRequired,
  tooltipMessage: PropTypes.string,
  OtpStore: PropTypes.shape({
    generateOtpFunc: PropTypes.func.isRequired,
    resendOtpFunc: PropTypes.func.isRequired,
    validateOtpFunc: PropTypes.func.isRequired,
    setCodVerified: PropTypes.func.isRequired,
  }).isRequired,
  phoneOtpValidatingMessage: PropTypes.String,
};

const getFinalComponent = component =>
  inject("OtpStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: OtpV2.wrappedComponent,
  component: OtpV2,
};

export default ProjectComponent;