const cdnbasepath = `https://${process.env.REACT_APP_CDNBASEPATH}`;
export default {
  LOADER: `${cdnbasepath}/client/assets/images/icon/loader.gif`,
  NOSTORES: `${cdnbasepath}/nostores.gif`,
  PAYTM_LOGO: `${cdnbasepath}/client/assets/images/paytm_logo.jpg`,
  nnnowLogoUrl:
    "https://cdn02.nnnow.com/web-images/master/navtree_metaData/59b2425ae4b0d70964ee66e0/1505806763887/12NNNOWLOGODESKTOP.png",
  nnnowMobileLogo: `${cdnbasepath}/client/assets/images/nnnow-logo-beta_1.png`,
  nnnowBaseUrl: "https://odin-qa.ailiens.com/",
  gmailLogo: `${cdnbasepath}/google-social-login.svg`,
  fbLogo: `${cdnbasepath}/facebook.png`,
  sadFaceLogo: `${cdnbasepath}/sad_face.png`,
  googleMapsUrl: "//maps.google.com/maps?z=12&t=m&q=",
  noImg: `${cdnbasepath}/client/assets/images/icon/defaultSkuImage.png`,
  fpImage: `${cdnbasepath}/Forgot+password+(1).png`,
  missedImage: `${cdnbasepath}/phone-number.png`,
  resetImage: `${cdnbasepath}/reset_password.png`,
  brush_stroke: `${cdnbasepath}/brush-stroke.png`,
  cvvImge: `${cdnbasepath}/client/assets/images/checkout-final-17.png`,
  favorites_no_products: `${cdnbasepath}/client/assets/images/my-favorites-no-product.png`,
  sadSmileyImage: `${cdnbasepath}/client/assets/images/sad_smiley.png`,
  noAddressImage: `${cdnbasepath}/client/assets/images/address_map.jpg`,
  storeLocator_illustration: `${cdnbasepath}/locate+store+illustration.png`,
  orderdetailsFooter: `${cdnbasepath}/client/assets/images/orderdetails-footer.png`,
  paymentPending: `${cdnbasepath}/paymentpending.svg`,
  orderdetailsMobileFooter: `${cdnbasepath}/client/assets/images/orderdetails-mobile-footer.png`,
  youtubeLogo: `${cdnbasepath}/client/assets/images/youtubeicon.svg`,
  feebackCustomerImage: {
    IMAGE: [
      `${cdnbasepath}/client/assets/images/app/hari.png`,
      `${cdnbasepath}/client/assets/images/app/gokul.png`,
    ],
  },
  customerRating: {
    RATING: `${cdnbasepath}/client/assets/images/app/ratings.png`,
  },
  APP_SCREEN: `${cdnbasepath}/client/assets/images/app/app-screens.jpg`,
  APP_STORE: `${cdnbasepath}/client/assets/images/app/apple-logo.png`,
  PLAY_STORE: `${cdnbasepath}/client/assets/images/app/play-logo.png`,
  GETAPP: `${cdnbasepath}/client/assets/appDownload.png`,
  PDP_BANNER_IMAGES: {
    desktop: `${cdnbasepath}/client/assets/images/pdp/pdp_desktop_1.jpg`,
    mobile: `${cdnbasepath}/client/assets/images/pdp/pdp_mobile_1.jpg`,
  },

  PDP_MOBILE_BANNER_IMAGE: {
    gap: "https://www.gap.com/",
    unlimited:"https://www.unlimited.com/" ,
    sephora:"https://www.sephora.com/" ,
    blog:"https://www.blog.com/" ,
    tommyhilfiger:"https://www.tommyhilfiger.com/" ,
    calvinklein:"https://www.calvinklein.com/" ,
    uspoloassn:"https://www.uspoloassn.com/" ,
    flyingmachine:"https://www.flyingmachine.com/" ,
    aeropostale:"https://www.aeropostale.com/" ,
    arrow:"https://www.arrow.com/" ,
    arvind:"https://www.arvind.com/" ,
    edhardy:"https://www.edhardy.com/" ,
    thechildrensplace:"https://www.thechildrensplace.com/" ,
    trueblue:"https://www.trueblue.com/" ,
    stride:"https://www.stride.com/" ,
    colehaan:"https://www.colehaan.com/" ,
    default:`${cdnbasepath}/client/assets/images/pdp/pdp_mobile_1.jpg`,
  },

  LOYALTY_EXPIRY_ICON: `${cdnbasepath}/loyalty_expiry.png`,
  VISA_CARD: `${cdnbasepath}/visa.png`,
  MASTER_CARD: `${cdnbasepath}/master.png`,
  AMEX_CARD: `${cdnbasepath}/amex.png`,
  LOYALTY_GIFT_IMAGE: `${cdnbasepath}/loyalty_gift.png`,
  PDP_NON_RETURNABLE_LOGO: `${cdnbasepath}/client/assets/images/pdp/pdp_non_returnable_logo.png`,
  SIZE_NUDGE_INFO_LOGO: {
    desktop: `${cdnbasepath}/client/assets/images/filter/info-logo-desktop.png`,
    mobile: `${cdnbasepath}/client/assets/images/filter/info-logo-mobile.png`,
  },
  FORGOT_PASSWORD_IMG: {
    mobile:`${cdnbasepath}/Group%2043335.svg`,
    email:`${cdnbasepath}/Group%2043336.svg`
  },
  LOGIN_SCREEN_IMG:`${cdnbasepath}/Group%2043389.svg`,
  OTP_SCREEN_IMG: `${cdnbasepath}/Group%2043373.svg`,
  MISSING_FIELD_IMG:`${cdnbasepath}/Group%2043362.svg`,
  noResultFoundImage: `${cdnbasepath}/noresult/no_result.png`,

  coin: `${cdnbasepath}/pdp/coin.png`,
  delivery_truck: `${cdnbasepath}/pdp/delivery-truck.png`,
  exchange: `${cdnbasepath}/pdp/exchange.png`,
  rupee: `${cdnbasepath}/pdp/rupee.png`,

  pdp_share: {
    facebook: `${cdnbasepath}/pdp/share/Facebook.png`,
    twitter: `${cdnbasepath}/pdp/share/Twitter.png`,
    whats_app: `${cdnbasepath}/pdp/share/whats_app.png`,
    copy_link: `${cdnbasepath}/pdp/share/copy_link.png`,
  },

  pdp_footer_get_app: {
    google_play: `${cdnbasepath}/pdp/google_play.png`,
    app_store: `${cdnbasepath}/pdp/app_store.png`,
  },

  coupon: {
    go_back: `${cdnbasepath}/client/assets/images/promotions/go_back_btn.png`,
    blue_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_blue_tag.png`,
    green_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_green_tag.png`,
    sky_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_sky_tag.png`,
    orange_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_orange_tag.png`,
    red_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_red_tag.png`,
    yellow_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_yellow_tag.png`,
    gray_tag: `${cdnbasepath}/client/assets/images/promotions/coupon_gray_tag.png`,
    info: `${cdnbasepath}/client/assets/images/promotions/info.png`
  },

  ratings: {
    filled_star: `${cdnbasepath}/rating/filled_star.png`,
    empty_star: `${cdnbasepath}/rating/empty_star.png`,
    empty_starv2: `${cdnbasepath}/rating/empty_starv2.png`,
    gallery: `${cdnbasepath}/rating/gallery.png`,
    camera: `${cdnbasepath}/rating/camera.png`,
    camerav2: `${cdnbasepath}/rating/camerav2.png`,
    colse: `${cdnbasepath}/rating/close.png`,
    halfFilledStar:`${cdnbasepath}/rating/halfFilledStar.png`,
    backButton:`${cdnbasepath}/rating/backButton.png`,
    published: `${cdnbasepath}/rating/Published.svg`
  }

};
