export default {
  REGISTER_CLICKED: "initiate_signup",
  REGISTER_SUCCESSFUL: "register_successful",
  REGISTER_FAILED: "register_failed",
  REGISTER_ABORTED: "register_rejected",
  LOGIN_CLICKED: "login_clicked",
  LOGIN_SUCCESSFUL: "login_successful",
  LOGIN_FAILED: "login_failed",
  ADWORDS: "Adwords",
  SEPHORAADWORDS: "sephoraAdwords",
  LISTING: "ListingPage",
  VIEW_ITEM_LIST: "view_item_list",
  CATEGORY_SELECTED: "category_viewed",
  ECOMM_LISTING: "category",
  ECOMM_HOME: "home",
  PRODUCT: "product",
  PRODUCT_VIEW: "view",
  PRODUCT_VIEW_ITEM: "view_item",
  SELECT_ITEM: "select_item",
  GTM_CURRENCY: "INR",
  PLACE_ORDER: "place_order",
  PRODUCT_WISHLIST: "shortlist",
  LOGIN: "signin",
  REGISTER: "signup",
  LOGOUT: "logout",
  EMAIL_SUBSCRIBE: "email_subscribe",
  ADD_PRODUCT: "add_to_cart",
  REMOVE_PRODUCT: "remove_from_cart",
  VIEW_CART: "view_cart",
  USER_ATTRIBUTES: "user_attr",
  PROMO_SUCCESS: "coupon_success",
  SELECT_PROMO: "select_promotion",
  PROMO_FAILURE: "coupon_failed",
  PROMO_CLICK: "apply_coupon_click",
  CHECKOUT_CLICK: "checkout_click",
  CHECKOUT_SHIPPING: "checkout_shipping",
  CHECKOUT_BEGIN: "begin_checkout",
  CHECKOUT_PAYMENT: "checkout_payment",
  ADD_PAYMENT_INFO: "add_payment_info",
  VIEW_PROMOTION:"view_promotion",
  MYBAG_LANDED: "mybag",
  CONVERSION: "purchase",
  SEPHORA_CONVERSION: "sephoraAdwordsConversion",
  CANCEL_INITATED: "cancel_initiated",
  CANCEL_COMPLETE: "cancel_completed",
  RETURN_INITATED: "return_initiated",
  RETURN_COMPLETE: "return_completed",
  PAYMENT_FAILURE: "payment_failed",
  PAYMENT_SUCCESS: "payment_success",
  PLACE_ORDER_CLICK: "place_order_click",
  STORE_LOCATOR_CLICK: "store_locator_click",
  STORE_LOCATOR_SEARCH: "store_locator_search",
  MYFAVOURITE_CLICK: "myfavourite_click",
  CONTACTUS_CLICK: "contactus_click",
  MYACCOUNT_CLICK: "myaccount_click",
  MYORDER_CLICK: "myorder_click",
  MYBAG_CLICK: "mybag_click", 
  MYBAG_EDIT: "edit_bag",
  PRODUCT_SHARE: "product_share",
  SHOP_NOW_CLICK: "shop_now",
  SEARCH_QUERY: "search",
  ADD_ADDRESS: "add_address",
  MYBAG_SHORTLIST: "mybag_shortlist",
  SELECT_SIZE: "select_size",
  SHIPPING: "shipping",
  PAYMENT: "payment",
  CART: "cart"
};
