import { action } from "mobx";
import {
  addNEFT,
  downloadReturnForm,
  getReturnData,
  getCancelData,
  makeReturn,
  makeCancel,
  initiateExchange,
} from "./services";

import { RETURNS } from "npmlinks-constants";
const { RETURN_MODES } = RETURNS;

class ReturnStoreActions {
  @action
  getReturnData(orderId, itemId) {
    this.loader = true;
    return new Promise((resolve, reject) => {
      getReturnData(JSON.stringify({ orderId, itemId }))
        .then(res => {
          if (res) {
            if (res.returnReasons && res.returnReasons.length > 0) {
              this.reasons = res.returnReasons.map(reason =>
                Object.assign({
                  id: reason.id,
                  reason: reason.reason,
                }),
              );
            }

            if (res.neftDetailList && res.neftDetailList.length > 0) {
              res.neftDetailList.forEach(data => {
                this.neftList.push(data);
                if (this.neftList.length > 0 && this.neftList[0].id)
                  this.selectedNeftId = this.neftList[0].id;
              });
            }

            if (res.returnModes && res.returnModes.length > 0) {
              this.returnModes = res.returnModes;
              if (process.env.REACT_APP_RETURN_SELFSHIP_ENABLED === "false") {
                this.returnModes = this.returnModes.filter(function(obj) {
                  return obj.name !== RETURN_MODES.SELFSHIP;
                });
              }
            }

            if (res.selfShipInfo) {
              this.selfShipInfo = res.selfShipInfo;
              this.selfShipFcId = res.selfShipInfo.fcId;
            }

            if (
              res.refundAmountDetails &&
              res.refundAmountDetails.totalRefund
            ) {
              this.refundAmount = res.refundAmountDetails.totalRefund;
            }

            if (res.isNeftNeeded) {
              this.isNeftNeeded = res.isNeftNeeded;
            }
          }
          this.loader = false;
          resolve(res);
        })
        .catch(error => {
          this.loader = false;
          reject(error);
        });
    });
  }

  @action
  getCancelData(orderId, itemId) {
    this.loader = true;
    return new Promise((resolve, reject) => {
      getCancelData(
        JSON.stringify({
          cancelItemIds: [itemId],
          orderId: orderId
        }),
      )
        .then(res => {
          if (res) {
            if (res.cancelReasons && res.cancelReasons.length > 0) {
              this.reasons = res.cancelReasons.map(reason =>
                Object.assign({
                  id: reason.id,
                  reason: reason.reason,
                }),
              );
            }

            if (
              res.refundAmountDetails &&
              res.refundAmountDetails.totalRefund
            ) {
              this.refundAmount = res.refundAmountDetails.totalRefund;
            }
            if (res.isNeftNeeded) {
              this.isNeftNeeded = res.isNeftNeeded;
            }
            if (res.neftDetailList && res.neftDetailList.length > 0) {
              res.neftDetailList.forEach(data => {
                this.neftList.push(data);
                if (this.neftList.length > 0 && this.neftList[0].id)
                  this.selectedNeftId = this.neftList[0].id;
              });
            }
          }
          this.loader = false;
          resolve(res);
        })
        .catch(error => {
          this.loader = false;
          reject(error);
        });
    });
  }

  @action
  makeReturn() {
    const payload = this.getReturnPayload();
    return new Promise((resolve, reject) => {
      makeReturn(JSON.stringify(payload))
        .then(res => {
          this.returnId = res.returnId;
          this.returnConsignmentId = res.returnConsignmentId;
          this.retunLocation = res.returnLocation;
          resolve(res);
        })
        .catch(error => {
          this.returnId = " Rf0da72";
          this.returnConsignmentId = "Cccc45b";
          this.returnLocation = {
            city: "Bangalore",
            postCode: "560083",
            state: "Karnataka",
            streetName: "Sakalavara Post, Hullahalli",
            streetNo: "S.No. 178/2, C.K. Palya",
            town: "Bangalore",
          };
          reject(error);
        });
    });
  }

  @action
  makeCancel() {
    const payload = this.getCancelPayload();
    return new Promise((resolve, reject) => {
      makeCancel(JSON.stringify(payload))
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  setloginModal(value) {
    this.isModalOpen = value;
  }

  @action
  setReasons(reasonId, comment) {
    this.selectedReasonId = reasonId;
    this.comment = comment;
  }

  @action
  setItemId(itemId) {
    this.itemId = itemId;
  }

  @action
  setReturnAddressId(addressId) {
    this.selectedReturnAddressId = addressId;
  }

  @action
  setReturnMode(option) {
    this.selectedReturnMode = option;
  }

  @action
  addNEFTDetails(NEFT) {
    return new Promise((resolve, reject) => {
      addNEFT(JSON.stringify(NEFT))
        .then(response => {
          const { data } = response;
          if (data && data[0]) {
            this.neftList.unshift(data[0]);
            this.selectedNeftId = data[0].id;
            resolve(data);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  setReturnNeftId(id) {
    this.selectedNeftId = id;
  }

  @action
  downloadForm() {
    const data = this.getReturnFormPayload();
    return new Promise((resolve, reject) => {
      downloadReturnForm(JSON.stringify(data))
        .then(response => {
          const file = new Blob([response], { type: "application/pdf" });
          // //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          if (fileURL && !response.fileURL) {
            response.fileURL = fileURL;
            resolve(response);
          }
          reject();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  @action
  setItemIdAndOrderIdToStore(itemId, orderId) {
    this.itemId = itemId;
    this.orderId = orderId;
  }

  @action
  nukeReturnStoreData() {
    this.comment = "";
    this.isNeftNeeded = false;
    this.itemId = "";
    this.neftList = [];
    this.orderId = "";
    this.reasons = [];
    this.refundAmount = 0;
    this.returnConsignmentId = "";
    this.returnId = "";
    this.returnLocation = "";
    this.returnModes = [];
    this.selectedNeftId = "";
    this.selectedReasonId = null;
    this.selectedReturnAddressId = "";
    this.selfShipFcId = null;
    this.selfShipInfo = {};
    this.invoiceFileUrl = "";
  }

  @action
  makeExchange(data, mail, sizeRequested) {
    const requestBody = this.getExchangeMailPayload(data, mail, sizeRequested);
    return new Promise((resolve, reject) => {
      initiateExchange(JSON.stringify(requestBody))
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default ReturnStoreActions;
