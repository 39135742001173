import { toJS } from "mobx";
import { Toastr } from "arv-reactcomponents";
// eslint-disable-next-line import/no-extraneous-dependencies
import { brandFilterKeys } from "npmlinks-constants";
import { objToQueryParams, isEmptyObject } from "../../utils";

/**
 * forms filter request based on selected filters and query
 * @param {String, Object} searchQuery, filters
 * // TODO need to make api call
 * @return {Object} filter request object
 */
const formSearchRequest = (searchText, filters) => {
  const getSelectedSubMenu = curFilter => {
    if (Array.isArray(toJS(curFilter.list))) {
      return curFilter.list.filter(submenu => submenu.selected);
    }
    return []
      .concat(...Object.values(toJS(curFilter.list)))
      .map(subbrands => subbrands.list)
      .reduce((prev, curr) => prev.concat(curr))
      .filter(item => item.selected);
  };
  const queryObj =
    (filters &&
      filters
        .filter(filter => filter.selectedSubMenu)
        .reduce((prev, curFilter) => {
          // eslint-disable-next-line
          prev[curFilter.filterKeys] = getSelectedSubMenu(curFilter)
            .map(
              l =>
                (l.legalBrandNames &&
                  l.legalBrandNames.join(curFilter.seperator)) ||
                l.value ||
                `${l.min}-${l.max}`,
            )
            .join(curFilter.seperator);
          return prev;
        }, {})) ||
    {};
  if (searchText) {
    queryObj.q = searchText;
  }
  return !isEmptyObject(queryObj) ? objToQueryParams(queryObj) : "";
};

const inputValidSearchParams = () => {
  Toastr.showToastr({
    className: "nwc-toastr-list-danger nw-toastr",
    message: `Please input something to search...`,
    timeout: 3000,
  });
  return null;
};

const processFilterResponce = (inputArray, isDesktop) =>
  inputArray.map((tempIcon, index) => {
    const icon = tempIcon;
    icon.isActive = false;
    icon.id = index;
    icon.isBackgroundHidden = false;
    if (icon.displayName === "Color") {
      icon.class = {
        default: "icomoon-swatch",
        selected: "icomoon-check",
      };
      icon.backgroundColor = "#98d6d2";
      icon.filterKeys = "f-co";
      icon.seperator = "--";
    }
    if (icon.displayName === "Price") {
      icon.id = index;
      icon.backgroundColor = "#f2258b";
      icon.filterKeys = "f-pr";
      icon.class = {
        default: "icomoon-money",
        selected: "icomoon-check",
      };
      icon.seperator = "-";
    }
    if (icon.displayName === "Occasion") {
      icon.id = index;
      icon.class = {
        default: "icomoon-birthday-cake",
        selected: "icomoon-check",
      };
      icon.backgroundColor = "#ea0020";
      icon.filterKeys = "f-oc";
      icon.seperator = "--";
    }
    if (icon.displayName === "Brand") {
      icon.id = index;
      icon.class = {
        default: "icomoon-pricetag",
        selected: "icomoon-check",
      };
      if (isDesktop) {
        const [x] = brandFilterKeys;
        icon.activeBrand = x;
      } else {
        icon.activeBrand = brandFilterKeys.join();
      }

      icon.backgroundColor = "#f76e00";
      icon.filterKeys = "f-br";
      icon.seperator = "--";
      icon.list = brandFilterKeys
        .join()
        .split("")
        .reduce((prev, alphabet) => {
          // eslint-disable-next-line no-param-reassign
          prev[alphabet] = {
            key: alphabet,
            list: icon.list.filter(
              cur =>
                cur.displayName &&
                cur.displayName[0] &&
                cur.displayName[0].toUpperCase() === alphabet,
            ),
          };
          return prev;
        }, {});
    }
    return icon;
  });

export { formSearchRequest, inputValidSearchParams, processFilterResponce };
