const validate = html => {
  const doc = document.createElement("div");
  doc.innerHTML = html;
  return doc.innerHTML === html;
};
const sanitizeHTML = (values = "") =>
  values.replace(/</g, "&lt;").replace(/>/g, "&gt;");

const HTMLUtils = {
  validate,
  sanitizeHTML,
};
export default HTMLUtils;
