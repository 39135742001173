import { action } from "mobx";

import { isWishList, readCookie } from "../../utils";
import { Toastr } from "arv-reactcomponents";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import {
  wishList,
  getWishList,
  deleteWishList,
  setBrandWishList,
  getBrandWishList,
  deleteFavBrand,
  deleteFavProduct,
} from "./service";
import { pageType } from "npmlinks-constants";

class WishlistActions {
  @action
  wishList(styleId = null) {
    const iswishList = isWishList(styleId);
    if (iswishList) {
      return wishList(iswishList)
        .then(res => {
          sessionStorage.removeItem("wishList");
          if (res && res.status === true && res.data === "alreadyAdded") {
            Toastr.showToastr({
              className: "nwc-toastr-list-notification nw-toastr",
              message: "Oops! This product is already in your favourites",
              timeout: 3000,
            });
          } else if (res.status === false) {
            Toastr.showToastr({
              className: "nwc-toastr-list-danger nw-toastr",
              message: "Oops! This item has not been added to your favourites.",
              timeout: 3000,
            });
          } else {
            const category =
              window.pageType === pageType.pdp
                ? Event.category.PRODUCT_DESCRIPTION_PAGE
                : Event.category.LISTING_PAGE;
            Analytics.trackEvent({
              action: Event.action.SHORLIST_SUCCESS,
              label: styleId,
              category,
            });

            Toastr.showToastr({
              className: "nwc-toastr-list-success nw-toastr",
              message:
                "Good mooove! This item has been added to your favourites.",
              timeout: 3000,
            });
            const [product] = res.data;
            const { id, styleId: style } = product;
            this.wishListData.push({ styleId: style, favouriteId: id });
          }
          return res;
        })
        .catch(() => {
          Toastr.showToastr({
            className: "nwc-toastr-list-danger nw-toastr",
            message: "Oops! This item has not been added to your favourites.",
            timeout: 3000,
          });
          return false;
        });
    }
    return false;
  }

  @action
  getWishList() {
    return getWishList().then(
      res => {
        this.favoritesData = res.data && res.data.products;
        this.wishListData = res.data.products.map(product => {
          const { styleId, favouriteId } = product;
          return {
            styleId,
            favouriteId,
          };
        });
        return res;
      },
      err => {
        this.favoritesData = [];
        return err;
      },
    );
  }

  @action
  deleteWishList(favouriteId) {
    const body = JSON.stringify({ favouriteId, favtype: "products" });
    return deleteWishList(body).then(res => {
      const { styleId } = res.data;
      const category =
        window.pageType === pageType.pdp
          ? Event.category.PRODUCT_DESCRIPTION_PAGE
          : Event.category.LISTING_PAGE;
      Analytics.trackEvent({
        action: Event.action.SHORLIST_REMOVED,
        label: styleId,
        category,
      });
      this.wishListData = this.wishListData.filter(
        product => product.styleId !== styleId && product.id !== favouriteId,
      );
      return res;
    });
  }
  @action
  getfavBrand() {
    getBrandWishList().then(response => {
      const brandObject =
        (response && response.data && response.data.brand) || null;
      this.brandWishListData =
        (brandObject &&
          Object.keys(brandObject).map(item => {
            const brandId =
              (brandObject[item] && brandObject[item].brand) || "";
            return { brandId, id: item };
          })) ||
        [];
    });
  }
  @action
  brandWishList(inputBrand) {
    const brand = inputBrand || this.tempWishListBrand;
    if (!brand) {
      return null;
    }
    const body = JSON.stringify([{ brand }]);
    return setBrandWishList(body).then(response => {
      this.brandWishListData = response.data;
      return response;
    });
  }
  @action
  setTempBrandWishList(brand) {
    this.tempWishListBrand = brand;
  }
  @action
  deleteFavBrand(brandId) {
    const { id } = this.brandWishListData.find(
      item => item.brandId === brandId,
    );
    const index = this.brandWishListData.findIndex(
      item => item.brandId === brandId,
    );
    return deleteFavBrand(id).then(response => {
      this.brandWishListData.splice(index, 1);
      return response;
    });
  }

  @action
  deleteFavProduct(product) {
    const { favouriteId } = product;
    const body = JSON.stringify({ favtype: "products", favouriteId });
    return deleteFavProduct(body).then(response => {
      const deletedProductStyleId = response.data && response.data.styleId;
      this.favoritesData = this.favoritesData.filter(
        product => product.styleId !== deletedProductStyleId,
      );
      return response;
    });
  }
}

export default WishlistActions;
