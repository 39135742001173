import { action } from "mobx";
import * as Service from "./walletStore.Service";
import { readCookie } from "../../utils";

class WalletStoreActions {
  @action
  setSelectedWallet(walletCode) {
    this.selectedWalletCode = walletCode;
  }

  //TODO:: for now not using needs to check in version2
  @action
  setWalletMobileNumber(mobileNumber) {
    this.walletMobileNumber = mobileNumber || readCookie("walletMobileNumber");
  }

  @action
  updateSelectedWallet(walletInfo) {
    this.walletOptions[this.selectedWalletCode] = Object.assign(
      {},
      { ...this.walletOptions[this.selectedWalletCode] },
      { ...walletInfo },
    );
  }

  @action
  toggleWalletOptions(wallet) {
    this.initEWalletOptions(this.eWalletOptions);
    this.walletSelection[wallet] = true;
    this.setSelectedWallet(wallet);
  }


  @action
  initWalletOptions(walletOptions = {}) {
    this.walletOptions = Object.assign({}, walletOptions);
  }

  @action
  initEWalletOptions(eWalletOptions = {}) {
    this.eWalletOptions = Object.assign({}, eWalletOptions);
    const walletOptionsObject = {};
    Object.keys(eWalletOptions).forEach(wallet => {
      walletOptionsObject[wallet] = false;
    });
    this.walletSelection = Object.assign({}, walletOptionsObject);
  }
  
  /**
   * after getting Insufficeint  balance response from makepaymentv2 api we have to store this info so that we can submit this info to payu
   * @param successData
   */
  @action
  setSuccessFlow(paymentResponse ) {
    this.addMoneyRequestBody = paymentResponse;
    this.success = Object.assign({}, this.success, paymentResponse);
  }

  @action
  setEWalletFlow(paymentResponse) {
    this.walletSuccess = Object.assign({}, paymentResponse);
  }


  @action
  setPaytmOtpVerified(verifiedFlag){
    this.paytmOtpVerified = verifiedFlag;
  }
}

export default WalletStoreActions;
