import React from "react";

// Import Depedency Component Here

import LoginV1 from "./LoginV1";
import LoginV2 from "./LoginV2";

function Login() {
  return process.env.REACT_APP_LOGIN_VERSION === "v2" ? <LoginV2.component /> : <LoginV1.component />;
}

export { default as SocialLoginWebView } from "./SocialLoginWebView";
export { default as LoginV1 } from "./LoginV1";
export { default as LoginV2 } from "./LoginV2";
export { default as EmailInput } from "./EmailInput";
export { default as ForgotPassword } from "./ForgotPassword";
export { default as MissedFields } from "./MissedFields";
export { default as MobileNumberInput } from "./MobileNumberInput";
export { default as PasswordInput } from "./PasswordInput";
export { default as ResetPassword } from "./ResetPassword";
export { default as SocialLoginError } from "./SocialLoginError";
export { default as UserId } from "./UserId";
export { default as UserNameInput } from "./UserNameInput";
export { default as CountDown } from "./CountDown"
 
const getFinalComponent = component => component;

export const ProjectComponent = {
  getFinalComponent,
  wrapper: Login,
  component: Login,
};

export default ProjectComponent.component;