import React from "react";
import { PropTypes } from "prop-types";
import ColorIcon from "./colors";
import GenericIcon from "./generic";
import BrandIcon from "./brand";

function IconSpecific(props) {
  let res;
  switch (props.selectedIcon.filterType) {
    case "Color":
      res = (
        <ColorIcon.component
          key={props.selectedIcon.filterType}
          icon={props.selectedIcon}
          selectElementAndFilter={props.selectElementAndFilter}
          className={props.className}
        />
      );
      break;
    case "Price":
    case "Occasion":
      res = (
        <GenericIcon.component
          key={props.selectedIcon.filterType}
          icon={props.selectedIcon}
          selectElementAndFilter={props.selectElementAndFilter}
          className={props.className}
        />
      );
      break;
    case "Brand":
      res = (
        <BrandIcon.component
          key={props.selectedIcon.filterType}
          icon={props.selectedIcon}
          selectElementAndFilter={props.selectElementAndFilter}
          toggleActiveBrand={props.toggleActiveBrand}
          className={props.className}
        />
      );
      break;
    default:
      res = <div />;
  }
  return res;
}
IconSpecific.defaultProps = {
  selectedIcon: {},
};
IconSpecific.propTypes = {
  selectedIcon: PropTypes.shape({
    filterType: PropTypes.string,
    class: PropTypes.shape({}),
    backgroundColor: PropTypes.string,
    list: PropTypes.shape([]),
    isActive: PropTypes.bool,
  }),
  selectElementAndFilter: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export { default as IconSpecificBrand } from "./brand";
export { default as IconSpecificColors } from "./colors";
export { default as IconSpecificGeneric } from "./generic";

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: IconSpecific,
  component: IconSpecific,
};

export default ProjectComponent;
