import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import {Button, Img} from "arv-reactcomponents";

import {ROUTE_PATHS, GETAPP, Endpoints} from "npmlinks-constants";
import Anchor from "../../../component/Anchor";
import { GTM } from "../../../analytics";
import GTMEvent from "../../../analytics/eventFactory/GTMEvents";

@inject("CommonStore", "LoginStore", "MybagStore")
@observer
class MobileAction extends Component {
  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.reset = this.reset.bind(this);
    this.triggerGTMOnClick = this.triggerGTMOnClick.bind(this);
  }

  triggerGTMOnClick(e, eventName) {
    GTM.pushEvent(eventName);
  }

  onLogin() {
    this.props.LoginStore.setloginModal(true);
    this.reset();
    this.triggerGTMOnClick(GTMEvent.LOGIN_CLICKED);
  }

  get getClassName() {
    return this.props.CommonStore.isBrandPage
      ? "nw-mobileactionv2-brand-span"
      : "nw-mobileactionv2-header-span";
  }
  get hideForBrand() {
    return (
      (this.props.CommonStore.isBrandPage &&
        this.props.className === "nw-nnnow-header" &&
        "nwc-hide") ||
      ""
    );
  }
  get isAccount() {
    return this.props.LoginStore.isUserLoggedIn ? "" : "nwc-hide";
  }

  logout() {
    this.props.LoginStore.logoutAction();
    this.props.MybagStore.clearCart();
    if (window.pageType === "orderconfirmation") {
      window.location = "/";
    }
  }
  reset() {
    this.props.reset();
  }

  render() {
    return (
      <li>
        <ul className={`nw-mobileactionv2-list ${this.hideForBrand}`}>
          <li className={`nw-mobileactionv2-links ${this.isAccount}`}>
            <Anchor.component
              href={`${ROUTE_PATHS.MYORDERS}`}
              to={`${ROUTE_PATHS.MYORDERS}`}
              target="_self"
              onClick={e => this.triggerGTMOnClick(e, GTMEvent.MYORDER_CLICK)}
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-delivery-web nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  MY ORDERS{" "}
                </span>
              </Button>
            </Anchor.component>
          </li>
          <li className={`nw-mobileactionv2-links ${this.isAccount}`}>
            <Anchor.component
              href={`${ROUTE_PATHS.ACCOUNT}`}
              to={`${ROUTE_PATHS.ACCOUNT}`}
              target="_self"
              onClick={e => this.triggerGTMOnClick(e, GTMEvent.MYACCOUNT_CLICK)}
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-person1 nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  MY ACCOUNT{" "}
                </span>
              </Button>
            </Anchor.component>
          </li>
         <li className={`nw-mobileactionv2-links ${this.isAccount}`}>
            <Anchor.component
              href={`${ROUTE_PATHS.LOYALTY}`}
              to={`${ROUTE_PATHS.LOYALTY}`}
              target="_self"
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-loyalty_main nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  MY LOYALITY{" "}
                </span>
              </Button>
            </Anchor.component>
          </li>
          <li className="nw-mobileactionv2-links">
            <Anchor.component
              href={`${ROUTE_PATHS.STORELOCATOR}`}
              to={`${ROUTE_PATHS.STORELOCATOR}`}
              onClick={e => this.triggerGTMOnClick(e, GTMEvent.STORE_LOCATOR_CLICK)}
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-marker3 nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  STORE LOCATOR{" "}
                </span>
              </Button>
            </Anchor.component>
          </li>
          <li className="nw-mobileactionv2-links">
            <Anchor.component
              href={`${GETAPP.PATH}`}
              to={`${GETAPP.PATH}`}
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-get-app nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  {GETAPP.DESKTOP_LABEL}
                </span>
              </Button>
            </Anchor.component>
          </li>
          <li className="nw-mobileactionv2-links">
            <Anchor.component
              href={`${ROUTE_PATHS.CONTACTUS}`}
              to={`${ROUTE_PATHS.CONTACTUS}`}
              target="_self"
              onClick={e => this.triggerGTMOnClick(e, GTMEvent.CONTACTUS_CLICK)}
            >
              <Button className="nw-mobileactionv2-button" onClick={this.reset}>
                <i className="icomoon-phone nw-mobileactionv2-icon" />
                <span className={`nw-mobileactionv2-text ${this.getClassName}`}>
                  {" "}
                  CONTACT US{" "}
                </span>
              </Button>
            </Anchor.component>
          </li>
        </ul>
      </li>
    );
  }
}

MobileAction.defaultProps = {
  className: "",
};

MobileAction.wrappedComponent.propTypes = {
  reset: PropTypes.func.isRequired,
  MybagStore: PropTypes.shape({
    clearCart: PropTypes.func.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isBrandPage: PropTypes.bool,
  }).isRequired,
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool,
    setloginModal: PropTypes.func.isRequired,
    logoutAction: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

const getFinalComponent = component =>
  inject("CommonStore", "LoginStore", "MybagStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: MobileAction.wrappedComponent,
  component: MobileAction,
};

export default ProjectComponent;
