import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import PriceInfoBlock from "../PriceInfoBlock";
import { ToCurrency } from "../../../utils";

class PriceBlock extends PureComponent {
  get discountText() {
    const { discountRange, selectedSku } = this.props;
    if (discountRange) {
      const { min, max } = discountRange;
      const { discountInPercentage, skuId } = selectedSku;
      if (skuId && discountInPercentage && discountInPercentage !== 0) {
        return ` (${discountInPercentage}% Off)`;
      }
      if (!skuId && (min !== 0 || max !== 0)) {
        return (min === max && ` (${min}% Off)`) || ` (Upto ${max}% Off)`;
      }
    }
    return null;
  }
  get tnc() {
    const displayPromotion =
      this.props.promotions &&
      this.props.promotions.filter(promotion => promotion.displayDiscount);
    return (
      (displayPromotion && displayPromotion[0] && displayPromotion[0].tnc) ||
      "Promotion Till Stocks Last"
    );
  }
  get terms() {
    return this.props.showTC && this.discountText ? (
      <div className="nw-price-terms">
        <span className="nw-promo-tc">T&C</span>
        <div className="nw-price-hover">{this.tnc}</div>
      </div>
    ) : (
      ""
    );
  }

  get mrpText() {
    const { mrpRange, sellingPriceRange, selectedSku } = this.props;
    if (sellingPriceRange && mrpRange) {
      const { min: mrpMin, max: mrpMax } = mrpRange;
      const { min: spMin, max: spMax } = sellingPriceRange;
      const { mrp } = selectedSku;

      if (this.discountText !== null) {
        return (
          (mrp && ToCurrency(mrp)) ||
          (mrpMin === mrpMax && spMin === spMax && ToCurrency(mrpMax)) ||
          ""
        );
      }
    }
    return null;
  }

  get sellingPriceText() {
    const { mrpRange, sellingPriceRange, selectedSku } = this.props;
    if (sellingPriceRange && mrpRange) {
      const { min: mrpMin, max: mrpMax } = mrpRange;
      const { min: spMin, max: spMax } = sellingPriceRange;

      const minVal = Math.min(mrpMin, spMin);
      const maxVal = Math.max(mrpMax, spMax);

      const { price, skuId } = selectedSku;
      if (skuId && price) {
        return ToCurrency(price);
      }
      return (
        (mrpMin === mrpMax && spMin === spMax && ToCurrency(spMax)) ||
        `${ToCurrency(minVal)} - ${ToCurrency(maxVal)}`
      );
    } else if (mrpRange) {
      const { min: mrpMin, max: mrpMax } = mrpRange;
      return (
        (mrpMin === mrpMax && ToCurrency(mrpMax)) ||
        `${ToCurrency(mrpMin)} - ${ToCurrency(mrpMax)}`
      );
    }
    return null;
  }

  get hasDiscountClassName() {
    return (this.discountText !== null && "is-having-discount") || "";
  }

  get showPriceBlock() {
    const {
      selectedSku: { skuId },
      sellingPriceRange,
    } = this.props;
    if (
      skuId ||
      (sellingPriceRange &&
        (sellingPriceRange.min > 0 || sellingPriceRange.max > 0))
    ) {
      return "";
    }
    return "nwc-hide";
  }

  get priceInfoBlock() {
    const {
      showPriceInfoBlock,
      styleId,
      mrpRange,
      sellingPriceRange,
    } = this.props;
    if (showPriceInfoBlock && sellingPriceRange && mrpRange) {
      return (
        <PriceInfoBlock.component
          mrpText={this.mrpText}
          sellingPriceText={this.sellingPriceText}
          discountText={this.discountText}
          mrpRange={mrpRange}
          sellingPriceRange={sellingPriceRange}
          hasDiscountClassName={this.hasDiscountClassName}
          styleId={styleId}
        />
      );
    }
    return null;
  }

  render() {
    const { className, ProductLoader } = this.props;
    return (
      <div className={`nw-priceblock ${className} ${this.showPriceBlock}`}>
        {ProductLoader ? (
          <div className="nwc-contentloader" />
        ) : (
          <div className="nw-priceblock-container">
            {(this.mrpText && (
              <del
                className={`
                nw-priceblock-amt
                nw-priceblock-mrp
                ${this.hasDiscountClassName}
              `}
              >
                {this.mrpText}
              </del>
            )) ||
              null}
            <span
              className={`
                nw-priceblock-amt
                nw-priceblock-sellingprice
                ${this.hasDiscountClassName}
              `}
            >
              {this.sellingPriceText}
            </span>
            <span
              className={`nw-priceblock-discount ${this.hasDiscountClassName}`}
            >
              {this.discountText}
            </span>
            {this.terms}
            {this.priceInfoBlock}
          </div>
        )}
      </div>
      
    );
  }
}

PriceBlock.defaultProps = {
  promotions: [],
  className: "",
  selectedSku: {
    mrp: null,
    price: null,
    discountInPercentage: null,
    skuId: "",
  },
  ProductLoader: false,
  discountRange: {
    min: 0,
    max: 0,
  },
  mrpRange: {
    min: 0,
    max: 0,
  },
  sellingPriceRange: {
    min: 0,
    max: 0,
  },
  showTC: false,
  showPriceInfoBlock: false,
};

PriceBlock.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  ProductLoader: PropTypes.bool,
  showTC: PropTypes.bool,
  selectedSku: PropTypes.shape({
    mrp: PropTypes.number,
    price: PropTypes.number,
    discountInPercentage: PropTypes.number,
    skuId: PropTypes.string,
  }),
  discountRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  mrpRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  sellingPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  showPriceInfoBlock: PropTypes.bool,
  styleId: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: PriceBlock,
  component: PriceBlock,
};

export default ProjectComponent;
