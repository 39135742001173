import { observable, computed } from "mobx";
import { PAYMENTPAGE } from "npmlinks-constants";
import CardActions from "../CreditCardStore/actions";
import { setSurlFurl, paymentBaseRequest } from "../../utils";

/**
 * userEntered Data --- userEnteredCardData
 * based on userEnter Data ( pick 6 digits of cards)  verified data got from server-- binInfo
 * (place order, makepaymentv2 api ) when payment got successful then response got from server so that ( after replacing some values) we can submit this info to Payment gateway--
 * this data will be stored in ---  success
 */
class CreditCardClass extends CardActions {
  constructor() {
    super();
    this.cardInfoEntered = false;
  }
  @observable
  userEnteredCardData = {
    cardNumber: "",
    cardHolderName: "",
    cvv: null,
    expYear: null,
    expMonth: "",
    isSavedCard: true,
  };
  @observable success = {};
  @observable
  binInfo = {
    cvvLength: PAYMENTPAGE.CARD.CVVLENGTH.DEFAULT,
    cardInfo: {},
  };
  @observable cardData = {};
  @observable binNumber = null;
  @computed
  get successWithUserEnteredCardData() {
    const { userEnteredCardData } = this;
    const responseDataWithUserEnteredValue = Object.assign({}, this.success);
    if (responseDataWithUserEnteredValue && responseDataWithUserEnteredValue.transactions) {
      const { banking } = responseDataWithUserEnteredValue.transactions;
      if (banking && banking.requestParameters && banking.requestParameters.length) {
        let { requestParameters } = banking;
        requestParameters = requestParameters.map(paramTemp => {
          let param = Object.assign({}, paramTemp);
          param = setSurlFurl(param, responseDataWithUserEnteredValue.orderId);

          switch (param.key) {
            case "CCNUM":
              param.info.pgPValue = userEnteredCardData.cardNumber;
              break;
            case "CCNAME":
              param.info.pgPValue = userEnteredCardData.cardHolderName;
              break;
            case "CCVV":
              param.info.pgPValue = userEnteredCardData.cvv;
              break;
            case "CCEXPYR":
              param.info.pgPValue = userEnteredCardData.expYear;
              break;
            case "CCEXPM":
              // PAYU accepts months in two digits format in preprod
              param.info.pgPValue = userEnteredCardData.expMonth;
              break;
            default:
              break;
          }
          return param;
        });
        return responseDataWithUserEnteredValue;
      }
    }
    return null;
  }

  /**
   * for making place order call we need payment info that we construct here
   * @returns {{ paymentDetail: {bankCode: *, description: string, saveCard: *, savedCardPG: null, savedCardToken: null}, paymentTypeCode: string}}
   */
  @computed
  get paymentRequest() {
    const { paymentTypeInfo, typeCode }  = this.binInfo && this.binInfo.cardInfo;
    const cards = this.cardData[typeCode];
    const { pgList = [], pgParameters = {} } = this.cardData;
    const selectedCard = cards && cards.find(card => card.bankCode === paymentTypeInfo.bankCode) || [];  
    let requestedPG = selectedCard && selectedCard.requestedPG;
    let razorpayKey = selectedCard && selectedCard.pgRequestParameters && selectedCard.pgRequestParameters.RAZORPAY_KEY || '';
    if(!this.binInfo.cardInfo.isFound && pgList && pgList.includes(PAYMENTPAGE.PAYMENT_GATEWAYS.RAZORPAY)) {
      requestedPG = PAYMENTPAGE.PAYMENT_GATEWAYS.RAZORPAY;
      razorpayKey = pgParameters.RAZORPAY_KEY;
    }
    return {
      paymentDetail: paymentBaseRequest(
        Object.assign({}, paymentTypeInfo, {
          saveCard: this.userEnteredCardData.isSavedCard,
          description: PAYMENTPAGE.CARDPAYMENTDESCRIPTION,
        }),
      ),
      paymentTypeCode: this.binInfo.cardInfo.typeCode,
      requestedPG,    
      razorpayKey,        
    };
  }
}

export default CreditCardClass;
