/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-destructuring */

const indexify = (
  sectionIndex,
  componentIndex,
  item,
  templateType,
  isDesktop,
) => {
  const returnItem = Object.assign({}, item);
  returnItem.sectionIndex = sectionIndex;
  returnItem.componentIndex = item.type === "section" ? null : componentIndex;
  if (!item || !item.children || item.children.length === 0) {
    return [componentIndex, returnItem];
  }
  let cIndex = componentIndex;
  let children = [];
  if (item.ltr === false && isDesktop) {
    children = item.children.reverse();
  } else if (isDesktop && templateType !== "BaseTemplate") {
    children = item.children.sort((a, b) => a.dPos - b.dPos);
  } else {
    children = item.children;
  }
  returnItem.children = children.map((child, iterationIndex) => {
    const newIndex =
      templateType !== "BaseTemplate"
        ? iterationIndex
        : child.type === "section"
        ? cIndex
        : cIndex + 1;
    const [index, innterItem] = indexify(
      sectionIndex,
      newIndex,
      child,
      templateType,
      isDesktop,
    );
    cIndex = index;
    return innterItem;
  });
  return [cIndex, returnItem];
};
const indexifyResponse = (initArray = [], templateType, isDesktop) =>
  initArray
    .filter(item => item.type)
    .map((item, index) => {
      const [, sitem] = indexify(index, -1, item, templateType, isDesktop);
      return sitem;
    });

export { indexifyResponse };
