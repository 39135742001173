/* eslint-disable */
// import https from "https";
import fetch from "isomorphic-fetch";
import { Utils } from "arv-reactcomponents";
import {
  Endpoints,
  blackBoltUrl,
  notToRetryURL,
  blackBoltVersion,
  RETURNS,
  MAIN_CONFIG,
  STORAGE_KEYS
} from "npmlinks-constants";
import Analytics from "../analytics";
import Event from "../analytics/eventFactory";
import {
  readCookie,
  eraseCookie,
  createCookie,
  getClientUniqueId,
  getCorrelationId,
  authHeader,
  allUrlQueryParams
} from "../utils";

const { RETURN_COMPLETE } = RETURNS;

require("es6-promise").polyfill();

let fetchTokenPromise = null;

function handleNewErrors(response, requestBody, type, isSSRCall) {
  try {
    if (response.status === 504) {
      console.log("504 error from client side ==>", JSON.stringify(response));
    }

    if (!response.ok && response.status !== 401) {
      const timestamp = new Date().toUTCString();
      const responseTime = `res1 ${timestamp}`;
      const errorObj = {
        url: response.url,
        clientSessionId: requestBody.headers.clientSessionId || null,
        correlationId:
          requestBody.headers.correlationid ||
          requestBody.headers.correlationId ||
          null,
        statusCode: response.status,
        requestTime: response.requestTime || "",
        responseTime: responseTime || "",
        message: response.message || ""
      };

      if (isSSRCall) {
        const errorMailObj = {
          statusCode: response.status,
          error: {
            message: response.message,
            correlationId:
              requestBody.headers.correlationid ||
              requestBody.headers.correlationId ||
              null
          },
          options: {
            uri: response.url,
            json: requestBody,
            requestTime: response.requestTime || ""
          }
        };
        triggerMail(errorMailObj);
      }

      Analytics.trackEvent({
        category: Event.category.API_FAIL,
        action: Event.action.getApiFailUrl(requestBody.method, response.url),
        label: Event.label.getApiFailObj(errorObj)
      });
    }
    if (response.controller) {
      response.controller.abort();
      return null;
    }
    if (type === RETURN_COMPLETE.DOWNLOAD_FORM_TITLE) {
      return Promise.all([response.ok, response.blob(), response.status]);
    }
    return Promise.all([
      response.ok,
      response.json().catch(
        _ =>
          response.text().then(function(text) {
            // do something with the text response
            return `${text} correlationId: ${requestBody.headers.correlationid}`;
          }),
        function(err) {
          return ` correlationId: ${requestBody.headers.correlationid}`;
        }
      ),
      response.status
    ]);
  } catch (err) {
    if (isSSRCall) {
      const errorMailObj = {
        statusCode: response.status,
        error: {
          message: "Handle new errors failed",
          correlationId:
            requestBody.headers.correlationid ||
            requestBody.headers.correlationId ||
            null
        },
        options: {
          uri: response.url,
          json: requestBody
        }
      };
      triggerMail(errorMailObj);
    }

    return err;
  }
}

function triggerMail(body) {
  return post("errorMail", {}, JSON.stringify(body));
}

const getTokens = () => {
  const body = JSON.stringify({
    grant_type: "refresh_token",
    refresh_token: readCookie("refreshToken")
  });
  return post("login", {}, body)
    .then(res => {
      const {
        access_token: accessToken,
        refresh_token: refreshToken
      } = res.data;
      createCookie("accessToken", accessToken);
      createCookie("refreshToken", refreshToken);
      return accessToken;
    })
    .catch(err => {
      Analytics.trackEvent({
        action: Event.action.REFRESH_TOKEN_FAIL,
        category: Event.category.LOGIN
      });
      Analytics.trackEvent({
        action: Event.action.LOGOUT,
        category: Event.category.LOGIN,
        interaction: true
      });

      ["userName", "accessToken", "refreshToken", "cust_email"].forEach(val => {
        eraseCookie(val);
      });
      window.localStorage.removeItem("user_email");
      Utils.localStorage.removeItem(STORAGE_KEYS.RECENTLY_VIEWED);
      window.location.reload();
    });
};

function retry(url, requestBody, type) {
  if (!fetchTokenPromise) {
    fetchTokenPromise = getTokens();
  }
  return fetchTokenPromise
    .then(accessToken => {
      fetchTokenPromise = null;
      const { authorization } = authHeader(accessToken);
      requestBody.headers.authorization = authorization;
      return makeApiCall(url, requestBody, type);
    })
    .catch(err => {
      fetchTokenPromise = null;
      throw err;
    });
}

function handleTimeout(url, requestBody, type) {
  let controller = null;
  if (window && window.AbortController) {
    controller = new window.AbortController();
    const signal = controller.signal;
    requestBody.signal = signal;
  }
  if (
    !requestBody.headers.requrl ||
    requestBody.headers.requrl === "undefined"
  ) {
    delete requestBody.headers.requrl;
  }
  return Promise.race([
    fetch(url, requestBody, type),
    new Promise(resolve =>
      setTimeout(() => {
        const errorObj = {
          url,
          clientSessionId: requestBody.headers.clientSessionId || null,
          correlationId:
            requestBody.headers.correlationid ||
            requestBody.headers.correlationId ||
            null,
          statusCode: 504
        };
        return resolve({
          status: 504,
          url,
          controller: controller,
          message: "API cancelled from UI",
          error: errorObj
        });
      }, MAIN_CONFIG.DEF_API_TIMEOUT)
    )
  ]);
}

function makeApiCall(url, requestBody, type, isSSRCall) {
  var startDate = new Date();
  return handleTimeout(url, requestBody, type)
    .then(response => handleNewErrors(response, requestBody, type, isSSRCall))
    .then(response => {
      const [err, res, status] = (response && Array.from(response)) || [];
      if (!err) {
        if (
          status === 401 &&
          blackBoltUrl.indexOf(type) === -1 &&
          notToRetryURL.indexOf(type) === -1
        ) {
          return retry(url, requestBody, type);
        }
        throw {
          ...res,
          status
        };
      } else {
        let responseTime = new Date() - startDate;
        if(responseTime && responseTime >= 5000 ){
          const analyticsObj = {
            url: url,
            clientSessionId: requestBody.headers.clientSessionId || null,
            correlationId:
              requestBody.headers.correlationid ||
              requestBody.headers.correlationId ||
              null,
            statusCode: response && response.length && response[2] || "",
            responseTime: responseTime || ""
          };
          Analytics.trackEvent({
            category: Event.category.SLOW_API,
            action: Event.action.getApiFailUrl(requestBody.method,url),
            label: Event.label.getApiFailObj(analyticsObj)
          });
        }
        
        return res;
      }
    });
}

const GetStaticHeaders = headers => {
  const staticHeaders = {
    module: allUrlQueryParams().module || "odin",
    accept: "application/json",
    "Content-Type": "application/json",
    bbversion: blackBoltVersion,
    clientSessionId: getClientUniqueId() || ""
  };
  Object.assign(
    headers,
    {
      correlationId: getCorrelationId()
    },
    staticHeaders
  );
  const env_origin = process.env.REACT_APP_SITEORIGIN;
  if (env_origin && !headers.requrl) {
    headers.requrl = `https://${env_origin}`;
  }
};

const GetStaticHeadersImage = headers => {
  const staticHeaders = {
    module: allUrlQueryParams().module || "odin",
    bbversion: blackBoltVersion,
    clientSessionId: getClientUniqueId() || "",
  };
  Object.assign(
    headers,
    {
      correlationId: getCorrelationId(),
    },
    staticHeaders,
  );
  const env_origin = process.env.REACT_APP_SITEORIGIN;
  if (env_origin && !headers.requrl) {
    headers.requrl = `https://${env_origin}`;
  }
};

const get = (type, customHeaders, param, isSSRCall = false) => {
  let url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "GET",
    headers
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return makeApiCall(url, requestBody, type, isSSRCall);
};

const post = (type, customHeaders, body, param, isSSRCall = false) => {
  let url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "POST",
    headers,
    body
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return makeApiCall(url, requestBody, type, isSSRCall);
};

const postImage = (type, customHeaders, body, param) => {
  let url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders,
  };
  GetStaticHeadersImage(headers);
  const requestBody = {
    method: "POST",
    headers,
    body,
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return makeApiCall(url, requestBody, type);
};

const put = (type, customHeaders, body, param, isSSRCall = false) => {
  let url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "PUT",
    headers,
    body
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return makeApiCall(url, requestBody, type, isSSRCall);
};

const deleteApi = (type, customHeaders, body, param) => {
  let url = `${Endpoints[type]}`;
  const headers = {
    ...customHeaders
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "DELETE",
    headers,
    body
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return makeApiCall(url, requestBody, type);
};
export { get, post, put, getTokens, deleteApi,postImage };
