import { action } from "mobx";

import { serviceNavListData } from "./services";

class NavListActions {
  @action
  setNnnowMenuShow(bool) {
    this.brandPageNnnowMenuShow = bool;
  }

  @action
  setSelectedNav(nav) {
    this.selectedNav = nav;
  }

  @action
  getNavListData(platform, url = "", isSSRCall = false) {
    const requrl =
      url || (window && window.location && window.location.hostname) || "";
    const headers = { platform, requrl };
    return new Promise((resolve, reject) => {
      serviceNavListData(headers, isSSRCall).then(
        action(response => {
          this.navListData = response;
          resolve(response);
        }),
        action(err => {
          // console.log(err);
          this.navListData = {};
          reject(err);
        })
      );
    });
  }
}

export default NavListActions;
