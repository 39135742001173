const ORDERCONFIRMPAGE = {
  SUB_TITLE_TEXT: "THANKS FOR YOUR ORDER ID",
  SUB_TITLE_TEXT_PENDING: "YOUR PAYMENT IS PENDING",
  SUB_TITLE_TEXT_PENDING_H1: "DON'T WORRY, WE'VE GOT THIS!",
  ERROR_MESSAGE: "Oops! Something went wrong while fetching payment status.",
  ORDER_USER_INFO:
    "We’ll soon send you a confirmation email & sms on $mail & $phoneNumber respectively",
  ORDER_DELIVERY_INFO:
    "Having your order delivered? We’ll send you the tracking number when it ships",
  ORDER_USER_INFO_PENDING:
    "We’re currently processing your payment. This usually takes a few minutes but can take up to 30 minutes.",
  ORDER_DELIVERY_INFO_PENDING:
    "You’ll receive an email confirmation once the payment is successful.",
  ORDER_STOREPICK_INFO:
    "Doing a store pick-up? We’ll notify you when your order is ready. Please show your confirmation email at the store",
  ORDER_SUMMARY_BLOCK: {
    TITLE: "Order Summary",
    ORDER_ID: "Order ID",
    ORDER_TOTAL: "Order Total",
    TOTAL_QTY: "Total Qty",
    DATE_PLACED: "Date Placed",
    PAYMENT_TYPE: "Payment",
    EXPECTED_DELIVERY: "Expected Delivery*",
    TRACK_ORDER_BTN: "TRACK ORDER",
    PRINT_ORDER_BTN: "PRINT ORDER",
    ORDER_URL_ENDPOINT: "/orderdetails/"
  },
  PRICING_DETAILS_BLOCK: {
    TITLE: "Pricing Details",
    SUBTOTAL: "Subtotal",
    SHIPPING: "Shipping",
    DISCOUNTS: "Discounts",
    LOYALTY: "Loyalty",
    TAX: "Tax",
    TOTAL: "Total",
    CURRENCY: "₹",
    COD_CHARGES: "COD Charges",
  },
  ORDER_DELIVERY_DETAILS: {
    TITLE: "Delivery Details",
    SUB_TITLE_TEXT: "Your order will be delivered to this address:"
  },
  ORDER_STORE_PICKUP: {
    TITLE: "Store Pick Up",
    SUB_TITLE_TEXT_FIRST:
      "Your order will be confirmed via SMS and email within 30 minutes.",
    SUB_TITLE_TEXT_SECOND:
      "Order will be auto-cancelled if it is not picked up within 7 days"
  },
  PRODUCT_DETAILS_BLOCK: {
    TITLE: "Product",
    STATUS_TITLE: "Status",
    PRICE_TITLE: "Price",
    QTY: "Qty",
    SIZE: "Size",
    BRAND_SIZE: "Brand Size",
    COLOR: "Colour",
    PRICE: "Unit Price",
    NO_IMAGE_TEXT: "No Image Found",
    SHIPPING_METHOD: {
      standard: "Standard Delivery",
      ndd: "Delivery Tomorrow",
      sdd: "Delivery Today",
      STOREPICK: "StorePick"
    }
  }
};

export { ORDERCONFIRMPAGE };
