import { observable, computed } from "mobx";
import LoginStoreAction from "./loginStore.actions";

import { readCookie } from "../../utils";

class LoginStoreClass extends LoginStoreAction {
  @observable isUserLoggedIn = false;
  @observable isModalOpen = false;
  @observable authError = false;
  @observable loader = false;
  @observable authErrorMsg = "";
  @observable missingFieldsLogin = {};
  @observable currentView = "login";
  @observable socialLoader = false;
  @observable userId = "";
  @observable captcha = false;
  @observable disabledbutton = false;
  @observable preventClose = false;
  @observable goBackToLoginSub = false;
  // TODO remove once token gives new data
  @observable accessTokenData = {};
  @observable refreshTokenData = {};
  @observable account = {};
  @observable firstName = "";
  @observable userName = readCookie("userName");
  @observable email = readCookie("cust_email");
  @observable mobileNumber = readCookie("mobileNumber");
  @observable passwordValidationRegex = {
    regex: /^[\S]{8,}$/,
    errorMessage: "Please enter at least 8 characters.",
  };
  @observable passwordRegexLoader = true;
  @observable userExistErrorMsg = "";
  @observable otpAuthErrorMsg ="";
  @observable passwordAuthErrorMsg = "";
  @observable userMobileNumberV2 = "";
  @observable registerWithOTPErrorMsg = "";
  @observable disableSigninButton = false;
  @observable passwordLoginV2Loader = false;
  @observable otpLoginV2Loader = false;
  @observable userEmailV2 = "";
  @observable showOtp = false;
  @observable showHeader = true;
  @observable mobileNoEntered = false;
  @observable hideCloseBtn =false;
  isAuthenticated() {
    return this.isUserLoggedIn;
  }
}
// const LoginStore = new LoginStoreClass();
export default LoginStoreClass;
