import { observable } from "mobx";
import HeaderActions from "./actions";

class HeaderStoreClass extends HeaderActions {
  constructor(props) {
    super(props);
    if (props) {
      this.isFixed1 = props.isFixed;
    }
  }
  @observable isFixed = this.isFixed1 || false;
  @observable isFixedHeaderHidden = false;
}

// const HeaderStoreConst = new HeaderStore();

export default HeaderStoreClass;
