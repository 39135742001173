import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Button, Input, Utils, Toastr } from "arv-reactcomponents";
import { validateEmail } from "../../../utils";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import GTM from "../../../analytics/GTM";
import { EMAIL_SUBSCRIPTION } from "npmlinks-constants";

@inject("FooterStore")
@observer
class EmailSubscriptionv2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subsriberEmail: "",
    };

    this.subsribeSubmit = this.subsribeSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  subsribeSubmit() {
    const { subsriberEmail } = this.state;
    if(!subsriberEmail) {
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Please enter an email",
        timeout: 3000,
      });
      return;
    }
    if (validateEmail(subsriberEmail)) {
      this.props.FooterStore.subscribe(this.state.subsriberEmail);
      Analytics.trackEvent({
        action: Event.action.SUBSCRIBE_USER,
        category: Event.category.FOOTER,
        label: Event.label.getEmailSubscribeLabel(this.state.subsriberEmail),
      });
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Good Mooove! You've Just Signed Up For The Latest Updates",
        timeout: 3000,
      });

      GTM.pushWithEvent(
        {
          emailId: this.state.subsriberEmail
        }, Event.GTMEvent.EMAIL_SUBSCRIBE
      );
        this.setState({subsriberEmail: ""});
    } else {
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Please enter a valid email",
        timeout: 3000,
      });
    }
  }

  handleChange(event) {
    this.setState({ subsriberEmail: event.target.value });
  }
  render() {
    return (
      <div className="nw-newsletter-containerv2">
        <div className="nw-subscription-title">Subscribe to us for exciting offers</div>
        <div className="nw-subscription-containerv2">
          <Input
            onChange={this.handleChange}
            className="nw-subscription-inputv2 nwc-inp-normal"
            type="email"
            placeholder={EMAIL_SUBSCRIPTION.ENTER_EMAIL}
            value={this.state.subsriberEmail}
          />
          <Button
            className="nw-subscription-submitv2 nwc-btn-normal"
            onClick={this.subsribeSubmit}
            onKeyPress={Utils.noop}
          >
            {EMAIL_SUBSCRIPTION.SEND_BTN_TEXT}
          </Button>
        </div>
      </div>
    );
  }
}

EmailSubscriptionv2.wrappedComponent.propTypes = {
  FooterStore: PropTypes.shape({
    subscribe: PropTypes.func,
    isSubscribed: PropTypes.bool,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("FooterStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: EmailSubscriptionv2.wrappedComponent,
  component: EmailSubscriptionv2,
};

export default ProjectComponent;
