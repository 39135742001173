import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import Anchor from "../../component/Anchor";


@inject("NavListStore")
@observer
class PromoText extends Component {

  constructor(props) {
    super(props);
    this.triggerGAOnPromoClick = this.triggerGAOnPromoClick.bind(this);
  }

  triggerGAOnPromoClick() {
    Analytics.trackEvent({
      action: Event.action.PROMO_LINK_CLICKED,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_ABOVE_MENU_CTR,
    });
  }

  get navMetaData() {
    return (
      (this.props.NavListStore.navListData &&
        this.props.NavListStore.navListData.data &&
        this.props.NavListStore.navListData.data.metaData) ||
      {}
    );
  }


  render() {
    
    if (this.navMetaData.topBandMessage) {
      return (
        <Anchor.component
        className="nw-promotext nw-promotext-link"
        href={this.navMetaData.topBandUrl}
        to={this.navMetaData.topBandUrl}
        target="_self"
        onClick={this.triggerGAOnPromoClick}
      >
       <i className="icomoon-star nw-promotext-star nw-promotext-starviolet" />
             <i className="icomoon-star nw-promotext-star nw-promotext-staryellow" />
             <i className="icomoon-star nw-promotext-star nw-promotext-starpink" />
             <div className="nw-promotext-text">
               <span className="nw-promotext-eachtext">
               </span>
               <span className="nw-promotext-eachtext">
                 {this.navMetaData.topBandMessage}
               </span>
             </div>
             <i className="icomoon-star nw-promotext-star nw-promotext-starpink" />
             <i className="icomoon-star nw-promotext-star nw-promotext-staryellow" />
             <i className="icomoon-star nw-promotext-star nw-promotext-starviolet" />
      </Anchor.component>
      );
    }

    return null;
  }
}

PromoText.wrappedComponent.propTypes = {
  NavListStore: PropTypes.shape({
    navListData: PropTypes.object,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: PromoText.wrappedComponent,
  component: PromoText,
};

export default ProjectComponent;
