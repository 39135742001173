import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  GridContainer,
  GridRow,
  GridColumn,
  Button,
  Toastr,
  Ripple,
} from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import FilterIconList from "../FilterIconList";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";

@inject("CommonStore")
@observer
class Filter extends Component {
  constructor(props) {
    super(props);
    this.toggleSearchButton = this.toggleSearchButton.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }
  componentDidMount() {
    const { isDesktop } = this.props.CommonStore;
    this.props.store.getFilters(isDesktop);
  }
  toggleSearchButton(event) {
    this.props.store.toggleSearch();
    Analytics.trackEvent({
      action: Event.action.SEARCH_CLOSE,
      label: Event.label.getSource(window.pageType),
      category: Event.category.SEARCH,
    });
    event.preventDefault();
  }
  handleSearch() {
    this.props.store.handleSearch();
    Analytics.trackEvent({
      action: Event.action.FIND_NOW_CLICK,
      label: Event.label.getSearchQuery(this.props.store.searchText),
      category: Event.category.SEARCH,
    });
  }
  render() {
    const { currentState } = this.props;
    return (
      <div className="nw-search-filter">
        <div className="nw-search-filter-first-row">
          <p className="nw-search-specific-text">Wanna get specific?</p>
          <Button
            className="nw-search-filter-close nwc-btn nwc-close nwc-close-normal nwc-modal-close"
            onClick={this.toggleSearchButton}
          />
        </div>
        <GridContainer className="nw-search-icons">
          <GridRow>
            <GridColumn className="nwc-grid-col-sm-4">
              <FilterIconList.component store={this.props.store} />
            </GridColumn>
            <GridColumn
              className={`nwc-grid-col-sm-4 nw-find-now-button-wrapper ${currentState}`}
            >
              <Ripple className="nwc-ripple-dark">
                <Button
                  className="nw-find-now-button nwc-btn-secondary nwc-btn-xxl"
                  onClick={this.handleSearch}
                >
                  FIND NOW
                </Button>
              </Ripple>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </div>
    );
  }
}
Filter.wrappedComponent.propTypes = {
  currentState: PropTypes.string.isRequired,
  store: PropTypes.shape({
    toggleSearch: PropTypes.func,
    handleSearch: PropTypes.func,
    getFilters: PropTypes.func,
    searchText: PropTypes.string,
    filters: PropTypes.shape([]),
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Filter.wrappedComponent,
  component: Filter,
};

export default ProjectComponent;
