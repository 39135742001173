import { action } from "mobx";
import { PAYMENTPAGE } from "npmlinks-constants";
import { generateOtp, resendOtp, validateOtp, generateOtpV2, resendOtpV2 } from "./service";

class OtpStoreActions {
  /**
   * // //in case of cod this will be taken care by codOtp component
   * // body.otpTemplateId = this.otpClientID;
   * @param body
   * @param throughService
   */
  @action
  //check this- isLoyaltychange?
  generateOtpFunc(body, throughService = PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH) {
    Object.assign(body, {
      otpTemplateId:
      throughService === PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
      ? this.otpClientID
      : undefined,
    });
    return generateOtp(JSON.stringify(body), throughService).then(
      action(res => {
        // console.log(res.data.sessionID);
        this.otpStateInfo = res.data;
        this.sessionID = res && res.data && res.data.sessionID;
        return res.data;
      }),
      action(err => {
        throw err;
      }),
    );
  }

  @action
  //check this- isLoyaltychange?
  generateOtpFuncReg(body, throughService = PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH) {
    Object.assign(body, {
      otpTemplateId:
      throughService === PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
      ? this.otpClientID
      : undefined,
    });
    return generateOtpV2(JSON.stringify(body), throughService).then(
      action(res => {
        // console.log(res.data.sessionID);
        this.otpStateInfo = res;
        this.sessionID = res && res.sessionID;
        return res;
      }),
      action(err => {
        throw err;
      }),
    );
  }

  @action
  resendOtpFunc(body, throughService = PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH) {
    if (throughService === PAYMENTPAGE.OTPTHROUGHSERVICE.HEIMDALL) {
      return this.generateOtpFunc(body, throughService);
    }
    body.otpTemplateId = this.otpClientID;
    body.sessionId = this.sessionID;
    body = JSON.stringify(body);
    // console.log(body);

    return resendOtp(body, throughService).then(
      action(res => {
        return res.data;
      }),
      action(err => {
        throw err;
      }),
    );
  }

  @action
  resendOtpFuncReg(body, throughService = PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH) {
    if (throughService === PAYMENTPAGE.OTPTHROUGHSERVICE.HEIMDALL) {
      return this.generateOtpFunc(body, throughService);
    }
    body.otpTemplateId = this.otpClientID;
    body.sessionId = this.sessionID;
    body = JSON.stringify(body);
    // console.log(body);

    return resendOtpV2(body, throughService).then(
      action(res => {
        return res;
      }),
      action(err => {
        throw err;
      }),
    );
  }

  @action
  validateOtpFunc(body, throughService = PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH) {
    return validateOtp(JSON.stringify(body), throughService).then(
      action(res => {
        return res.data;
      }),
      action(err => {
        this.isCodVerified = false;
        throw err;
      }),
    );
  }

  @action
  setCodVerified(flag = false) {
    this.isCodVerified = flag;
  }
}

export default OtpStoreActions;
