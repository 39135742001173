import { observable, action, computed } from "mobx";
import SavedStoreActions from "../SavedCardStore/actions";
import { setSurlFurl, paymentBaseRequest } from "../../utils";

class SavedStoreClass extends SavedStoreActions {
  @observable savedCards = [];
  @observable selectedSavedCardToken = null;

  @observable
  cvvData = {
    cvv: null,
    isValid: false,
  };
  @observable success = null;

  @computed
  get defaultSavedCard() {
    return this.savedCards && this.savedCards.length
      ? this.savedCards.filter(card => card.isDefault)
      : [];
  }

  @computed
  get selectedSavedCard() {
    return this.savedCards.find(
      card => card.cardToken === this.selectedSavedCardToken,
    );
  }

  @computed
  get successWithUserEnteredCvv() {
    try {
      const selectedSavedCard = this.savedCards.find(
        card => card.cardToken === this.selectedSavedCardToken,
      );
      const responseDataWithUserEnteredValue = Object.assign({}, this.success);
      const { cardCvv } = this.cvvData;
      if (responseDataWithUserEnteredValue && responseDataWithUserEnteredValue.transactions) {
        const { banking } = responseDataWithUserEnteredValue.transactions;
        let { requestParameters } = banking;
        if (requestParameters && requestParameters.length)
          requestParameters = requestParameters.map(paramTemp => {
            let param = Object.assign({}, paramTemp);
            param = setSurlFurl(param, responseDataWithUserEnteredValue.orderId);
            switch (param.key) {
              case "PAYMENTTYPE":
                param.info.pgPValue = selectedSavedCard.cardType;
                break;
              case "CCVV":
                param.info.pgPValue = cardCvv;
                break;
              case "CCEXPYR":
                param.info.pgPValue = selectedSavedCard.expYear;
                break;
              case "CCEXPM":
                param.info.pgPValue = selectedSavedCard.expMonth;
                break;
              default:
                break;
            }
            return param;
          });
        return responseDataWithUserEnteredValue;
      }
      return null;
    } catch (err) {
      // console.log(err);
      return err;
    }
  }

  @computed
  get paymentRequest() {
    // try to use computed value here this.selectedSavedCard
    let selectedSavedCard = this.savedCards.find(
      card => card.cardToken === this.selectedSavedCardToken,
    );
    if (!selectedSavedCard) selectedSavedCard = {};
    return {
      paymentDetail: paymentBaseRequest(
        Object.assign({}, selectedSavedCard, {
          savedCardPG: selectedSavedCard.pgCodeRef,
          savedCardToken: selectedSavedCard.cardToken,
          saveCard: true,
        }),
      ),
      paymentTypeCode: selectedSavedCard.typeCode,
      requestedPG: selectedSavedCard.pgCodeRef || null,
      razorpayKey: selectedSavedCard.pgRequestParameters && selectedSavedCard.pgRequestParameters.RAZORPAY_KEY || '',
    };
  }
}
export default SavedStoreClass;
