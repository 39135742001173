const cdnBasePath = `https://${process.env.REACT_APP_CDNBASEPATH}`;
const PROMOTIONS = {
  APPLY_PROMO_TITLE: "Apply / Select promo code",
  INPUT_PLACEHOLDER: "Enter Promo",
  PROMO_APPLY_BTN: "apply promo",
  APPLY_PROMO_IMAGE:
    `${cdnBasePath}/client/assets/images/promotions/icon_promo.png`,
  APPLY_COUPON_BTN: "APPLY PROMO",
  SAVED_TEXT: "You save ₹",
  ADD_ELIGIBLE_PRODUCTS: "ADD ELIGIBLE PRODUCTS",
  APPLICABLE_PROMOS: "APPLICABLE PROMOS",
  NEW_USER_PROMO:"NEW USER PROMO",
  UNLOCK_TO_AVAIL: "UNLOCK TO AVAIL",
  VALID_TILL: "Valid till :",
  ADD_PRODUCTS: "ADD PRODUCTS",
};

export default PROMOTIONS;
