import React from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { Button, Ripple, Img, Toastr } from "arv-reactcomponents";
import UserId from "../UserId";

import Analytics from "../../../analytics";
import GTM from "../../../analytics/GTM";
import Event from "../../../analytics/eventFactory";
import { blackboltConfig, Login, Endpoints } from "npmlinks-constants";

@inject("LoginStore")
@observer
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.userId = props.LoginStore.userId;
    this.userIdFields = null;
    this.onSubmit = this.onSubmit.bind(this);
    this.showLoginView = this.showLoginView.bind(this);
    this.showRegisterView = this.showRegisterView.bind(this);
  }
  componentDidMount() {
    const invalidInput = document.querySelector(".nw-frgt-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-frgt-form input.is-invalid",
    );
    const { userId, inputType } = this.userIdFields.state;
    if (invalidInput) {
      invalidInput.focus();
      if (!userId) {
        Toastr.showToastr({
          className: "nwc-toastr-list-notification nw-toastr",
          message: Login.LOGIN_MESSAGES.FORGOTPASSWORD_INVALID_CREDENTIALS,
          timeout: 3000,
        });
      }
    }
    if (this.userIdFields.state.isUserIdValid) {
      Analytics.trackEvent({
        action: Event.action.FORGOT_PASSWORD_ATTEMPT,
        category: Event.category.LOGIN,
        label: inputType,
      });
      this.props.LoginStore.forgotPasswordAction(
        userId,
        blackboltConfig[inputType],
      );
    } else {
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: Login.LOGIN_MESSAGES.INVALID_ID,
        timeout: 3000,
      });
    }
  }
  get checkIsFormValid() {
    return this.props.LoginStore.authError ? "" : "nwc-hidden";
  }
  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }
  showLoginView(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.LOGIN_CLICK,
      category: Event.category.LOGIN,
    });
    GTM.pushEvent(Event.GTMEvent.LOGIN_CLICKED);
    this.props.LoginStore.changeView("login");
  }
  showRegisterView(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.REGISTER_CLICK,
      category: Event.category.LOGIN,
      label: Event.label.getSource("home"),
    });
    GTM.pushEvent(Event.GTMEvent.REGISTER_CLICKED);
    this.props.LoginStore.changeView("register");
  }
  render() {
    return (
      <div className="nw-login-container">
        <div className="nw-login-heading">
          <h1 className="nw-login-title">
            {Login.LOGIN_MESSAGES.FORGOTPASSPAGE_TITLE}
          </h1>
          <p className="nw-login-sub-title">
            {Login.LOGIN_MESSAGES.FORGOTPASSPAGE_SUBTITLE}
          </p>
        </div>
        <div className="nw-forgotpassword-img">
          <Img
            className="nw-fpIconImg"
            src={Endpoints.fpImage}
            alt="forgot password"
          />
        </div>
        <form className="nw-form nw-frgt-form" onSubmit={this.onSubmit}>
          <UserId.component
            className="nw-login-input nw-grgt-userid"
            placeholder={Login.LOGIN_MESSAGES.FORGOTPASSPAGE_PH_USERID}
            name="text"
            value={this.userId}
            ref={values => {
              this.userIdFields = values;
            }}
            required
          />
          <Ripple className="nwc-ripple-light">
            <Button
              type="submit"
              className="nwc-btn-primary nw-login-btn nw-grgt-submitbtn"
              onClick={this.onSubmit}
            >
              SUBMIT
              <div className={`nw-login-loader ${this.isShowLoader}`}>
                <Img
                  className="nw-loader"
                  src={Endpoints.LOADER}
                  alt="loader"
                />
              </div>
            </Button>
          </Ripple>
          <span className={`nw-login-error ${this.checkIsFormValid}`}>
            {this.props.LoginStore.authErrorMsg}
          </span>
          <Button
            className={`nw-register-link nw-login-link nw-grgt-register
            ${this.checkIsFormValid}`}
            onClick={this.showRegisterView}
          >
            {Login.LOGIN_MESSAGES.CREATEACCOUNT}
          </Button>
          <Button
            className="nw-login-link nw-grgt-home"
            onClick={this.showLoginView}
          >
            {Login.LOGIN_MESSAGES.BACKTOLOGIN}
          </Button>
        </form>
      </div>
    );
  }
}
ForgotPassword.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    authError: PropTypes.bool.isRequired,
    authErrorMsg: PropTypes.string.isRequired,
    forgotPasswordAction: PropTypes.func,
    changeView: PropTypes.func.isRequired,
    userId: PropTypes.string,
    loader: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ForgotPassword.wrappedComponent,
  component: ForgotPassword,
};

export default ProjectComponent;