/* eslint-disable */
import { action } from "mobx";
import { Toastr } from "arv-reactcomponents";
import {
  clearGuestCart,
  getGuestCart,
  setGuestCart,
  mybagGuest,
  mergeCart,
  myBagDetails,
  removeFromBag,
  addToBag,
} from "./service";
import { Utils } from "arv-reactcomponents";
import { Checkout, PDP_CONFIG } from "npmlinks-constants";

import { isAuthenticated, arrayToKeyValuePair } from "../../utils";
const CartToaster = message => {
  Toastr.showToastr({
    className: "nwc-toastr-list-success nw-toastr",
    message,
    timeout: 3000,
  });
  return null;
};

const handlePricingError = (data, HSN_ERROR_PRODUCT_NOT_IN_BAG = false) => {
  if (
    data.itemsHavingIssueInPricing &&
    data.itemsHavingIssueInPricing.length > 0
  ) {
    const products =
      data.itemsHavingIssueInPricing.length === 1 ? "product" : "products";
    Toastr.showToastr({
      className: "nwc-toastr-list-danger nw-toastr",
      message: !HSN_ERROR_PRODUCT_NOT_IN_BAG ? Checkout.CHECKOUT_MESSAGES.SKU_NOT_ADDED_MESSAGE.replace(
        "$count",
        data.itemsHavingIssueInPricing.length
      ).replace(
        "$product",
        products
      ) : Checkout.CHECKOUT_MESSAGES.SKU_NOT_ADDED_MESSAGE_HSN_ERROR,
      timeout: 3000,
    });
    return null;
  }
};

class MyBagActions {
  /*
logout-
do error handling
if we have added one new product  that does't exist in cart then we need to extend old products
else we need to check for Quantity and then merge Qty and overwrite the product
 TODO:: need to work on data inconsistency
 */
  @action
  addToBag(product) {
    try {
      let skuId, qty;
      let tempProductQtyInBag;
      skuId = this.getSku(product);
      qty = this.getQty(product);
      if (this.isProductInTheBag(product)) {
        tempProductQtyInBag =
          parseInt(this.cartItems[skuId].qty || 1) + parseInt(qty || 1);
      }
      const sellableQuantity = this.getSellableQuantity(product);
      if (!sellableQuantity > 0) {
        Toastr.showToastr({
          className: "nwc-toastr-list-danger nw-toastr",
          message: PDP_CONFIG.OOS_MESSAGE,
          timeout: 3000,
        });
        return this.cartItems;
      }
      if (tempProductQtyInBag && tempProductQtyInBag > sellableQuantity) {
        Toastr.showToastr({
          className: "nwc-toastr-list-danger nw-toastr",
          message: PDP_CONFIG.MAX_QTY.replace(
            "$sellableQuantity",
            sellableQuantity,
          ),
          timeout: 3000,
        });
        return this.cartItems;
      }
      //TODO::: handle here or in case of login handle on server and in case of not-loggein handle on UI
      // this.handleErrorsAndExceptopions(null, tempProductQtyInBag, product);

      if (isAuthenticated()) {
        return addToBag(this.myBagRequestPayload(product)).then(
          myBagData => {
            this.cartItems = arrayToKeyValuePair(
              myBagData.data.products,
              "skuId",
            );
            if (
              myBagData.data &&
              myBagData.data.itemsHavingIssueInPricing &&
              myBagData.data.itemsHavingIssueInPricing.length > 0
            ) {
              handlePricingError(myBagData.data, true);
              return;
            }
            CartToaster(PDP_CONFIG.ADD_TO_BAG_SUCCESS);
          });
      } else {
        product.qty = tempProductQtyInBag || qty;
        this.cartItems = { ...this.cartItems, ...{ [skuId]: product } };
        setGuestCart(this.cartItems);
        CartToaster(PDP_CONFIG.ADD_TO_BAG_SUCCESS);
      }
    } catch (err) {
      throw err;
    }
  }

  /*
  logout case -
      cart will be in sync with local storage
      when there is no data in guest cart that time  cartItems should be  empty object {}
   TODO:: we can show static data after that can make a call and update the product in background
   TODO:::: Refactor this if-else  to four different state--
   */
  @action
  getMyBagDetails() {
    const authenticated = isAuthenticated();
    let isCartAvailable = this.isCartAvailable(getGuestCart());
    return new Promise((resolve, reject) => {
      if (authenticated && isCartAvailable) {
        return mergeCart(
          this.myBagRequestPayload(Object.values(getGuestCart())),
        ).then(
          action(myBagData => {
            //this.handleErrorsAndExceptopions(myBagData.products);
            handlePricingError(myBagData.data);
            this.cartItems = arrayToKeyValuePair(
              myBagData.data && myBagData.data.products,
              "skuId",
            );
            clearGuestCart();
            resolve(this.cartItems);
          }),
        );
      } else if (authenticated && !isCartAvailable) {
        return myBagDetails().then(
          action(myBagData => {
            //this.handleErrorsAndExceptopions(myBagData.products);
            handlePricingError(myBagData.data);
            this.cartItems = arrayToKeyValuePair(
              myBagData.data && myBagData.data.products,
              "skuId",
            );
            resolve(this.cartItems);
          }),
        );
      } else if (!authenticated && isCartAvailable) {
        return mybagGuest(
          this.myBagRequestPayload(Object.values(getGuestCart())),
        ).then(
          action(myBagOverviewData => {
            //this.handleErrorsAndExceptopions(myBagOverviewData);
            handlePricingError(myBagOverviewData.data);
            this.updateCart(myBagOverviewData);
            resolve(this.cartItems);
          }),
        );
      } else {
        resolve(this.cartItems);
      }
    });
  }

  @action
  removeFromBag(product) {
    return new Promise((resolve, reject) => {
      try {
        let skuId;
        if (isAuthenticated()) {
          return removeFromBag(this.myBagRequestPayload(product, true)).then(
            action(myBagData => {
              this.cartItems = arrayToKeyValuePair(
                myBagData.data.products,
                "skuId",
              );
              resolve(this.cartItems);
              return this.cartItems;
              //handle exception
              //update local cart data
            }),
          );
        } else {
          if (this.isProductInTheBag(product)) {
            skuId = this.getSku(product);
            delete this.cartItems[skuId];
            const temp = this.cartItems;
            this.cartItems = {};
            this.cartItems = temp;
          }
          if (this.cartItems && Object.values(this.cartItems).length == 0) {
            this.cartItems = {};
          }
          setGuestCart(this.cartItems);
          resolve(this.cartItems);
          return this.cartItems;
        }
      } catch (err) {
        reject(err);
        throw err;
      }
    });
  }

  @action
  clearCart() {
    clearGuestCart();
    this.cartItems = {};
  }

  @action
  updateQuantityOfProduct() { }
}

export default MyBagActions;
