/* eslint-disable consistent-return */
export default {
  // TOP_ABOVE_MENU_CTR -- supertopnav
  STORE_LOCATOR_CLICK: "storelocator_click",
  GET_APP: "get_app_click",
  LOYALTY_CLICK: "loyalty_header_click",
  TRACK_ORDER: "track_order_click",
  PROMO_LINK_CLICKED: "promo_link_click",

  // TOP_MENU_NAVIGATION -- topnav
  LOGO_CLICK: "logo_click",
  NNNOW_LOGO_CLICK: "nnnow_logo_click",
  MYBAG_CLICK: "mybag_click",
  LOGIN_CLICK: "login_click",
  MENU_OPEN: "open",
  MENU_CLOSE: "close",
  FAVORITE_CLICK: "favorite_header_click",
  
  // LOGIN
  LOGIN_CANCEL: "cancel",
  LOGIN_STARTED: "started",
  LOGIN_FILLED: "filled_attempt",
  LOGIN_SUCCESS: "success",
  LOGIN_ERROR: "login_error",
  OTP_CLICK: "otp_clicked",
  FORGOT_PASSWORD_CLICK: "forgot_password_click",
  REGISTER_CLICK: "register_click",
  REGISTER_ATTEMPT: "final_register_attempt",
  REGISTER_SUCCESS: "final_register_success",
  REGISTER_ERROR: "register_login_error",
  MISSED_FIELDS: "enter_phone_screen",
  MISSED_FIELDS_ERROR: "no_phone_email_error",
  FACEBOOK_LOGIN: "fb_click",
  GMAIL_LOGIN: "google_click",
  FORGOT_PASSWORD_ATTEMPT: "forgot_password_attempt",
  FORGOT_PASSWORD_SUCCESS: "forgot_password_success",
  LOGOUT: "logout",
  SOCIAL_LOGIN_PHONE_NUDGE: "social_login_phone_nudge",
  FILLED_ATTEMPT:"filled_attempt",
  NEW_USER:"new_user",
  USER_FOUND:"user_found",
  PHONE_VERIFY:"phone_verify",
  OTP_CLICKED:"otp_clicked",
  PASSWORD_CLICKED:"password_clicked",

  // minipdp
  COLOR_CHANGE: "color_change",
  SIZE_CHANGE: "size_change",
  ADD_TO_BAG_ISSUE: "add_to_bag_size_issue",
  ADD_TO_BAG_SUCCESS: "add_to_bag_success",
  MINI_PDP_CLOSE: "cancel",
  PRODUCT_CAROUSEL_VIEW_ALL: "view_all_click",
  PRODUCT_CAROUSEL: "click",
  // SEARCH
  SEARCH_CLICK: "search_click",
  SEARCH_CLOSE: "close_button_click",
  REPEAT_SEARCH: "repeat_search",
  SEARCH_NORESULT:"0_search_result",
  QUERY_ENTER: "query_enter",
  BLANK_QUERY_ERROR: "blank_query_error",
  FIND_NOW_CLICK: "find_now_click",
  FILTER_BRAND_APPLIED: "brand_applied",
  FILTER_COLOR_APPLIED: "color_applied",

  AUTOSUGGEST_SEARCH_CLICK: "search",
  AUTOSUGGEST_LIST_ITEM_CLICK: "click_listing_",
  AUTOSUGGEST_ZERO_LISTING_RESULT: "0_listing_result",

  getFilterType: type => {
    if (type === "Occasion") return "occasion_applied";
    else if (type === "Price") return "budget_applied";
  },

  // FILTER
  FILTER_CATEGORY: "filter_category",

  // AUTHINTERCEPTOR
  REFRESH_TOKEN_FAIL: "blackboltRefreshTokenFailed",
  getApiFailUrl: (method, url) => `${method}__${url}`,

  // FOOTER
  SUBSCRIBE_USER: "email_subscribe",
  BACK_TO_TOP: "back_to_top_click",
  WHO_WE_ARE: "who_we_are_click",
  CUSTOMER_CARE_CLICK: "customer_care_click",
  CONTACT_US_CLICK: "contact_us_click",
  FB_CLICK: "fb_click",
  INSTA_CLICK: "insta_click",
  TWITTER_CLICK: "twitter_click",
  YOUTUBE_CLICK: "youtube_click",
  POLICY_CLICK: "privacy_policy_click",
  T_N_C_CLICK: "t_and_c_click",

  // MINI BAG
  MINIBAG_MYBAG_CLICK: "mybag_click",
  MINIBAG_CHECKOUT_CLICK: "checkout_click",
  MINIBAG_PRODUCT_REMOVE: "product_remove",

  // LISTING
  LANDED: "landed",
  SORT_HIGH_TO_LOW: "sort_price_high_to_low",
  SORT_LOW_TO_HIGH: "sort_price_low_to_high",
  SORT_NEWEST: "sort_newest",
  SORT_POPULARITY: "sort_popularity",
  SORT_RELEVANCE: "sort_relevance",
  GRID_VIEW: "grid_grid",
  LIST_VIEW: "grid_single",
  STORE_PINCODE: "store_pincode",
  DELIVERY_PINCODE: "delivery_pincode",
  getSortType: sort => `sort_${sort}`,
  getShopNNNOW: (page, imageSize, device, index) =>
    `shop_page_${page}_size_${imageSize}_${device}_index_${index}`,
  getFavClick: (page, imageSize, device, index) =>
    `fav_page_${page}_size_${imageSize}_${device}_index_${index}`,
  getProductClick: (page, imageSize, device, index) =>
    `product_click_page_${page}_size_${imageSize}_${device}_index_${index}`,
  getProductImpression: (page, imageSize, device, index) =>
    `impr_page_${page}_size_${imageSize}_${device}_index_${index}`,
  getListPageNo: pageNo => `page_${pageNo}`,
  getMasterTemplateActions: (inputArray = []) =>
    inputArray.filter(item => item || item === 0).join("_"),
  // PDP
  CHECKOUT_CLICK: "checkout_click",
  BT_SHARE: "share",
  SHARE_HOVER: "share_hover",
  SHARE_SELECTED: "share_selected",
  SHARE_CLICK: "share_click",
  SHARE_CLOSE: "share_close",
  SHORTLIST_PRE_LOGIN: "short_list_pre_login",
  SHORLIST_SUCCESS: "short_list_success",
  SHORLIST_REMOVED: "short_list_removed",
  REMOVED: "remove",
  ADD_TO_BAG_SIZE_PROMT: "add_to_bag_size_prompt",
  ADD_TO_BAG_SIZE_PROMPT_SELECTED: "size_prompt_selected",
  ADD_TO_BAG_SIZE_PROMPT_CLOSE: "size_prompt_close",
  COMPLETE_LOAD: "complete_load",
  FIT_GUIDE_CLICK: "fit_guide_click",
  OTHER_ELIGIBLE_PRODUCTS_CLICK: "other_eligible_products_click",
  PINCODE_CHECKED_SIZE_ISSUE: "pincode_checked_size_issue",
  IMAGE_CHANGE: "image_change",
  FINER_DETAILS_TAB: "finer_details_tab",
  OFFER_CODE_CLICK: "offer_code_click",
  ABOUT_TAB: "about_tab",
  RETURNS_TAB: "returns_tab",
  SHORTLIST_PRELOGIN: "short_list_pre_login",
  PINCODE_CHECKED: "pincode_checked",
  PINCODE_ENTERED: "pincode_entered",
  PINCODE_ERROR: "pincode_error",
  STORES_NEARBY_CLICK: "stores_nearby_click",
  ZOOM_CLICK: "zoom_click",
  VIDEO_CLICK: "video_click",
  getTabClicked: active => `${active}_tab`,
  secondaryProductsClick: (index, type) =>
    `${type.replace(" ", "_").toLowerCase()}_${index}`,
  getFavClickFromPdp: (page, imageSize, device, index, origin = "") =>
    `${origin
      .replace(" ", "_")
      .toLowerCase()}_fav_page_${page}_size_${imageSize}_${device}_index_${index}`,
  getShopNNNOWFromPdp: (page, imageSize, device, index, origin = "") =>
    `${origin
      .replace(" ", "_")
      .toLowerCase()}_shop_page_${page}_size_${imageSize}_${device}_index_${index}`,
  BRAND_SELECTION: "brand_selection",
  PINCODE_FILL: "pincode_fill",
  FIND_NOW_CLICK_SL: "find_now_click",
  FIND_NOW_ERROR: "find_now_error",
  STORE_UNIT_CLICK: "store_unit_click",
  MORE_INFO_CLICK: "more_info_click",
  GET_DIRECTION_CLICK: "get_direction_click",
  SHOP_STORE_CLICK: "shop_store_click",
  MAP_STORE_CLICK: "map_store_click",
  ZERO_STORE: "0_store",
  MASTER_TEMPLATE_PAGES: {
    BT_MNAV_CLICK: "mnav_click",
    BT_PCAR_CHANGE: "pcar_chng",
    BT_PCAR_SALL: "pcar_sall",
    BT_PCAR_VALL: "pcar_vall",
    BT_PCAR_CLICK: "pcar_click",
    DESKTOP_NAV: "dnav_click",
    LEFT_NAV_DESKTOP: "left_nav_desktop_click",
    LEFT_NAV_MOBILE: "left_nav_mobile",
    TOP_BAR: "top_bar",
    TOP_BANNER: "banner_click",
    PRODUCT_CAROUSEL: "product_carousel",
    QUICK_LINKS: "quicklinks_click",
    BT_QUICK_LINKS: "ql_click",
    BANNER_CAROUSEL: "banner_carousel_click",
    BLOG_STORY: "bstory_click",
    BLOG_STORY_SHARE: "bstory_share",
    STORY: "story_click",
    SECTION_CAROUSEL: "story_click",
    VIDEO: "video_play",
    BT_VIDEO: "svideo_play",
    STORY_CAROUSEL: "scar_chng",
  },
  // payment page actions
  PAYMENT_CARD_SELECTED: "payment_card_selected",
  PAYMENT_CARD_ENTERED: card => `payment_card_${card}_selected`,
  PAYMENT_CARD_SAVE: "payment_card_save",
  PAYMENT_NETBANKING_SELECTED: "payment_netbanking_selected",
  PAYMENT_PAYTM_SELECTED: "payment_paytm_selected",
  PAYMENT_NETBANKING_ENTERED: bank  => `payment_netbanking_${bank}_selected`,
  PAYMENT_COD_SELECTED: "payment_cod_selected",
  PAYMENT_COD_CAPTCHA_ATTEMPT: "payment_cod_captcha_attempt",
  PAYMENT_COD_CAPTCHA_ERROR: "payment_cod_captcha_error",
  PAYMENT_WALLET_SELECTED: wallet => `payment_wallet_${wallet}_selected`,
  PAYMENT_UPI_ENTERED: 'payment_upi_selected',
  PAYMENT_STEP_2_DONE: "payment_step_2_done",
  PAYMENT_FAILURE: "payment_failure",
  TRANSACTION_FAILURE: "transaction_failure",
  PAYMENT_TO_DELIVERY_CLICK: "payment_to_delivery_click",
  RED_CATCHA_ERROR: "red_captcha_error",
  OTP_VERIFICATION_REQUESTED: "otp_verification_requested",
  OTP_RESEND_REQUESTED: "otp_resend_requested",
  OTP_VERIFICATION_SUCCESS: "otp_verfication_success",
  OTP_VERIFICATION_FAILED: "otp_verfication_failed",

  // Loyalty:
  LOYALTY_CHECKBOX_SELECTED: "loyalty_checkbox_selected",
  LOYALTY_CHECKBOX_UNSELECTED: "loyalty_checkbox_unselected",

  // Shipping page:

  SHIPPING: "shipping",
  MYBAG_LANDED: "landed",
  MYBAG_QTY_CHANGE: "quantity_change",
  MYBAG_PRODUCT_REMOVE: "product_remove",
  MYBAG_PRODUCT_SHORTLIST: "product_shortlist",
  MYBAG_CHECKOUT_CLICK: "checkout_click",
  MYBAG_PRODUCT_CLICK: "product_click",
  CONTINUE_SHOPPING: "continue_shopping_click",
  PROMO_ATTEMPT: "promo_attempt",
  PROMO_SUCCESS: "promo_success",
  PROMO_FAILURE: "promo_failure",
  PROMO_REMOVE: "promo_remove",
  PROMO_FETCH: "promo_fetch",
  ADD_FROM_FAV_CLICK: "add_from_fav_click",
  CHECKOUT_LANDED: "started",
  CHECKOUT_EDITBAG: "edit_bag",
  Delivery_pincode_check: "delivery_pincode_check",
  Delivery_pincode_error: "delivery_pincode_error",
  Change_pincode_click: "change_pincode_click",
  Change_address_click: "change_address_click",
  Checkout_Step_1_Done: "delivery_step_1_done",
  Add_a_new_address_click: "add_a_new_address_click",
  Change_address_selected: "change_address_selected",
  Add_new_deliver_click: "add_new_deliver_click",
  Add_address_success: "add_address_success",
  Add_address_failure: "add_address_failure",
  SUCCESSFUL_ORDER: "successful_order",
  ZERO_RESULTS: "0_result",
  CHECKOUT_COLLECTFROMSTORE_CLICK: "collect_from_store_click",
  CHECKOUT_STORE_SELECTED: "store_selected",
  CHECKOUT_DELIVERY_METHOD_UPDATE: "delivery_method",

  // RAZORPAY
  SELECTED_PAYMENT_GATEWAY: "selected_payment_pg",
  RAZORPAY_SUCCESS: "razorpay_payment_success",
  RAZORPAY_FAILURE: "razorpay_payment_failure",

  //phonepe
  PHONEPE_SUCCESS: "phonepe_payment_success",
  PHONEPE_FAILURE: "phonepe_payment_failure",

  // PDP
  PRICE_INFO_BLOCK_HOVER: "price_info_hover",
  PRICE_INFO_BLOCK_CLICK: "price_info_click",

  // EXCHANGE
  EXCHANGE_CLICK: "exchange_click",
  EXCHANGE_SIZE_SUBMIT: "exchange_size_submit",

  // INSTALL PROMPT
  ADD_TO_HOME_SUCCESS: "add",
  ADD_TO_HOME_APP: "open",
};
