import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { GETAPP } from "npmlinks-constants";
import Anchor from "../../component/Anchor";
import Analytics, { EventFactory as Event } from "../../analytics";

class GetAppLink extends PureComponent {
  constructor(props) {
    super(props);

    this.onLinkClick = this.onLinkClick.bind(this);
  }

  onLinkClick() {
    Analytics.trackEvent({
      action: Event.action.GET_APP,
      category: Event.category.TOP_ABOVE_MENU_CTR,
      interaction: true,
    });
  }

  render() {
    const { className } = this.props;
    return (
      <Anchor.component
        className={`nw-getapplink ${className}`}
        href={`${GETAPP.PATH}`}
        to={`${GETAPP.PATH}`}
        target="_self"
        onClick={this.onLinkClick}
      >
        <i className="icomoon-get-app nw-getapplink-personicon" />
        <span className="nw-getapplink-text">{GETAPP.LABEL}</span>
      </Anchor.component>
    );
  }
};

GetAppLink.defaultProps = {
  className: "",
};

GetAppLink.propTypes = {
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: GetAppLink,
  component: GetAppLink,
};

export default ProjectComponent;
