import { observable, computed } from "mobx";
import LoyaltyStoreAction from "./actions";
import { PAYMENTPAGE } from "npmlinks-constants";

class LoyaltyStoreClass extends LoyaltyStoreAction {

  @observable isLoyaltyVerified = false;
  @observable loyaltyCards = [];
  @observable loyaltyMoreInfoData = {};
  @observable loader = false;
  @observable loyaltyCheck = false;

  @computed
  get paymentRequest() {
    return {
      paymentDetail: {
        description: PAYMENTPAGE.ORDER_REQUEST.LOYALTY,
        saveCard: null,
        savedCardPG: null,
        savedCardToken: null,
      },
      paymentTypeCode: PAYMENTPAGE.ORDER_REQUEST.LOYALTY,
    };
  }
}

export default LoyaltyStoreClass;
