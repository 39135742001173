import { INSTALL_PROMPT_COMPONENT } from "npmlinks-constants";

/**
 * generate an array  of  element ( a range )
 * @param start
 * @param end
 * @param steps
 * @returns {IterableIterator<*>}
 */
function* range(start, end, steps = 1) {
  for (let i = start; i <= end; i += steps) {
    yield i;
  }
}

const generateMonthList = (startingMonth, MonthLength) => [
  ...range(startingMonth, MonthLength),
];

const generateYearList = (startingYear, yearLength) => [
  ...range(startingYear, yearLength),
];

const getTimeDiffInMins = (previousDateObj, currentDateObj) => {
  const diff =
    (currentDateObj - previousDateObj) / INSTALL_PROMPT_COMPONENT.TIME_DIFF;
  return Math.abs(Math.round(diff));
};

export { generateMonthList, generateYearList, getTimeDiffInMins };
