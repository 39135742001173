import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { GridContainer, GridRow, GridColumn } from "arv-reactcomponents";

import TopbarText from "../TopbarTextv2";

@inject("NavListStore")
@observer
class TopBar extends Component {
  get navMetaData() {
    return (
      (this.props.NavListStore.navListData &&
        this.props.NavListStore.navListData.data &&
        this.props.NavListStore.navListData.data.metaData) ||
      {}
    );
  }

  render() {
    if (this.navMetaData.topBandMessage) {
      return (
        <GridContainer className="nw-header-topbar">
          <GridRow className="nw-topbarv2-row">
            <GridColumn className="nw-topbarv2-col">
              <TopbarText.component />
            </GridColumn>
          </GridRow>
        </GridContainer>
      );
    }

    return null;
  }
}

TopBar.wrappedComponent.propTypes = {
  NavListStore: PropTypes.shape({
    navListData: PropTypes.object,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: TopBar.wrappedComponent,
  component: TopBar,
};

export default ProjectComponent;
