// Import Core Components here

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Login, Endpoints } from "npmlinks-constants";
import { Utils } from "arv-reactcomponents";

// Import App depedency here
import {
  Button,
  GridRow,
  GridColumn,
  GridContainer,
  Img,
  Ripple,
  Loader,
  Toastr,
} from "arv-reactcomponents";

import PasswordInput from "../../PasswordInput";
import UserId from "../../UserId";
import { Captcha } from "../../../../molecules";
import { gmailInit, OAuth2Init, isWebView } from "../../utils";
import { fbInit } from "../../../../utils";
import Analytics from "../../../../analytics";
import GTM from "../../../../analytics/GTM";
import Event from "../../../../analytics/eventFactory";

@inject("LoginStore", "CommonStore")
@observer
class LoginSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FbLoaded: false,
    };
    this.userId = props.LoginStore.userId;
    this.userIdFields = null;
    this.passwordFields = null;
    this.onLogin = this.onLogin.bind(this);
    this.fbLoaded = this.fbLoaded.bind(this);
    this.showRegisterView = this.showRegisterView.bind(this);
    this.showForgotPasswordView = this.showForgotPasswordView.bind(this);
    this.onFbClick = this.onFbClick.bind(this);
    this.onGmailClick = this.onGmailClick.bind(this);
    this.webViewGmailClick = this.webViewGmailClick.bind(this);
  }
  componentDidMount() {
    gmailInit(this.onGmailClick);
    fbInit(this.fbLoaded);
    const invalidInput = document.querySelector(".nw-sublogin-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
  }
  onLogin(e) {
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-sublogin-form input.is-invalid",
    );
    const { userId, inputType } = this.userIdFields.state;
    const { password } = this.passwordFields.state;
    if (invalidInput) {
      invalidInput.focus();
      if (!userId || !password) {
        Toastr.showToastr({
          className: "nwc-toastr-list-notification nw-toastr",
          message: Login.LOGIN_MESSAGES.INVALID_CREDENTIALS,
          timeout: 3000,
        });
      }
    }
    if (
      this.passwordFields.state.isPasswordInputValid &&
      this.userIdFields.state.isUserIdValid
    ) {
      Analytics.trackEvent({
        action: Event.action.LOGIN_FILLED,
        category: Event.category.LOGIN,
        label: inputType,
      });
      this.props.LoginStore.loginUserAction(userId, password);
    }
  }
  onFbClick() {
    Analytics.trackEvent({
      action: Event.action.FACEBOOK_LOGIN,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.fbAction();
  }
  onGmailClick(accessToken, type) {
    Analytics.trackEvent({
      action: Event.action.GMAIL_LOGIN,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.loginUserSocially(accessToken, type);
  }
  get isFormValid() {
    return this.props.LoginStore.authError ? "" : "nwc-hidden";
  }
  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }
  get isSocialLoader() {
    return this.props.LoginStore.socialLoader ? "" : "nwc-hidden";
  }

  fbLoaded() {
    this.setState({ FbLoaded: true });
  }
  showRegisterView() {
    Analytics.trackEvent({
      action: Event.action.REGISTER_CLICK,
      category: Event.category.LOGIN,
      label: Event.label.getSource("home"),
    });
    GTM.pushEvent(Event.GTMEvent.REGISTER_CLICKED);
    this.props.LoginStore.changeView("register");
  }
  showForgotPasswordView() {
    if (this.userIdFields.state.isUserIdValid) {
      const { userId } = this.userIdFields.state;
      this.props.LoginStore.changeView("forgotPassword", userId);
    } else {
      this.props.LoginStore.changeView("forgotPassword");
    }
    Analytics.trackEvent({
      action: Event.action.FORGOT_PASSWORD_CLICK,
      category: Event.category.LOGIN,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  webViewGmailClick(event) {
    event.preventDefault();
    OAuth2Init();
  }

  get gmailComponent() {
    if (isWebView()) {
      return (
        <Button
          className="nw-social-btn nw-gmail"
          id="google-oauth-login"
          onClick={this.webViewGmailClick}
        >
          <Img src={Endpoints.gmailLogo} alt="google sign-in" onError={Utils.noop} />
          <span className="nw-social-text">
            {Login.LOGIN_MESSAGES.GOOGLE_TEXT}
          </span>
        </Button>
      );
    }
    return (
      <Button className="nw-social-btn nw-gmail" id="google-login" disabled>
        <Img src={Endpoints.gmailLogo} alt="google sign-in" onError={Utils.noop} />
        <span className="nw-social-text">
          {Login.LOGIN_MESSAGES.GOOGLE_TEXT}
        </span>
      </Button>
    );
  }

  get facebookComponent() {
    return (
      <Button
        className="nw-social-btn nw-fb"
        onClick={this.onFbClick}
        disabled={!this.state.FbLoaded}
      >
        <Img src={Endpoints.fbLogo} alt="facebook sign-in" onError={Utils.noop}/>
        <span className="nw-social-text">
          {Login.LOGIN_MESSAGES.FB_TEXT}
        </span>
      </Button>
    )
  }

  render() {
    const {captcha}=this.props.LoginStore;
    return (
      <div className="nw-login-container">
        <div
          className={`
            nw-login-loader
            nw-social-loader
            ${this.isSocialLoader}
          `}
        >
          <Loader className="nw-sloader" />
        </div>
        <div className="nw-login-heading">
          <h1 className="nw-login-title">
            {Login.LOGIN_MESSAGES.LOGINPAGE_TITLE}
          </h1>
          <p className="nw-login-sub-title">
            {Login.LOGIN_MESSAGES.LOGINPAGE_SUBTITLE}
          </p>
        </div>
        <div>
          <GridContainer className="nw-social-login">
            <GridRow className="nw-social-login-row">
              <GridColumn className="nwc-grid-col-xs nw-slogin-buttons">
                {this.gmailComponent}
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs nw-slogin-buttons">
                {this.facebookComponent}
              </GridColumn>
            </GridRow>
          </GridContainer>
          <div className="nw-login-divider">
            <span className="nw-login-or">Or</span>
            <div className="nw-divider" />
          </div>
        </div>
        <form className="nw-form nw-sublogin-form" onSubmit={this.onLogin}>
          <UserId.component
            className="nw-login-input nw-sublogin-userid"
            placeholder={Login.LOGIN_MESSAGES.LOGINPAGE_PH_USERID}
            name="text"
            value={this.userId}
            ref={values => {
              this.userIdFields = values;
            }}
            required
          />
          <PasswordInput.component
            className="nw-login-input nw-sublogin-password"
            placeholder={Login.LOGIN_MESSAGES.LOGINPAGE_PH_PASSWORD}
            name="password"
            showErrorMsg={Utils.noop}
            ref={values => {
              this.passwordFields = values;
            }}
            required
          />
          {captcha && <Captcha.component loadFor="login"/>}
          <Button
            onClick={this.showForgotPasswordView}
            type="button"
            className="nw-login-link nw-sublogin-forgotpwd"
          >
            {Login.LOGINV2_MESSAGES.FORGOT_PASS_LABEL}
          </Button>
          <Ripple className="nwc-ripple-light">
            <Button
              type="submit"
              className="nwc-btn-primary nw-login-btn nw-sublogin-submitbtn" disabled={this.props.LoginStore.disabledbutton}
            >
              {Login.LOGIN_MESSAGES.LOGIN_BTN_TEXT}
              <div className={`nw-login-loader ${this.isShowLoader}`}>
                <Img
                  className="nw-loader"
                  src={Endpoints.LOADER}
                  alt="loader"
                />
              </div>
            </Button>
          </Ripple>
          <span className={`nw-login-error ${this.isFormValid}`}>
            {this.props.LoginStore.authErrorMsg}
          </span>
        </form>
        <div className="nw-login-reg-container">
          <p className="nw-login-reg-text">
            {Login.LOGIN_MESSAGES.NEW_USER_TEXT}
          </p>
          <span>
            <Button
              onClick={this.showRegisterView}
              className="nw-login-reg-button nw-sublogin-registerbtn"
            >
              {Login.LOGIN_MESSAGES.REGISTER_TEXT}
            </Button>
          </span>
        </div>
      </div>
    );
  }
}
LoginSub.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    loginUserAction: PropTypes.func.isRequired,
    fbAction: PropTypes.func.isRequired,
    loginUserSocially: PropTypes.func.isRequired,
    authError: PropTypes.bool.isRequired,
    authErrorMsg: PropTypes.string.isRequired,
    changeView: PropTypes.func.isRequired,
    userId: PropTypes.string,
    loader: PropTypes.bool.isRequired,
    socialLoader: PropTypes.bool.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: LoginSub.wrappedComponent,
  component: LoginSub,
};

export default ProjectComponent;
