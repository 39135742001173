import React, { Component } from "react";
import PropTypes from "prop-types";
import lodashGet from "lodash.get";
import { Button } from "arv-reactcomponents";

import Anchor from "../../component/Anchor";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import { generateGaLabel } from "../../utils";

class NavButtonList extends Component {
  constructor(props) {
    super(props);

    this.trackNavButtonClick = this.trackNavButtonClick.bind(this);
  }

  trackNavButtonClick(menu) {
    this.name = lodashGet(menu, "name", null);
    const eventAction = `${generateGaLabel(this.name)}_click`;
    Analytics.trackEvent({
      action: eventAction,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_MENU_NAVIGATION,
    });
  }

  render() {
    const { buttonList = [] } = this.props;
    if (buttonList.length) {
      return buttonList.map(item => (
        <Anchor.component
          href={item.url}
          to={item.url}
          className="nw-brandnavcontainernew-link"
          onClick={() => this.trackNavButtonClick(item)}
          target="_self"
        >
          <Button className="nwc-btn-secondary navbuttonlist-button">
            {item.name}
          </Button>
        </Anchor.component>
      ));
    }
    return null;
  }
}

NavButtonList.propTypes = {
  buttonList: PropTypes.arrayOf().isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: NavButtonList,
  component: NavButtonList,
};

export default ProjectComponent;
