import FooterStoreClass from "./FooterStore";
import HeaderStoreClass from "./HeaderStore";
import GetTypeStoreClass from "./GetTypeStore";
import NavListStoreClass from "./NavListStore";
import CommonStoreClass from "./CommonStore";
import LoginStoreClass from "./Login";
import SearchBarStoreClass from "./SearchBarStore";
import StoreLocatorStore from "./StoreLocatorStore";
import StoreListStore from "./StoreListStore";
import ProductStoreClass from "./ProductStore";
import MybagStoreClass from "./MybagStore";
import WishListStoreClass from "./WishListStore";
import BrandStoreClass from "./BrandStore";
import MyOrdersStoreClass from "./MyOrdersStore";

import PromotionsStoreClass from "./PromotionsStore";
import CheckoutStoreClass from "./CheckoutStore";
import PaymentStoreClass from "./PaymentStore";
import AddressStoreClass from "./AddressStore";
import StaticPageStore from "./StaticPageStore";

import CodStoreClass from "./CodStore";
import OtpStoreClass from "./OtpStore";
import CreditCardClass from "./CreditCardStore";
import MyAccountStoreClass from "./MyAccountStore";
import ReturnStoreClass from "./ReturnStore";
import SavedCardStoreClass from "./SavedCardStore";
import NetbankingStoreClass from "./NetbankingStore";
import WalletStoreClass from "./WalletStore";
import OrderConfirmationClass from "./OrderConfirmationStore";
import NoResultsStoreClass from "./NoResultsStore";
import RazorpayStoreClass from "./RazorpayStore";
import LoyaltyStoreClass from "./LoyaltyStore";
import ReviewStoreClass from "./ReviewStore";
import CardlessEmiStoreClass from "./CardlessEmiStore";
import PhonePeStoreClass from "./PhonepeStore";
/**
  Inject Inital State into Stores
*/
const setState = state => {
  const stores = {};
  const MybagStore = state
    ? new MybagStoreClass(state.MybagStore)
    : new MybagStoreClass();
  const WishListStore = state
    ? new WishListStoreClass(state.WishListStore)
    : new WishListStoreClass();
  Object.assign(stores, {
    MybagStore,
    WishListStore,
    FooterStore: state
      ? new FooterStoreClass(state.FooterStore)
      : new FooterStoreClass(),
    HeaderStore: state
      ? new HeaderStoreClass(state.header)
      : new HeaderStoreClass(),
    GetTypeStore: state
      ? new GetTypeStoreClass(state.GetTypeStore)
      : new GetTypeStoreClass(),
    NavListStore: state
      ? new NavListStoreClass(state.NavListStore)
      : new NavListStoreClass(),
    CommonStore: state
      ? new CommonStoreClass(state.CommonStore)
      : new CommonStoreClass(),
    LoginStore: state
      ? new LoginStoreClass(state.login)
      : new LoginStoreClass(),
    SearchBarStore: state
      ? new SearchBarStoreClass(state.searchBar)
      : new SearchBarStoreClass(),
    StoreLocatorStore: state
      ? new StoreLocatorStore(state.StoreLocatorStore)
      : new StoreLocatorStore(),
    StoreListStore: state
      ? new StoreListStore(state.StoreListStore)
      : new StoreListStore(),
    ProductStore: state
      ? new ProductStoreClass(state.ProductStore)
      : new ProductStoreClass(),
    BrandStore: state
      ? new BrandStoreClass(state.BrandStore)
      : new BrandStoreClass(),
    OrdersStore: state
      ? new MyOrdersStoreClass(state.OrdersStore)
      : new MyOrdersStoreClass(),
    PromotionsStore: state
      ? new PromotionsStoreClass(state.PromotionsStore)
      : new PromotionsStoreClass(),
    CheckoutStore: state
      ? new CheckoutStoreClass(state.CheckoutStore)
      : new CheckoutStoreClass(),
    AddressStore: state
      ? new AddressStoreClass(state.AddressStore)
      : new AddressStoreClass(),
    CodStore: state ? new CodStoreClass(state.CodStore) : new CodStoreClass(),
    OtpStore: state ? new OtpStoreClass(state.CodStore) : new OtpStoreClass(),
    PaymentStore: state
      ? new PaymentStoreClass(state.PaymentStore)
      : new PaymentStoreClass(),
    CreditCardStore: state
      ? new CreditCardClass(state.CreditCardStore)
      : new CreditCardClass(),
    MyAccountStore: state
      ? new MyAccountStoreClass(state.MyAccountStore)
      : new MyAccountStoreClass(),
    ReturnStore: state
      ? new ReturnStoreClass(state.ReturnStore)
      : new ReturnStoreClass(),
    SavedCardStore: state
      ? new SavedCardStoreClass(state.SavedCardStore)
      : new SavedCardStoreClass(),
    NetbankingStore: state
      ? new NetbankingStoreClass(state.NetbankingStore)
      : new NetbankingStoreClass(),
    WalletStore: state
      ? new WalletStoreClass(state.WalletStore)
      : new WalletStoreClass(),
    OrderConfirmationStore: state
      ? new OrderConfirmationClass(state.OrderConfirmationStore)
      : new OrderConfirmationClass(),
    NoResultsStore: state
      ? new NoResultsStoreClass(state.NoResultsStore)
      : new NoResultsStoreClass(),
    StaticPageStore: state
      ? new StaticPageStore(state.StaticPageStore)
      : new StaticPageStore(),
      RazorpayStore: state
      ? new RazorpayStoreClass(state.RazorpayStore)
      : new RazorpayStoreClass(),
    LoyaltyStore: state
      ? new LoyaltyStoreClass(state.LoyaltyStore)
      : new LoyaltyStoreClass(),
    ReviewStore: state
      ? new ReviewStoreClass(state.ReviewStore)
      : new ReviewStoreClass(),
      CardlessEmiStore: state
      ? new CardlessEmiStoreClass(state.CardlessEmiStore)
      : new CardlessEmiStoreClass(),
      PhonepeStore: state
      ? new PhonePeStoreClass(state.CardlessEmiStore)
      : new PhonePeStoreClass(),
  });
  return stores;
};

class Stores {
  constructor(state = {}) {
    this.setStores = this.setStores.bind(this);
    this.getStores = this.getStores.bind(this);
    this.stores = setState(state);
  }
  getStores() {
    return this.stores;
  }
  setStores(state) {
    this.stores = setState(state);
  }
}
export default Stores;
