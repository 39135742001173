import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Button, Utils } from "arv-reactcomponents";

import BrandNavContainer from "../BrandNavContainer";
import NnnowLogo from "../NnnowLogo";

@inject("NavListStore")
@observer
class BrandPageNnnowBtn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.toggleBrandMenu = this.toggleBrandMenu.bind(this);
    this.fixOrUnFixScroll = this.fixOrUnFixScroll.bind(this);
  }

  toggleBrandMenu() {
    const { NavListStore } = this.props;
    const { brandPageNnnowMenuShow } = NavListStore;
    if (!brandPageNnnowMenuShow) {
      this.props.NavListStore.setNnnowMenuShow(true);
      this.fixOrUnFixScroll(true);
    } else {
      this.props.NavListStore.setNnnowMenuShow(false);
      this.fixOrUnFixScroll(false);
    }
  }

  fixOrUnFixScroll(bool) {
    if (bool) {
      Utils.fixScroll();
    } else {
      Utils.unFixScroll();
    }
  }

  render() {
    const { className } = this.props;

    return (
      <Fragment>
        <div className={`nw-brandpagennnowbtn ${className}`}>
        { process.env.REACT_APP_BRANDPAGE_HAMBURGER === "true" &&
          <Button className="nw-brandpagennnowbtn-btn" onClick={this.toggleBrandMenu}>
            <i className="icomoon-menu" />
          </Button>
        }
          <NnnowLogo.component className="nw-brandpagennnowbtn-image" />
        </div>
      </Fragment>
    );
  }
}

BrandPageNnnowBtn.defaultProps = {
  className: "",
};

BrandPageNnnowBtn.wrappedComponent.propTypes = {
  className: PropTypes.string,
  NavListStore: PropTypes.shape({
    brandPageNnnowMenuShow: PropTypes.bool,
    setNnnowMenuShow: PropTypes.func,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandPageNnnowBtn,
  component: BrandPageNnnowBtn,
};

export default ProjectComponent;