import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
import { Utils } from "arv-reactcomponents";

import NavTreeContainer from "../NavTreeContainer";

@inject("NavListStore")
@observer
class SubNav extends Component {
  get navMenu() {
    const { navListData } = this.props.NavListStore;
    const menu = lodashGet(navListData, "data.menu.level1", null);

    return menu;
  }

  showNavTree(bool, menu) {
    return () => {
      if (bool) {
        this.props.NavListStore.setSelectedNav(menu);
      } else {
        this.props.NavListStore.setSelectedNav(null);
      }
    };
  }

  render() {
    const { selectedNav } = this.props.NavListStore;

    if (this.navMenu) {
      return this.navMenu.map(menu => (
        <div
          onMouseEnter={this.showNavTree(true, menu)}
          onFocus={Utils.noop}
          onMouseLeave={this.showNavTree(false)}
          onBlur={Utils.noop}
        >
          <NavTreeContainer.component
            level={2}
            list={menu.level2}
            active={menu === selectedNav}
            mainNav={menu.name}
            viewAllUrl={menu.url}
            bannerUrl={menu.bannerUrl}
            bannerImg={menu.bannerImage}
          />
        </div>
      ));
    }

    return null;
  }
}

SubNav.wrappedComponent.propTypes = {
  NavListStore: PropTypes.shape({
    navListData: PropTypes.shape({}),
    selectedNav: PropTypes.shape({}),
    setSelectedNav: PropTypes.func,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: SubNav.wrappedComponent,
  component: SubNav,
};

export default ProjectComponent;
