import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class FooterDoc extends PureComponent {
  get isMainTitle() {
    const { index, isLanding } = this.props;

    return index === 0 && isLanding;
  }

  get classNameForAllignment() {
    const { alignment } = this.props;
    return `nw-footerdoc-section-${ alignment.toLowerCase() }`;
  }

  get classNameForContent() {
    const { alignment } = this.props;
    return `nw-footerdoc-sectioncontent-${ alignment.toLowerCase() }`;
  }

  render() {
    const { item } = this.props;
    return (
      <section className={`nw-footerdoc-section ${this.classNameForAllignment}`}>
        {item.footerTitle ? (
          !this.isMainTitle ? (
            <h2
              className="nw-footerdoc-sectiontitle"
              dangerouslySetInnerHTML={{ __html: item.footerTitle }}
            />
          ) : (
              <h1
                className="nw-footerdoc-sectiontitle"
                dangerouslySetInnerHTML={{ __html: item.footerTitle }}
              />
            )
        ) : null}
        <div
          className={`nw-footerdoc-sectioncontent ${this.classNameForContent}`}
          dangerouslySetInnerHTML={{ __html: item.footerDescription }}
        />
      </section>
    );
  }
}

FooterDoc.defaultProps = {
  alignment: "vertical"
}

FooterDoc.propTypes = {
  isLanding: PropTypes.bool.isRequired,
  item: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  alignment: PropTypes.string.isRequired
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FooterDoc,
  component: FooterDoc,
};

export default ProjectComponent;
