import React, { Component } from "react";
import { Img, GridContainer, GridRow, GridColumn } from "arv-reactcomponents";
import { withRouter } from "react-router";
import Anchor from "../../Anchor";
import {
  MyBag,
  Checkout,
  COPYRIGHT_TEXT,
  footerUrls,
  FOOTER_TEXTS,
} from "npmlinks-constants";
import PropTypes from "prop-types";

@withRouter
class CheckoutFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "",
    };
    this.checkPageType = this.checkPageType.bind(this);
  }

  componentDidUpdate() {
    this.checkPageType();
  }

  componentDidMount() {
    this.checkPageType();
  }

  checkPageType() {
    const { pathname } = this.props.location;
    const [currentView] = Object.keys(
      Checkout.CHECKOUT_ROUTES.SUPPORTED,
    ).filter(item => Checkout.CHECKOUT_ROUTES.SUPPORTED[item] === pathname);
    const { currentView: stateView } = this.state;
    if (stateView !== currentView) {
      this.setState({ currentView });
    }
  }

  get isPaymentPage() {
    return this.state.currentView === "payment" && "nw-checkoutfooter-payment";
  }

  render() {
    return (
      <GridContainer className={`nw-checkoutfooter ${this.isPaymentPage}`}>
        <GridRow className="nw-checkoutfooter-wrapper">
          <GridColumn className="nw-checkoutfooter-security-wrapper nwc-grid-col-sm-6">
            {MyBag.IMAGES.SECURITY_IMAGES.map(image => (
              <Img
                className="nw-checkoutfooter-security-img"
                src={`${image}`}
                key={image}
              />
            ))}
          </GridColumn>
          <GridColumn className="nw-checkoutfooter-links nwc-grid-col-sm-6">
            <ul className="nw-checkoutfooter-list">
              <li className="nw-checkoutfooter-link nw-checkoutfooter-copyright">
                {COPYRIGHT_TEXT}
              </li>
              <li className="nw-checkoutfooter-link nw-checkoutfooter-pri-pol">
                <Anchor.component
                  className="nw-checkoutfooter-anchors"
                  href={footerUrls.privacypolicy}
                  to={footerUrls.privacypolicy}
                  target="_self"
                >
                  {FOOTER_TEXTS.PRIVACY_POLICY}
                </Anchor.component>
              </li>
              <li className="nw-checkoutfooter-link nw-checkoutfooter-tnc">
                <Anchor.component
                  className="nw-checkoutfooter-anchors"
                  href={footerUrls.termsconditions}
                  to={footerUrls.termsconditions}
                  target="_self"
                >
                  {FOOTER_TEXTS.TNC}
                </Anchor.component>
              </li>
            </ul>
          </GridColumn>
        </GridRow>
      </GridContainer>
    );
  }
}

CheckoutFooter.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: CheckoutFooter,
  component: CheckoutFooter,
};

export default ProjectComponent;
