import { get, post } from "../../api";
import { authHeader } from "../../utils";

const getOrderConfirmationDetails = orderId =>
  get("orderConfirm", authHeader(), orderId);

const getLoyaltyPointsData = orderId =>
  get("loyaltyPointsData", authHeader(), orderId);

  const getOrderPaymentStatus = transactionId =>
  post("orderPaymentPending", authHeader(),null, transactionId);

export { getOrderConfirmationDetails, getLoyaltyPointsData, getOrderPaymentStatus };
