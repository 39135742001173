// Import Core Components here
import React, { Component } from "react";
import PropTypes from "prop-types";

// Import App depedency here
import { Input } from "arv-reactcomponents";

class UserId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.value,
      userIdInputErrorMsg: "",
      isUserIdValid: false,
      inputType: "",
      userIdErrorClass: "",
      isValid: "",
    };

    this.onBlur = this.onBlur.bind(this);
    this.validateUserId = this.validateUserId.bind(this);
  }

  componentDidMount() {
    const { userId } = this.state;
    if (userId) {
      const e = {
        target: {
          value: userId,
        },
      };
      this.validateUserId(e);
    }
  }
  onBlur() {
    let userIdErrorClass = "nwc-hidden";
    let isValid = "";
    if (!this.state.isUserIdValid) {
      userIdErrorClass = this.state.userId ?  "" : "nwc-hidden";
      isValid = "is-invalid";
    }
    this.setState({ userIdErrorClass, isValid });
  }

  validateUserId(e) {
    this.setState({ userIdErrorClass: "nwc-hidden" });
    let pattern;
    const validateUserIdField = {
      isValid: true,
      errorMsg: "",
      type: "",
    };
    const { value } = e.target;
    if (value.match(/[a-z]+/i)) {
      validateUserIdField.type = "email";
      pattern = /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
      if (!pattern.test(value)) {
        validateUserIdField.isValid = false;
        validateUserIdField.errorMsg = "Please enter a valid email address.";
        validateUserIdField.type = "email";
      }
    } else {
      validateUserIdField.type = "mobile";
      pattern = /^[6789]\d{9}$/;
      if (!pattern.test(value)) {
        validateUserIdField.isValid = false;
        validateUserIdField.errorMsg = "Please enter a valid Phone number.";
      }
    }
    this.setState({
      isUserIdValid: validateUserIdField.isValid,
      userIdInputErrorMsg: validateUserIdField.errorMsg,
      userId: value,
      inputType: validateUserIdField.type,
      isValid: "",
    });
  }

  render() {
    const { userIdInputErrorMsg, isValid, inputType, userId } = this.state;
    const { className, placeholder, name, classNameErr} = this.props;
    return (
      <div>
        <Input
          className={`${className} ${isValid}`}
          name={name}
          onBlur={this.onBlur}
          onChange={this.validateUserId}
          placeholder={placeholder}
          type="text"
          value={userId}
          ref={values => {
            this.validateUserIdFieldRef = values;
          }}
        />
        <div className={`${classNameErr} ${this.state.userIdErrorClass}`}>
          {userIdInputErrorMsg}
        </div>
      </div>
    );
  }
}

UserId.defaultProps = {
  className: "",
  value: "",
  required: false,
  classNameErr: "nw-form-err-msg",
};

UserId.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  classNameErr: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: UserId,
  component: UserId,
};

export default ProjectComponent;
