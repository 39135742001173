import * as Service from "./services";
import { action } from "mobx";

class OrderConfirmationActions {
  @action
  getOrderConfirm(orderId) {
    this.isLoader = true;
    return new Promise((resolve, reject) => {
      Service.getOrderConfirmationDetails(orderId)
        .then(
          action(res => {
            const data = res.data;
            this.orderConfirmationData = data;
            this.isLoader = false;
            resolve(data);
          }),
        )
        .catch(err => {
          reject(err);
          this.isLoader = false;
        });
    });
  }

  @action
  getOrderPaymentStatus(transactionId) {
    return new Promise((resolve, reject) => {
      Service.getOrderPaymentStatus(transactionId)
        .then(
          action(res => {
            const data = res;
            resolve(data);
          }),
        )
        .catch(err => {
          reject(err);
          this.isLoader = false;
        });
    });
  }

  @action
  getLoyaltyPointsData(orderId) {
    return new Promise((resolve, reject) => {
      Service.getLoyaltyPointsData(orderId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default OrderConfirmationActions;
