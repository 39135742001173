import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import { inject } from "mobx-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AFFILIATE_EXPIRY,
  STORAGE_KEYS,
  MAIN_CONFIG,
  URL_CONFIG,
  STATIC_META_TAG,
} from "npmlinks-constants";
import { Utils, Toastr, LazyImg } from "arv-reactcomponents";
import Footer from "../component/Footer";
import HeaderV2 from "../component/HeaderV2";

import {
  MobileAppPrompt,
  Listing,
  MyOrders,
  OrderDetails,
  Pdp,
  MyBag,
  Checkout,
  OrderConfirmation,
  MyAccount,
  StoreLocator,
  NoResults,
  BrandPage,
  Favorites,
  WhoWeAre,
  CustomerCare,
  TermsAndConditions,
  PrivacyPolicy,
  ContactUs,
  Apps,
  NotFound,
  BrandsList,
  Login,
  AllReviews,
  SolusAI
} from "./routes";

import { SocialLoginWebView } from "../component/Login";
import UiRoutingComponent from "./UiRoutingComponent";
import { queryParamsToObject, createCookie } from "../utils";

if (process.env.REACT_APP_isNotCONSOLE) {
  window.console.log = Utils.noop;
}

Utils.polyfill();
Toastr.setConfig({
  limitTo: 1, // Change default value of limitTo to `3`
});

const publicPath = "/";
export const routeCodes = {
  Header: `${publicPath}`,
  Login: `${publicPath}`,
  StoreLocator: `${publicPath}store-locator`,
  Search: `${publicPath}`,
  Product: `${publicPath}products`,
  Footer: `${publicPath}`,
  Style: `${publicPath}style`,
  NOTFOUND: `*`,
};
// addLocaleData([...en, ...es, ...fr, ...ru]);
// const messages = {};

@withRouter
@inject("LoginStore")
class App extends Component {
  static handleQuery(event) {
    if (window && event.persisted) {
      window.location.reload();
    }
    const urlQueryParams = queryParamsToObject(window.location.search);
    if (urlQueryParams.utm_medium) {
      const date = new Date();
      const newExpiryTime = date.setMinutes(AFFILIATE_EXPIRY);
      createCookie(
        STORAGE_KEYS.UTM_MEDIUM,
        urlQueryParams.utm_medium,
        newExpiryTime,
      );
    }
  }

  constructor() {
    super();
    this.unlistenToRouteChange = null;
    this.onRouteChange = this.onRouteChange.bind(this);
    this.setStaticMeta = this.setStaticMeta.bind(this);

    if (
      (global && global.isServer) ||
      /Prerender/i.test(window.navigator.userAgent)
    ) {
      LazyImg.bypass();
    }
  }

  componentDidMount() {
    window.addEventListener("pageshow", App.handleQuery);
    this.onRouteChange(true);
  }

  componentWillUnmount() {
    window.removeEventListener("pageshow", App.handleQuery);
    this.onRouteChange(false);
  }

  onRouteChange(init) {
    if (init) {
      const { pathname } = this.props.location;
      this.unlistenToRouteChange = this.props.history.listen(location => {
        this.setStaticMeta();
        // window ? window.GL = Object.assign({}, window.GL, {staticHeader: isstaticHeader(location.pathname),pathname:location.pathname}):null;
        // if(window){
        //   window.GL = Object.assign({}, window.GL, {staticHeader: isstaticHeader(location.pathname),pathname:location.pathname})
        // }
        const currentPage =
          (location && location.state && location.state.currentPage) || "";
        if (
          !MAIN_CONFIG.SCROLLTOTOP_EXCLUDEDPAGES.includes(currentPage) &&
          !MAIN_CONFIG.SCROLLTOTOP_EXCLUDEDPATHS.includes(pathname)
        ) {
          window.scrollTo(0, 0);
        }
      });
    } else if (this.unlistenToRouteChange) {
      this.unlistenToRouteChange();
    }
  }

  setStaticMeta() {
    const { pathname } = this.props.history.location;
    const { staticContext } = (this.context && this.context.router) || {};
    const hostname =
      (staticContext && staticContext.req && staticContext.req.hostname) ||
      (window && window.location && window.location.hostname) ||
      "";
    const metaTag = document.querySelector("meta[name='apple-itunes-app']");
    if (metaTag) {
      const content = `${STATIC_META_TAG}${hostname}${pathname}`;
      metaTag.setAttribute("content", content);
    }
  }

  render() {
    return (
      <div>
        <div className="nw-app" onTouchStart={Utils.addInputBlurForIOS}>
          {/* <Route path={routeCodes.Header} component={Header.component} /> */}
          <Route path={routeCodes.Header} component={HeaderV2.component} />
          <div className="nw-app-body">
            <Switch>
              <Route exact path="/" component={BrandPage} />
              <Route
                exact
                path="/reset-password"
                component={BrandPage.component}
              />
              <Route exact path="/account" component={MyAccount} />
              <Route path="/store-locator" component={StoreLocator} />
              <Route path="/style" component={Pdp} />
              <Route path="/styles" component={Listing} />
              <Route path="/products" component={Listing} />
              <Route path="/search" component={Listing} />
              <Route path="/whoweare" component={WhoWeAre} />
              <Route path="/customercare" component={CustomerCare} />
              <Route
                path="/termsconditions"
                component={TermsAndConditions}
              />
              <Route
                path="/privacypolicy"
                component={PrivacyPolicy}
              />
              <Route
                path="/contactus"
                component={ContactUs}
              />
              <Route path="/noresults" component={NoResults} />
              <Route path="/apps" component={Apps} />
              <Route path="/mybag" component={MyBag} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/myorders" component={MyOrders} />
              <Route path="/favorites" component={Favorites} />
              <Route path="/brandslist" component={BrandsList} />
              <Route
                path={process.env.REACT_APP_GOOGLE_REDIRECT_URI}
                component={SocialLoginWebView.component}
              />
              <Route
                path={process.env.REACT_APP_FACEBOOK_REDIRECT_URI}
                component={SocialLoginWebView.component}
              />
              <Route
                path={URL_CONFIG.NOT_FOUND}
                component={NotFound}
              />
              <Route
                path="/orderdetails/:orderId"
                component={OrderDetails}
              />
              <Route
                path="/orderconfirmation/:orderId/:status"
                component={OrderConfirmation}
              />
              <Route
                path="/orderconfirmation/:orderId/:status/:transactionId"
                component={OrderConfirmation}
              />
              <Route
                path="/allreviews/:styleId"
                component={AllReviews}
              />
              <Route
                path="/solusAI/:bitlyCode"
                component={SolusAI}
              />
              <Route path="/:type" component={UiRoutingComponent} />
              
            </Switch>
            <MobileAppPrompt/>
          </div>
          <Route path={routeCodes.Footer} component={Footer.component} />
          <Route path={routeCodes.Login} component={Login} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default App;