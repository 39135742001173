import { action } from "mobx";

class NetbankingStoreActions {
  @action
  setSelectedNetbanking(netBankingData) {
    this.selectedNetbankingData = netBankingData;
  }

  @action
  setNetbankingOptions({ netBankingBanks = [] }) {
    this.netBankingBanks = Object.assign(
      [],
      this.netBankingBanks,
      netBankingBanks,
    );
  }

  /**
   * after getting proper response from makepaymentv2 api we have to store this info so that we can submit this info to payu
   * @param successData
   */
  @action
  setSuccessFlow(successData) {
    this.success = Object.assign({}, this.success, successData);
  }
}

export default NetbankingStoreActions;
