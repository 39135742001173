import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Utils } from "arv-reactcomponents";
import Analytics, { GTM } from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import BodyOverlay from "../../BodyOverlay";
import { scrollTime, filterKeys } from "npmlinks-constants";
import {
  getFilterUrl,
  appendCprToUrl,
  somethingWentWrong,
  getCurrentHref,
  queryParamsToObject,
  objToQueryParams,
} from "../../../utils";

@withRouter
@inject("ProductStore", "CommonStore")
@observer
class FilterActionsWrapper extends Component {
  constructor() {
    super();
    this.modifiedChild = this.modifiedChild.bind(this);
    this.filterClick = this.filterClick.bind(this);
  }
  filterClick(filter, type) {
    const { ProductStore, mapping, filterlist: filterFromProps } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const filterlist = type ? filter : filterFromProps;
    this.props.onFilterSelect();
    const location = isDesktop
      ? this.props.location
      : ProductStore.mobileTempUrlCache;
    let { pathname, search } = location;
    pathname = pathname || this.props.location.pathname;
    search = search || this.props.location.search;
    if (
      type === "customPriceFilters" ||
      (mapping === filterKeys.Price &&
        ProductStore.customPriceFilters.length > 0)
    ) {
      const queryObj = queryParamsToObject(search);
      delete queryObj[filterKeys.Price];
      search = `${objToQueryParams(queryObj)}`;
    }
    search = search.indexOf("?") > -1 ? search : `?${search}`;
    const pagePath = `${pathname}${search}`;

    ProductStore.setListingPageNo(1);
    const pathWithPageNo = Utils.setUrlParameter(pagePath, "p", 1);

    const initUrl = getFilterUrl(filterlist, mapping, pathWithPageNo);
    const filterUrl = appendCprToUrl(initUrl, ProductStore.ProductData);
    const deliveryPincode =
      ProductStore.omniPincodeCache.delivery_time ||
      ProductStore.omniPincodeCache.nearby_stores;
    const storePincode =
      ProductStore.omniPincodeCache.nearby_stores ||
      ProductStore.omniPincodeCache.delivery_time;
    const { radius } = ProductStore.omniPincodeCache;
    let finalUrl = filterUrl;
    if (deliveryPincode) {
      finalUrl = getFilterUrl({ value: deliveryPincode }, "p-dl", finalUrl);
    }
    if (storePincode) {
      finalUrl = getFilterUrl({ value: storePincode }, "p-st", finalUrl);
    }
    if (radius && storePincode) {
      finalUrl = getFilterUrl({ value: radius }, "r", finalUrl);
    }

    let newUrl = `${pathname}?${finalUrl}`;
    if (isDesktop) {
      BodyOverlay.component.show();
      ProductStore.getAllProductData(newUrl).then(
        () => {
          Utils.scrollTo(window, 0, 130, scrollTime.fast);
          BodyOverlay.component.hide();
        },
        () => {
          BodyOverlay.component.hide();
          somethingWentWrong();
        },
      );
      this.props.history.push(newUrl);
    } else {
      newUrl = `${newUrl}&v=0`;
      ProductStore.storeTempUrl(pathname, `?${finalUrl}`);
      // if (type === "customPriceFilters") {
      //   ProductStore.triggerMobileApplyCall(true);
      // } else {
      ProductStore.triggerMobileApplyCall(false);
      ProductStore.getAllProductData(newUrl, "mobileFilters").then(
        () => {},
        () => {
          somethingWentWrong();
        },
      );
      // }
    }

    const filterTitle =
      this.props && (this.props.filterTitle || this.props.name);
    Analytics.trackEvent({
      action: `filter_${filterTitle.toLowerCase()}`,
      category: Event.category.LISTING_PAGE,
      label: getCurrentHref(),
    });

    if(filterTitle) {
      const value = this.props.filterlist.value;
      GTM.pushWithEvent({
        category: filterTitle,
        value,
      }, Event.GTMEvent.CATEGORY_SELECTED);
    }
  }
  modifiedChild(child) {
    const onClickFn = child.props.onChange || Utils.noop;
    return React.cloneElement(child, {
      onChange: (e, type) => {
        onClickFn(e);
        this.filterClick(e, type);
      },
    });
  }
  render() {
    const { children } = this.props;
    if (children) {
      return (
        <Fragment>{React.Children.map(children, this.modifiedChild)}</Fragment>
      );
    }
    return <div />;
  }
}

FilterActionsWrapper.wrappedComponent.defaultProps = {
  onFilterSelect: Utils.noop,
};

FilterActionsWrapper.wrappedComponent.propTypes = {
  filterlist: PropTypes.shape({}).isRequired,
  mapping: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onFilterSelect: PropTypes.func,
  ProductStore: PropTypes.shape({}).isRequired,
  filterTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};
const getFinalComponent = component =>
  inject("ProductStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FilterActionsWrapper.wrappedComponent,
  component: FilterActionsWrapper,
};

export default ProjectComponent;
