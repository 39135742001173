import { Utils } from "arv-reactcomponents";
import { arrayToKeyValuePair, cookiesStorage } from "../../utils";

const Storage = true ? cookiesStorage : Utils.localStorage;
const myBagKeyOdin = "cart-guest";

const getGuestCart = () => {
  // return data in odin2.0 format {key:{skuId:,qnt:}}

  let guestCart = Storage.getItem(myBagKeyOdin);
  guestCart = JSON.parse(guestCart) || {};
  // TODO:: only written for data safety, actullay not needed , need to remove after validation
  guestCart = Array.isArray(guestCart) ? guestCart : guestCart.products;
  if (guestCart && guestCart.length) {
    guestCart = arrayToKeyValuePair(guestCart, "skuId");
    return guestCart;
  }
  if (typeof guestCart === "object") {
    return guestCart || {};
  }
  return guestCart || {};
};

const getSku = product => product.skuId || product.selectedSku.skuId;
const getQty = product =>
  product.qty || (product.selectedSku && product.selectedSku.qty) || 1;

const clearGuestCart = () => {
  Storage.removeItem(myBagKeyOdin);
};

const setGuestCart = (updatedGuestCart = {}) => {
  let localMyBagData = updatedGuestCart && Object.values(updatedGuestCart);
  if (localMyBagData && localMyBagData.length) {
    localMyBagData = localMyBagData.map(product => ({
      skuId: getSku(product),
      qty: getQty(product),
    }));
    localMyBagData = JSON.stringify(localMyBagData);
    Storage.setItem(myBagKeyOdin, localMyBagData);
  } else {
    clearGuestCart();
  }
};

export { getGuestCart, clearGuestCart, setGuestCart };
