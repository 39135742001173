import React from "react";
import { PropTypes } from "prop-types";
import { GridRow, GridColumn, Label, Input } from "arv-reactcomponents";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";

function ColorIcon(props) {
  const subMenuClick = index => {
    props.selectElementAndFilter(props.icon.id, index);
    Analytics.trackEvent({
      action: Event.action.FILTER_COLOR_APPLIED,
      label: Event.label.getSource(window.pageType),
      category: Event.category.SEARCH,
    });
  };

  return (
    <GridRow
      className={`nw-search-items-color ${props.className}`}
      role="presentation"
    >
      {props.icon.list.map((c, index) => {
        const pStyle = {
          backgroundColor: c.colorCode,
          id: c.value,
        };
        return (
          <GridColumn
            key={c.value}
            className="nw-search-specific-icon nwc-grid-col-sm-2"
          >
            <Label htmlFor={`searchbar-filter-color-${c.value}`}>
              <Input
                id={`searchbar-filter-color-${c.value}`}
                type="checkbox"
                className="nwc-hidden-filter-checkbox"
                value={index}
                checked={c.selected || false}
                name={c.value}
                onChange={() => {
                  subMenuClick(index);
                }}
              />
              <span
                className={`nw-color-circle ${c.selected ? "is-selected" : ""}`}
                style={pStyle}
                title={c.displayName}
              />
              <span
                className={`nw-color-circle-text ${
                  c.selected ? "is-selected" : ""
                }`}
              >
                {c.displayName}
              </span>
            </Label>
          </GridColumn>
        );
      })}
    </GridRow>
  );
}
ColorIcon.defaultProps = {
  icon: { isActive: false },
};
ColorIcon.propTypes = {
  icon: PropTypes.shape({
    list: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.array]),
    isActive: PropTypes.bool,
    id: PropTypes.number,
  }),
  selectElementAndFilter: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ColorIcon,
  component: ColorIcon,
};

export default ProjectComponent;
