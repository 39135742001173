const PRICE_FILTER = {
  NUMBERS_ONLY: "Numbers only, please.",
  ALL_BOXES: "Please enter both Min & Max.",
  GREATER: "Max price should be > Min price, please.",
  ALL_PRICE_RANGES_BUTTONTEXT: "All Price Ranges",
  PLACEHOLDERS: {
    MIN: "Min",
    MAX: "Max",
  },
  HYPHEN: "-",
  FILTER_NAME: "customPriceFilter",
  AMOUNT_LIMIT: 1000000,
  AMOUNT_LIMIT_ERROR: "Please enter an amount below ₹ 10 Lacs",
  NO_ZERO_START: "Sorry! price cannot start with 0.",
  ERROR_TIMEOUT: 5000,
  KEYBOARD_INACTIVE_TIMEOUT: 2000,
};

const APPLY_CANCEL_BUTTON = {
  CANCEL: "Clear all",
  APPLY: "APPLY",
};

const OFFER_TEXT = "OFFER";

//SizeNudgeBlock
const SIZENUDGEBLOCK_TEXT = "Please select a size before selecting stores for more relevant results";

const LISTING = { PRICE_FILTER, APPLY_CANCEL_BUTTON, OFFER_TEXT, SIZENUDGEBLOCK_TEXT };

export default LISTING;
