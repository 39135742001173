// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { observer, inject } from "mobx-react";
import { Utils, Loader, Toastr } from "arv-reactcomponents";

import { handleRedirect, extractKey } from "../utils";
import { OAuth2, FACEBOOK_WEBVIEW, Login } from "npmlinks-constants";

@withRouter
@inject("LoginStore", "CommonStore")
@observer
class SocialLoginWebView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finalRouteOnLogin: "",
    };

    this.init = this.init.bind(this);
    this.updateUserCurrentJounery = this.updateUserCurrentJounery.bind(this);
    this.handleFacebookRedirect = this.handleFacebookRedirect.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const { isDesktop } = this.props.CommonStore;
    const { location } = window;
    const { pathname, hash } = location;
    if (!isDesktop) {
      this.props.LoginStore.setLoader(true);
      const userState = Utils.localStorage.getItem("currentUserState");
      const goToRouteOnLogin = window.sessionStorage.getItem(
        "goToRouteOnLogin",
      );
      const finalRoute =
        goToRouteOnLogin !== "undefined" ? goToRouteOnLogin : null;
      const result = pathname === FACEBOOK_WEBVIEW.redirectURI ? this.handleFacebookRedirect(location) : handleRedirect(hash);
      if (result && result.accessToken && result.config) {
        this.props.LoginStore.loginUserSocially(
          result.accessToken,
          result.config,
        );
        this.setState({
          finalRouteOnLogin: finalRoute || userState.path,
        });
      } else {
        const { finalRouteOnLogin } = this.state;
        this.props.history.push(finalRouteOnLogin || OAuth2.fallBackRoute);
        Toastr.showToastr({
          className: "nwc-toastr-list-danger nw-toastr",
          message: Login.LOGIN_MESSAGES.FAILED_TO_LOGIN_WITH_FB,
          timeout: 3000,
        });
      }
    }
  }

  handleFacebookRedirect(location) {
    const { search, hash } = location;
    const stateParam = window.sessionStorage.getItem("userSessionId");
    if (search.indexOf(FACEBOOK_WEBVIEW.errorRedirect) > -1) {
      return false;
    } 
    const stateParamFromRequest = extractKey("state", hash);
    if (stateParamFromRequest !== stateParam) {
      return false;
    }
    return handleRedirect(hash, true);
  }

  updateUserCurrentJounery(isUserLoggedIn) {
    const { finalRouteOnLogin } = this.state;
    if (isUserLoggedIn) {
      this.props.history.push(finalRouteOnLogin || OAuth2.fallBackRoute);
    }
  }

  render() {
    const { isUserLoggedIn } = this.props.LoginStore;
    this.updateUserCurrentJounery(isUserLoggedIn);
    return (
      !isUserLoggedIn && (
        <div className="nw-socialloginwebview-loader">
          <Loader />
        </div>
      )
    );
  }
}

SocialLoginWebView.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool.isRequired,
    setLoader: PropTypes.func.isRequired,
    loginUserSocially: PropTypes.func.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: SocialLoginWebView.wrappedComponent,
  component: SocialLoginWebView,
};

export default ProjectComponent;
