const createCookie = function(name, value, expireTime) {
  let expires = "";
  if (expireTime) {
    const date = new Date();
    date.setTime(expireTime);
    expires = `; expires= ${date.toGMTString()}`;
  }
  const sameSite = "; SameSite=Lax";
  if (window.location.hostname === "localhost") {
    document.cookie = `${name} = ${value} ${expires}; path=/${sameSite}`;
  } else {
    document.cookie = `${name} = ${value} ${expires}; path=/${sameSite}; secure;domain=${
      process.env.REACT_APP_DOMAIN_NAME
    }`;
  }
};

const readCookie = function(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  // eslint-disable-next-line
  for (let key of ca.keys()) {
    let c = ca[key];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = function(name) {
  createCookie(name, "", -1);
};

const getExpiryTime = time => {
  const date = new Date();
  // eslint-disable-next-line radix
  const parsedTime = (time && parseInt(time)) || null;
  const newExpiryTime = date.setMinutes(parsedTime);
  return newExpiryTime;
};

const cookiesStorage = {
  getItem: readCookie,
  setItem: createCookie,
  removeItem: eraseCookie,
  getExpiry: getExpiryTime,
};

export { createCookie, readCookie, eraseCookie };
export default cookiesStorage;
