import {
  post
} from "../../api";
import {
  authHeader
} from "../../utils";

const makePayment = body =>{
  if (process.env.REACT_APP_PAYTMENABLED==="true") {
    return post("makePaymentV2", authHeader(), body);
  } else {
    return post("makePayment", authHeader(), body);
  }
}
const getPaymentmethods = (body, mobileNumber) => {
  if (process.env.REACT_APP_PAYTMENABLED==="true") {
    const newBody = Object.assign({}, { ...body }, { mobileNumber });
    return post("getPaymentMethodsV6", authHeader(), JSON.stringify(newBody));
  } else {
    return post("getPaymentMethods", authHeader(), JSON.stringify(body));
  }
};

export { makePayment, getPaymentmethods };
