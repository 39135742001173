const SHARE = {
  TWITTER_EXTENSION: "https://twitter.com/intent/tweet?url=",
  PINTEREST_EXTENSION: "http://pinterest.com/pin/create/button/?url=",
  FB_SHARE_TITLE: "nnnow",
  WHATSAPP_EXTENSION_MOBILE: "whatsapp://send?text=",
  WHATSAPP_EXTENSION_DESKTOP: "https://web.whatsapp.com/send?text=",
};

const FAV = {
  SUCCESSFULL_ADD: "Good mooove! This item has been added to your favourites.",
  SUCCESSFULL_REMOVED: "This item has been removed from your favourites.",
  ERROR: "Oops something went wrong, plz try again",
};

const FAVNSHARE = { SHARE, FAV };

export default FAVNSHARE;
