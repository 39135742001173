import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Login, PAYMENTPAGE } from "npmlinks-constants";
import OtpV2 from "../../../../OtpV2";

@inject("LoginStore")
@observer
class RegisterOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generateOtpReqBody: {},
      validateOtpReq: {},
    };
    this.afterValidationCallBack = this.afterValidationCallBack.bind(this);
    this.afterGenerateOtpCallBack = this.afterGenerateOtpCallBack.bind(this);
    this.afterValidationFailedCallBack = this.afterValidationFailedCallBack.bind(this);
    this.onboardDetails = this.props.LoginStore.formatSocialNormalRegisterQuery(
      this.props.onboardDetails,
      this.props.missingFields,
    );
  }

  componentDidMount() {
    const { mobileNo } = this.props.onboardDetails;
    this.setState({
      generateOtpReqBody: {
        mobileNumber: mobileNo,
      },
    });
  }

  afterGenerateOtpCallBack(generateOtpResponse) {
    if (generateOtpResponse && generateOtpResponse.sessionID) {
      const { mobileNo } = this.props.onboardDetails;
      const { sessionID } = generateOtpResponse;
      this.setState({
        validateOtpReq: {
          mobileNumber: mobileNo,
          sessionID,
        },
      });
    }
  }

  afterValidationCallBack(response) {
    if (response && response.data) {
      this.props.createAccount(response, true);
    }
  }

  afterValidationFailedCallBack(response) {
    this.props.createAccount(response, false);
  }

  render() {
    const { generateOtpReqBody, validateOtpReq } = this.state;
    return (
      <div className="nwc-register-otp">
        <OtpV2.component
          generateOtpReqBody={generateOtpReqBody}
          validateOtpReq={validateOtpReq}
          otpHeading=""
          otpSubHeading={Login.LOGIN_MESSAGES.REGISTERPAGE_PH_OTP_SENT_MESSAGE}
          throughService={PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH}
          afterGenerateOtpCallBack={this.afterGenerateOtpCallBack}
          afterValidationCallBack={this.afterValidationCallBack}
          afterValidationFailedCallBack={this.afterValidationFailedCallBack}
          registration={true}
          onboardDetails={this.onboardDetails}
          phoneOtpValidatingMessage={
            Login.LOGIN_MESSAGES.REGISTERPAGE_PH_OTP_VALIDATING_MESSAGE
          }
        />
      </div>
    );
  }
}

RegisterOtp.wrappedComponent.propTypes = {
  onboardDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    mobileNo: PropTypes.number.isRequired,
  }).isRequired,
  createAccount: PropTypes.func.isRequired,
  LoginStore: PropTypes.shape({
    mobileNumber: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const projectComponent = {
  getFinalComponent,
  wrapper: RegisterOtp.wrappedComponent,
  component: RegisterOtp,
};

export default projectComponent;