import { observable, computed } from "mobx";

import StaticPageStoreActions from "./actions";
import { brandFilter } from "./method";

import { BRANDSLIST } from "npmlinks-constants";

class StaticPageStore extends StaticPageStoreActions {
  constructor() {
    super();

    this.temp = "";
  }

  @observable whoWeAreDescription = this.temp;
  @observable customerCareDescription = this.temp;
  @observable termsAndConditionDescription = this.temp;
  @observable privacyPolicyDescription = this.temp;
  @observable contactusResponse = false;
  @observable brandListObject = [];
  @observable brandBannerObject = [];
  @observable trendingBannerObject = [];
  @observable brandListFilter = ["View All"];
  @observable filterValue = "View All";
  @observable searchValue = this.temp;
  @observable brandFilterId = null;

  @computed get filteredBrandList() {
    if (this.brandListObject && this.brandListObject.length) {
      // Both brandJourney + homeJourney filtering is done here
      const brandData = brandFilter(
        this.brandListObject,
        this.brandFilterId,
        this.filterValue,
        this.searchValue,
        BRANDSLIST.HOME_JOURNEY_BRAND_FILTER,
      );

      return brandData && brandData.length ? brandData : [];
    }
    return [];
  }
}

export default StaticPageStore;
