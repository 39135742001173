import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import FilterList from "../../FilterList";
import BodyOverlay from "../../../BodyOverlay";

@inject("ProductStore")
@observer
class FilterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
  }

  get isActiveClassName() {
    return (this.state.isActive && "is-active") || "";
  }

  showFilter() {
    if (!this.props.ProductStore.isFetchingData) {
      this.setState({
        isActive: true,
      });
      BodyOverlay.component.show("nw-filtercontainernew-overlay");
    }
  }

  hideFilter() {
    if (!this.props.ProductStore.isFetchingData) {
      this.setState({
        isActive: false,
      });
      BodyOverlay.component.hide();
    }
  }

  render() {
    const { eachfilterBy } = this.props;

    return (
      <li
        className="nw-filtercontainernew-list"
        onMouseEnter={this.showFilter}
        onMouseLeave={this.hideFilter}
      >
        <span className="nw-filtercontainernew-button">
          {eachfilterBy.displayName}
        </span>
        {eachfilterBy.list.length > 0 && (
          <ul className="nw-filtercontainernew-dummycontainer">
            <li>
              <ul
                className={`nw-filtercontainernew-wrapper nwc-custom-scrollbar ${
                  this.isActiveClassName
                }`}
              >
                {eachfilterBy.list.map((eachlist, index) => (
                  <FilterList.component
                    key={eachlist.value}
                    filterlist={eachlist}
                    name="All Filters"
                    index={index}
                    mapping={eachfilterBy.mapping}
                    onFilterSelect={this.hideFilter}
                    filterTitle={eachfilterBy.displayName}
                  />
                ))}
              </ul>
            </li>
          </ul>
        )}
      </li>
    );
  }
}

FilterContainer.wrappedComponent.propTypes = {
  eachfilterBy: PropTypes.shape({}).isRequired,
  ProductStore: PropTypes.shape({
    isFetchingData: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FilterContainer.wrappedComponent,
  component: FilterContainer,
};

export default ProjectComponent;
