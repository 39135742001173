/*
  list to dictionary conversion
  convert an array of object to key:value pair
  key should be present in array-- don't need to check
 */
const arrayToKeyValuePair = (list = [], key) =>
  list.reduce((prev, cur) => {
    if (cur && cur[key]) {
      // eslint-disable-next-line
      prev[cur[key]] = cur;
    }
    return prev;
  }, {});

export { arrayToKeyValuePair };
