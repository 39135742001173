import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Input, Button, Utils, Toastr } from "arv-reactcomponents";
import Analytics, { GTM } from "../../analytics";
import Event from "../../analytics/eventFactory";
import Filter from "./Filter";
import { pageType, BLANK_SEARCH_MESSAGE } from "npmlinks-constants";
import { HTMLUtils } from "../../utils";
import GTMEvents from "../../analytics/eventFactory/GTMEvents";

@inject("SearchBarStore", "ProductStore")
@observer
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.handleBlankSearchQuery = this.handleBlankSearchQuery.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    this.queryUpdatedFromStore = false;
  }
  componentDidUpdate() {
    if (this.props.SearchBarStore.showSearch) {
      const container = document.querySelector(".nw-search");
      if (container) {
        Utils.scrollTo(container, 0, 0);
      }
      console.log("search active");
      const searchInput = document.querySelector(".nw-search-input");
      if (searchInput) {
        searchInput.focus();
      }
      const { queryApplied } = this.props.ProductStore;
      if (queryApplied && !this.queryUpdatedFromStore) {
        this.queryUpdatedFromStore = true;
        this.updateSearchText(queryApplied);
      }
    }
  }
  get isSearchOpenClassName() {
    return (!this.props.SearchBarStore.showSearch && "nwc-hide") || "";
  }
  handleBlankSearchQuery(event) {
    let value = event ? event.target.value : this.state.searchText;
    value = HTMLUtils.sanitizeHTML(value);
    const searchQuery = value.replace(/\s/g, "");
    if (searchQuery.length === 0) {
      this.setState({ searchText: "" });
      return true;
    }
    return false;
  }

  updateSearchText(queryApplied) {
    if (queryApplied !== this.state.searchText) {
      const obj = {
        target: {
          value: queryApplied,
        },
      };
      this.handleChange(obj);
    }
  }
  toggleSearch() {
    this.props.SearchBarStore.toggleSearch();
  }
  handleChange(event) {
    const searchQuery = this.handleBlankSearchQuery(event);
    if (searchQuery) {
      this.props.SearchBarStore.setSearchText("");
      return this.isSearchOpenClassName;
    }
    const value = HTMLUtils.sanitizeHTML(event.target.value);
    this.setState({ searchText: value });
    this.props.SearchBarStore.setSearchText(value);
  }

  handleSubmit(event) {
    const { searchText } = this.state;
    const queryLength = searchText.length > 0;
    event.preventDefault();
    const searchQuery = this.handleBlankSearchQuery();
    if (searchQuery) {
      Analytics.trackEvent({
        action: Event.action.BLANK_QUERY_ERROR,
        label: Event.label.getSearchQuery(searchText),
        category: Event.category.SEARCH,
      });
      Toastr.showToastr({
        className: "nwc-toastr-list-danger nw-toastr",
        message: BLANK_SEARCH_MESSAGE,
        timeout: 3000,
      });
      return this.isSearchOpenClassName;
    }
    this.props.SearchBarStore.handleSearch();
    if (window.pageType === pageType.listing) {
      Analytics.trackEvent({
        action: queryLength
          ? Event.action.REPEAT_SEARCH
          : Event.action.BLANK_QUERY_ERROR,
        label: Event.label.getSearchQuery(this.state.searchText),
        category: Event.category.SEARCH,
      });
    } else {
      Analytics.trackEvent({
        action: queryLength
          ? Event.action.QUERY_ENTER
          : Event.action.BLANK_QUERY_ERROR,
        label: Event.label.getSearchQuery(this.state.searchText),
        category: Event.category.SEARCH,
      });
    }
    GTM.pushWithEvent({
      queryString: this.state.searchText
    },
    GTMEvents.SEARCH_QUERY);
  }

  render() {
    const { currentState } = this.props;
    const { showSearch } = this.props.SearchBarStore;
    return (
      <div className={`nw-search ${this.isSearchOpenClassName}`}>
        <div className="nw-search-container">
          <form
            onSubmit={this.handleSubmit}
            autoComplete="off"
            id="nwc-search-form"
            className="nw-seach-form"
          >
            <Input
              id="search"
              type="text"
              className="nwc-inp-dark-background nwc-inp-xxl nw-search-input"
              placeholder="Enter Search..."
              value={this.state.searchText}
              onChange={this.handleChange}
            />
          </form>
          <Filter.component
            className="nw-search-filters"
            store={this.props.SearchBarStore}
            currentState={currentState}
          />
          <Button className="nw-search-overlay" onClick={this.toggleSearch}>
            <div />
          </Button>
        </div>
      </div>
    );
  }
}
Search.wrappedComponent.defaultProps = {
  currentState: "",
};
Search.wrappedComponent.propTypes = {
  ProductStore: PropTypes.shape({
    queryApplied: PropTypes.string.isRequired,
  }).isRequired,
  currentState: PropTypes.string,
  SearchBarStore: PropTypes.shape({
    showSearch: PropTypes.bool,
    searchText: PropTypes.string,
    setSearchText: PropTypes.func,
    toggleSearch: PropTypes.func,
    handleSearch: PropTypes.func,
  }).isRequired,
};

export { default as Filter } from "./Filter";
export { default as FilterIcon } from "./FilterIcon";
export { default as FilterIconList } from "./FilterIconList";
export * as IconSpecific from "./IconSpecific";

const getFinalComponent = component =>
  inject("SearchBarStore", "ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Search.wrappedComponent,
  component: Search,
};

export default ProjectComponent;
