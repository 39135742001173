import React from "react";
import PropTypes from "prop-types";
import { footerUrls, COPYRIGHT_TEXT } from "npmlinks-constants";
import Anchor from "../../Anchor";

function FinePrintLinks(props) {
  return (
    <div className="nw-fineprint-container">
      <Anchor.component className="nw-fineprint-links">
        {COPYRIGHT_TEXT}
      </Anchor.component>
      <Anchor.component
        target="_self"
        className="nw-fineprint-links"
        href={props.store.basePath + footerUrls.privacypolicy}
        to={props.store.basePath + footerUrls.privacypolicy}
      >
        {footerUrls.privacytext}
      </Anchor.component>
      <Anchor.component
        target="_self"
        className="nw-fineprint-links"
        href={props.store.basePath + footerUrls.termsconditions}
        to={props.store.basePath + footerUrls.termsconditions}
      >
        {footerUrls.tnctext}
      </Anchor.component>
    </div>
  );
}

FinePrintLinks.propTypes = {
  store: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FinePrintLinks,
  component: FinePrintLinks,
};

export default ProjectComponent;
