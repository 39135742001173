/* eslint-disable prettier/prettier */
// Import Core Components here
import PropTypes from "prop-types";
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import loadashGet from "lodash.get";
import Analytics from "../../../../analytics";
import GTM from "../../../../analytics/GTM";
import Event from "../../../../analytics/eventFactory";
import { blackboltConfig, Login, Endpoints } from "npmlinks-constants";
import RegisterOtp from "./RegisterOTP";

// Import App depedency here
import { Button, Ripple, Utils, Loader } from "arv-reactcomponents";

import EmailInput from "../../EmailInput";
import MobileNumberInput from "../../MobileNumberInput";
import PasswordInput from "../../PasswordInput";
import UserNameInput from "../../UserNameInput";

@inject("LoginStore", "OtpStore")
@observer
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authErrorMsg: "",
      showOtpField: false,
      hideFormFieldClass: "",
      highlightNameField: false,
      highlightMobileField: false,
      highlightEmailField: false,
      highlightPasswordField: false,
    };
    this.emailFields = null;
    this.mobileNoFields = null;
    this.passwordFields = null;
    this.userNameFields = null;
    this.onboardDetailsObj = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.showLoginView = this.showLoginView.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.generateOtp = this.generateOtp.bind(this);
    this.checkUserExist = this.checkUserExist.bind(this);
    this.directRegister = this.directRegister.bind(this);
    this.handleErrorCall = this.handleErrorCall.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.mobileNumberChange = this.mobileNumberChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }
  componentDidMount() {
    const invalidInput = document.querySelector(".nw-register-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
    this.props.LoginStore.getPasswordValidationRegex();
  }

  generateOtp() {
    this.setState({
      hideFormFieldClass: "nwc-hide",
      showOtpField: true,
      authErrorMsg: ""
    });
  }

  checkUserExist(mobileNo, email) {
    this.props.LoginStore.checkUserExist(mobileNo, email)
      .then(res => {
        const { emailIdPresent, mobileNumberPresent } = res;
        if (emailIdPresent || mobileNumberPresent) {
          const defaultErrorMsg = blackboltConfig.DEFAULT_CHECK_USER_ERROR_MSG;
          const { message = defaultErrorMsg } = res;
          this.setState({
            authErrorMsg: message
          });
        } else {
          this.generateOtp();
        }
      })
      .catch(err => {
        this.setState({
          authErrorMsg: blackboltConfig.ERROR_MSG
        });
      });
  }

  onSubmit(e) {
    this.setState({ authErrorMsg: "" });
    e.preventDefault();
    const invalidInput = document.querySelector(
      ".nw-register-form input.is-invalid"
    );
    if (invalidInput) {
      invalidInput.focus();
    }

    const { email, isEmailInputValid } = this.emailFields.state;
    const { mobileNumber, isMobileInputValid } = this.mobileNoFields.state;
    const { userName: name, isUserInputValid } = this.userNameFields.state;
    const { password, isPasswordInputValid } = this.passwordFields.state;

    if (!name) {
      this.setState({ highlightNameField: true });
    }
    if (!mobileNumber) {
      this.setState({ highlightMobileField: true });
    }
    if (!email) {
      this.setState({ highlightEmailField: true });
    }
    if (!password) {
      this.setState({ highlightPasswordField: true });
    }
    if (
      isEmailInputValid &&
      isMobileInputValid &&
      isPasswordInputValid &&
      isUserInputValid
    ) {
      Analytics.trackEvent({
        action: Event.action.REGISTER_ATTEMPT,
        category: Event.category.LOGIN
      });
      const onboardDetails = {
        email,
        mobileNumber,
        password,
        name
      };
      this.onboardDetailsObj = Object.assign({}, onboardDetails);
      if (process.env.REACT_APP_REGISTER_OTP_ENABLED === "true") {
        this.checkUserExist(mobileNumber, email);
      } else {
        this.directRegister();
      }
    }
  }

  // register with validatedmobile number
  createAccount(data, success) {
    this.setState({ showOtpField: false, hideFormFieldClass: "" });
    if (success) {
      this.props.LoginStore.otpUserRegistration(data.register);
    } else {
      this.handleErrorCall(data);
    }
  }

  // register without validating mobile number
  directRegister() {
    this.props.LoginStore.userOnboard(this.onboardDetailsObj)
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        this.handleErrorCall(error);
      });
  }

  handleErrorCall(data) {
    const errObj = loadashGet(data, "data.errors", []);
    const finalErrorDetail =
      errObj &&
      errObj[0] &&
      blackboltConfig.ERROR_CODE.includes(errObj[0].status)
        ? errObj[0].detail
        : blackboltConfig.ERROR_MSG;
    this.setState({
      authErrorMsg: finalErrorDetail
    });
    Analytics.trackEvent({
      action: Event.action.REGISTER_ERROR,
      category: Event.category.LOGIN,
      label: Event.label.getRegisterationError(errObj)
    });
    GTM.pushEvent(Event.GTMEvent.REGISTER_FAILED);
  }

  get isShowLoader() {
    return this.props.LoginStore.loader ? "" : "nwc-hidden";
  }

  showLoginView(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.LOGIN_CLICK,
      category: Event.category.LOGIN
    });
    GTM.pushEvent(Event.GTMEvent.LOGIN_CLICKED);
    this.props.LoginStore.changeView("login");
  }

  nameChange() {
    this.setState({ highlightNameField: false });
  }

  mobileNumberChange() {
    this.setState({ highlightMobileField: false });
  }

  emailChange() {
    this.setState({ highlightEmailField: false });
  }

  passwordChange() {
    this.setState({ highlightPasswordField: false });
  }

  render() {
    const {
      authErrorMsg,
      showOtpField,
      highlightNameField,
      highlightMobileField,
      highlightEmailField,
      highlightPasswordField
    } = this.state;
    const {
      passwordValidationRegex,
      passwordRegexLoader
    } = this.props.LoginStore;
    const { regex, errorMessage } = toJS(passwordValidationRegex);
    if (passwordRegexLoader) {
      return (
        <div className="nw-register-loader-wrapper">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="nw-login-container">
          <div className="nw-login-heading">
            <h1 className="nw-login-title">
              {Login.LOGIN_MESSAGES.REGISTERPAGE_TITLE}
            </h1>
            <p className="nw-login-sub-title">
              {Login.LOGIN_MESSAGES.REGISTERPAGE_SUBTITLE}
            </p>
          </div>
          <form
            className={`nw-form nw-register-form ${this.state.hideFormFieldClass}`}
            name="registerForm"
            onSubmit={this.onSubmit}
          >
            <UserNameInput.component
              className="nw-login-input nw-register-userid"
              placeholder={Login.LOGIN_MESSAGES.REGISTERPAGE_PH_NAME}
              name="username"
              ref={values => {
                this.userNameFields = values;
              }}
              highlightNameField={highlightNameField}
              onNameChange={this.nameChange}
              required
            />
            <MobileNumberInput.component
              className="nw-login-input nw-register-mobilenumber"
              placeholder={Login.LOGIN_MESSAGES.REGISTERPAGE_PH_MOBILENO}
              name="mobile number"
              maxLength={10}
              ref={values => {
                this.mobileNoFields = values;
              }}
              highlightMobileField={highlightMobileField}
              onMobileNumberChange={this.mobileNumberChange}
              required
            />
            <EmailInput.component
              className="nw-login-input nw-register-email"
              placeholder={Login.LOGIN_MESSAGES.REGISTERPAGE_PH_EMAIL}
              name="email"
              ref={values => {
                this.emailFields = values;
              }}
              highlightEmailField={highlightEmailField}
              onEmailChange={this.emailChange}
              required
            />
            <PasswordInput.component
              className="nw-login-input nw-register-password"
              placeholder={Login.LOGIN_MESSAGES.REGISTERPAGE_PH_PASSWORD}
              name="password"
              showErrorMsg={Utils.noop}
              minLength={8}
              ref={values => {
                this.passwordFields = values;
              }}
              pattern={regex}
              msg={errorMessage}
              highlightPasswordField={highlightPasswordField}
              onPasswordChange={this.passwordChange}
              required
            />
            <Ripple className="nwc-ripple-light">
              <Button
                onClick={this.onSubmit}
                className="nwc-btn-primary nw-login-btn nw-registerv1-submitbtn"
              >
                register
                <div className={`nw-login-loader ${this.isShowLoader}`}>
                  <img
                    className="nw-loader"
                    src={Endpoints.LOADER}
                    alt="loader"
                  />
                </div>
              </Button>
            </Ripple>
            {authErrorMsg ? (
              <span className="nw-login-error">{authErrorMsg}</span>
            ) : (
              ""
            )}
            <Button
              onClick={this.showLoginView}
              className="nw-login-link nw-register-home"
            >
              {Login.LOGIN_MESSAGES.BACKTOLOGIN}
            </Button>
          </form>
          {showOtpField && (
            <RegisterOtp.component
              onboardDetails={this.onboardDetailsObj}
              createAccount={this.createAccount}
            />
          )}
        </div>
      );
    }
  }
}

Register.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    userOnboard: PropTypes.func.isRequired,
    changeView: PropTypes.func.isRequired,
    loader: PropTypes.bool.isRequired,
    passwordValidationRegex: PropTypes.shape({
      regex: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("LoginStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Register.wrappedComponent,
  component: Register
};

export default ProjectComponent;
