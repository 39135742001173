import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { Utils } from "arv-reactcomponents";
import MainList from "./MainList";

@inject("NavListStore")
@observer
class BrandNavContainer extends Component {
  constructor(props) {
    super(props);

    this.closeBrandMenu = this.closeBrandMenu.bind(this);
  }

  get isActiveClassName() {
    const { NavListStore } = this.props;
    const { brandPageNnnowMenuShow } = NavListStore;

    return (brandPageNnnowMenuShow && "is-active") || "";
  }

  closeBrandMenu() {
    this.props.NavListStore.setNnnowMenuShow(false);
    Utils.unFixScroll();
  }

  render() {
    const navData = this.props.NavListStore.navListData;
    const { data: navDataData } = navData || {};
    if (!navDataData)
      return null;

    return (
      <nav
        className={`
          nw-brandnavcontainernew-listcontainer 
          ${this.isActiveClassName}
        `}
        onClick={this.closeBrandMenu}
        onKeyDown={Utils.noop}
        role="presentation"
      >
        <ul
          className="nw-brandnavcontainernew-list"
          onClick={Utils.preventEventPropagation}
          onKeyDown={Utils.noop}
          role="presentation"
        >
          {(navDataData &&
            navDataData.nnnow &&
            navDataData.nnnow.level1 &&
            navDataData.nnnow.level1.map(topMenu => (
              <MainList.component key={topMenu.name} menu={topMenu} />
            ))) ||
            null}
        </ul>
      </nav>
    );
  }
}

BrandNavContainer.wrappedComponent.propTypes = {
  NavListStore: PropTypes.shape({
    setNnnowMenuShow: PropTypes.func,
    brandPageNnnowMenuShow: PropTypes.bool,
    navListData: PropTypes.object,
    getNavListData: PropTypes.func,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { default as MainList } from "./MainList";

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: BrandNavContainer.wrappedComponent,
  component: BrandNavContainer,
};

export default ProjectComponent;
