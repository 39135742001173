import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Input, Label, Utils } from "arv-reactcomponents";
import { isFilterSelected } from "../../../utils";
import FilterActionsWrapper from "../FilterActionsWrapper";

@withRouter
@inject("ProductStore")
@observer
class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected:
        props.filterlist.isSelected ||
        isFilterSelected(
          props.filterlist,
          props.mapping,
          props.ProductStore.mobileTempUrlCache.search,
        ),
    };
    this.filterClick = this.filterClick.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      isSelected:
        nextProps.filterlist.isSelected ||
        isFilterSelected(
          nextProps.filterlist,
          nextProps.mapping,
          nextProps.ProductStore.mobileTempUrlCache.search,
        ),
    });
  }
  get showForColor() {
    return (this.props.mapping === "f-co" && "nwc-active") || "nwc-hide";
  }

  filterClick() {
    this.setState({ isSelected: !this.state.isSelected });
  }
  render() {
    const { filterlist, className } = this.props;
    if (!filterlist || !filterlist.count) return null;

    return (
      <li className="nw-filterlist-eachfilter">
        <Label
          className="nw-filterlist-label"
          htmlFor={`filterlist-each-filter-${filterlist.displayName}`}
        >
          <FilterActionsWrapper.component {...this.props}>
            <Input
              id={`filterlist-each-filter-${filterlist.displayName}`}
              type="checkbox"
              value={this.props.index}
              className={`nwc-inp-checkbox-primary nwc-inp-xxs is-covered ${className}`}
              checked={this.state.isSelected}
              name={filterlist.displayName}
              onChange={this.filterClick}
            />
          </FilterActionsWrapper.component>
          <span
            style={{ backgroundColor: filterlist.colorCode }}
            className={`nw-filterlist-color ${this.showForColor}`}
          />
          <span className={`nw-filterlist-span ${className}`}>
            {filterlist.displayName}
            <span className="nw-filterlist-count">({filterlist.count})</span>
          </span>
        </Label>
        {this.state.isSelected &&
          filterlist.list &&
          filterlist.list.length > 0 && (
            <ul>
              {filterlist.list.map((subfilter, index) => (
                <FilterList
                  filterlist={subfilter}
                  mapping={this.props.mapping}
                  ProductStore={this.props.ProductStore}
                  location={this.props.location}
                  history={this.props.history}
                  className={this.props.className}
                  onFilterSelect={Utils.noop}
                  value={index}
                  key={subfilter.value}
                  type="subFilters"
                />
              ))}
            </ul>
          )}
      </li>
    );
  }
}

FilterList.wrappedComponent.defaultProps = {
  className: "",
  onFilterSelect: Utils.noop,
  // type: "",
};

FilterList.wrappedComponent.propTypes = {
  // type: PropTypes.string,
  index: PropTypes.number.isRequired,
  filterlist: PropTypes.shape({
    colorCode: PropTypes.string,
    isSelected: PropTypes.bool,
  }).isRequired,
  className: PropTypes.string,
  mapping: PropTypes.string.isRequired,
  ProductStore: PropTypes.shape({
    setListingPageNo: PropTypes.func.isRequired,
    mobileTempUrlCache: PropTypes.shape().isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  onFilterSelect: PropTypes.func,
  filterTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: FilterList.wrappedComponent,
  component: FilterList,
};

export default ProjectComponent;
