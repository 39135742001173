const LOYALTY_EARN_POINTS_TEXT = "YOU'VE EARNED LOYALTY POINTS!";
const LOYALTY_REDEEM_POINTS_TEXT =
  "Redeem your reward points at any of our brand’s stores near you.";
const LOYALTY_POINTS_LINK_TEXT = "Check your Loyalty Points";
const LOYALTY_TABLE_HEADINGS = [
  "Brands",
  "Points Earned",
  "Loyalty Value",
  "Loyalty Balance",
];
const LOYALTY_SUMMARY_TEXT1 = "HERE'S";
const LOYALTY_SUMMARY_TEXT2 = "SUMMARY OF YOUR";
const LOYALTY_POINTS_TEXT = "LOYALTY POINTS";

const LOYALTY_BLOCK_MESSAGES = {
  ERROR_MESSAGE: "Oops! Something went wrong while fetching Loyalty Data.",
};

export {
  LOYALTY_EARN_POINTS_TEXT,
  LOYALTY_POINTS_LINK_TEXT,
  LOYALTY_REDEEM_POINTS_TEXT,
  LOYALTY_TABLE_HEADINGS,
  LOYALTY_SUMMARY_TEXT1,
  LOYALTY_SUMMARY_TEXT2,
  LOYALTY_POINTS_TEXT,
  LOYALTY_BLOCK_MESSAGES,
};
