import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Utils } from "arv-reactcomponents";
import { readCookie } from "../../utils";
import MyAccountLink from "../MyAccountLink";
import LogoutButton from "../LogoutButton";
import TrackOrderLink from "../TrackOrderLink";
import FavoritesLink from "../FavoritesLink";

@inject("LoginStore", "CommonStore")
@observer
class StaticLoggedInMenu extends PureComponent {
  constructor() {
    super();

    this.state = {
      isDowpdownActive: false,
    };

    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.toggleDropDownArrow = this.toggleDropDownArrow.bind(this);
  }

  get dropdownActiveClassName() {
    return this.state.isDowpdownActive ? "" : "nwc-hide";
  }

  get dropdownInActiveClassName() {
    return !this.state.isDowpdownActive ? "" : "nwc-hide";
  }

  get firstName() {
    const firstName = this.props.LoginStore.firstName;
    return readCookie("userName") || firstName;
  }

  toggleDropDownArrow(){
    if(!this.state.isDowpdownActive) {
      this.showDropdown();
    } else {
      this.hideDropdown();
    }
  }

  showDropdown() {
    this.setState({
      isDowpdownActive: true,
    });
  }

  hideDropdown() {
    this.setState({
      isDowpdownActive: false,
    });
  }

  render() {
    const { className } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const onMouseEnter = isDesktop && this.showDropdown || Utils.noop;
    return (
      <div
        className={`nw-staticloggedinmenu ${className}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={this.hideDropdown}
      >
        <div className="nw-staticloggedinmenu-namewrapper" onClick={this.toggleDropDownArrow}>
          <i className="icomoon-person1 nw-staticloggedinmenu-personicon nw-mobile-only"></i>
          <span className="nw-staticloggedinmenu-username nw-desktop-only" title={this.firstName}>
            {this.firstName}
          </span>
          <i className={`icomoon-arrow_bottom nw-staticloggedinmenu-arrowbottom ${this.dropdownInActiveClassName}`} />
          <i className={`icomoon-arrow_top nw-staticloggedinmenu-arrowbottom ${this.dropdownActiveClassName}`} />
        </div>
        <ul className={`nw-staticloggedinmenu-listul ${this.dropdownActiveClassName}`}>
          <li
            className="nw-staticloggedinmenu-listli"
          >
            <MyAccountLink.component
              className="nw-staticloggedinmenu-btns"
            />
          </li>
          <li
            className="nw-staticloggedinmenu-listli"
          >
            <FavoritesLink.component
              className="nw-staticloggedinmenu-btns"
            >
              Favorites
            </FavoritesLink.component>
          </li>
          <li
            className="nw-staticloggedinmenu-listli"
          >
            <TrackOrderLink.component
              className="nw-staticloggedinmenu-btns"
            >
              My Orders
            </TrackOrderLink.component>
          </li>
          <li
            className="nw-staticloggedinmenu-listli nw-loggedinmenu-signout"
          >
            <LogoutButton.component
              className="nw-staticloggedinmenu-btns"
            />
          </li>
        </ul>
      </div>
    );
  }
}

StaticLoggedInMenu.wrappedComponent.defaultProps = {
  className: "",
};

StaticLoggedInMenu.wrappedComponent.propTypes = {
  className: PropTypes.string,
  LoginStore: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("LoginStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StaticLoggedInMenu.wrappedComponent,
  component: StaticLoggedInMenu,
};

export default ProjectComponent;
