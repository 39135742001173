import { deleteApi, get, post, put } from "../../api";
import { authHeader } from "../../utils";

const userAddressesData = (headers, param) =>
  get("shippingAddress", { ...headers, ...authHeader() }, param);

const pincodeDetailsData = (headers, pincode) =>
  get("pincodeDetails", authHeader(), headers, pincode);

const addAddressService = (body, servicibilityCheck, param) =>
  post(
    "addAddress",
    { ...authHeader(), returnaddresscheck: servicibilityCheck },
    body,
    param
  );

const removeAddress = addressId =>
  deleteApi("shippingAddress", authHeader(), null, addressId);
const setAddressDefault = addressId =>
  put(
    "shippingAddress",
    authHeader(),
    null,
    `${addressId}?defaultAddress=true`
  );

const editAddress = (body, addressIdItemIdParam, servicibilityCheck) =>
  put(
    "editAddress",
    { ...authHeader(), returnaddresscheck: servicibilityCheck },
    body,
    addressIdItemIdParam
  );

export {
  addAddressService,
  pincodeDetailsData,
  removeAddress,
  setAddressDefault,
  userAddressesData,
  editAddress
};