export default class GTM {
  /**
   * Pushes an object to GTM data layer
   */
  static pushWithEvent(analyticsObject, eventName = null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(analyticsObject, { event: eventName });
  }

  static pushObject(analyticsObject) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(analyticsObject);
  }

  static pushEvent(eventName = null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: eventName });
  }

  static clearEcommDataLayer() {
    window.dataLayer.push({"ecommerce" : null})
  }

  /**
   * Resets the dataLayer on initialisation
   */
  static reset() {
    // this.pushObject({
    //   pageType: window.pageType,
    //   ecommerce: false,
    // });
  }

  // Similarly we will push for other GTM events
  static identify(traits) {
    this.push({
      GAEmail: traits.email,
      Identify: "1",
      Joined_at: traits.createdAt,
      Name: traits.name,
    });
  }
}
