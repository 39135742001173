import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import lodashGet from "lodash.get";
import { Img } from "arv-reactcomponents";

import { Endpoints } from "npmlinks-constants";
import Analytics, { EventFactory as Event } from "../../analytics";
import Anchor from "../../component/Anchor";

@inject("NavListStore", "CommonStore")
@observer
class Logo extends Component {
  static onLogoClick() {
    Analytics.trackEvent({
      action: Event.action.LOGO_CLICK,
      label: Event.label.getSource(window.pageType),
      category: Event.category.TOP_MENU_NAVIGATION,
    });
  }

  get logoAlt() {
    const { CommonStore, alt } = this.props;
    const { brandName } = CommonStore;

    return brandName ? `${brandName} Logo` : alt;
  }

  render() {
  	const { NavListStore, alt, className } = this.props;
  	const { navListData } = NavListStore;
    const logoFromApi = lodashGet(navListData, "data.logos.brand.image", null);
    const logoUrl = logoFromApi || Endpoints.nnnowLogoUrl;

    if (logoUrl) {
      return (
      	<Anchor.component
          href="/"
          to="/"
          target="_self"
          className={`nw-logo-link ${className}`}
          onClick={Logo.onLogoClick}
        >
          <Img
            className="nw-logo-img"
            src={logoUrl}
            alt={this.logoAlt}
          />
        </Anchor.component>
      );
    }
    return null;
  }
}

Logo.wrappedComponent.defaultProps = {
  className: "",
  alt: "NNNOW Logo",
};

Logo.wrappedComponent.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  NavListStore: PropTypes.shape({
  	navListData: PropTypes.shape({}).isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("NavListStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Logo.wrappedComponent,
  component: Logo,
};

export default ProjectComponent;
