import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { Utils } from "arv-reactcomponents";

class NavList extends Component {
  constructor(props) {
    super(props);

    this.paramValidation = this.paramValidation.bind(this);
    this.getHref = this.getHref.bind(this);
    this.isButtonWithBorder = this.isButtonWithBorder.bind(this);
    this.showPlusMinus = this.showPlusMinus.bind(this);
  }

  paramValidation(param, level) {
    return (
      param &&
      param[`level${level + 1}`] &&
      param[`level${level + 1}`].length !== 0
    );
  }

  getHref(param, level) {
    if (this.paramValidation(param, level)) {
      // eslint-disable-next-line no-script-url
      return "javascript:void(0)";
    }
    return param.url;
  }

  isButtonWithBorder(param) {
    return (
      (param.nodeType === "button_with_border" &&
        "nw-navlistv2-buttonwithborder") ||
      ""
    );
  }

  showPlusMinus(param, level) {
    if (
      this.paramValidation(param, level) &&
      !param.isMenuOpen &&
      (param.nodeType === "collapsible" || param.nodeType === "sub_header")
    )
      return <i className="icomoon-plus" />;
    else if (
      this.paramValidation(param, level) &&
      param.isMenuOpen &&
      (param.nodeType === "collapsible" || param.nodeType === "sub_header")
    )
      return <i className="icomoon-minus" />;
    return null;
  }

  render () {
    const { props } = this;
    const { list, level } = props;
    if (!list || (Array.isArray(toJS(list)) && list.length === 0)) {
      return null;
    }
    const topMenu = list.map(eachTopMenu => (
      <li
        className={`nw-navlistv2-li ${this.isButtonWithBorder(eachTopMenu)}`}
        key={eachTopMenu.name}
      >
        <a
          className={`nw-navlistv2-links nw-navlistv2-links${props.level} ${
            props.className
          } `}
          href={this.getHref(eachTopMenu, level)}
          target="_self"
          onClick={() =>
            props.action(eachTopMenu, this.paramValidation(eachTopMenu, level))
          }
        >
          <span className="nw-navlistv2-text">{eachTopMenu.name}</span>
          {this.showPlusMinus(eachTopMenu, level)}
        </a>
        {eachTopMenu.name &&
          list.length !== 0 &&
          eachTopMenu.isMenuOpen && (
            <ul
              className={`nw-navlistv2-ul nw-navlistv2-ul${props.level} ${
                props.className
              }`}
            >
              <NavList
                className={props.className}
                level={level + 1}
                list={eachTopMenu[`level${level + 1}`]}
                action={props.action}
              />
            </ul>
          )}
      </li>
    ));
    return topMenu;
  }
}

NavList.defaultProps = {
  className: "",
};

NavList.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number.isRequired,
  list: PropTypes.shape({}).isRequired,
  action: PropTypes.func.isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: NavList,
  component: NavList,
};

export { ProjectComponent as NavList };
