import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Input, Button, Utils, Img, Toastr } from "arv-reactcomponents";
import { PAYMENTPAGE, Endpoints, Login } from "npmlinks-constants";
import lodashGet from "lodash.get";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import CountDown from "../../CountDown";
import { RegisterCaptcha } from "../../../../molecules";

@inject("OtpStore", "LoginStore", "CodStore")
@observer
class OtpSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBtnLoader: false,
      resendButtonDisabled: false,
      errorMsg: "",
      enableSubmitBtn: false,
      otpDetails: {},
      triggerTimer: true,
      showCaptchaComponent: false
    };
    this.setSubmitBtn = this.setSubmitBtn.bind(this);
    this.validateOtpEntered = this.validateOtpEntered.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.resendOtpV2 = this.resendOtpV2.bind(this);
    this.callRegisterCaptcha = this.callRegisterCaptcha.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.hideCountDown = this.hideCountDown.bind(this);
    this.gotoPswdScreen = this.gotoPswdScreen.bind(this);
    this.props.LoginStore.resetErrorMsg("");
  }

  componentDidMount() {
    this.generateOtp();
    this.props.LoginStore.setHideCloseBtn(true);
    const invalidInput = document.querySelector(".nw-sublogin-form input");
    if (invalidInput) {
      setTimeout(() => {
        invalidInput.focus();
      });
    }
  }

  generateOtp() {
    const { showHeader } = this.props.LoginStore;
    const { onBackView } = this.props;
    const captchaEnabled = process.env.REACT_APP_REGISTER_CAPTCHA_ENABLED;
    const generateOtpReqBody = {
      mobileNumber: this.props.LoginStore.userMobileNumberV2
    };
    if (captchaEnabled === "true" && onBackView == "registrationV2") {
      const { captchaToken } = this.props.CodStore;
      generateOtpReqBody.captchaToken = captchaToken;
      this.props.OtpStore.generateOtpFuncReg(
        generateOtpReqBody,
        PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
      ).then(
        res => {
          this.afterGenerateOtpCallBack(res);
        },
        err => {
          const {
            VERIFIED_USER_FLASH_ERROR_MSG,
            NON_VERIFIED_USER_FLASH_ERROR_MSG
          } = Login.LOGIN_SUBV2_MSG;
          const msg = showHeader
            ? NON_VERIFIED_USER_FLASH_ERROR_MSG
            : VERIFIED_USER_FLASH_ERROR_MSG;
          Toastr.showToastr({
            className: "nwc-toastr-list-danger nw-toastr",
            message: msg,
            timeOut: 3000
          });
        }
      );
    }
    else if(captchaEnabled !== "true" && onBackView == "registrationV2") {
      this.props.OtpStore.generateOtpFuncReg(
        generateOtpReqBody,
        PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
      ).then(
        res => {
          this.afterGenerateOtpCallBack(res);
        },
        err => {
          const {
            VERIFIED_USER_FLASH_ERROR_MSG,
            NON_VERIFIED_USER_FLASH_ERROR_MSG
          } = Login.LOGIN_SUBV2_MSG;
          const msg = showHeader
            ? NON_VERIFIED_USER_FLASH_ERROR_MSG
            : VERIFIED_USER_FLASH_ERROR_MSG;
          Toastr.showToastr({
            className: "nwc-toastr-list-danger nw-toastr",
            message: msg,
            timeOut: 3000
          });
        }
      );
    }
     else {
      this.props.OtpStore.generateOtpFunc(
        generateOtpReqBody,
        PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
      ).then(
        res => {
          this.afterGenerateOtpCallBack(res);
        },
        err => {
          const {
            VERIFIED_USER_FLASH_ERROR_MSG,
            NON_VERIFIED_USER_FLASH_ERROR_MSG
          } = Login.LOGIN_SUBV2_MSG;
          const msg = showHeader
            ? NON_VERIFIED_USER_FLASH_ERROR_MSG
            : VERIFIED_USER_FLASH_ERROR_MSG;
          Toastr.showToastr({
            className: "nwc-toastr-list-danger nw-toastr",
            message: msg,
            timeOut: 3000
          });
        }
      );
    }
  }

  afterGenerateOtpCallBack(generateOtpResponse) {
    if (generateOtpResponse && generateOtpResponse.sessionID) {
      const { sessionID } = generateOtpResponse;
      this.setState({
        otpDetails: {
          sessionID: sessionID
        }
      });
    }
  }

  callRegisterCaptcha() {
    this.setState({ showCaptchaComponent: true });
    const { onBackView } = this.props;
    const captchaEnabled = process.env.REACT_APP_REGISTER_CAPTCHA_ENABLED;
    if (captchaEnabled === "true" && onBackView == "registrationV2") {
      setTimeout(() => {
        this.resendOtpV2();
      }, 2500);
    }
    else if (captchaEnabled !== "true" && onBackView == "registrationV2") {
        this.resendOtpV2();
    }
    else{
      this.resendOtp();
    }
  }

  resendOtp() {
    const generateOtpReqBody = {
      mobileNumber: this.props.LoginStore.userMobileNumberV2
    };
    Analytics.trackEvent({
      action: Event.action.OTP_RESEND_REQUESTED,
      category: Event.category.CHECKOUT
    });

    const { triggerTimer } = this.state;
    this.setState({
      errorMsg: "",
      otpDetails: {
        passPhrase: "",
      },
      triggerTimer: !triggerTimer,
    });
    this.props.OtpStore.resendOtpFunc(
      generateOtpReqBody,
      PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
    ).then(
      res => {
        this.setState({ resendButtonDisabled: false });
        this.afterGenerateOtpCallBack(res);
      },
      error => {
        const errorMsg = lodashGet(error, "data.detail", "");
        this.setState({
          triggerTimer:false,
          resendButtonDisabled: !!errorMsg,
          errorMsg
        });
      }
    );
    document.getElementById("nw-otp-input").value = "";
  }

  resendOtpV2() {
    const generateOtpReqBody = {
      mobileNumber: this.props.LoginStore.userMobileNumberV2
    };
    const captchaEnabled =
      process.env.REACT_APP_REGISTER_CAPTCHA_ENABLED || false;
    const { onBackView } = this.props;
    if (captchaEnabled == "true" && onBackView == "registrationV2") {
      this.setState({ showCaptchaComponent: false });
      const { captchaToken } = this.props.CodStore;
      generateOtpReqBody.captchaToken = captchaToken;
    }
    Analytics.trackEvent({
      action: Event.action.OTP_RESEND_REQUESTED,
      category: Event.category.CHECKOUT
    });

    const { triggerTimer } = this.state;
    this.setState({
      errorMsg: "",
      otpDetails: {
        passPhrase: ""
      },
      triggerTimer: !triggerTimer
    });
    this.props.OtpStore.resendOtpFuncReg(
      generateOtpReqBody,
      PAYMENTPAGE.OTPTHROUGHSERVICE.FLASH
    ).then(
      res => {
        this.setState({ resendButtonDisabled: false });
        this.afterGenerateOtpCallBack(res);
      },
      error => {
        const errorMsg = lodashGet(error, "data.detail", "");
        this.setState({
          triggerTimer: false,
          resendButtonDisabled: !!errorMsg,
          errorMsg
        });
      }
    );
    document.getElementById("nw-otp-input").value = "";
  }

  submitOtp(e) {
    e.preventDefault();
    this.props.onClickSubmit(e);
  }

  gotoPswdScreen(e) {
    e.preventDefault();
    Analytics.trackEvent({
      action: Event.action.PASSWORD_CLICKED,
      category: Event.category.LOGIN,
    });
    this.props.LoginStore.changeView("loginOtp");
    this.props.LoginStore.setShowOtp(false);
  }
  hideCountDown() {
    const { triggerTimer } = this.state;
    this.setState({
      triggerTimer: !triggerTimer,
    });
  }

  get timerComponent() {
    const { triggerTimer } = this.state;
    if (triggerTimer) {
      // eslint-disable-next-line radix
      const timeout = parseInt(process.env.REACT_APP_LOGIN_RESEND_OTP_TIMEOUT);
      return (
        <div className="nw-otpsub-countdownblock">
          <CountDown.component
            className="nw-otpsub-countdown"
            timeOut={timeout}
            hideTimer={this.hideCountDown}
          />
        </div>
      );
    }
  }

  validateOtpEntered(e) {
    const charCode = typeof e.which === "number" ? e.which : e.keyCode;
    if (isNaN(String.fromCharCode(charCode))) {
      //eslint-disable-line
      e.preventDefault();
    }
    this.setState(
      {
        errorMsg: "",
        otpDetails: {
          ...this.state.otpDetails,
          passPhrase: e.target.value
        }
      },
      () => {
        this.setSubmitBtn();
      }
    );
  }

  setSubmitBtn() {
    const { otpDetails } = this.state;
    if (PAYMENTPAGE.VALID_OTP_SIZES.includes(otpDetails.passPhrase.length)) {
      this.setState({
        enableSubmitBtn: true
      });
      this.props.otpDetailsCallBack(otpDetails);
    } else {
      this.setState({
        enableSubmitBtn: false
      });
    }
  }

  get isFailurRegister() {
    return this.props.LoginStore.registerWithOTPErrorMsg ? "" : "nwc-hidden";
  }

  get showbtnLoader() {
    return this.state.showBtnLoader ? "" : "nwc-hide";
  }

  get header() {
    return (
      <div className="nw-login-heading">
        <div>{Login.LOGIN_SUBV2_MSG.OTP_SCREEN_HEADING1}</div>
        <div>{Login.LOGIN_SUBV2_MSG.OTP_SCREEN_HEADING2}</div>
        <Img
          src={Endpoints.OTP_SCREEN_IMG}
          alt="nnnow"
          onError={Utils.noop}
          className="nw-loginotp-img"
        ></Img>
      </div>
    );
  }

  render() {
    const { otpAuthErrorMsg, userMobileNumberV2 } = this.props.LoginStore;
    const { onBackView } = this.props;
    const {
      resendButtonDisabled,
      enableSubmitBtn,
      errorMsg,
      showCaptchaComponent
    } = this.state;
    const captchaEnabled =
      process.env.REACT_APP_REGISTER_CAPTCHA_ENABLED || false;
    const { showHeader } = this.props;
    return (
      <div className="nw-otp-containerd">
        {showHeader && this.header}
        <form
          className="nw-loginotp-show-block nw-sublogin-form"
          onSubmit={this.submitOtp}
        >
          <div className="login-otpsub-heading">{`${Login.LOGIN_SUBV2_MSG.OTP_SUB_HEADING1}${userMobileNumberV2}${Login.LOGIN_SUBV2_MSG.OTP_SUB_HEADING2}`}</div>
          <div className="nw-loginv2-label nw-loginv2-otplabel">OTP</div>
          <Input
            id="nw-otp-input"
            className="nw-login-input nw-otpsub-input"
            classNameErr="nw-form-err-msgv2"
            type="tel"
            maxlength={PAYMENTPAGE.OTP_MAXDIGIT}
            minlength={PAYMENTPAGE.OTP_MINDIGIT}
            onChange={this.validateOtpEntered}
            onKeyPress={this.validateOtpEntered}
          />
          <div className={`nw-form-err-msgv2`}>
            {errorMsg || otpAuthErrorMsg}{" "}
          </div>
          <div className="nw-otpsub-block">
            {!showHeader && (
              <Button
                className="nw-goto-pswd-screen"
                onClick={this.gotoPswdScreen}
              >
                {Login.LOGIN_SUBV2_MSG.GOTO_OTP_SCREEN_LABEL}
              </Button>
            )}
            {(this.state.triggerTimer && this.timerComponent) || (
              <span className="nw-otpsub-resend-text">
                {PAYMENTPAGE.OTP_RESEND_MSG}
                <Button
                  className="nw-otp-resendbtn nw-otpsub-resendbtn"
                  onClick={() => this.callRegisterCaptcha()}
                  disabled={resendButtonDisabled}
                >
                  {PAYMENTPAGE.OTP_RESND_CTA}
                </Button>
              </span>
            )}
          </div>
          <Button
            type="submit"
            onClick={this.onSubmit}
            className="nwc-btn-primary nw-login-btn nw-register-submitbtn nw-loginotp-otp-submit"
            disabled={!enableSubmitBtn}
          >
            Submit
            <div className={`nw-login-loader ${this.showbtnLoader}`}>
              <Img className="nw-loader" src={Endpoints.LOADER} alt="loader" />
            </div>
          </Button>
        </form>
        {showCaptchaComponent && onBackView == "registrationV2" && captchaEnabled === "true" && (
          <RegisterCaptcha.component loadFor="login" />
        )}
      </div>
    );
  }
}

OtpSub.defaultProps = {
  showHeader: true,
}



OtpSub.wrappedComponent.propTypes = {
  OtpStore: PropTypes.shape({
    resendOtpFunc: PropTypes.func.isRequired,
    validateOtpFunc: PropTypes.func.isRequired,
  }).isRequired,
  LoginStore: PropTypes.shape({
    disableSigninButton: PropTypes.bool.isRequired,
    otpAuthErrorMsg: PropTypes.string.isRequired,
    registerWithOTPErrorMsg: PropTypes.string.isRequired,
    resetErrorMsg: PropTypes.func.isRequired,
  }).isRequired,
};

const getFinalComponent = component => inject("OtpStore","CodStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: OtpSub.wrappedComponent,
  component: OtpSub
};

export default ProjectComponent;