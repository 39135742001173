import { post } from "../../api";
import { authHeader } from "../../utils";

const addNEFT = body => post("addNEFT", authHeader(), body);
const downloadReturnForm = body =>
  post("downloadReturnForm", authHeader(), body);
const getReturnData = body => post("returnsDetails", authHeader(), body);
const getCancelData = body => post("cancelDetails", authHeader(), body);
const makeReturn = body => post("makeReturn", authHeader(), body);

const makeCancel = body => post("makeCancel", authHeader(), body);
const initiateExchange = body => post("makeExchange", authHeader(), body);

export {
  getReturnData,
  getCancelData,
  makeReturn,
  makeCancel,
  addNEFT,
  downloadReturnForm,
  initiateExchange,
};
