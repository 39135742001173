const CHECKOUTPAGE = {
  CHEKOUT_EMPTY_MSG: "Oh, your bag seems empty. Start Shopping NNNOW.",
  SHOPNOW_BUTTON: "SHOP NOW",
  CONTINUE_BUTTON: "CONTINUE",
  CHECKOUT_SUBHEADER: {
    TITLE: "CHECKOUT",
    SHIPPING_BUTTON: {
      COUNT: 1,
      TEXT: "Shipping",
      ACTIVE_ROUTES: ["shipping"],
    },
    PAYMENT_BUTTON: {
      COUNT: 2,
      TEXT: "Payment",
      ACTIVE_ROUTES: ["payment", "paymentfailure"],
    },
  },
};

const CHECKOUT_BUTTON_TEXTS = {
  FAVORITE: "Move to Favorites",
  REMOVE: "Remove",
  SAVED_ADDRESSES: "SAVED ADDRESSES",
  ADD_ADDRESS: "ADD ADDRESS",
  CHANGE_ADDRESS: "CHANGE",
  DELIVER_HERE: "DELIVER HERE",
  ADD_ADDRESS_MODAl: "+ ADD ADDRESS",
  EDIT_BAG: "EDIT BAG",
};
const CHECKOUT_BASE_REQUEST = {
  graphProps: {
    isBagDetails: true,
    isPromotionDetails: true,
    isPricingOverview: true,
    isApplicablePromotions: false,
  },
  type: "",
  page: "",
  guestCart: [],
  removeCart: [],
  selectedShippingMethods: [],
  updateQtyCart: [],
};
const CHECKOUT_PATHNAMES = [
  "/mybag",
  "/checkout",
  "/checkout/shipping",
  "/checkout/payment",
];
const CHECKOUT_ROUTES = {
  DEFAULT: "/checkout/shipping",
  SUPPORTED: {
    mybag: "/mybag",
    shipping: "/checkout/shipping",
    payment: "/checkout/payment",
    paymentfailure: "/checkout/onpaymentfailure",
  },
};
const CHECKOUT_CONFIG = {
  COOKIE_KEY: "cart-guest",
  PROMO_KEY: "couponCode",
  PROMO_MESSAGE_TIMEOUT: 3000,
  SAVED_SHIPPING_METHODS: "savedShippingMethods",
  ADDRESS_ID: "shippingAddressId",
};
const CHECKOUT_MESSAGES = {
  NON_LOGGEDIN_CASE: {
    MESSAGE: "Please Login To Continue",
    CTA: "LOGIN",
  },
  SKU_NOT_ADDED_MESSAGE:
    "Sorry! $count $product in your bag has gone out of stock and couldn't be added.",
  SKU_NOT_ADDED_MESSAGE_HSN_ERROR:
    "Ooops! Currently this product is out of stock so couldn't be added.",
  LIMIT_BREACH_MESSAGE: "You can add only $count of $name",
  PINCODE_SCREEN_TITLE: "Enter your pincode to view shipping options",
  PAYMENT_FAILURE: {
    TITLE: "YOUR ORDER WAS NOT PLACED",
    MESSAGE: "Something went wrong. Please try again.",
    LOYALTY_ERROR: "The deducted Loyalty points will reflect back in your account within 2 hours.",
  },
  NO_PRODUCTS: "Oops! Your Cart is Empty",
  ADDRESS_NOT_SELECTED: "Please select a delivery address",
  DELIVERY_MISMATCH: "Oops, one of your delivery selected isn't valid anymore",
  ERROR_MESSAGE: "Oops! Something went wrong.",
  OOS_TOASTR: "Please remove out of stock items to continue",
  NON_DELIVERABLE_TOASTR:
    "Sorry! One or more items are not serviceable to your pincode. Try removing them to continue",
  SELECT_DELIVERY_METHOD: "Select Delivery method for each product",
  ADDRESS: {
    PINCODE_TITLE: "Shipping options for ",
    DELIVERY_ADDRESS_TITLE: "Delivery Address",
    SAVED_ADDRESSES_TITLE: "Saved Addresses",
    NEW_DELIVERY_ADDRESS_TITLE: "Add Address",
    CHANGE_ADDRESS_TITLE: "Change Address",
    DEFAULT_ADDRESS_TEXT: " (default)",
    SET_AS_DEFAULT_TEXT: "Set this as the default address",
    CITY_TEXT: "City",
    STATE_TEXT: "State",
    ADDRESS_TEXT: "Address ",
    LANDMARK_TEXT: "Landmark ",
    PINCODE_TEXT: "Pincode ",
    NAME_TEXT: "Your Name ",
    MOBILE_NO_TEXT: "Mobile number ",
    ALTERNATE_MOBILE_NO_TEXT: "Alternate Number",
    FILL_MANDATORY_FIELDS: "Please fill all the mandatory fields",
    NO_VALID_PINCODE: "Please enter a valid pincode",
    NOT_DELIVERABLE: "Sorry! we currently don't deliver to this pincode",
  },
  COUPON_TITLE: {
    APPLY: "APPLY PROMO CODE",
    CHANGE: "CHANGE PROMO CODE",
  },
  COUPON_MESSAGE: {
    SUCCESS: "Woot! You just got a discount",
    REMOVE: "Coupon has been removed from your bag",
    INVALID:
      "Oh no! This coupon isn't valid for your bag. Please check terms and conditions of the coupon",
  },
  STORE: {
    STORES_NEARBY: "store$count nearby",
    MORE_AVAILABLE_STORES: {
      SINGLE: "more store",
      PLURAL: "more stores",
    },
  },
  PRODUCT: {
    OOS: {
      TITLE: "Sorry! ",
      MESSAGE: "This product is Out of Stock",
    },
    NOT_SERVICEABLE: {
      TITLE: "Sorry! ",
      MESSAGE: "Delivery / Store option is not available for this location",
    },
    REMOVE: "This product has been successfully removed",
    FAVORITE: "This product has been successfully favorited",
    QTY_UPDATE: "The quantity has been successfully updated",
  },
  ORDER_CONFIRMATION: {
    NO_ORDER_ID_FOUND: "This orderId doesn't exist",
  },
};
const CHECKOUT_IMAGES = {
  PROMOTION:
    "https://s3-ap-southeast-1.amazonaws.com/web-resources-odin/promoicon.png",
};
const CHECKOUT_API_ACTIONS = {
  UPDATE: "update",
  DELETE: "delete",
  READ: "read",
};
const CHECKOUT_DELIVERY_OPTIONS = {
  DUMMY_TEXT: "Other Delivery Options Available",
  STOREPICK: "STOREPICK",
  DUMMY: "DUMMY",
  STANDARD: "standard",
  NDD: "ndd",
  SDD: "sdd",
};

const CHECKOUT_EXTRA_KEYS_FOR_CART = {
  FAVORITE: ["styleId", "productId"],
  REMOVE: ["productId"],
};
const STAYINPAYMENT = process.env.REACT_APP_STAYINPAYMENT === "true";

const CHECKOUT_EMPTY_STATE = {
  IMAGE_ALT_TEXT: "Empty bag here",
};

const DELIVERY_CHARGES = {
  TITLE: "Delivery Charges",
};

export {
  STAYINPAYMENT,
  CHECKOUT_EXTRA_KEYS_FOR_CART,
  CHECKOUTPAGE,
  CHECKOUT_BUTTON_TEXTS,
  CHECKOUT_BASE_REQUEST,
  CHECKOUT_PATHNAMES,
  CHECKOUT_ROUTES,
  CHECKOUT_CONFIG,
  CHECKOUT_MESSAGES,
  CHECKOUT_IMAGES,
  CHECKOUT_API_ACTIONS,
  CHECKOUT_DELIVERY_OPTIONS,
  CHECKOUT_EMPTY_STATE,
  DELIVERY_CHARGES,
};
