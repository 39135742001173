import { observable } from "mobx";
import NoResultsActions from "./actions";

class NoResultsStoreClass extends NoResultsActions {
  @observable loading = false;
  @observable newArrivalData = [];
  @observable onSaleData = [];
}

export default NoResultsStoreClass;
