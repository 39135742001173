const FAVORITES = {
  EMPTY_MSG: "Got your eye on something?",
  EMPTY_SUBMSG_LINE1: "Add it to your favourites till you decide,",
  EMPTY_SUBMSG_LINE2: "you've just got to have it",
  SHOP_NNNOW_BTN: "SHOP NNNOW",
  PRODUCT_DELETED: "The product is deleted from here.",
  PRODUCT_ADDED: "We've added this product to your bag",
  COLOR_LABEL: "Color",
  FAV_TITLE: "MY FAVOURITES",
  OUT_OF_STOCK: "OUT OF STOCK",
  CTAS: {
    MOVE_TO_BAG: "Move to Bag",
    REMOVE: "Remove",
    SHARE: "Share",
  },
  SIZE_TEXT:"Size",
  BRAND_SIZE:"Brand Size",
};

export { FAVORITES };
