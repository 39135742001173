const MYACCOUNT = {
  INFO_FORM_LABELS: {
    EMAIL: "Email",
    NAME: "Name",
    PHONE: "Phone Number",
    PASSWORD: "Password",
  },
  CTAS: {
    CHANGE: "change",
    EDIT: "edit",
    SAVE: "save",
    SAVING: "saving",
  },
  PLACEHOLDERS: {
    USER_NAME: "So we know how to call you",
    EMAIL: "For a secure shopping experience",
    PHONE_NUMBER: "So we can save your info",
    PASSWORD: "So you can sign in",
  },
  SHOW_SUBTITLES: false,
};

const CHANGE_PASSWORD = {
  title: "Change Password",
  currentPasswordText: "Current Password",
  newPasswordText: "New Password",
  confirmPasswordText: "Confirm Password",
  MISMATCH_ERROR_MSG: "Passwords do not match",
  SUBMIT: "CHANGE PASSWORD",
};

const MY_ACCOUNT_MENU = [
  {
    color: "blue",
    iconName: "icomoon-person1",
    title: "My Information",
    type: "user-information",
    subtitle: "Update your name and password.",
  },
  {
    color: "loyalty",
    iconName: "icomoon-loyalty_main",
    title: "My Loyalty",
    type: "loyalty",
    subtitle: "Loyalty points details",
    hash: "#loyalty",
  },
  {
    color: "green",
    iconName: "icomoon-home",
    title: "My Addresses",
    type: "address",
    subtitle: "Managing your existing addresses or add a new one.",
  },
  {
    color: "yellow",
    iconName: "icomoon-credit-card",
    title: "My Payment Methods",
    type: "payment-methods",
    subtitle: "Manage your credit and debit cards.",
  },
];

const ACCOUNT_SAVED_CARDS = {
  CARD_NUMBER: "CARD NUMBER",
  VALID: "VALID TRHOUGH",
  REMOVE_BUTTON: "Remove",
  NO_CARD: "You have not set up any payment methods yet.",
};

const ACCOUNT_ADDRESS_LIST = {
  noAddressComponentTitle: "No Addresses Here",
  noAddressComponentSubHeader:
    "Start shopping for your shipping address to appear",
  addAddressButton: "ADD ADDRESS",
};

const IFSC_CODE = {
  invalidIFSC: "Not a valid IFSC Code, please check",
};

const USER_NAME_INPUT = {
  invalidUserName: "Name must have only alphabets",
  minmumCharacters: "Plug in atleast 3 characters",
};

const FAVORITES = {
  EMPTY_MSG: "Got your eye on something?",
  EMPTY_SUBMSG_LINE1: "Add it to this Short List till you decide",
  EMPTY_SUBMSG_LINE2: "you've just got to have it",
  SHOP_NNNOW_BTN: "SHOP NNNOW",
  PRODUCT_DELETED: "The product is deleted from here.",
  PRODUCT_ADDED: "We've added this product to your bag",
  COLOR_LABEL: "Color",
  FAV_TITLE: "MY FAVORITES",
  CTAS: {
    MOVE_TO_BAG: "Move to Bag",
    REMOVE: "Remove",
    SHARE: "Share",
  },
};

const LOYALTY = {
  NO_LOYALTY_MSG: "No Loyalty Data Available",
  MORE_INFO: "More Info",
  TIER: "Tier",
  POINTS: "Points",
  VALUE: "Value",
  LOYALTY_TIER: "Tier",
  UPGRADE_CRITERIA: "Upgrade Criteria",
  ELIGIBILITY_CRITERIA: "Eligibility Criteria",
  EARN: "Earn",
  BURN: "Burn",
  CONVERSION: "Conversion",
  EXPIRY: "Expiry",
  TIER_DOWNGRADE: "Tier Downgrade",
  MORE_INFO_TITLE_SUFFIX: "Loyalty Program",
  NO_TIERS_MSG: "No Loyalty tiers found for this brand",
};

export {
  MYACCOUNT,
  CHANGE_PASSWORD,
  MY_ACCOUNT_MENU,
  ACCOUNT_SAVED_CARDS,
  ACCOUNT_ADDRESS_LIST,
  IFSC_CODE,
  USER_NAME_INPUT,
  FAVORITES,
  LOYALTY,
};
