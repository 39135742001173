import React from "react";
import { footerUrls } from "npmlinks-constants";
import Anchor from "../../Anchor";
import { GTM } from "../../../analytics";
import GTMEvent from "../../../analytics/eventFactory/GTMEvents";

function triggerGTM(e, eventName) {
  GTM.pushEvent(eventName);
}

function FooterNav() {
  return (
    <div className="nw-footer-nav-container">
      <Anchor.component
        target="_self"
        id="nw-footer-nav-links-whoweare"
        className="nw-footer-nav-links"
        href={footerUrls.whoweare}
        to={footerUrls.whoweare}
      >
        WHO WE ARE
      </Anchor.component>
      <Anchor.component
        target="_self"
        id="nw-footer-nav-links-customercare"
        className="nw-footer-nav-links"
        href={footerUrls.customercare}
        to={footerUrls.customercare}
      >
        FREQUENTLY ASKED QUESTIONS
      </Anchor.component>
      <Anchor.component
        target="_self"
        id="nw-footer-nav-links-contactus"
        className="nw-footer-nav-links"
        href={footerUrls.contactus}
        to={footerUrls.contactus}
        onClick={e => triggerGTM(e,GTMEvent.CONTACTUS_CLICK)}
      >
        CONTACT US
      </Anchor.component>
    </div>
  );
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FooterNav,
  component: FooterNav,
};

export default ProjectComponent;
