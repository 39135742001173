import OrderConfirmationActions from "./actions";
import { observable } from "mobx";

class OrderConfirmationClass extends OrderConfirmationActions {
  constructor() {
    super();
  }
  @observable isLoader = true;
  @observable orderConfirmationData = null;
}

export default OrderConfirmationClass;
