import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import BackToTop from "./BackToTop";
import EmailSubscription from "./EmailSubscription";
import SocialMediaLinks from "./SocialMediaLinks";
import FooterNav from "./FooterNav";
import FinePrintLinks from "./FinePrintLinks";
import FooterLinks from "./FooterLinks";
import CheckoutFooter from "./CheckoutFooter";
import FooterDocs from "./FooterDocs";
import { isApp, getBaseRouteForPath, isIframe, isPdpPage } from "../../utils";
import { FOOTER_HIDDEN_PATHS, STATIC_FOOTER_PATHS } from "npmlinks-constants";
import PdpFooter from "./PdpFooter";

@inject("FooterStore", "ProductStore", "CommonStore")
@observer
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false
    };
  }

  componentDidMount() {
    this.props.FooterStore.getFooterLinkData().then(() => {
      console.log("data is avail from api");
    });

    this.setState({
      isMounted: true
    });
  }
  componentDidCatch(error, errorInfo) {
    console.error(`error in footer ${error} ${errorInfo} ${this}`);
  }

  get hideFooterClassName() {
    return this.props.FooterStore.isFooterDefaultVisible ||
      !this.props.ProductStore.isHavingMoreProducts
      ? ""
      : "nwc-hide";
  }
  get staticFooter() {
    return STATIC_FOOTER_PATHS.includes(
      getBaseRouteForPath(this.props.location.pathname)
    );
  }
  get hiddenFooter() {
    return FOOTER_HIDDEN_PATHS.includes(
      getBaseRouteForPath(this.props.location.pathname)
    );
  }

  get footerComponents() {
    const { FooterStore } = this.props;
    return (
      <footer className="nw-footer-wrapper">
        <BackToTop.component />
        <EmailSubscription.component />
        <FooterDocs.component />
        <FooterNav.component store={FooterStore} />
        <SocialMediaLinks.component />
        <FinePrintLinks.component store={FooterStore} />
        <FooterLinks.component footerLinkData={FooterStore.footerlinksData} />
      </footer>
    );
  }

  render() {
    const { FooterStore } = this.props;
    const { isMounted } = this.state;

    if (isApp || isIframe || this.hiddenFooter || !isMounted) {
      return null;
    } else if (this.staticFooter) {
      return (
        <footer className="nw-footer-wrapper">
          <CheckoutFooter.component />
        </footer>
      );
    } else if (isPdpPage(window.location.pathname)) {
      return (
        <div>
          <div className="nw-pdp-footermobileonly">
            <PdpFooter.component FooterStore={FooterStore} />
          </div>
          <div className="nw-pdp-footerdesktoponly">
            {this.footerComponents}
          </div>
        </div>
      );
    }

    return this.footerComponents;
  }
}

Footer.wrappedComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  FooterStore: PropTypes.shape({
    footerlinksData: PropTypes.object,
    footerData: PropTypes.object,
    getFooterLinkData: PropTypes.func,
    isFooterDefaultVisible: PropTypes.bool.isRequired
  }).isRequired,
  ProductStore: PropTypes.shape({
    isHavingMoreProducts: PropTypes.bool
  }).isRequired
};

export { default as BackToTop } from "./BackToTop";
export { default as CheckoutFooter } from "./CheckoutFooter";
export { default as EmailSubscription } from "./EmailSubscription";
export { default as FinePrintLinks } from "./FinePrintLinks";
export { default as FooterLinkList } from "./FooterLinkList";
export { default as FooterLinks } from "./FooterLinks";
export { default as FooterNav } from "./FooterNav";
export { default as SocialMediaLinks } from "./SocialMediaLinks";
export { default as FooterDocs } from "./FooterDocs";

const getFinalComponent = component =>
  inject("FooterStore", "ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: Footer.wrappedComponent,
  component: Footer
};

export default ProjectComponent;
