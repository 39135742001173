import React from "react";
import { footerUrls, Endpoints } from "npmlinks-constants";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import Anchor from "../../Anchor";

function mediaClick(e, socialMedia) {
  e.preventDefault();
  Analytics.trackEvent({
    action: socialMedia,
    category: Event.category.FOOTER
  });

  window.location = e.target.parentElement.href;
}

function twitterClick(e, socialMedia) {
  e.preventDefault();
  Analytics.trackEvent({
    action: socialMedia,
    category: Event.category.FOOTER
  });
  
  window.location = "https://twitter.com/heynnnow";
}
function SocialMediaLinks() {
  return (
    <div className="nw-social-media-container">
      <Anchor.component
        className="nw-social-media-links"
        href={footerUrls.facebook}
        to={footerUrls.facebook}
        onClick={e => mediaClick(e, Event.action.FB_CLICK)}
      >
        <i className="icomoon-facebook" />
      </Anchor.component>
      <Anchor.component
        className="nw-social-media-links"
        href={footerUrls.intagram}
        to={footerUrls.intagram}
        onClick={e => mediaClick(e, Event.action.INSTA_CLICK)}
      >
        <i className="icomoon-instagram" />
      </Anchor.component>
      <Anchor.component
        className="nw-social-media-links"
        href={footerUrls.twitter}
        to={footerUrls.twitter}
        onClick={e => twitterClick(e, Event.action.TWITTER_CLICK)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
        </svg>     
      </Anchor.component>
      <Anchor.component
        className="nw-social-media-links"
        href={footerUrls.youtube}
        to={footerUrls.youtube}
        onClick={e => mediaClick(e, Event.action.YOUTUBE_CLICK)}
      >
        <img className="nw-youtube-logo" src={Endpoints.youtubeLogo} alt="youtube"/>
      </Anchor.component>
    </div>
  );
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SocialMediaLinks,
  component: SocialMediaLinks
};

export default ProjectComponent;
